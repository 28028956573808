.ol-box {
  box-sizing: border-box;
  background-color: #fff6;
  border: 1.5px solid #b3c5db;
  border-radius: 2px;
}

.ol-mouse-position {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ol-scale-line {
  background: #003c884d;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-line-inner {
  color: #eee;
  text-align: center;
  will-change: contents, width;
  border: 1px solid #eee;
  border-top: none;
  margin: 1px;
  font-size: 10px;
  transition: all .25s;
}

.ol-scale-singlebar-even {
  background-color: #000;
}

.ol-scale-singlebar-odd {
  background-color: #fff;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-step-marker {
  float: right;
  z-index: 10;
  background-color: #000;
  width: 1px;
  height: 15px;
}

.ol-scale-step-text {
  z-index: 11;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 12px;
  position: absolute;
  bottom: -5px;
}

.ol-scale-text {
  text-align: center;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 14px;
  position: absolute;
  bottom: 25px;
}

.ol-scale-singlebar {
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid #000;
  height: 10px;
  position: relative;
}

.ol-unsupported {
  display: none;
}

.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
}

.ol-viewport canvas {
  all: unset;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  background-color: #fff6;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
}

.ol-control:hover {
  background-color: #fff9;
}

.ol-zoom {
  top: .5em;
  left: .5em;
}

.ol-rotate {
  transition: opacity .25s linear, visibility linear;
  top: .5em;
  right: .5em;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}

.ol-full-screen {
  top: .5em;
  right: .5em;
}

.ol-control button {
  color: #fff;
  font-weight: bold;
  font-size: inherit;
  text-align: center;
  background-color: #003c8880;
  border: none;
  border-radius: 2px;
  width: 1.375em;
  height: 1.375em;
  margin: 1px;
  padding: 0;
  line-height: .4em;
  text-decoration: none;
  display: block;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  will-change: transform;
  font-size: 1.2em;
  font-weight: normal;
  display: block;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:hover, .ol-control button:focus {
  background-color: #003c88b3;
  text-decoration: none;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  flex-flow: row-reverse;
  align-items: center;
  max-width: calc(100% - 1.3em);
  display: flex;
  bottom: .5em;
  right: .5em;
}

.ol-attribution a {
  color: #003c88b3;
  text-decoration: none;
}

.ol-attribution ul {
  color: #000;
  text-shadow: 0 0 2px #fff;
  margin: 0;
  padding: 1px .5em;
  font-size: 12px;
}

.ol-attribution li {
  list-style: none;
  display: inline;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-width: inherit;
  vertical-align: middle;
  max-height: 2em;
}

.ol-attribution button {
  flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: #fffc;
}

.ol-attribution.ol-uncollapsible {
  border-radius: 4px 0 0;
  bottom: 0;
  right: 0;
}

.ol-attribution.ol-uncollapsible img {
  max-height: 1.6em;
  margin-top: -.2em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  height: 200px;
  top: 4.5em;
  left: .5em;
}

.ol-zoomslider button {
  height: 10px;
  position: relative;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  bottom: .5em;
  left: .5em;
}

.ol-overviewmap.ol-uncollapsible {
  border-radius: 0 4px 0 0;
  bottom: 0;
  left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
  display: block;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  width: 150px;
  height: 150px;
  margin: 2px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: #fffc;
}

.ol-overviewmap-box {
  border: 2px dotted #003c88b3;
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

@font-face {
  font-family: Material Design Icons;
  src: url("materialdesignicons-webfont.95c2af92.eot");
  src: url("materialdesignicons-webfont.95c2af92.eot#iefix&v=7.4.47") format("embedded-opentype"), url("materialdesignicons-webfont.96ffe6e9.woff2") format("woff2"), url("materialdesignicons-webfont.fe6d5e0f.woff") format("woff"), url("materialdesignicons-webfont.15fc987b.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.mdi:before, .mdi-set {
  font: 24px / 1 Material Design Icons;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.mdi-ab-testing:before {
  content: "󰇉";
}

.mdi-abacus:before {
  content: "󱛠";
}

.mdi-abjad-arabic:before {
  content: "󱌨";
}

.mdi-abjad-hebrew:before {
  content: "󱌩";
}

.mdi-abugida-devanagari:before {
  content: "󱌪";
}

.mdi-abugida-thai:before {
  content: "󱌫";
}

.mdi-access-point:before {
  content: "󰀃";
}

.mdi-access-point-check:before {
  content: "󱔸";
}

.mdi-access-point-minus:before {
  content: "󱔹";
}

.mdi-access-point-network:before {
  content: "󰀂";
}

.mdi-access-point-network-off:before {
  content: "󰯡";
}

.mdi-access-point-off:before {
  content: "󱔑";
}

.mdi-access-point-plus:before {
  content: "󱔺";
}

.mdi-access-point-remove:before {
  content: "󱔻";
}

.mdi-account:before {
  content: "󰀄";
}

.mdi-account-alert:before {
  content: "󰀅";
}

.mdi-account-alert-outline:before {
  content: "󰭐";
}

.mdi-account-arrow-down:before {
  content: "󱡨";
}

.mdi-account-arrow-down-outline:before {
  content: "󱡩";
}

.mdi-account-arrow-left:before {
  content: "󰭑";
}

.mdi-account-arrow-left-outline:before {
  content: "󰭒";
}

.mdi-account-arrow-right:before {
  content: "󰭓";
}

.mdi-account-arrow-right-outline:before {
  content: "󰭔";
}

.mdi-account-arrow-up:before {
  content: "󱡧";
}

.mdi-account-arrow-up-outline:before {
  content: "󱡪";
}

.mdi-account-badge:before {
  content: "󱬊";
}

.mdi-account-badge-outline:before {
  content: "󱬋";
}

.mdi-account-box:before {
  content: "󰀆";
}

.mdi-account-box-edit-outline:before {
  content: "󱳈";
}

.mdi-account-box-minus-outline:before {
  content: "󱳉";
}

.mdi-account-box-multiple:before {
  content: "󰤴";
}

.mdi-account-box-multiple-outline:before {
  content: "󱀊";
}

.mdi-account-box-outline:before {
  content: "󰀇";
}

.mdi-account-box-plus-outline:before {
  content: "󱳊";
}

.mdi-account-cancel:before {
  content: "󱋟";
}

.mdi-account-cancel-outline:before {
  content: "󱋠";
}

.mdi-account-card:before {
  content: "󱮤";
}

.mdi-account-card-outline:before {
  content: "󱮥";
}

.mdi-account-cash:before {
  content: "󱂗";
}

.mdi-account-cash-outline:before {
  content: "󱂘";
}

.mdi-account-check:before {
  content: "󰀈";
}

.mdi-account-check-outline:before {
  content: "󰯢";
}

.mdi-account-child:before {
  content: "󰪉";
}

.mdi-account-child-circle:before {
  content: "󰪊";
}

.mdi-account-child-outline:before {
  content: "󱃈";
}

.mdi-account-circle:before {
  content: "󰀉";
}

.mdi-account-circle-outline:before {
  content: "󰭕";
}

.mdi-account-clock:before {
  content: "󰭖";
}

.mdi-account-clock-outline:before {
  content: "󰭗";
}

.mdi-account-cog:before {
  content: "󱍰";
}

.mdi-account-cog-outline:before {
  content: "󱍱";
}

.mdi-account-convert:before {
  content: "󰀊";
}

.mdi-account-convert-outline:before {
  content: "󱌁";
}

.mdi-account-cowboy-hat:before {
  content: "󰺛";
}

.mdi-account-cowboy-hat-outline:before {
  content: "󱟳";
}

.mdi-account-credit-card:before {
  content: "󱮦";
}

.mdi-account-credit-card-outline:before {
  content: "󱮧";
}

.mdi-account-details:before {
  content: "󰘱";
}

.mdi-account-details-outline:before {
  content: "󱍲";
}

.mdi-account-edit:before {
  content: "󰚼";
}

.mdi-account-edit-outline:before {
  content: "󰿻";
}

.mdi-account-eye:before {
  content: "󰐠";
}

.mdi-account-eye-outline:before {
  content: "󱉻";
}

.mdi-account-file:before {
  content: "󱲧";
}

.mdi-account-file-outline:before {
  content: "󱲨";
}

.mdi-account-file-text:before {
  content: "󱲩";
}

.mdi-account-file-text-outline:before {
  content: "󱲪";
}

.mdi-account-filter:before {
  content: "󰤶";
}

.mdi-account-filter-outline:before {
  content: "󰾝";
}

.mdi-account-group:before {
  content: "󰡉";
}

.mdi-account-group-outline:before {
  content: "󰭘";
}

.mdi-account-hard-hat:before {
  content: "󰖵";
}

.mdi-account-hard-hat-outline:before {
  content: "󱨟";
}

.mdi-account-heart:before {
  content: "󰢙";
}

.mdi-account-heart-outline:before {
  content: "󰯣";
}

.mdi-account-injury:before {
  content: "󱠕";
}

.mdi-account-injury-outline:before {
  content: "󱠖";
}

.mdi-account-key:before {
  content: "󰀋";
}

.mdi-account-key-outline:before {
  content: "󰯤";
}

.mdi-account-lock:before {
  content: "󱅞";
}

.mdi-account-lock-open:before {
  content: "󱥠";
}

.mdi-account-lock-open-outline:before {
  content: "󱥡";
}

.mdi-account-lock-outline:before {
  content: "󱅟";
}

.mdi-account-minus:before {
  content: "󰀍";
}

.mdi-account-minus-outline:before {
  content: "󰫬";
}

.mdi-account-multiple:before {
  content: "󰀎";
}

.mdi-account-multiple-check:before {
  content: "󰣅";
}

.mdi-account-multiple-check-outline:before {
  content: "󱇾";
}

.mdi-account-multiple-minus:before {
  content: "󰗓";
}

.mdi-account-multiple-minus-outline:before {
  content: "󰯥";
}

.mdi-account-multiple-outline:before {
  content: "󰀏";
}

.mdi-account-multiple-plus:before {
  content: "󰀐";
}

.mdi-account-multiple-plus-outline:before {
  content: "󰠀";
}

.mdi-account-multiple-remove:before {
  content: "󱈊";
}

.mdi-account-multiple-remove-outline:before {
  content: "󱈋";
}

.mdi-account-music:before {
  content: "󰠃";
}

.mdi-account-music-outline:before {
  content: "󰳩";
}

.mdi-account-network:before {
  content: "󰀑";
}

.mdi-account-network-off:before {
  content: "󱫱";
}

.mdi-account-network-off-outline:before {
  content: "󱫲";
}

.mdi-account-network-outline:before {
  content: "󰯦";
}

.mdi-account-off:before {
  content: "󰀒";
}

.mdi-account-off-outline:before {
  content: "󰯧";
}

.mdi-account-outline:before {
  content: "󰀓";
}

.mdi-account-plus:before {
  content: "󰀔";
}

.mdi-account-plus-outline:before {
  content: "󰠁";
}

.mdi-account-question:before {
  content: "󰭙";
}

.mdi-account-question-outline:before {
  content: "󰭚";
}

.mdi-account-reactivate:before {
  content: "󱔫";
}

.mdi-account-reactivate-outline:before {
  content: "󱔬";
}

.mdi-account-remove:before {
  content: "󰀕";
}

.mdi-account-remove-outline:before {
  content: "󰫭";
}

.mdi-account-school:before {
  content: "󱨠";
}

.mdi-account-school-outline:before {
  content: "󱨡";
}

.mdi-account-search:before {
  content: "󰀖";
}

.mdi-account-search-outline:before {
  content: "󰤵";
}

.mdi-account-settings:before {
  content: "󰘰";
}

.mdi-account-settings-outline:before {
  content: "󱃉";
}

.mdi-account-star:before {
  content: "󰀗";
}

.mdi-account-star-outline:before {
  content: "󰯨";
}

.mdi-account-supervisor:before {
  content: "󰪋";
}

.mdi-account-supervisor-circle:before {
  content: "󰪌";
}

.mdi-account-supervisor-circle-outline:before {
  content: "󱓬";
}

.mdi-account-supervisor-outline:before {
  content: "󱄭";
}

.mdi-account-switch:before {
  content: "󰀙";
}

.mdi-account-switch-outline:before {
  content: "󰓋";
}

.mdi-account-sync:before {
  content: "󱤛";
}

.mdi-account-sync-outline:before {
  content: "󱤜";
}

.mdi-account-tag:before {
  content: "󱰛";
}

.mdi-account-tag-outline:before {
  content: "󱰜";
}

.mdi-account-tie:before {
  content: "󰳣";
}

.mdi-account-tie-hat:before {
  content: "󱢘";
}

.mdi-account-tie-hat-outline:before {
  content: "󱢙";
}

.mdi-account-tie-outline:before {
  content: "󱃊";
}

.mdi-account-tie-voice:before {
  content: "󱌈";
}

.mdi-account-tie-voice-off:before {
  content: "󱌊";
}

.mdi-account-tie-voice-off-outline:before {
  content: "󱌋";
}

.mdi-account-tie-voice-outline:before {
  content: "󱌉";
}

.mdi-account-tie-woman:before {
  content: "󱪌";
}

.mdi-account-voice:before {
  content: "󰗋";
}

.mdi-account-voice-off:before {
  content: "󰻔";
}

.mdi-account-wrench:before {
  content: "󱢚";
}

.mdi-account-wrench-outline:before {
  content: "󱢛";
}

.mdi-adjust:before {
  content: "󰀚";
}

.mdi-advertisements:before {
  content: "󱤪";
}

.mdi-advertisements-off:before {
  content: "󱤫";
}

.mdi-air-conditioner:before {
  content: "󰀛";
}

.mdi-air-filter:before {
  content: "󰵃";
}

.mdi-air-horn:before {
  content: "󰶬";
}

.mdi-air-humidifier:before {
  content: "󱂙";
}

.mdi-air-humidifier-off:before {
  content: "󱑦";
}

.mdi-air-purifier:before {
  content: "󰵄";
}

.mdi-air-purifier-off:before {
  content: "󱭗";
}

.mdi-airbag:before {
  content: "󰯩";
}

.mdi-airballoon:before {
  content: "󰀜";
}

.mdi-airballoon-outline:before {
  content: "󱀋";
}

.mdi-airplane:before {
  content: "󰀝";
}

.mdi-airplane-alert:before {
  content: "󱡺";
}

.mdi-airplane-check:before {
  content: "󱡻";
}

.mdi-airplane-clock:before {
  content: "󱡼";
}

.mdi-airplane-cog:before {
  content: "󱡽";
}

.mdi-airplane-edit:before {
  content: "󱡾";
}

.mdi-airplane-landing:before {
  content: "󰗔";
}

.mdi-airplane-marker:before {
  content: "󱡿";
}

.mdi-airplane-minus:before {
  content: "󱢀";
}

.mdi-airplane-off:before {
  content: "󰀞";
}

.mdi-airplane-plus:before {
  content: "󱢁";
}

.mdi-airplane-remove:before {
  content: "󱢂";
}

.mdi-airplane-search:before {
  content: "󱢃";
}

.mdi-airplane-settings:before {
  content: "󱢄";
}

.mdi-airplane-takeoff:before {
  content: "󰗕";
}

.mdi-airport:before {
  content: "󰡋";
}

.mdi-alarm:before {
  content: "󰀠";
}

.mdi-alarm-bell:before {
  content: "󰞎";
}

.mdi-alarm-check:before {
  content: "󰀡";
}

.mdi-alarm-light:before {
  content: "󰞏";
}

.mdi-alarm-light-off:before {
  content: "󱜞";
}

.mdi-alarm-light-off-outline:before {
  content: "󱜟";
}

.mdi-alarm-light-outline:before {
  content: "󰯪";
}

.mdi-alarm-multiple:before {
  content: "󰀢";
}

.mdi-alarm-note:before {
  content: "󰹱";
}

.mdi-alarm-note-off:before {
  content: "󰹲";
}

.mdi-alarm-off:before {
  content: "󰀣";
}

.mdi-alarm-panel:before {
  content: "󱗄";
}

.mdi-alarm-panel-outline:before {
  content: "󱗅";
}

.mdi-alarm-plus:before {
  content: "󰀤";
}

.mdi-alarm-snooze:before {
  content: "󰚎";
}

.mdi-album:before {
  content: "󰀥";
}

.mdi-alert:before {
  content: "󰀦";
}

.mdi-alert-box:before {
  content: "󰀧";
}

.mdi-alert-box-outline:before {
  content: "󰳤";
}

.mdi-alert-circle:before {
  content: "󰀨";
}

.mdi-alert-circle-check:before {
  content: "󱇭";
}

.mdi-alert-circle-check-outline:before {
  content: "󱇮";
}

.mdi-alert-circle-outline:before {
  content: "󰗖";
}

.mdi-alert-decagram:before {
  content: "󰚽";
}

.mdi-alert-decagram-outline:before {
  content: "󰳥";
}

.mdi-alert-minus:before {
  content: "󱒻";
}

.mdi-alert-minus-outline:before {
  content: "󱒾";
}

.mdi-alert-octagon:before {
  content: "󰀩";
}

.mdi-alert-octagon-outline:before {
  content: "󰳦";
}

.mdi-alert-octagram:before {
  content: "󰝧";
}

.mdi-alert-octagram-outline:before {
  content: "󰳧";
}

.mdi-alert-outline:before {
  content: "󰀪";
}

.mdi-alert-plus:before {
  content: "󱒺";
}

.mdi-alert-plus-outline:before {
  content: "󱒽";
}

.mdi-alert-remove:before {
  content: "󱒼";
}

.mdi-alert-remove-outline:before {
  content: "󱒿";
}

.mdi-alert-rhombus:before {
  content: "󱇎";
}

.mdi-alert-rhombus-outline:before {
  content: "󱇏";
}

.mdi-alien:before {
  content: "󰢚";
}

.mdi-alien-outline:before {
  content: "󱃋";
}

.mdi-align-horizontal-center:before {
  content: "󱇃";
}

.mdi-align-horizontal-distribute:before {
  content: "󱥢";
}

.mdi-align-horizontal-left:before {
  content: "󱇂";
}

.mdi-align-horizontal-right:before {
  content: "󱇄";
}

.mdi-align-vertical-bottom:before {
  content: "󱇅";
}

.mdi-align-vertical-center:before {
  content: "󱇆";
}

.mdi-align-vertical-distribute:before {
  content: "󱥣";
}

.mdi-align-vertical-top:before {
  content: "󱇇";
}

.mdi-all-inclusive:before {
  content: "󰚾";
}

.mdi-all-inclusive-box:before {
  content: "󱢍";
}

.mdi-all-inclusive-box-outline:before {
  content: "󱢎";
}

.mdi-allergy:before {
  content: "󱉘";
}

.mdi-alpha:before {
  content: "󰀫";
}

.mdi-alpha-a:before {
  content: "󰫮";
}

.mdi-alpha-a-box:before {
  content: "󰬈";
}

.mdi-alpha-a-box-outline:before {
  content: "󰯫";
}

.mdi-alpha-a-circle:before {
  content: "󰯬";
}

.mdi-alpha-a-circle-outline:before {
  content: "󰯭";
}

.mdi-alpha-b:before {
  content: "󰫯";
}

.mdi-alpha-b-box:before {
  content: "󰬉";
}

.mdi-alpha-b-box-outline:before {
  content: "󰯮";
}

.mdi-alpha-b-circle:before {
  content: "󰯯";
}

.mdi-alpha-b-circle-outline:before {
  content: "󰯰";
}

.mdi-alpha-c:before {
  content: "󰫰";
}

.mdi-alpha-c-box:before {
  content: "󰬊";
}

.mdi-alpha-c-box-outline:before {
  content: "󰯱";
}

.mdi-alpha-c-circle:before {
  content: "󰯲";
}

.mdi-alpha-c-circle-outline:before {
  content: "󰯳";
}

.mdi-alpha-d:before {
  content: "󰫱";
}

.mdi-alpha-d-box:before {
  content: "󰬋";
}

.mdi-alpha-d-box-outline:before {
  content: "󰯴";
}

.mdi-alpha-d-circle:before {
  content: "󰯵";
}

.mdi-alpha-d-circle-outline:before {
  content: "󰯶";
}

.mdi-alpha-e:before {
  content: "󰫲";
}

.mdi-alpha-e-box:before {
  content: "󰬌";
}

.mdi-alpha-e-box-outline:before {
  content: "󰯷";
}

.mdi-alpha-e-circle:before {
  content: "󰯸";
}

.mdi-alpha-e-circle-outline:before {
  content: "󰯹";
}

.mdi-alpha-f:before {
  content: "󰫳";
}

.mdi-alpha-f-box:before {
  content: "󰬍";
}

.mdi-alpha-f-box-outline:before {
  content: "󰯺";
}

.mdi-alpha-f-circle:before {
  content: "󰯻";
}

.mdi-alpha-f-circle-outline:before {
  content: "󰯼";
}

.mdi-alpha-g:before {
  content: "󰫴";
}

.mdi-alpha-g-box:before {
  content: "󰬎";
}

.mdi-alpha-g-box-outline:before {
  content: "󰯽";
}

.mdi-alpha-g-circle:before {
  content: "󰯾";
}

.mdi-alpha-g-circle-outline:before {
  content: "󰯿";
}

.mdi-alpha-h:before {
  content: "󰫵";
}

.mdi-alpha-h-box:before {
  content: "󰬏";
}

.mdi-alpha-h-box-outline:before {
  content: "󰰀";
}

.mdi-alpha-h-circle:before {
  content: "󰰁";
}

.mdi-alpha-h-circle-outline:before {
  content: "󰰂";
}

.mdi-alpha-i:before {
  content: "󰫶";
}

.mdi-alpha-i-box:before {
  content: "󰬐";
}

.mdi-alpha-i-box-outline:before {
  content: "󰰃";
}

.mdi-alpha-i-circle:before {
  content: "󰰄";
}

.mdi-alpha-i-circle-outline:before {
  content: "󰰅";
}

.mdi-alpha-j:before {
  content: "󰫷";
}

.mdi-alpha-j-box:before {
  content: "󰬑";
}

.mdi-alpha-j-box-outline:before {
  content: "󰰆";
}

.mdi-alpha-j-circle:before {
  content: "󰰇";
}

.mdi-alpha-j-circle-outline:before {
  content: "󰰈";
}

.mdi-alpha-k:before {
  content: "󰫸";
}

.mdi-alpha-k-box:before {
  content: "󰬒";
}

.mdi-alpha-k-box-outline:before {
  content: "󰰉";
}

.mdi-alpha-k-circle:before {
  content: "󰰊";
}

.mdi-alpha-k-circle-outline:before {
  content: "󰰋";
}

.mdi-alpha-l:before {
  content: "󰫹";
}

.mdi-alpha-l-box:before {
  content: "󰬓";
}

.mdi-alpha-l-box-outline:before {
  content: "󰰌";
}

.mdi-alpha-l-circle:before {
  content: "󰰍";
}

.mdi-alpha-l-circle-outline:before {
  content: "󰰎";
}

.mdi-alpha-m:before {
  content: "󰫺";
}

.mdi-alpha-m-box:before {
  content: "󰬔";
}

.mdi-alpha-m-box-outline:before {
  content: "󰰏";
}

.mdi-alpha-m-circle:before {
  content: "󰰐";
}

.mdi-alpha-m-circle-outline:before {
  content: "󰰑";
}

.mdi-alpha-n:before {
  content: "󰫻";
}

.mdi-alpha-n-box:before {
  content: "󰬕";
}

.mdi-alpha-n-box-outline:before {
  content: "󰰒";
}

.mdi-alpha-n-circle:before {
  content: "󰰓";
}

.mdi-alpha-n-circle-outline:before {
  content: "󰰔";
}

.mdi-alpha-o:before {
  content: "󰫼";
}

.mdi-alpha-o-box:before {
  content: "󰬖";
}

.mdi-alpha-o-box-outline:before {
  content: "󰰕";
}

.mdi-alpha-o-circle:before {
  content: "󰰖";
}

.mdi-alpha-o-circle-outline:before {
  content: "󰰗";
}

.mdi-alpha-p:before {
  content: "󰫽";
}

.mdi-alpha-p-box:before {
  content: "󰬗";
}

.mdi-alpha-p-box-outline:before {
  content: "󰰘";
}

.mdi-alpha-p-circle:before {
  content: "󰰙";
}

.mdi-alpha-p-circle-outline:before {
  content: "󰰚";
}

.mdi-alpha-q:before {
  content: "󰫾";
}

.mdi-alpha-q-box:before {
  content: "󰬘";
}

.mdi-alpha-q-box-outline:before {
  content: "󰰛";
}

.mdi-alpha-q-circle:before {
  content: "󰰜";
}

.mdi-alpha-q-circle-outline:before {
  content: "󰰝";
}

.mdi-alpha-r:before {
  content: "󰫿";
}

.mdi-alpha-r-box:before {
  content: "󰬙";
}

.mdi-alpha-r-box-outline:before {
  content: "󰰞";
}

.mdi-alpha-r-circle:before {
  content: "󰰟";
}

.mdi-alpha-r-circle-outline:before {
  content: "󰰠";
}

.mdi-alpha-s:before {
  content: "󰬀";
}

.mdi-alpha-s-box:before {
  content: "󰬚";
}

.mdi-alpha-s-box-outline:before {
  content: "󰰡";
}

.mdi-alpha-s-circle:before {
  content: "󰰢";
}

.mdi-alpha-s-circle-outline:before {
  content: "󰰣";
}

.mdi-alpha-t:before {
  content: "󰬁";
}

.mdi-alpha-t-box:before {
  content: "󰬛";
}

.mdi-alpha-t-box-outline:before {
  content: "󰰤";
}

.mdi-alpha-t-circle:before {
  content: "󰰥";
}

.mdi-alpha-t-circle-outline:before {
  content: "󰰦";
}

.mdi-alpha-u:before {
  content: "󰬂";
}

.mdi-alpha-u-box:before {
  content: "󰬜";
}

.mdi-alpha-u-box-outline:before {
  content: "󰰧";
}

.mdi-alpha-u-circle:before {
  content: "󰰨";
}

.mdi-alpha-u-circle-outline:before {
  content: "󰰩";
}

.mdi-alpha-v:before {
  content: "󰬃";
}

.mdi-alpha-v-box:before {
  content: "󰬝";
}

.mdi-alpha-v-box-outline:before {
  content: "󰰪";
}

.mdi-alpha-v-circle:before {
  content: "󰰫";
}

.mdi-alpha-v-circle-outline:before {
  content: "󰰬";
}

.mdi-alpha-w:before {
  content: "󰬄";
}

.mdi-alpha-w-box:before {
  content: "󰬞";
}

.mdi-alpha-w-box-outline:before {
  content: "󰰭";
}

.mdi-alpha-w-circle:before {
  content: "󰰮";
}

.mdi-alpha-w-circle-outline:before {
  content: "󰰯";
}

.mdi-alpha-x:before {
  content: "󰬅";
}

.mdi-alpha-x-box:before {
  content: "󰬟";
}

.mdi-alpha-x-box-outline:before {
  content: "󰰰";
}

.mdi-alpha-x-circle:before {
  content: "󰰱";
}

.mdi-alpha-x-circle-outline:before {
  content: "󰰲";
}

.mdi-alpha-y:before {
  content: "󰬆";
}

.mdi-alpha-y-box:before {
  content: "󰬠";
}

.mdi-alpha-y-box-outline:before {
  content: "󰰳";
}

.mdi-alpha-y-circle:before {
  content: "󰰴";
}

.mdi-alpha-y-circle-outline:before {
  content: "󰰵";
}

.mdi-alpha-z:before {
  content: "󰬇";
}

.mdi-alpha-z-box:before {
  content: "󰬡";
}

.mdi-alpha-z-box-outline:before {
  content: "󰰶";
}

.mdi-alpha-z-circle:before {
  content: "󰰷";
}

.mdi-alpha-z-circle-outline:before {
  content: "󰰸";
}

.mdi-alphabet-aurebesh:before {
  content: "󱌬";
}

.mdi-alphabet-cyrillic:before {
  content: "󱌭";
}

.mdi-alphabet-greek:before {
  content: "󱌮";
}

.mdi-alphabet-latin:before {
  content: "󱌯";
}

.mdi-alphabet-piqad:before {
  content: "󱌰";
}

.mdi-alphabet-tengwar:before {
  content: "󱌷";
}

.mdi-alphabetical:before {
  content: "󰀬";
}

.mdi-alphabetical-off:before {
  content: "󱀌";
}

.mdi-alphabetical-variant:before {
  content: "󱀍";
}

.mdi-alphabetical-variant-off:before {
  content: "󱀎";
}

.mdi-altimeter:before {
  content: "󰗗";
}

.mdi-ambulance:before {
  content: "󰀯";
}

.mdi-ammunition:before {
  content: "󰳨";
}

.mdi-ampersand:before {
  content: "󰪍";
}

.mdi-amplifier:before {
  content: "󰀰";
}

.mdi-amplifier-off:before {
  content: "󱆵";
}

.mdi-anchor:before {
  content: "󰀱";
}

.mdi-android:before {
  content: "󰀲";
}

.mdi-android-studio:before {
  content: "󰀴";
}

.mdi-angle-acute:before {
  content: "󰤷";
}

.mdi-angle-obtuse:before {
  content: "󰤸";
}

.mdi-angle-right:before {
  content: "󰤹";
}

.mdi-angular:before {
  content: "󰚲";
}

.mdi-angularjs:before {
  content: "󰚿";
}

.mdi-animation:before {
  content: "󰗘";
}

.mdi-animation-outline:before {
  content: "󰪏";
}

.mdi-animation-play:before {
  content: "󰤺";
}

.mdi-animation-play-outline:before {
  content: "󰪐";
}

.mdi-ansible:before {
  content: "󱂚";
}

.mdi-antenna:before {
  content: "󱄙";
}

.mdi-anvil:before {
  content: "󰢛";
}

.mdi-apache-kafka:before {
  content: "󱀏";
}

.mdi-api:before {
  content: "󱂛";
}

.mdi-api-off:before {
  content: "󱉗";
}

.mdi-apple:before {
  content: "󰀵";
}

.mdi-apple-finder:before {
  content: "󰀶";
}

.mdi-apple-icloud:before {
  content: "󰀸";
}

.mdi-apple-ios:before {
  content: "󰀷";
}

.mdi-apple-keyboard-caps:before {
  content: "󰘲";
}

.mdi-apple-keyboard-command:before {
  content: "󰘳";
}

.mdi-apple-keyboard-control:before {
  content: "󰘴";
}

.mdi-apple-keyboard-option:before {
  content: "󰘵";
}

.mdi-apple-keyboard-shift:before {
  content: "󰘶";
}

.mdi-apple-safari:before {
  content: "󰀹";
}

.mdi-application:before {
  content: "󰣆";
}

.mdi-application-array:before {
  content: "󱃵";
}

.mdi-application-array-outline:before {
  content: "󱃶";
}

.mdi-application-braces:before {
  content: "󱃷";
}

.mdi-application-braces-outline:before {
  content: "󱃸";
}

.mdi-application-brackets:before {
  content: "󰲋";
}

.mdi-application-brackets-outline:before {
  content: "󰲌";
}

.mdi-application-cog:before {
  content: "󰙵";
}

.mdi-application-cog-outline:before {
  content: "󱕷";
}

.mdi-application-edit:before {
  content: "󰂮";
}

.mdi-application-edit-outline:before {
  content: "󰘙";
}

.mdi-application-export:before {
  content: "󰶭";
}

.mdi-application-import:before {
  content: "󰶮";
}

.mdi-application-outline:before {
  content: "󰘔";
}

.mdi-application-parentheses:before {
  content: "󱃹";
}

.mdi-application-parentheses-outline:before {
  content: "󱃺";
}

.mdi-application-settings:before {
  content: "󰭠";
}

.mdi-application-settings-outline:before {
  content: "󱕕";
}

.mdi-application-variable:before {
  content: "󱃻";
}

.mdi-application-variable-outline:before {
  content: "󱃼";
}

.mdi-approximately-equal:before {
  content: "󰾞";
}

.mdi-approximately-equal-box:before {
  content: "󰾟";
}

.mdi-apps:before {
  content: "󰀻";
}

.mdi-apps-box:before {
  content: "󰵆";
}

.mdi-arch:before {
  content: "󰣇";
}

.mdi-archive:before {
  content: "󰀼";
}

.mdi-archive-alert:before {
  content: "󱓽";
}

.mdi-archive-alert-outline:before {
  content: "󱓾";
}

.mdi-archive-arrow-down:before {
  content: "󱉙";
}

.mdi-archive-arrow-down-outline:before {
  content: "󱉚";
}

.mdi-archive-arrow-up:before {
  content: "󱉛";
}

.mdi-archive-arrow-up-outline:before {
  content: "󱉜";
}

.mdi-archive-cancel:before {
  content: "󱝋";
}

.mdi-archive-cancel-outline:before {
  content: "󱝌";
}

.mdi-archive-check:before {
  content: "󱝍";
}

.mdi-archive-check-outline:before {
  content: "󱝎";
}

.mdi-archive-clock:before {
  content: "󱝏";
}

.mdi-archive-clock-outline:before {
  content: "󱝐";
}

.mdi-archive-cog:before {
  content: "󱝑";
}

.mdi-archive-cog-outline:before {
  content: "󱝒";
}

.mdi-archive-edit:before {
  content: "󱝓";
}

.mdi-archive-edit-outline:before {
  content: "󱝔";
}

.mdi-archive-eye:before {
  content: "󱝕";
}

.mdi-archive-eye-outline:before {
  content: "󱝖";
}

.mdi-archive-lock:before {
  content: "󱝗";
}

.mdi-archive-lock-open:before {
  content: "󱝘";
}

.mdi-archive-lock-open-outline:before {
  content: "󱝙";
}

.mdi-archive-lock-outline:before {
  content: "󱝚";
}

.mdi-archive-marker:before {
  content: "󱝛";
}

.mdi-archive-marker-outline:before {
  content: "󱝜";
}

.mdi-archive-minus:before {
  content: "󱝝";
}

.mdi-archive-minus-outline:before {
  content: "󱝞";
}

.mdi-archive-music:before {
  content: "󱝟";
}

.mdi-archive-music-outline:before {
  content: "󱝠";
}

.mdi-archive-off:before {
  content: "󱝡";
}

.mdi-archive-off-outline:before {
  content: "󱝢";
}

.mdi-archive-outline:before {
  content: "󱈎";
}

.mdi-archive-plus:before {
  content: "󱝣";
}

.mdi-archive-plus-outline:before {
  content: "󱝤";
}

.mdi-archive-refresh:before {
  content: "󱝥";
}

.mdi-archive-refresh-outline:before {
  content: "󱝦";
}

.mdi-archive-remove:before {
  content: "󱝧";
}

.mdi-archive-remove-outline:before {
  content: "󱝨";
}

.mdi-archive-search:before {
  content: "󱝩";
}

.mdi-archive-search-outline:before {
  content: "󱝪";
}

.mdi-archive-settings:before {
  content: "󱝫";
}

.mdi-archive-settings-outline:before {
  content: "󱝬";
}

.mdi-archive-star:before {
  content: "󱝭";
}

.mdi-archive-star-outline:before {
  content: "󱝮";
}

.mdi-archive-sync:before {
  content: "󱝯";
}

.mdi-archive-sync-outline:before {
  content: "󱝰";
}

.mdi-arm-flex:before {
  content: "󰿗";
}

.mdi-arm-flex-outline:before {
  content: "󰿖";
}

.mdi-arrange-bring-forward:before {
  content: "󰀽";
}

.mdi-arrange-bring-to-front:before {
  content: "󰀾";
}

.mdi-arrange-send-backward:before {
  content: "󰀿";
}

.mdi-arrange-send-to-back:before {
  content: "󰁀";
}

.mdi-arrow-all:before {
  content: "󰁁";
}

.mdi-arrow-bottom-left:before {
  content: "󰁂";
}

.mdi-arrow-bottom-left-bold-box:before {
  content: "󱥤";
}

.mdi-arrow-bottom-left-bold-box-outline:before {
  content: "󱥥";
}

.mdi-arrow-bottom-left-bold-outline:before {
  content: "󰦷";
}

.mdi-arrow-bottom-left-thick:before {
  content: "󰦸";
}

.mdi-arrow-bottom-left-thin:before {
  content: "󱦶";
}

.mdi-arrow-bottom-left-thin-circle-outline:before {
  content: "󱖖";
}

.mdi-arrow-bottom-right:before {
  content: "󰁃";
}

.mdi-arrow-bottom-right-bold-box:before {
  content: "󱥦";
}

.mdi-arrow-bottom-right-bold-box-outline:before {
  content: "󱥧";
}

.mdi-arrow-bottom-right-bold-outline:before {
  content: "󰦹";
}

.mdi-arrow-bottom-right-thick:before {
  content: "󰦺";
}

.mdi-arrow-bottom-right-thin:before {
  content: "󱦷";
}

.mdi-arrow-bottom-right-thin-circle-outline:before {
  content: "󱖕";
}

.mdi-arrow-collapse:before {
  content: "󰘕";
}

.mdi-arrow-collapse-all:before {
  content: "󰁄";
}

.mdi-arrow-collapse-down:before {
  content: "󰞒";
}

.mdi-arrow-collapse-horizontal:before {
  content: "󰡌";
}

.mdi-arrow-collapse-left:before {
  content: "󰞓";
}

.mdi-arrow-collapse-right:before {
  content: "󰞔";
}

.mdi-arrow-collapse-up:before {
  content: "󰞕";
}

.mdi-arrow-collapse-vertical:before {
  content: "󰡍";
}

.mdi-arrow-decision:before {
  content: "󰦻";
}

.mdi-arrow-decision-auto:before {
  content: "󰦼";
}

.mdi-arrow-decision-auto-outline:before {
  content: "󰦽";
}

.mdi-arrow-decision-outline:before {
  content: "󰦾";
}

.mdi-arrow-down:before {
  content: "󰁅";
}

.mdi-arrow-down-bold:before {
  content: "󰜮";
}

.mdi-arrow-down-bold-box:before {
  content: "󰜯";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "󰜰";
}

.mdi-arrow-down-bold-circle:before {
  content: "󰁇";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "󰁈";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "󰁉";
}

.mdi-arrow-down-bold-outline:before {
  content: "󰦿";
}

.mdi-arrow-down-box:before {
  content: "󰛀";
}

.mdi-arrow-down-circle:before {
  content: "󰳛";
}

.mdi-arrow-down-circle-outline:before {
  content: "󰳜";
}

.mdi-arrow-down-drop-circle:before {
  content: "󰁊";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "󰁋";
}

.mdi-arrow-down-left:before {
  content: "󱞡";
}

.mdi-arrow-down-left-bold:before {
  content: "󱞢";
}

.mdi-arrow-down-right:before {
  content: "󱞣";
}

.mdi-arrow-down-right-bold:before {
  content: "󱞤";
}

.mdi-arrow-down-thick:before {
  content: "󰁆";
}

.mdi-arrow-down-thin:before {
  content: "󱦳";
}

.mdi-arrow-down-thin-circle-outline:before {
  content: "󱖙";
}

.mdi-arrow-expand:before {
  content: "󰘖";
}

.mdi-arrow-expand-all:before {
  content: "󰁌";
}

.mdi-arrow-expand-down:before {
  content: "󰞖";
}

.mdi-arrow-expand-horizontal:before {
  content: "󰡎";
}

.mdi-arrow-expand-left:before {
  content: "󰞗";
}

.mdi-arrow-expand-right:before {
  content: "󰞘";
}

.mdi-arrow-expand-up:before {
  content: "󰞙";
}

.mdi-arrow-expand-vertical:before {
  content: "󰡏";
}

.mdi-arrow-horizontal-lock:before {
  content: "󱅛";
}

.mdi-arrow-left:before {
  content: "󰁍";
}

.mdi-arrow-left-bold:before {
  content: "󰜱";
}

.mdi-arrow-left-bold-box:before {
  content: "󰜲";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "󰜳";
}

.mdi-arrow-left-bold-circle:before {
  content: "󰁏";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "󰁐";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "󰁑";
}

.mdi-arrow-left-bold-outline:before {
  content: "󰧀";
}

.mdi-arrow-left-bottom:before {
  content: "󱞥";
}

.mdi-arrow-left-bottom-bold:before {
  content: "󱞦";
}

.mdi-arrow-left-box:before {
  content: "󰛁";
}

.mdi-arrow-left-circle:before {
  content: "󰳝";
}

.mdi-arrow-left-circle-outline:before {
  content: "󰳞";
}

.mdi-arrow-left-drop-circle:before {
  content: "󰁒";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "󰁓";
}

.mdi-arrow-left-right:before {
  content: "󰹳";
}

.mdi-arrow-left-right-bold:before {
  content: "󰹴";
}

.mdi-arrow-left-right-bold-outline:before {
  content: "󰧁";
}

.mdi-arrow-left-thick:before {
  content: "󰁎";
}

.mdi-arrow-left-thin:before {
  content: "󱦱";
}

.mdi-arrow-left-thin-circle-outline:before {
  content: "󱖚";
}

.mdi-arrow-left-top:before {
  content: "󱞧";
}

.mdi-arrow-left-top-bold:before {
  content: "󱞨";
}

.mdi-arrow-oscillating:before {
  content: "󱲑";
}

.mdi-arrow-oscillating-off:before {
  content: "󱲒";
}

.mdi-arrow-projectile:before {
  content: "󱡀";
}

.mdi-arrow-projectile-multiple:before {
  content: "󱠿";
}

.mdi-arrow-right:before {
  content: "󰁔";
}

.mdi-arrow-right-bold:before {
  content: "󰜴";
}

.mdi-arrow-right-bold-box:before {
  content: "󰜵";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "󰜶";
}

.mdi-arrow-right-bold-circle:before {
  content: "󰁖";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "󰁗";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "󰁘";
}

.mdi-arrow-right-bold-outline:before {
  content: "󰧂";
}

.mdi-arrow-right-bottom:before {
  content: "󱞩";
}

.mdi-arrow-right-bottom-bold:before {
  content: "󱞪";
}

.mdi-arrow-right-box:before {
  content: "󰛂";
}

.mdi-arrow-right-circle:before {
  content: "󰳟";
}

.mdi-arrow-right-circle-outline:before {
  content: "󰳠";
}

.mdi-arrow-right-drop-circle:before {
  content: "󰁙";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "󰁚";
}

.mdi-arrow-right-thick:before {
  content: "󰁕";
}

.mdi-arrow-right-thin:before {
  content: "󱦰";
}

.mdi-arrow-right-thin-circle-outline:before {
  content: "󱖘";
}

.mdi-arrow-right-top:before {
  content: "󱞫";
}

.mdi-arrow-right-top-bold:before {
  content: "󱞬";
}

.mdi-arrow-split-horizontal:before {
  content: "󰤻";
}

.mdi-arrow-split-vertical:before {
  content: "󰤼";
}

.mdi-arrow-top-left:before {
  content: "󰁛";
}

.mdi-arrow-top-left-bold-box:before {
  content: "󱥨";
}

.mdi-arrow-top-left-bold-box-outline:before {
  content: "󱥩";
}

.mdi-arrow-top-left-bold-outline:before {
  content: "󰧃";
}

.mdi-arrow-top-left-bottom-right:before {
  content: "󰹵";
}

.mdi-arrow-top-left-bottom-right-bold:before {
  content: "󰹶";
}

.mdi-arrow-top-left-thick:before {
  content: "󰧄";
}

.mdi-arrow-top-left-thin:before {
  content: "󱦵";
}

.mdi-arrow-top-left-thin-circle-outline:before {
  content: "󱖓";
}

.mdi-arrow-top-right:before {
  content: "󰁜";
}

.mdi-arrow-top-right-bold-box:before {
  content: "󱥪";
}

.mdi-arrow-top-right-bold-box-outline:before {
  content: "󱥫";
}

.mdi-arrow-top-right-bold-outline:before {
  content: "󰧅";
}

.mdi-arrow-top-right-bottom-left:before {
  content: "󰹷";
}

.mdi-arrow-top-right-bottom-left-bold:before {
  content: "󰹸";
}

.mdi-arrow-top-right-thick:before {
  content: "󰧆";
}

.mdi-arrow-top-right-thin:before {
  content: "󱦴";
}

.mdi-arrow-top-right-thin-circle-outline:before {
  content: "󱖔";
}

.mdi-arrow-u-down-left:before {
  content: "󱞭";
}

.mdi-arrow-u-down-left-bold:before {
  content: "󱞮";
}

.mdi-arrow-u-down-right:before {
  content: "󱞯";
}

.mdi-arrow-u-down-right-bold:before {
  content: "󱞰";
}

.mdi-arrow-u-left-bottom:before {
  content: "󱞱";
}

.mdi-arrow-u-left-bottom-bold:before {
  content: "󱞲";
}

.mdi-arrow-u-left-top:before {
  content: "󱞳";
}

.mdi-arrow-u-left-top-bold:before {
  content: "󱞴";
}

.mdi-arrow-u-right-bottom:before {
  content: "󱞵";
}

.mdi-arrow-u-right-bottom-bold:before {
  content: "󱞶";
}

.mdi-arrow-u-right-top:before {
  content: "󱞷";
}

.mdi-arrow-u-right-top-bold:before {
  content: "󱞸";
}

.mdi-arrow-u-up-left:before {
  content: "󱞹";
}

.mdi-arrow-u-up-left-bold:before {
  content: "󱞺";
}

.mdi-arrow-u-up-right:before {
  content: "󱞻";
}

.mdi-arrow-u-up-right-bold:before {
  content: "󱞼";
}

.mdi-arrow-up:before {
  content: "󰁝";
}

.mdi-arrow-up-bold:before {
  content: "󰜷";
}

.mdi-arrow-up-bold-box:before {
  content: "󰜸";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "󰜹";
}

.mdi-arrow-up-bold-circle:before {
  content: "󰁟";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "󰁠";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "󰁡";
}

.mdi-arrow-up-bold-outline:before {
  content: "󰧇";
}

.mdi-arrow-up-box:before {
  content: "󰛃";
}

.mdi-arrow-up-circle:before {
  content: "󰳡";
}

.mdi-arrow-up-circle-outline:before {
  content: "󰳢";
}

.mdi-arrow-up-down:before {
  content: "󰹹";
}

.mdi-arrow-up-down-bold:before {
  content: "󰹺";
}

.mdi-arrow-up-down-bold-outline:before {
  content: "󰧈";
}

.mdi-arrow-up-drop-circle:before {
  content: "󰁢";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "󰁣";
}

.mdi-arrow-up-left:before {
  content: "󱞽";
}

.mdi-arrow-up-left-bold:before {
  content: "󱞾";
}

.mdi-arrow-up-right:before {
  content: "󱞿";
}

.mdi-arrow-up-right-bold:before {
  content: "󱟀";
}

.mdi-arrow-up-thick:before {
  content: "󰁞";
}

.mdi-arrow-up-thin:before {
  content: "󱦲";
}

.mdi-arrow-up-thin-circle-outline:before {
  content: "󱖗";
}

.mdi-arrow-vertical-lock:before {
  content: "󱅜";
}

.mdi-artboard:before {
  content: "󱮚";
}

.mdi-artstation:before {
  content: "󰭛";
}

.mdi-aspect-ratio:before {
  content: "󰨤";
}

.mdi-assistant:before {
  content: "󰁤";
}

.mdi-asterisk:before {
  content: "󰛄";
}

.mdi-asterisk-circle-outline:before {
  content: "󱨧";
}

.mdi-at:before {
  content: "󰁥";
}

.mdi-atlassian:before {
  content: "󰠄";
}

.mdi-atm:before {
  content: "󰵇";
}

.mdi-atom:before {
  content: "󰝨";
}

.mdi-atom-variant:before {
  content: "󰹻";
}

.mdi-attachment:before {
  content: "󰁦";
}

.mdi-attachment-check:before {
  content: "󱫁";
}

.mdi-attachment-lock:before {
  content: "󱧄";
}

.mdi-attachment-minus:before {
  content: "󱫂";
}

.mdi-attachment-off:before {
  content: "󱫃";
}

.mdi-attachment-plus:before {
  content: "󱫄";
}

.mdi-attachment-remove:before {
  content: "󱫅";
}

.mdi-atv:before {
  content: "󱭰";
}

.mdi-audio-input-rca:before {
  content: "󱡫";
}

.mdi-audio-input-stereo-minijack:before {
  content: "󱡬";
}

.mdi-audio-input-xlr:before {
  content: "󱡭";
}

.mdi-audio-video:before {
  content: "󰤽";
}

.mdi-audio-video-off:before {
  content: "󱆶";
}

.mdi-augmented-reality:before {
  content: "󰡐";
}

.mdi-aurora:before {
  content: "󱮹";
}

.mdi-auto-download:before {
  content: "󱍾";
}

.mdi-auto-fix:before {
  content: "󰁨";
}

.mdi-auto-mode:before {
  content: "󱰠";
}

.mdi-auto-upload:before {
  content: "󰁩";
}

.mdi-autorenew:before {
  content: "󰁪";
}

.mdi-autorenew-off:before {
  content: "󱧧";
}

.mdi-av-timer:before {
  content: "󰁫";
}

.mdi-awning:before {
  content: "󱮇";
}

.mdi-awning-outline:before {
  content: "󱮈";
}

.mdi-aws:before {
  content: "󰸏";
}

.mdi-axe:before {
  content: "󰣈";
}

.mdi-axe-battle:before {
  content: "󱡂";
}

.mdi-axis:before {
  content: "󰵈";
}

.mdi-axis-arrow:before {
  content: "󰵉";
}

.mdi-axis-arrow-info:before {
  content: "󱐎";
}

.mdi-axis-arrow-lock:before {
  content: "󰵊";
}

.mdi-axis-lock:before {
  content: "󰵋";
}

.mdi-axis-x-arrow:before {
  content: "󰵌";
}

.mdi-axis-x-arrow-lock:before {
  content: "󰵍";
}

.mdi-axis-x-rotate-clockwise:before {
  content: "󰵎";
}

.mdi-axis-x-rotate-counterclockwise:before {
  content: "󰵏";
}

.mdi-axis-x-y-arrow-lock:before {
  content: "󰵐";
}

.mdi-axis-y-arrow:before {
  content: "󰵑";
}

.mdi-axis-y-arrow-lock:before {
  content: "󰵒";
}

.mdi-axis-y-rotate-clockwise:before {
  content: "󰵓";
}

.mdi-axis-y-rotate-counterclockwise:before {
  content: "󰵔";
}

.mdi-axis-z-arrow:before {
  content: "󰵕";
}

.mdi-axis-z-arrow-lock:before {
  content: "󰵖";
}

.mdi-axis-z-rotate-clockwise:before {
  content: "󰵗";
}

.mdi-axis-z-rotate-counterclockwise:before {
  content: "󰵘";
}

.mdi-babel:before {
  content: "󰨥";
}

.mdi-baby:before {
  content: "󰁬";
}

.mdi-baby-bottle:before {
  content: "󰼹";
}

.mdi-baby-bottle-outline:before {
  content: "󰼺";
}

.mdi-baby-buggy:before {
  content: "󱏠";
}

.mdi-baby-buggy-off:before {
  content: "󱫳";
}

.mdi-baby-carriage:before {
  content: "󰚏";
}

.mdi-baby-carriage-off:before {
  content: "󰾠";
}

.mdi-baby-face:before {
  content: "󰹼";
}

.mdi-baby-face-outline:before {
  content: "󰹽";
}

.mdi-backburger:before {
  content: "󰁭";
}

.mdi-backspace:before {
  content: "󰁮";
}

.mdi-backspace-outline:before {
  content: "󰭜";
}

.mdi-backspace-reverse:before {
  content: "󰹾";
}

.mdi-backspace-reverse-outline:before {
  content: "󰹿";
}

.mdi-backup-restore:before {
  content: "󰁯";
}

.mdi-bacteria:before {
  content: "󰻕";
}

.mdi-bacteria-outline:before {
  content: "󰻖";
}

.mdi-badge-account:before {
  content: "󰶧";
}

.mdi-badge-account-alert:before {
  content: "󰶨";
}

.mdi-badge-account-alert-outline:before {
  content: "󰶩";
}

.mdi-badge-account-horizontal:before {
  content: "󰸍";
}

.mdi-badge-account-horizontal-outline:before {
  content: "󰸎";
}

.mdi-badge-account-outline:before {
  content: "󰶪";
}

.mdi-badminton:before {
  content: "󰡑";
}

.mdi-bag-carry-on:before {
  content: "󰼻";
}

.mdi-bag-carry-on-check:before {
  content: "󰵥";
}

.mdi-bag-carry-on-off:before {
  content: "󰼼";
}

.mdi-bag-checked:before {
  content: "󰼽";
}

.mdi-bag-personal:before {
  content: "󰸐";
}

.mdi-bag-personal-off:before {
  content: "󰸑";
}

.mdi-bag-personal-off-outline:before {
  content: "󰸒";
}

.mdi-bag-personal-outline:before {
  content: "󰸓";
}

.mdi-bag-personal-plus:before {
  content: "󱲤";
}

.mdi-bag-personal-plus-outline:before {
  content: "󱲥";
}

.mdi-bag-personal-tag:before {
  content: "󱬌";
}

.mdi-bag-personal-tag-outline:before {
  content: "󱬍";
}

.mdi-bag-suitcase:before {
  content: "󱖋";
}

.mdi-bag-suitcase-off:before {
  content: "󱖍";
}

.mdi-bag-suitcase-off-outline:before {
  content: "󱖎";
}

.mdi-bag-suitcase-outline:before {
  content: "󱖌";
}

.mdi-baguette:before {
  content: "󰼾";
}

.mdi-balcony:before {
  content: "󱠗";
}

.mdi-balloon:before {
  content: "󰨦";
}

.mdi-ballot:before {
  content: "󰧉";
}

.mdi-ballot-outline:before {
  content: "󰧊";
}

.mdi-ballot-recount:before {
  content: "󰰹";
}

.mdi-ballot-recount-outline:before {
  content: "󰰺";
}

.mdi-bandage:before {
  content: "󰶯";
}

.mdi-bank:before {
  content: "󰁰";
}

.mdi-bank-check:before {
  content: "󱙕";
}

.mdi-bank-circle:before {
  content: "󱰃";
}

.mdi-bank-circle-outline:before {
  content: "󱰄";
}

.mdi-bank-minus:before {
  content: "󰶰";
}

.mdi-bank-off:before {
  content: "󱙖";
}

.mdi-bank-off-outline:before {
  content: "󱙗";
}

.mdi-bank-outline:before {
  content: "󰺀";
}

.mdi-bank-plus:before {
  content: "󰶱";
}

.mdi-bank-remove:before {
  content: "󰶲";
}

.mdi-bank-transfer:before {
  content: "󰨧";
}

.mdi-bank-transfer-in:before {
  content: "󰨨";
}

.mdi-bank-transfer-out:before {
  content: "󰨩";
}

.mdi-barcode:before {
  content: "󰁱";
}

.mdi-barcode-off:before {
  content: "󱈶";
}

.mdi-barcode-scan:before {
  content: "󰁲";
}

.mdi-barley:before {
  content: "󰁳";
}

.mdi-barley-off:before {
  content: "󰭝";
}

.mdi-barn:before {
  content: "󰭞";
}

.mdi-barrel:before {
  content: "󰁴";
}

.mdi-barrel-outline:before {
  content: "󱨨";
}

.mdi-baseball:before {
  content: "󰡒";
}

.mdi-baseball-bat:before {
  content: "󰡓";
}

.mdi-baseball-diamond:before {
  content: "󱗬";
}

.mdi-baseball-diamond-outline:before {
  content: "󱗭";
}

.mdi-baseball-outline:before {
  content: "󱱚";
}

.mdi-bash:before {
  content: "󱆃";
}

.mdi-basket:before {
  content: "󰁶";
}

.mdi-basket-check:before {
  content: "󱣥";
}

.mdi-basket-check-outline:before {
  content: "󱣦";
}

.mdi-basket-fill:before {
  content: "󰁷";
}

.mdi-basket-minus:before {
  content: "󱔣";
}

.mdi-basket-minus-outline:before {
  content: "󱔤";
}

.mdi-basket-off:before {
  content: "󱔥";
}

.mdi-basket-off-outline:before {
  content: "󱔦";
}

.mdi-basket-outline:before {
  content: "󱆁";
}

.mdi-basket-plus:before {
  content: "󱔧";
}

.mdi-basket-plus-outline:before {
  content: "󱔨";
}

.mdi-basket-remove:before {
  content: "󱔩";
}

.mdi-basket-remove-outline:before {
  content: "󱔪";
}

.mdi-basket-unfill:before {
  content: "󰁸";
}

.mdi-basketball:before {
  content: "󰠆";
}

.mdi-basketball-hoop:before {
  content: "󰰻";
}

.mdi-basketball-hoop-outline:before {
  content: "󰰼";
}

.mdi-bat:before {
  content: "󰭟";
}

.mdi-bathtub:before {
  content: "󱠘";
}

.mdi-bathtub-outline:before {
  content: "󱠙";
}

.mdi-battery:before {
  content: "󰁹";
}

.mdi-battery-10:before {
  content: "󰁺";
}

.mdi-battery-10-bluetooth:before {
  content: "󰤾";
}

.mdi-battery-20:before {
  content: "󰁻";
}

.mdi-battery-20-bluetooth:before {
  content: "󰤿";
}

.mdi-battery-30:before {
  content: "󰁼";
}

.mdi-battery-30-bluetooth:before {
  content: "󰥀";
}

.mdi-battery-40:before {
  content: "󰁽";
}

.mdi-battery-40-bluetooth:before {
  content: "󰥁";
}

.mdi-battery-50:before {
  content: "󰁾";
}

.mdi-battery-50-bluetooth:before {
  content: "󰥂";
}

.mdi-battery-60:before {
  content: "󰁿";
}

.mdi-battery-60-bluetooth:before {
  content: "󰥃";
}

.mdi-battery-70:before {
  content: "󰂀";
}

.mdi-battery-70-bluetooth:before {
  content: "󰥄";
}

.mdi-battery-80:before {
  content: "󰂁";
}

.mdi-battery-80-bluetooth:before {
  content: "󰥅";
}

.mdi-battery-90:before {
  content: "󰂂";
}

.mdi-battery-90-bluetooth:before {
  content: "󰥆";
}

.mdi-battery-alert:before {
  content: "󰂃";
}

.mdi-battery-alert-bluetooth:before {
  content: "󰥇";
}

.mdi-battery-alert-variant:before {
  content: "󱃌";
}

.mdi-battery-alert-variant-outline:before {
  content: "󱃍";
}

.mdi-battery-arrow-down:before {
  content: "󱟞";
}

.mdi-battery-arrow-down-outline:before {
  content: "󱟟";
}

.mdi-battery-arrow-up:before {
  content: "󱟠";
}

.mdi-battery-arrow-up-outline:before {
  content: "󱟡";
}

.mdi-battery-bluetooth:before {
  content: "󰥈";
}

.mdi-battery-bluetooth-variant:before {
  content: "󰥉";
}

.mdi-battery-charging:before {
  content: "󰂄";
}

.mdi-battery-charging-10:before {
  content: "󰢜";
}

.mdi-battery-charging-100:before {
  content: "󰂅";
}

.mdi-battery-charging-20:before {
  content: "󰂆";
}

.mdi-battery-charging-30:before {
  content: "󰂇";
}

.mdi-battery-charging-40:before {
  content: "󰂈";
}

.mdi-battery-charging-50:before {
  content: "󰢝";
}

.mdi-battery-charging-60:before {
  content: "󰂉";
}

.mdi-battery-charging-70:before {
  content: "󰢞";
}

.mdi-battery-charging-80:before {
  content: "󰂊";
}

.mdi-battery-charging-90:before {
  content: "󰂋";
}

.mdi-battery-charging-high:before {
  content: "󱊦";
}

.mdi-battery-charging-low:before {
  content: "󱊤";
}

.mdi-battery-charging-medium:before {
  content: "󱊥";
}

.mdi-battery-charging-outline:before {
  content: "󰢟";
}

.mdi-battery-charging-wireless:before {
  content: "󰠇";
}

.mdi-battery-charging-wireless-10:before {
  content: "󰠈";
}

.mdi-battery-charging-wireless-20:before {
  content: "󰠉";
}

.mdi-battery-charging-wireless-30:before {
  content: "󰠊";
}

.mdi-battery-charging-wireless-40:before {
  content: "󰠋";
}

.mdi-battery-charging-wireless-50:before {
  content: "󰠌";
}

.mdi-battery-charging-wireless-60:before {
  content: "󰠍";
}

.mdi-battery-charging-wireless-70:before {
  content: "󰠎";
}

.mdi-battery-charging-wireless-80:before {
  content: "󰠏";
}

.mdi-battery-charging-wireless-90:before {
  content: "󰠐";
}

.mdi-battery-charging-wireless-alert:before {
  content: "󰠑";
}

.mdi-battery-charging-wireless-outline:before {
  content: "󰠒";
}

.mdi-battery-check:before {
  content: "󱟢";
}

.mdi-battery-check-outline:before {
  content: "󱟣";
}

.mdi-battery-clock:before {
  content: "󱧥";
}

.mdi-battery-clock-outline:before {
  content: "󱧦";
}

.mdi-battery-heart:before {
  content: "󱈏";
}

.mdi-battery-heart-outline:before {
  content: "󱈐";
}

.mdi-battery-heart-variant:before {
  content: "󱈑";
}

.mdi-battery-high:before {
  content: "󱊣";
}

.mdi-battery-lock:before {
  content: "󱞜";
}

.mdi-battery-lock-open:before {
  content: "󱞝";
}

.mdi-battery-low:before {
  content: "󱊡";
}

.mdi-battery-medium:before {
  content: "󱊢";
}

.mdi-battery-minus:before {
  content: "󱟤";
}

.mdi-battery-minus-outline:before {
  content: "󱟥";
}

.mdi-battery-minus-variant:before {
  content: "󰂌";
}

.mdi-battery-negative:before {
  content: "󰂍";
}

.mdi-battery-off:before {
  content: "󱉝";
}

.mdi-battery-off-outline:before {
  content: "󱉞";
}

.mdi-battery-outline:before {
  content: "󰂎";
}

.mdi-battery-plus:before {
  content: "󱟦";
}

.mdi-battery-plus-outline:before {
  content: "󱟧";
}

.mdi-battery-plus-variant:before {
  content: "󰂏";
}

.mdi-battery-positive:before {
  content: "󰂐";
}

.mdi-battery-remove:before {
  content: "󱟨";
}

.mdi-battery-remove-outline:before {
  content: "󱟩";
}

.mdi-battery-sync:before {
  content: "󱠴";
}

.mdi-battery-sync-outline:before {
  content: "󱠵";
}

.mdi-battery-unknown:before {
  content: "󰂑";
}

.mdi-battery-unknown-bluetooth:before {
  content: "󰥊";
}

.mdi-beach:before {
  content: "󰂒";
}

.mdi-beaker:before {
  content: "󰳪";
}

.mdi-beaker-alert:before {
  content: "󱈩";
}

.mdi-beaker-alert-outline:before {
  content: "󱈪";
}

.mdi-beaker-check:before {
  content: "󱈫";
}

.mdi-beaker-check-outline:before {
  content: "󱈬";
}

.mdi-beaker-minus:before {
  content: "󱈭";
}

.mdi-beaker-minus-outline:before {
  content: "󱈮";
}

.mdi-beaker-outline:before {
  content: "󰚐";
}

.mdi-beaker-plus:before {
  content: "󱈯";
}

.mdi-beaker-plus-outline:before {
  content: "󱈰";
}

.mdi-beaker-question:before {
  content: "󱈱";
}

.mdi-beaker-question-outline:before {
  content: "󱈲";
}

.mdi-beaker-remove:before {
  content: "󱈳";
}

.mdi-beaker-remove-outline:before {
  content: "󱈴";
}

.mdi-bed:before {
  content: "󰋣";
}

.mdi-bed-clock:before {
  content: "󱮔";
}

.mdi-bed-double:before {
  content: "󰿔";
}

.mdi-bed-double-outline:before {
  content: "󰿓";
}

.mdi-bed-empty:before {
  content: "󰢠";
}

.mdi-bed-king:before {
  content: "󰿒";
}

.mdi-bed-king-outline:before {
  content: "󰿑";
}

.mdi-bed-outline:before {
  content: "󰂙";
}

.mdi-bed-queen:before {
  content: "󰿐";
}

.mdi-bed-queen-outline:before {
  content: "󰿛";
}

.mdi-bed-single:before {
  content: "󱁭";
}

.mdi-bed-single-outline:before {
  content: "󱁮";
}

.mdi-bee:before {
  content: "󰾡";
}

.mdi-bee-flower:before {
  content: "󰾢";
}

.mdi-beehive-off-outline:before {
  content: "󱏭";
}

.mdi-beehive-outline:before {
  content: "󱃎";
}

.mdi-beekeeper:before {
  content: "󱓢";
}

.mdi-beer:before {
  content: "󰂘";
}

.mdi-beer-outline:before {
  content: "󱌌";
}

.mdi-bell:before {
  content: "󰂚";
}

.mdi-bell-alert:before {
  content: "󰵙";
}

.mdi-bell-alert-outline:before {
  content: "󰺁";
}

.mdi-bell-badge:before {
  content: "󱅫";
}

.mdi-bell-badge-outline:before {
  content: "󰅸";
}

.mdi-bell-cancel:before {
  content: "󱏧";
}

.mdi-bell-cancel-outline:before {
  content: "󱏨";
}

.mdi-bell-check:before {
  content: "󱇥";
}

.mdi-bell-check-outline:before {
  content: "󱇦";
}

.mdi-bell-circle:before {
  content: "󰵚";
}

.mdi-bell-circle-outline:before {
  content: "󰵛";
}

.mdi-bell-cog:before {
  content: "󱨩";
}

.mdi-bell-cog-outline:before {
  content: "󱨪";
}

.mdi-bell-minus:before {
  content: "󱏩";
}

.mdi-bell-minus-outline:before {
  content: "󱏪";
}

.mdi-bell-off:before {
  content: "󰂛";
}

.mdi-bell-off-outline:before {
  content: "󰪑";
}

.mdi-bell-outline:before {
  content: "󰂜";
}

.mdi-bell-plus:before {
  content: "󰂝";
}

.mdi-bell-plus-outline:before {
  content: "󰪒";
}

.mdi-bell-remove:before {
  content: "󱏫";
}

.mdi-bell-remove-outline:before {
  content: "󱏬";
}

.mdi-bell-ring:before {
  content: "󰂞";
}

.mdi-bell-ring-outline:before {
  content: "󰂟";
}

.mdi-bell-sleep:before {
  content: "󰂠";
}

.mdi-bell-sleep-outline:before {
  content: "󰪓";
}

.mdi-bench:before {
  content: "󱰡";
}

.mdi-bench-back:before {
  content: "󱰢";
}

.mdi-beta:before {
  content: "󰂡";
}

.mdi-betamax:before {
  content: "󰧋";
}

.mdi-biathlon:before {
  content: "󰸔";
}

.mdi-bicycle:before {
  content: "󱂜";
}

.mdi-bicycle-basket:before {
  content: "󱈵";
}

.mdi-bicycle-cargo:before {
  content: "󱢜";
}

.mdi-bicycle-electric:before {
  content: "󱖴";
}

.mdi-bicycle-penny-farthing:before {
  content: "󱗩";
}

.mdi-bike:before {
  content: "󰂣";
}

.mdi-bike-fast:before {
  content: "󱄟";
}

.mdi-bike-pedal:before {
  content: "󱰣";
}

.mdi-bike-pedal-clipless:before {
  content: "󱰤";
}

.mdi-bike-pedal-mountain:before {
  content: "󱰥";
}

.mdi-billboard:before {
  content: "󱀐";
}

.mdi-billiards:before {
  content: "󰭡";
}

.mdi-billiards-rack:before {
  content: "󰭢";
}

.mdi-binoculars:before {
  content: "󰂥";
}

.mdi-bio:before {
  content: "󰂦";
}

.mdi-biohazard:before {
  content: "󰂧";
}

.mdi-bird:before {
  content: "󱗆";
}

.mdi-bitbucket:before {
  content: "󰂨";
}

.mdi-bitcoin:before {
  content: "󰠓";
}

.mdi-black-mesa:before {
  content: "󰂩";
}

.mdi-blender:before {
  content: "󰳫";
}

.mdi-blender-outline:before {
  content: "󱠚";
}

.mdi-blender-software:before {
  content: "󰂫";
}

.mdi-blinds:before {
  content: "󰂬";
}

.mdi-blinds-horizontal:before {
  content: "󱨫";
}

.mdi-blinds-horizontal-closed:before {
  content: "󱨬";
}

.mdi-blinds-open:before {
  content: "󱀑";
}

.mdi-blinds-vertical:before {
  content: "󱨭";
}

.mdi-blinds-vertical-closed:before {
  content: "󱨮";
}

.mdi-block-helper:before {
  content: "󰂭";
}

.mdi-blood-bag:before {
  content: "󰳬";
}

.mdi-bluetooth:before {
  content: "󰂯";
}

.mdi-bluetooth-audio:before {
  content: "󰂰";
}

.mdi-bluetooth-connect:before {
  content: "󰂱";
}

.mdi-bluetooth-off:before {
  content: "󰂲";
}

.mdi-bluetooth-settings:before {
  content: "󰂳";
}

.mdi-bluetooth-transfer:before {
  content: "󰂴";
}

.mdi-blur:before {
  content: "󰂵";
}

.mdi-blur-linear:before {
  content: "󰂶";
}

.mdi-blur-off:before {
  content: "󰂷";
}

.mdi-blur-radial:before {
  content: "󰂸";
}

.mdi-bolt:before {
  content: "󰶳";
}

.mdi-bomb:before {
  content: "󰚑";
}

.mdi-bomb-off:before {
  content: "󰛅";
}

.mdi-bone:before {
  content: "󰂹";
}

.mdi-bone-off:before {
  content: "󱧠";
}

.mdi-book:before {
  content: "󰂺";
}

.mdi-book-account:before {
  content: "󱎭";
}

.mdi-book-account-outline:before {
  content: "󱎮";
}

.mdi-book-alert:before {
  content: "󱙼";
}

.mdi-book-alert-outline:before {
  content: "󱙽";
}

.mdi-book-alphabet:before {
  content: "󰘝";
}

.mdi-book-arrow-down:before {
  content: "󱙾";
}

.mdi-book-arrow-down-outline:before {
  content: "󱙿";
}

.mdi-book-arrow-left:before {
  content: "󱚀";
}

.mdi-book-arrow-left-outline:before {
  content: "󱚁";
}

.mdi-book-arrow-right:before {
  content: "󱚂";
}

.mdi-book-arrow-right-outline:before {
  content: "󱚃";
}

.mdi-book-arrow-up:before {
  content: "󱚄";
}

.mdi-book-arrow-up-outline:before {
  content: "󱚅";
}

.mdi-book-cancel:before {
  content: "󱚆";
}

.mdi-book-cancel-outline:before {
  content: "󱚇";
}

.mdi-book-check:before {
  content: "󱓳";
}

.mdi-book-check-outline:before {
  content: "󱓴";
}

.mdi-book-clock:before {
  content: "󱚈";
}

.mdi-book-clock-outline:before {
  content: "󱚉";
}

.mdi-book-cog:before {
  content: "󱚊";
}

.mdi-book-cog-outline:before {
  content: "󱚋";
}

.mdi-book-cross:before {
  content: "󰂢";
}

.mdi-book-edit:before {
  content: "󱚌";
}

.mdi-book-edit-outline:before {
  content: "󱚍";
}

.mdi-book-education:before {
  content: "󱛉";
}

.mdi-book-education-outline:before {
  content: "󱛊";
}

.mdi-book-heart:before {
  content: "󱨝";
}

.mdi-book-heart-outline:before {
  content: "󱨞";
}

.mdi-book-information-variant:before {
  content: "󱁯";
}

.mdi-book-lock:before {
  content: "󰞚";
}

.mdi-book-lock-open:before {
  content: "󰞛";
}

.mdi-book-lock-open-outline:before {
  content: "󱚎";
}

.mdi-book-lock-outline:before {
  content: "󱚏";
}

.mdi-book-marker:before {
  content: "󱚐";
}

.mdi-book-marker-outline:before {
  content: "󱚑";
}

.mdi-book-minus:before {
  content: "󰗙";
}

.mdi-book-minus-multiple:before {
  content: "󰪔";
}

.mdi-book-minus-multiple-outline:before {
  content: "󰤋";
}

.mdi-book-minus-outline:before {
  content: "󱚒";
}

.mdi-book-multiple:before {
  content: "󰂻";
}

.mdi-book-multiple-outline:before {
  content: "󰐶";
}

.mdi-book-music:before {
  content: "󰁧";
}

.mdi-book-music-outline:before {
  content: "󱚓";
}

.mdi-book-off:before {
  content: "󱚔";
}

.mdi-book-off-outline:before {
  content: "󱚕";
}

.mdi-book-open:before {
  content: "󰂽";
}

.mdi-book-open-blank-variant:before {
  content: "󰂾";
}

.mdi-book-open-blank-variant-outline:before {
  content: "󱳋";
}

.mdi-book-open-outline:before {
  content: "󰭣";
}

.mdi-book-open-page-variant:before {
  content: "󰗚";
}

.mdi-book-open-page-variant-outline:before {
  content: "󱗖";
}

.mdi-book-open-variant:before {
  content: "󱓷";
}

.mdi-book-open-variant-outline:before {
  content: "󱳌";
}

.mdi-book-outline:before {
  content: "󰭤";
}

.mdi-book-play:before {
  content: "󰺂";
}

.mdi-book-play-outline:before {
  content: "󰺃";
}

.mdi-book-plus:before {
  content: "󰗛";
}

.mdi-book-plus-multiple:before {
  content: "󰪕";
}

.mdi-book-plus-multiple-outline:before {
  content: "󰫞";
}

.mdi-book-plus-outline:before {
  content: "󱚖";
}

.mdi-book-refresh:before {
  content: "󱚗";
}

.mdi-book-refresh-outline:before {
  content: "󱚘";
}

.mdi-book-remove:before {
  content: "󰪗";
}

.mdi-book-remove-multiple:before {
  content: "󰪖";
}

.mdi-book-remove-multiple-outline:before {
  content: "󰓊";
}

.mdi-book-remove-outline:before {
  content: "󱚙";
}

.mdi-book-search:before {
  content: "󰺄";
}

.mdi-book-search-outline:before {
  content: "󰺅";
}

.mdi-book-settings:before {
  content: "󱚚";
}

.mdi-book-settings-outline:before {
  content: "󱚛";
}

.mdi-book-sync:before {
  content: "󱚜";
}

.mdi-book-sync-outline:before {
  content: "󱛈";
}

.mdi-book-variant:before {
  content: "󰂿";
}

.mdi-bookmark:before {
  content: "󰃀";
}

.mdi-bookmark-box:before {
  content: "󱭵";
}

.mdi-bookmark-box-multiple:before {
  content: "󱥬";
}

.mdi-bookmark-box-multiple-outline:before {
  content: "󱥭";
}

.mdi-bookmark-box-outline:before {
  content: "󱭶";
}

.mdi-bookmark-check:before {
  content: "󰃁";
}

.mdi-bookmark-check-outline:before {
  content: "󱍻";
}

.mdi-bookmark-minus:before {
  content: "󰧌";
}

.mdi-bookmark-minus-outline:before {
  content: "󰧍";
}

.mdi-bookmark-multiple:before {
  content: "󰸕";
}

.mdi-bookmark-multiple-outline:before {
  content: "󰸖";
}

.mdi-bookmark-music:before {
  content: "󰃂";
}

.mdi-bookmark-music-outline:before {
  content: "󱍹";
}

.mdi-bookmark-off:before {
  content: "󰧎";
}

.mdi-bookmark-off-outline:before {
  content: "󰧏";
}

.mdi-bookmark-outline:before {
  content: "󰃃";
}

.mdi-bookmark-plus:before {
  content: "󰃅";
}

.mdi-bookmark-plus-outline:before {
  content: "󰃄";
}

.mdi-bookmark-remove:before {
  content: "󰃆";
}

.mdi-bookmark-remove-outline:before {
  content: "󱍺";
}

.mdi-bookshelf:before {
  content: "󱉟";
}

.mdi-boom-gate:before {
  content: "󰺆";
}

.mdi-boom-gate-alert:before {
  content: "󰺇";
}

.mdi-boom-gate-alert-outline:before {
  content: "󰺈";
}

.mdi-boom-gate-arrow-down:before {
  content: "󰺉";
}

.mdi-boom-gate-arrow-down-outline:before {
  content: "󰺊";
}

.mdi-boom-gate-arrow-up:before {
  content: "󰺌";
}

.mdi-boom-gate-arrow-up-outline:before {
  content: "󰺍";
}

.mdi-boom-gate-outline:before {
  content: "󰺋";
}

.mdi-boom-gate-up:before {
  content: "󱟹";
}

.mdi-boom-gate-up-outline:before {
  content: "󱟺";
}

.mdi-boombox:before {
  content: "󰗜";
}

.mdi-boomerang:before {
  content: "󱃏";
}

.mdi-bootstrap:before {
  content: "󰛆";
}

.mdi-border-all:before {
  content: "󰃇";
}

.mdi-border-all-variant:before {
  content: "󰢡";
}

.mdi-border-bottom:before {
  content: "󰃈";
}

.mdi-border-bottom-variant:before {
  content: "󰢢";
}

.mdi-border-color:before {
  content: "󰃉";
}

.mdi-border-horizontal:before {
  content: "󰃊";
}

.mdi-border-inside:before {
  content: "󰃋";
}

.mdi-border-left:before {
  content: "󰃌";
}

.mdi-border-left-variant:before {
  content: "󰢣";
}

.mdi-border-none:before {
  content: "󰃍";
}

.mdi-border-none-variant:before {
  content: "󰢤";
}

.mdi-border-outside:before {
  content: "󰃎";
}

.mdi-border-radius:before {
  content: "󱫴";
}

.mdi-border-right:before {
  content: "󰃏";
}

.mdi-border-right-variant:before {
  content: "󰢥";
}

.mdi-border-style:before {
  content: "󰃐";
}

.mdi-border-top:before {
  content: "󰃑";
}

.mdi-border-top-variant:before {
  content: "󰢦";
}

.mdi-border-vertical:before {
  content: "󰃒";
}

.mdi-bottle-soda:before {
  content: "󱁰";
}

.mdi-bottle-soda-classic:before {
  content: "󱁱";
}

.mdi-bottle-soda-classic-outline:before {
  content: "󱍣";
}

.mdi-bottle-soda-outline:before {
  content: "󱁲";
}

.mdi-bottle-tonic:before {
  content: "󱄮";
}

.mdi-bottle-tonic-outline:before {
  content: "󱄯";
}

.mdi-bottle-tonic-plus:before {
  content: "󱄰";
}

.mdi-bottle-tonic-plus-outline:before {
  content: "󱄱";
}

.mdi-bottle-tonic-skull:before {
  content: "󱄲";
}

.mdi-bottle-tonic-skull-outline:before {
  content: "󱄳";
}

.mdi-bottle-wine:before {
  content: "󰡔";
}

.mdi-bottle-wine-outline:before {
  content: "󱌐";
}

.mdi-bow-arrow:before {
  content: "󱡁";
}

.mdi-bow-tie:before {
  content: "󰙸";
}

.mdi-bowl:before {
  content: "󰊎";
}

.mdi-bowl-mix:before {
  content: "󰘗";
}

.mdi-bowl-mix-outline:before {
  content: "󰋤";
}

.mdi-bowl-outline:before {
  content: "󰊩";
}

.mdi-bowling:before {
  content: "󰃓";
}

.mdi-box:before {
  content: "󰃔";
}

.mdi-box-cutter:before {
  content: "󰃕";
}

.mdi-box-cutter-off:before {
  content: "󰭊";
}

.mdi-box-shadow:before {
  content: "󰘷";
}

.mdi-boxing-glove:before {
  content: "󰭥";
}

.mdi-braille:before {
  content: "󰧐";
}

.mdi-brain:before {
  content: "󰧑";
}

.mdi-bread-slice:before {
  content: "󰳮";
}

.mdi-bread-slice-outline:before {
  content: "󰳯";
}

.mdi-bridge:before {
  content: "󰘘";
}

.mdi-briefcase:before {
  content: "󰃖";
}

.mdi-briefcase-account:before {
  content: "󰳰";
}

.mdi-briefcase-account-outline:before {
  content: "󰳱";
}

.mdi-briefcase-arrow-left-right:before {
  content: "󱪍";
}

.mdi-briefcase-arrow-left-right-outline:before {
  content: "󱪎";
}

.mdi-briefcase-arrow-up-down:before {
  content: "󱪏";
}

.mdi-briefcase-arrow-up-down-outline:before {
  content: "󱪐";
}

.mdi-briefcase-check:before {
  content: "󰃗";
}

.mdi-briefcase-check-outline:before {
  content: "󱌞";
}

.mdi-briefcase-clock:before {
  content: "󱃐";
}

.mdi-briefcase-clock-outline:before {
  content: "󱃑";
}

.mdi-briefcase-download:before {
  content: "󰃘";
}

.mdi-briefcase-download-outline:before {
  content: "󰰽";
}

.mdi-briefcase-edit:before {
  content: "󰪘";
}

.mdi-briefcase-edit-outline:before {
  content: "󰰾";
}

.mdi-briefcase-eye:before {
  content: "󱟙";
}

.mdi-briefcase-eye-outline:before {
  content: "󱟚";
}

.mdi-briefcase-minus:before {
  content: "󰨪";
}

.mdi-briefcase-minus-outline:before {
  content: "󰰿";
}

.mdi-briefcase-off:before {
  content: "󱙘";
}

.mdi-briefcase-off-outline:before {
  content: "󱙙";
}

.mdi-briefcase-outline:before {
  content: "󰠔";
}

.mdi-briefcase-plus:before {
  content: "󰨫";
}

.mdi-briefcase-plus-outline:before {
  content: "󰱀";
}

.mdi-briefcase-remove:before {
  content: "󰨬";
}

.mdi-briefcase-remove-outline:before {
  content: "󰱁";
}

.mdi-briefcase-search:before {
  content: "󰨭";
}

.mdi-briefcase-search-outline:before {
  content: "󰱂";
}

.mdi-briefcase-upload:before {
  content: "󰃙";
}

.mdi-briefcase-upload-outline:before {
  content: "󰱃";
}

.mdi-briefcase-variant:before {
  content: "󱒔";
}

.mdi-briefcase-variant-off:before {
  content: "󱙚";
}

.mdi-briefcase-variant-off-outline:before {
  content: "󱙛";
}

.mdi-briefcase-variant-outline:before {
  content: "󱒕";
}

.mdi-brightness-1:before {
  content: "󰃚";
}

.mdi-brightness-2:before {
  content: "󰃛";
}

.mdi-brightness-3:before {
  content: "󰃜";
}

.mdi-brightness-4:before {
  content: "󰃝";
}

.mdi-brightness-5:before {
  content: "󰃞";
}

.mdi-brightness-6:before {
  content: "󰃟";
}

.mdi-brightness-7:before {
  content: "󰃠";
}

.mdi-brightness-auto:before {
  content: "󰃡";
}

.mdi-brightness-percent:before {
  content: "󰳲";
}

.mdi-broadcast:before {
  content: "󱜠";
}

.mdi-broadcast-off:before {
  content: "󱜡";
}

.mdi-broom:before {
  content: "󰃢";
}

.mdi-brush:before {
  content: "󰃣";
}

.mdi-brush-off:before {
  content: "󱝱";
}

.mdi-brush-outline:before {
  content: "󱨍";
}

.mdi-brush-variant:before {
  content: "󱠓";
}

.mdi-bucket:before {
  content: "󱐕";
}

.mdi-bucket-outline:before {
  content: "󱐖";
}

.mdi-buffet:before {
  content: "󰕸";
}

.mdi-bug:before {
  content: "󰃤";
}

.mdi-bug-check:before {
  content: "󰨮";
}

.mdi-bug-check-outline:before {
  content: "󰨯";
}

.mdi-bug-outline:before {
  content: "󰨰";
}

.mdi-bug-pause:before {
  content: "󱫵";
}

.mdi-bug-pause-outline:before {
  content: "󱫶";
}

.mdi-bug-play:before {
  content: "󱫷";
}

.mdi-bug-play-outline:before {
  content: "󱫸";
}

.mdi-bug-stop:before {
  content: "󱫹";
}

.mdi-bug-stop-outline:before {
  content: "󱫺";
}

.mdi-bugle:before {
  content: "󰶴";
}

.mdi-bulkhead-light:before {
  content: "󱨯";
}

.mdi-bulldozer:before {
  content: "󰬢";
}

.mdi-bullet:before {
  content: "󰳳";
}

.mdi-bulletin-board:before {
  content: "󰃥";
}

.mdi-bullhorn:before {
  content: "󰃦";
}

.mdi-bullhorn-outline:before {
  content: "󰬣";
}

.mdi-bullhorn-variant:before {
  content: "󱥮";
}

.mdi-bullhorn-variant-outline:before {
  content: "󱥯";
}

.mdi-bullseye:before {
  content: "󰗝";
}

.mdi-bullseye-arrow:before {
  content: "󰣉";
}

.mdi-bulma:before {
  content: "󱋧";
}

.mdi-bunk-bed:before {
  content: "󱌂";
}

.mdi-bunk-bed-outline:before {
  content: "󰂗";
}

.mdi-bus:before {
  content: "󰃧";
}

.mdi-bus-alert:before {
  content: "󰪙";
}

.mdi-bus-articulated-end:before {
  content: "󰞜";
}

.mdi-bus-articulated-front:before {
  content: "󰞝";
}

.mdi-bus-clock:before {
  content: "󰣊";
}

.mdi-bus-double-decker:before {
  content: "󰞞";
}

.mdi-bus-electric:before {
  content: "󱤝";
}

.mdi-bus-marker:before {
  content: "󱈒";
}

.mdi-bus-multiple:before {
  content: "󰼿";
}

.mdi-bus-school:before {
  content: "󰞟";
}

.mdi-bus-side:before {
  content: "󰞠";
}

.mdi-bus-sign:before {
  content: "󱳁";
}

.mdi-bus-stop:before {
  content: "󱀒";
}

.mdi-bus-stop-covered:before {
  content: "󱀓";
}

.mdi-bus-stop-uncovered:before {
  content: "󱀔";
}

.mdi-bus-wrench:before {
  content: "󱳂";
}

.mdi-butterfly:before {
  content: "󱖉";
}

.mdi-butterfly-outline:before {
  content: "󱖊";
}

.mdi-button-cursor:before {
  content: "󱭏";
}

.mdi-button-pointer:before {
  content: "󱭐";
}

.mdi-cabin-a-frame:before {
  content: "󱢌";
}

.mdi-cable-data:before {
  content: "󱎔";
}

.mdi-cached:before {
  content: "󰃨";
}

.mdi-cactus:before {
  content: "󰶵";
}

.mdi-cake:before {
  content: "󰃩";
}

.mdi-cake-layered:before {
  content: "󰃪";
}

.mdi-cake-variant:before {
  content: "󰃫";
}

.mdi-cake-variant-outline:before {
  content: "󱟰";
}

.mdi-calculator:before {
  content: "󰃬";
}

.mdi-calculator-variant:before {
  content: "󰪚";
}

.mdi-calculator-variant-outline:before {
  content: "󱖦";
}

.mdi-calendar:before {
  content: "󰃭";
}

.mdi-calendar-account:before {
  content: "󰻗";
}

.mdi-calendar-account-outline:before {
  content: "󰻘";
}

.mdi-calendar-alert:before {
  content: "󰨱";
}

.mdi-calendar-alert-outline:before {
  content: "󱭢";
}

.mdi-calendar-arrow-left:before {
  content: "󱄴";
}

.mdi-calendar-arrow-right:before {
  content: "󱄵";
}

.mdi-calendar-badge:before {
  content: "󱮝";
}

.mdi-calendar-badge-outline:before {
  content: "󱮞";
}

.mdi-calendar-blank:before {
  content: "󰃮";
}

.mdi-calendar-blank-multiple:before {
  content: "󱁳";
}

.mdi-calendar-blank-outline:before {
  content: "󰭦";
}

.mdi-calendar-check:before {
  content: "󰃯";
}

.mdi-calendar-check-outline:before {
  content: "󰱄";
}

.mdi-calendar-clock:before {
  content: "󰃰";
}

.mdi-calendar-clock-outline:before {
  content: "󱛡";
}

.mdi-calendar-collapse-horizontal:before {
  content: "󱢝";
}

.mdi-calendar-collapse-horizontal-outline:before {
  content: "󱭣";
}

.mdi-calendar-cursor:before {
  content: "󱕻";
}

.mdi-calendar-cursor-outline:before {
  content: "󱭤";
}

.mdi-calendar-edit:before {
  content: "󰢧";
}

.mdi-calendar-edit-outline:before {
  content: "󱭥";
}

.mdi-calendar-end:before {
  content: "󱙬";
}

.mdi-calendar-end-outline:before {
  content: "󱭦";
}

.mdi-calendar-expand-horizontal:before {
  content: "󱢞";
}

.mdi-calendar-expand-horizontal-outline:before {
  content: "󱭧";
}

.mdi-calendar-export:before {
  content: "󰬤";
}

.mdi-calendar-export-outline:before {
  content: "󱭨";
}

.mdi-calendar-filter:before {
  content: "󱨲";
}

.mdi-calendar-filter-outline:before {
  content: "󱨳";
}

.mdi-calendar-heart:before {
  content: "󰧒";
}

.mdi-calendar-heart-outline:before {
  content: "󱭩";
}

.mdi-calendar-import:before {
  content: "󰬥";
}

.mdi-calendar-import-outline:before {
  content: "󱭪";
}

.mdi-calendar-lock:before {
  content: "󱙁";
}

.mdi-calendar-lock-open:before {
  content: "󱭛";
}

.mdi-calendar-lock-open-outline:before {
  content: "󱭜";
}

.mdi-calendar-lock-outline:before {
  content: "󱙂";
}

.mdi-calendar-minus:before {
  content: "󰵜";
}

.mdi-calendar-minus-outline:before {
  content: "󱭫";
}

.mdi-calendar-month:before {
  content: "󰸗";
}

.mdi-calendar-month-outline:before {
  content: "󰸘";
}

.mdi-calendar-multiple:before {
  content: "󰃱";
}

.mdi-calendar-multiple-check:before {
  content: "󰃲";
}

.mdi-calendar-multiselect:before {
  content: "󰨲";
}

.mdi-calendar-multiselect-outline:before {
  content: "󱭕";
}

.mdi-calendar-outline:before {
  content: "󰭧";
}

.mdi-calendar-plus:before {
  content: "󰃳";
}

.mdi-calendar-plus-outline:before {
  content: "󱭬";
}

.mdi-calendar-question:before {
  content: "󰚒";
}

.mdi-calendar-question-outline:before {
  content: "󱭭";
}

.mdi-calendar-range:before {
  content: "󰙹";
}

.mdi-calendar-range-outline:before {
  content: "󰭨";
}

.mdi-calendar-refresh:before {
  content: "󰇡";
}

.mdi-calendar-refresh-outline:before {
  content: "󰈃";
}

.mdi-calendar-remove:before {
  content: "󰃴";
}

.mdi-calendar-remove-outline:before {
  content: "󰱅";
}

.mdi-calendar-search:before {
  content: "󰥌";
}

.mdi-calendar-search-outline:before {
  content: "󱭮";
}

.mdi-calendar-star:before {
  content: "󰧓";
}

.mdi-calendar-star-four-points:before {
  content: "󱰟";
}

.mdi-calendar-star-outline:before {
  content: "󱭓";
}

.mdi-calendar-start:before {
  content: "󱙭";
}

.mdi-calendar-start-outline:before {
  content: "󱭯";
}

.mdi-calendar-sync:before {
  content: "󰺎";
}

.mdi-calendar-sync-outline:before {
  content: "󰺏";
}

.mdi-calendar-text:before {
  content: "󰃵";
}

.mdi-calendar-text-outline:before {
  content: "󰱆";
}

.mdi-calendar-today:before {
  content: "󰃶";
}

.mdi-calendar-today-outline:before {
  content: "󱨰";
}

.mdi-calendar-week:before {
  content: "󰨳";
}

.mdi-calendar-week-begin:before {
  content: "󰨴";
}

.mdi-calendar-week-begin-outline:before {
  content: "󱨱";
}

.mdi-calendar-week-outline:before {
  content: "󱨴";
}

.mdi-calendar-weekend:before {
  content: "󰻙";
}

.mdi-calendar-weekend-outline:before {
  content: "󰻚";
}

.mdi-call-made:before {
  content: "󰃷";
}

.mdi-call-merge:before {
  content: "󰃸";
}

.mdi-call-missed:before {
  content: "󰃹";
}

.mdi-call-received:before {
  content: "󰃺";
}

.mdi-call-split:before {
  content: "󰃻";
}

.mdi-camcorder:before {
  content: "󰃼";
}

.mdi-camcorder-off:before {
  content: "󰃿";
}

.mdi-camera:before {
  content: "󰄀";
}

.mdi-camera-account:before {
  content: "󰣋";
}

.mdi-camera-burst:before {
  content: "󰚓";
}

.mdi-camera-control:before {
  content: "󰭩";
}

.mdi-camera-document:before {
  content: "󱡱";
}

.mdi-camera-document-off:before {
  content: "󱡲";
}

.mdi-camera-enhance:before {
  content: "󰄁";
}

.mdi-camera-enhance-outline:before {
  content: "󰭪";
}

.mdi-camera-flip:before {
  content: "󱗙";
}

.mdi-camera-flip-outline:before {
  content: "󱗚";
}

.mdi-camera-front:before {
  content: "󰄂";
}

.mdi-camera-front-variant:before {
  content: "󰄃";
}

.mdi-camera-gopro:before {
  content: "󰞡";
}

.mdi-camera-image:before {
  content: "󰣌";
}

.mdi-camera-iris:before {
  content: "󰄄";
}

.mdi-camera-lock:before {
  content: "󱨔";
}

.mdi-camera-lock-open:before {
  content: "󱰍";
}

.mdi-camera-lock-open-outline:before {
  content: "󱰎";
}

.mdi-camera-lock-outline:before {
  content: "󱨕";
}

.mdi-camera-marker:before {
  content: "󱦧";
}

.mdi-camera-marker-outline:before {
  content: "󱦨";
}

.mdi-camera-metering-center:before {
  content: "󰞢";
}

.mdi-camera-metering-matrix:before {
  content: "󰞣";
}

.mdi-camera-metering-partial:before {
  content: "󰞤";
}

.mdi-camera-metering-spot:before {
  content: "󰞥";
}

.mdi-camera-off:before {
  content: "󰗟";
}

.mdi-camera-off-outline:before {
  content: "󱦿";
}

.mdi-camera-outline:before {
  content: "󰵝";
}

.mdi-camera-party-mode:before {
  content: "󰄅";
}

.mdi-camera-plus:before {
  content: "󰻛";
}

.mdi-camera-plus-outline:before {
  content: "󰻜";
}

.mdi-camera-rear:before {
  content: "󰄆";
}

.mdi-camera-rear-variant:before {
  content: "󰄇";
}

.mdi-camera-retake:before {
  content: "󰸙";
}

.mdi-camera-retake-outline:before {
  content: "󰸚";
}

.mdi-camera-switch:before {
  content: "󰄈";
}

.mdi-camera-switch-outline:before {
  content: "󰡊";
}

.mdi-camera-timer:before {
  content: "󰄉";
}

.mdi-camera-wireless:before {
  content: "󰶶";
}

.mdi-camera-wireless-outline:before {
  content: "󰶷";
}

.mdi-campfire:before {
  content: "󰻝";
}

.mdi-cancel:before {
  content: "󰜺";
}

.mdi-candelabra:before {
  content: "󱟒";
}

.mdi-candelabra-fire:before {
  content: "󱟓";
}

.mdi-candle:before {
  content: "󰗢";
}

.mdi-candy:before {
  content: "󱥰";
}

.mdi-candy-off:before {
  content: "󱥱";
}

.mdi-candy-off-outline:before {
  content: "󱥲";
}

.mdi-candy-outline:before {
  content: "󱥳";
}

.mdi-candycane:before {
  content: "󰄊";
}

.mdi-cannabis:before {
  content: "󰞦";
}

.mdi-cannabis-off:before {
  content: "󱙮";
}

.mdi-caps-lock:before {
  content: "󰪛";
}

.mdi-car:before {
  content: "󰄋";
}

.mdi-car-2-plus:before {
  content: "󱀕";
}

.mdi-car-3-plus:before {
  content: "󱀖";
}

.mdi-car-arrow-left:before {
  content: "󱎲";
}

.mdi-car-arrow-right:before {
  content: "󱎳";
}

.mdi-car-back:before {
  content: "󰸛";
}

.mdi-car-battery:before {
  content: "󰄌";
}

.mdi-car-brake-abs:before {
  content: "󰱇";
}

.mdi-car-brake-alert:before {
  content: "󰱈";
}

.mdi-car-brake-fluid-level:before {
  content: "󱤉";
}

.mdi-car-brake-hold:before {
  content: "󰵞";
}

.mdi-car-brake-low-pressure:before {
  content: "󱤊";
}

.mdi-car-brake-parking:before {
  content: "󰵟";
}

.mdi-car-brake-retarder:before {
  content: "󱀗";
}

.mdi-car-brake-temperature:before {
  content: "󱤋";
}

.mdi-car-brake-worn-linings:before {
  content: "󱤌";
}

.mdi-car-child-seat:before {
  content: "󰾣";
}

.mdi-car-clock:before {
  content: "󱥴";
}

.mdi-car-clutch:before {
  content: "󱀘";
}

.mdi-car-cog:before {
  content: "󱏌";
}

.mdi-car-connected:before {
  content: "󰄍";
}

.mdi-car-convertible:before {
  content: "󰞧";
}

.mdi-car-coolant-level:before {
  content: "󱀙";
}

.mdi-car-cruise-control:before {
  content: "󰵠";
}

.mdi-car-defrost-front:before {
  content: "󰵡";
}

.mdi-car-defrost-rear:before {
  content: "󰵢";
}

.mdi-car-door:before {
  content: "󰭫";
}

.mdi-car-door-lock:before {
  content: "󱂝";
}

.mdi-car-door-lock-open:before {
  content: "󱲁";
}

.mdi-car-electric:before {
  content: "󰭬";
}

.mdi-car-electric-outline:before {
  content: "󱖵";
}

.mdi-car-emergency:before {
  content: "󱘏";
}

.mdi-car-esp:before {
  content: "󰱉";
}

.mdi-car-estate:before {
  content: "󰞨";
}

.mdi-car-hatchback:before {
  content: "󰞩";
}

.mdi-car-info:before {
  content: "󱆾";
}

.mdi-car-key:before {
  content: "󰭭";
}

.mdi-car-lifted-pickup:before {
  content: "󱔭";
}

.mdi-car-light-alert:before {
  content: "󱤍";
}

.mdi-car-light-dimmed:before {
  content: "󰱊";
}

.mdi-car-light-fog:before {
  content: "󰱋";
}

.mdi-car-light-high:before {
  content: "󰱌";
}

.mdi-car-limousine:before {
  content: "󰣍";
}

.mdi-car-multiple:before {
  content: "󰭮";
}

.mdi-car-off:before {
  content: "󰸜";
}

.mdi-car-outline:before {
  content: "󱓭";
}

.mdi-car-parking-lights:before {
  content: "󰵣";
}

.mdi-car-pickup:before {
  content: "󰞪";
}

.mdi-car-search:before {
  content: "󱮍";
}

.mdi-car-search-outline:before {
  content: "󱮎";
}

.mdi-car-seat:before {
  content: "󰾤";
}

.mdi-car-seat-cooler:before {
  content: "󰾥";
}

.mdi-car-seat-heater:before {
  content: "󰾦";
}

.mdi-car-select:before {
  content: "󱡹";
}

.mdi-car-settings:before {
  content: "󱏍";
}

.mdi-car-shift-pattern:before {
  content: "󰽀";
}

.mdi-car-side:before {
  content: "󰞫";
}

.mdi-car-speed-limiter:before {
  content: "󱤎";
}

.mdi-car-sports:before {
  content: "󰞬";
}

.mdi-car-tire-alert:before {
  content: "󰱍";
}

.mdi-car-traction-control:before {
  content: "󰵤";
}

.mdi-car-turbocharger:before {
  content: "󱀚";
}

.mdi-car-wash:before {
  content: "󰄎";
}

.mdi-car-windshield:before {
  content: "󱀛";
}

.mdi-car-windshield-outline:before {
  content: "󱀜";
}

.mdi-car-wireless:before {
  content: "󱡸";
}

.mdi-car-wrench:before {
  content: "󱠔";
}

.mdi-carabiner:before {
  content: "󱓀";
}

.mdi-caravan:before {
  content: "󰞭";
}

.mdi-card:before {
  content: "󰭯";
}

.mdi-card-account-details:before {
  content: "󰗒";
}

.mdi-card-account-details-outline:before {
  content: "󰶫";
}

.mdi-card-account-details-star:before {
  content: "󰊣";
}

.mdi-card-account-details-star-outline:before {
  content: "󰛛";
}

.mdi-card-account-mail:before {
  content: "󰆎";
}

.mdi-card-account-mail-outline:before {
  content: "󰺘";
}

.mdi-card-account-phone:before {
  content: "󰺙";
}

.mdi-card-account-phone-outline:before {
  content: "󰺚";
}

.mdi-card-bulleted:before {
  content: "󰭰";
}

.mdi-card-bulleted-off:before {
  content: "󰭱";
}

.mdi-card-bulleted-off-outline:before {
  content: "󰭲";
}

.mdi-card-bulleted-outline:before {
  content: "󰭳";
}

.mdi-card-bulleted-settings:before {
  content: "󰭴";
}

.mdi-card-bulleted-settings-outline:before {
  content: "󰭵";
}

.mdi-card-minus:before {
  content: "󱘀";
}

.mdi-card-minus-outline:before {
  content: "󱘁";
}

.mdi-card-multiple:before {
  content: "󱟱";
}

.mdi-card-multiple-outline:before {
  content: "󱟲";
}

.mdi-card-off:before {
  content: "󱘂";
}

.mdi-card-off-outline:before {
  content: "󱘃";
}

.mdi-card-outline:before {
  content: "󰭶";
}

.mdi-card-plus:before {
  content: "󱇿";
}

.mdi-card-plus-outline:before {
  content: "󱈀";
}

.mdi-card-remove:before {
  content: "󱘄";
}

.mdi-card-remove-outline:before {
  content: "󱘅";
}

.mdi-card-search:before {
  content: "󱁴";
}

.mdi-card-search-outline:before {
  content: "󱁵";
}

.mdi-card-text:before {
  content: "󰭷";
}

.mdi-card-text-outline:before {
  content: "󰭸";
}

.mdi-cards:before {
  content: "󰘸";
}

.mdi-cards-club:before {
  content: "󰣎";
}

.mdi-cards-club-outline:before {
  content: "󱢟";
}

.mdi-cards-diamond:before {
  content: "󰣏";
}

.mdi-cards-diamond-outline:before {
  content: "󱀝";
}

.mdi-cards-heart:before {
  content: "󰣐";
}

.mdi-cards-heart-outline:before {
  content: "󱢠";
}

.mdi-cards-outline:before {
  content: "󰘹";
}

.mdi-cards-playing:before {
  content: "󱢡";
}

.mdi-cards-playing-club:before {
  content: "󱢢";
}

.mdi-cards-playing-club-multiple:before {
  content: "󱢣";
}

.mdi-cards-playing-club-multiple-outline:before {
  content: "󱢤";
}

.mdi-cards-playing-club-outline:before {
  content: "󱢥";
}

.mdi-cards-playing-diamond:before {
  content: "󱢦";
}

.mdi-cards-playing-diamond-multiple:before {
  content: "󱢧";
}

.mdi-cards-playing-diamond-multiple-outline:before {
  content: "󱢨";
}

.mdi-cards-playing-diamond-outline:before {
  content: "󱢩";
}

.mdi-cards-playing-heart:before {
  content: "󱢪";
}

.mdi-cards-playing-heart-multiple:before {
  content: "󱢫";
}

.mdi-cards-playing-heart-multiple-outline:before {
  content: "󱢬";
}

.mdi-cards-playing-heart-outline:before {
  content: "󱢭";
}

.mdi-cards-playing-outline:before {
  content: "󰘺";
}

.mdi-cards-playing-spade:before {
  content: "󱢮";
}

.mdi-cards-playing-spade-multiple:before {
  content: "󱢯";
}

.mdi-cards-playing-spade-multiple-outline:before {
  content: "󱢰";
}

.mdi-cards-playing-spade-outline:before {
  content: "󱢱";
}

.mdi-cards-spade:before {
  content: "󰣑";
}

.mdi-cards-spade-outline:before {
  content: "󱢲";
}

.mdi-cards-variant:before {
  content: "󰛇";
}

.mdi-carrot:before {
  content: "󰄏";
}

.mdi-cart:before {
  content: "󰄐";
}

.mdi-cart-arrow-down:before {
  content: "󰵦";
}

.mdi-cart-arrow-right:before {
  content: "󰱎";
}

.mdi-cart-arrow-up:before {
  content: "󰵧";
}

.mdi-cart-check:before {
  content: "󱗪";
}

.mdi-cart-heart:before {
  content: "󱣠";
}

.mdi-cart-minus:before {
  content: "󰵨";
}

.mdi-cart-off:before {
  content: "󰙫";
}

.mdi-cart-outline:before {
  content: "󰄑";
}

.mdi-cart-percent:before {
  content: "󱮮";
}

.mdi-cart-plus:before {
  content: "󰄒";
}

.mdi-cart-remove:before {
  content: "󰵩";
}

.mdi-cart-variant:before {
  content: "󱗫";
}

.mdi-case-sensitive-alt:before {
  content: "󰄓";
}

.mdi-cash:before {
  content: "󰄔";
}

.mdi-cash-100:before {
  content: "󰄕";
}

.mdi-cash-check:before {
  content: "󱓮";
}

.mdi-cash-clock:before {
  content: "󱪑";
}

.mdi-cash-edit:before {
  content: "󱲫";
}

.mdi-cash-fast:before {
  content: "󱡜";
}

.mdi-cash-lock:before {
  content: "󱓪";
}

.mdi-cash-lock-open:before {
  content: "󱓫";
}

.mdi-cash-marker:before {
  content: "󰶸";
}

.mdi-cash-minus:before {
  content: "󱉠";
}

.mdi-cash-multiple:before {
  content: "󰄖";
}

.mdi-cash-off:before {
  content: "󱱹";
}

.mdi-cash-plus:before {
  content: "󱉡";
}

.mdi-cash-refund:before {
  content: "󰪜";
}

.mdi-cash-register:before {
  content: "󰳴";
}

.mdi-cash-remove:before {
  content: "󱉢";
}

.mdi-cash-sync:before {
  content: "󱪒";
}

.mdi-cassette:before {
  content: "󰧔";
}

.mdi-cast:before {
  content: "󰄘";
}

.mdi-cast-audio:before {
  content: "󱀞";
}

.mdi-cast-audio-variant:before {
  content: "󱝉";
}

.mdi-cast-connected:before {
  content: "󰄙";
}

.mdi-cast-education:before {
  content: "󰸝";
}

.mdi-cast-off:before {
  content: "󰞊";
}

.mdi-cast-variant:before {
  content: "󰀟";
}

.mdi-castle:before {
  content: "󰄚";
}

.mdi-cat:before {
  content: "󰄛";
}

.mdi-cctv:before {
  content: "󰞮";
}

.mdi-cctv-off:before {
  content: "󱡟";
}

.mdi-ceiling-fan:before {
  content: "󱞗";
}

.mdi-ceiling-fan-light:before {
  content: "󱞘";
}

.mdi-ceiling-light:before {
  content: "󰝩";
}

.mdi-ceiling-light-multiple:before {
  content: "󱣝";
}

.mdi-ceiling-light-multiple-outline:before {
  content: "󱣞";
}

.mdi-ceiling-light-outline:before {
  content: "󱟇";
}

.mdi-cellphone:before {
  content: "󰄜";
}

.mdi-cellphone-arrow-down:before {
  content: "󰧕";
}

.mdi-cellphone-arrow-down-variant:before {
  content: "󱧅";
}

.mdi-cellphone-basic:before {
  content: "󰄞";
}

.mdi-cellphone-charging:before {
  content: "󱎗";
}

.mdi-cellphone-check:before {
  content: "󱟽";
}

.mdi-cellphone-cog:before {
  content: "󰥑";
}

.mdi-cellphone-dock:before {
  content: "󰄟";
}

.mdi-cellphone-information:before {
  content: "󰽁";
}

.mdi-cellphone-key:before {
  content: "󰥎";
}

.mdi-cellphone-link:before {
  content: "󰄡";
}

.mdi-cellphone-link-off:before {
  content: "󰄢";
}

.mdi-cellphone-lock:before {
  content: "󰥏";
}

.mdi-cellphone-marker:before {
  content: "󱠺";
}

.mdi-cellphone-message:before {
  content: "󰣓";
}

.mdi-cellphone-message-off:before {
  content: "󱃒";
}

.mdi-cellphone-nfc:before {
  content: "󰺐";
}

.mdi-cellphone-nfc-off:before {
  content: "󱋘";
}

.mdi-cellphone-off:before {
  content: "󰥐";
}

.mdi-cellphone-play:before {
  content: "󱀟";
}

.mdi-cellphone-remove:before {
  content: "󰥍";
}

.mdi-cellphone-screenshot:before {
  content: "󰨵";
}

.mdi-cellphone-settings:before {
  content: "󰄣";
}

.mdi-cellphone-sound:before {
  content: "󰥒";
}

.mdi-cellphone-text:before {
  content: "󰣒";
}

.mdi-cellphone-wireless:before {
  content: "󰠕";
}

.mdi-centos:before {
  content: "󱄚";
}

.mdi-certificate:before {
  content: "󰄤";
}

.mdi-certificate-outline:before {
  content: "󱆈";
}

.mdi-chair-rolling:before {
  content: "󰽈";
}

.mdi-chair-school:before {
  content: "󰄥";
}

.mdi-chandelier:before {
  content: "󱞓";
}

.mdi-charity:before {
  content: "󰱏";
}

.mdi-charity-search:before {
  content: "󱲂";
}

.mdi-chart-arc:before {
  content: "󰄦";
}

.mdi-chart-areaspline:before {
  content: "󰄧";
}

.mdi-chart-areaspline-variant:before {
  content: "󰺑";
}

.mdi-chart-bar:before {
  content: "󰄨";
}

.mdi-chart-bar-stacked:before {
  content: "󰝪";
}

.mdi-chart-bell-curve:before {
  content: "󰱐";
}

.mdi-chart-bell-curve-cumulative:before {
  content: "󰾧";
}

.mdi-chart-box:before {
  content: "󱕍";
}

.mdi-chart-box-multiple:before {
  content: "󱳍";
}

.mdi-chart-box-multiple-outline:before {
  content: "󱳎";
}

.mdi-chart-box-outline:before {
  content: "󱕎";
}

.mdi-chart-box-plus-outline:before {
  content: "󱕏";
}

.mdi-chart-bubble:before {
  content: "󰗣";
}

.mdi-chart-donut:before {
  content: "󰞯";
}

.mdi-chart-donut-variant:before {
  content: "󰞰";
}

.mdi-chart-gantt:before {
  content: "󰙬";
}

.mdi-chart-histogram:before {
  content: "󰄩";
}

.mdi-chart-line:before {
  content: "󰄪";
}

.mdi-chart-line-stacked:before {
  content: "󰝫";
}

.mdi-chart-line-variant:before {
  content: "󰞱";
}

.mdi-chart-multiline:before {
  content: "󰣔";
}

.mdi-chart-multiple:before {
  content: "󱈓";
}

.mdi-chart-pie:before {
  content: "󰄫";
}

.mdi-chart-pie-outline:before {
  content: "󱯟";
}

.mdi-chart-ppf:before {
  content: "󱎀";
}

.mdi-chart-sankey:before {
  content: "󱇟";
}

.mdi-chart-sankey-variant:before {
  content: "󱇠";
}

.mdi-chart-scatter-plot:before {
  content: "󰺒";
}

.mdi-chart-scatter-plot-hexbin:before {
  content: "󰙭";
}

.mdi-chart-timeline:before {
  content: "󰙮";
}

.mdi-chart-timeline-variant:before {
  content: "󰺓";
}

.mdi-chart-timeline-variant-shimmer:before {
  content: "󱖶";
}

.mdi-chart-tree:before {
  content: "󰺔";
}

.mdi-chart-waterfall:before {
  content: "󱤘";
}

.mdi-chat:before {
  content: "󰭹";
}

.mdi-chat-alert:before {
  content: "󰭺";
}

.mdi-chat-alert-outline:before {
  content: "󱋉";
}

.mdi-chat-minus:before {
  content: "󱐐";
}

.mdi-chat-minus-outline:before {
  content: "󱐓";
}

.mdi-chat-outline:before {
  content: "󰻞";
}

.mdi-chat-plus:before {
  content: "󱐏";
}

.mdi-chat-plus-outline:before {
  content: "󱐒";
}

.mdi-chat-processing:before {
  content: "󰭻";
}

.mdi-chat-processing-outline:before {
  content: "󱋊";
}

.mdi-chat-question:before {
  content: "󱜸";
}

.mdi-chat-question-outline:before {
  content: "󱜹";
}

.mdi-chat-remove:before {
  content: "󱐑";
}

.mdi-chat-remove-outline:before {
  content: "󱐔";
}

.mdi-chat-sleep:before {
  content: "󱋑";
}

.mdi-chat-sleep-outline:before {
  content: "󱋒";
}

.mdi-check:before {
  content: "󰄬";
}

.mdi-check-all:before {
  content: "󰄭";
}

.mdi-check-bold:before {
  content: "󰸞";
}

.mdi-check-circle:before {
  content: "󰗠";
}

.mdi-check-circle-outline:before {
  content: "󰗡";
}

.mdi-check-decagram:before {
  content: "󰞑";
}

.mdi-check-decagram-outline:before {
  content: "󱝀";
}

.mdi-check-network:before {
  content: "󰱓";
}

.mdi-check-network-outline:before {
  content: "󰱔";
}

.mdi-check-outline:before {
  content: "󰡕";
}

.mdi-check-underline:before {
  content: "󰸟";
}

.mdi-check-underline-circle:before {
  content: "󰸠";
}

.mdi-check-underline-circle-outline:before {
  content: "󰸡";
}

.mdi-checkbook:before {
  content: "󰪝";
}

.mdi-checkbook-arrow-left:before {
  content: "󱰝";
}

.mdi-checkbook-arrow-right:before {
  content: "󱰞";
}

.mdi-checkbox-blank:before {
  content: "󰄮";
}

.mdi-checkbox-blank-badge:before {
  content: "󱅶";
}

.mdi-checkbox-blank-badge-outline:before {
  content: "󰄗";
}

.mdi-checkbox-blank-circle:before {
  content: "󰄯";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "󰄰";
}

.mdi-checkbox-blank-off:before {
  content: "󱋬";
}

.mdi-checkbox-blank-off-outline:before {
  content: "󱋭";
}

.mdi-checkbox-blank-outline:before {
  content: "󰄱";
}

.mdi-checkbox-intermediate:before {
  content: "󰡖";
}

.mdi-checkbox-intermediate-variant:before {
  content: "󱭔";
}

.mdi-checkbox-marked:before {
  content: "󰄲";
}

.mdi-checkbox-marked-circle:before {
  content: "󰄳";
}

.mdi-checkbox-marked-circle-auto-outline:before {
  content: "󱰦";
}

.mdi-checkbox-marked-circle-minus-outline:before {
  content: "󱰧";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "󰄴";
}

.mdi-checkbox-marked-circle-plus-outline:before {
  content: "󱤧";
}

.mdi-checkbox-marked-outline:before {
  content: "󰄵";
}

.mdi-checkbox-multiple-blank:before {
  content: "󰄶";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "󰘻";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "󰘼";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "󰄷";
}

.mdi-checkbox-multiple-marked:before {
  content: "󰄸";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "󰘽";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "󰘾";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "󰄹";
}

.mdi-checkbox-multiple-outline:before {
  content: "󰱑";
}

.mdi-checkbox-outline:before {
  content: "󰱒";
}

.mdi-checkerboard:before {
  content: "󰄺";
}

.mdi-checkerboard-minus:before {
  content: "󱈂";
}

.mdi-checkerboard-plus:before {
  content: "󱈁";
}

.mdi-checkerboard-remove:before {
  content: "󱈃";
}

.mdi-cheese:before {
  content: "󱊹";
}

.mdi-cheese-off:before {
  content: "󱏮";
}

.mdi-chef-hat:before {
  content: "󰭼";
}

.mdi-chemical-weapon:before {
  content: "󰄻";
}

.mdi-chess-bishop:before {
  content: "󰡜";
}

.mdi-chess-king:before {
  content: "󰡗";
}

.mdi-chess-knight:before {
  content: "󰡘";
}

.mdi-chess-pawn:before {
  content: "󰡙";
}

.mdi-chess-queen:before {
  content: "󰡚";
}

.mdi-chess-rook:before {
  content: "󰡛";
}

.mdi-chevron-double-down:before {
  content: "󰄼";
}

.mdi-chevron-double-left:before {
  content: "󰄽";
}

.mdi-chevron-double-right:before {
  content: "󰄾";
}

.mdi-chevron-double-up:before {
  content: "󰄿";
}

.mdi-chevron-down:before {
  content: "󰅀";
}

.mdi-chevron-down-box:before {
  content: "󰧖";
}

.mdi-chevron-down-box-outline:before {
  content: "󰧗";
}

.mdi-chevron-down-circle:before {
  content: "󰬦";
}

.mdi-chevron-down-circle-outline:before {
  content: "󰬧";
}

.mdi-chevron-left:before {
  content: "󰅁";
}

.mdi-chevron-left-box:before {
  content: "󰧘";
}

.mdi-chevron-left-box-outline:before {
  content: "󰧙";
}

.mdi-chevron-left-circle:before {
  content: "󰬨";
}

.mdi-chevron-left-circle-outline:before {
  content: "󰬩";
}

.mdi-chevron-right:before {
  content: "󰅂";
}

.mdi-chevron-right-box:before {
  content: "󰧚";
}

.mdi-chevron-right-box-outline:before {
  content: "󰧛";
}

.mdi-chevron-right-circle:before {
  content: "󰬪";
}

.mdi-chevron-right-circle-outline:before {
  content: "󰬫";
}

.mdi-chevron-triple-down:before {
  content: "󰶹";
}

.mdi-chevron-triple-left:before {
  content: "󰶺";
}

.mdi-chevron-triple-right:before {
  content: "󰶻";
}

.mdi-chevron-triple-up:before {
  content: "󰶼";
}

.mdi-chevron-up:before {
  content: "󰅃";
}

.mdi-chevron-up-box:before {
  content: "󰧜";
}

.mdi-chevron-up-box-outline:before {
  content: "󰧝";
}

.mdi-chevron-up-circle:before {
  content: "󰬬";
}

.mdi-chevron-up-circle-outline:before {
  content: "󰬭";
}

.mdi-chili-alert:before {
  content: "󱟪";
}

.mdi-chili-alert-outline:before {
  content: "󱟫";
}

.mdi-chili-hot:before {
  content: "󰞲";
}

.mdi-chili-hot-outline:before {
  content: "󱟬";
}

.mdi-chili-medium:before {
  content: "󰞳";
}

.mdi-chili-medium-outline:before {
  content: "󱟭";
}

.mdi-chili-mild:before {
  content: "󰞴";
}

.mdi-chili-mild-outline:before {
  content: "󱟮";
}

.mdi-chili-off:before {
  content: "󱑧";
}

.mdi-chili-off-outline:before {
  content: "󱟯";
}

.mdi-chip:before {
  content: "󰘚";
}

.mdi-church:before {
  content: "󰅄";
}

.mdi-church-outline:before {
  content: "󱬂";
}

.mdi-cigar:before {
  content: "󱆉";
}

.mdi-cigar-off:before {
  content: "󱐛";
}

.mdi-circle:before {
  content: "󰝥";
}

.mdi-circle-box:before {
  content: "󱗜";
}

.mdi-circle-box-outline:before {
  content: "󱗝";
}

.mdi-circle-double:before {
  content: "󰺕";
}

.mdi-circle-edit-outline:before {
  content: "󰣕";
}

.mdi-circle-expand:before {
  content: "󰺖";
}

.mdi-circle-half:before {
  content: "󱎕";
}

.mdi-circle-half-full:before {
  content: "󱎖";
}

.mdi-circle-medium:before {
  content: "󰧞";
}

.mdi-circle-multiple:before {
  content: "󰬸";
}

.mdi-circle-multiple-outline:before {
  content: "󰚕";
}

.mdi-circle-off-outline:before {
  content: "󱃓";
}

.mdi-circle-opacity:before {
  content: "󱡓";
}

.mdi-circle-outline:before {
  content: "󰝦";
}

.mdi-circle-slice-1:before {
  content: "󰪞";
}

.mdi-circle-slice-2:before {
  content: "󰪟";
}

.mdi-circle-slice-3:before {
  content: "󰪠";
}

.mdi-circle-slice-4:before {
  content: "󰪡";
}

.mdi-circle-slice-5:before {
  content: "󰪢";
}

.mdi-circle-slice-6:before {
  content: "󰪣";
}

.mdi-circle-slice-7:before {
  content: "󰪤";
}

.mdi-circle-slice-8:before {
  content: "󰪥";
}

.mdi-circle-small:before {
  content: "󰧟";
}

.mdi-circular-saw:before {
  content: "󰸢";
}

.mdi-city:before {
  content: "󰅆";
}

.mdi-city-switch:before {
  content: "󱰨";
}

.mdi-city-variant:before {
  content: "󰨶";
}

.mdi-city-variant-outline:before {
  content: "󰨷";
}

.mdi-clipboard:before {
  content: "󰅇";
}

.mdi-clipboard-account:before {
  content: "󰅈";
}

.mdi-clipboard-account-outline:before {
  content: "󰱕";
}

.mdi-clipboard-alert:before {
  content: "󰅉";
}

.mdi-clipboard-alert-outline:before {
  content: "󰳷";
}

.mdi-clipboard-arrow-down:before {
  content: "󰅊";
}

.mdi-clipboard-arrow-down-outline:before {
  content: "󰱖";
}

.mdi-clipboard-arrow-left:before {
  content: "󰅋";
}

.mdi-clipboard-arrow-left-outline:before {
  content: "󰳸";
}

.mdi-clipboard-arrow-right:before {
  content: "󰳹";
}

.mdi-clipboard-arrow-right-outline:before {
  content: "󰳺";
}

.mdi-clipboard-arrow-up:before {
  content: "󰱗";
}

.mdi-clipboard-arrow-up-outline:before {
  content: "󰱘";
}

.mdi-clipboard-check:before {
  content: "󰅎";
}

.mdi-clipboard-check-multiple:before {
  content: "󱉣";
}

.mdi-clipboard-check-multiple-outline:before {
  content: "󱉤";
}

.mdi-clipboard-check-outline:before {
  content: "󰢨";
}

.mdi-clipboard-clock:before {
  content: "󱛢";
}

.mdi-clipboard-clock-outline:before {
  content: "󱛣";
}

.mdi-clipboard-edit:before {
  content: "󱓥";
}

.mdi-clipboard-edit-outline:before {
  content: "󱓦";
}

.mdi-clipboard-file:before {
  content: "󱉥";
}

.mdi-clipboard-file-outline:before {
  content: "󱉦";
}

.mdi-clipboard-flow:before {
  content: "󰛈";
}

.mdi-clipboard-flow-outline:before {
  content: "󱄗";
}

.mdi-clipboard-list:before {
  content: "󱃔";
}

.mdi-clipboard-list-outline:before {
  content: "󱃕";
}

.mdi-clipboard-minus:before {
  content: "󱘘";
}

.mdi-clipboard-minus-outline:before {
  content: "󱘙";
}

.mdi-clipboard-multiple:before {
  content: "󱉧";
}

.mdi-clipboard-multiple-outline:before {
  content: "󱉨";
}

.mdi-clipboard-off:before {
  content: "󱘚";
}

.mdi-clipboard-off-outline:before {
  content: "󱘛";
}

.mdi-clipboard-outline:before {
  content: "󰅌";
}

.mdi-clipboard-play:before {
  content: "󰱙";
}

.mdi-clipboard-play-multiple:before {
  content: "󱉩";
}

.mdi-clipboard-play-multiple-outline:before {
  content: "󱉪";
}

.mdi-clipboard-play-outline:before {
  content: "󰱚";
}

.mdi-clipboard-plus:before {
  content: "󰝑";
}

.mdi-clipboard-plus-outline:before {
  content: "󱌟";
}

.mdi-clipboard-pulse:before {
  content: "󰡝";
}

.mdi-clipboard-pulse-outline:before {
  content: "󰡞";
}

.mdi-clipboard-remove:before {
  content: "󱘜";
}

.mdi-clipboard-remove-outline:before {
  content: "󱘝";
}

.mdi-clipboard-search:before {
  content: "󱘞";
}

.mdi-clipboard-search-outline:before {
  content: "󱘟";
}

.mdi-clipboard-text:before {
  content: "󰅍";
}

.mdi-clipboard-text-clock:before {
  content: "󱣹";
}

.mdi-clipboard-text-clock-outline:before {
  content: "󱣺";
}

.mdi-clipboard-text-multiple:before {
  content: "󱉫";
}

.mdi-clipboard-text-multiple-outline:before {
  content: "󱉬";
}

.mdi-clipboard-text-off:before {
  content: "󱘠";
}

.mdi-clipboard-text-off-outline:before {
  content: "󱘡";
}

.mdi-clipboard-text-outline:before {
  content: "󰨸";
}

.mdi-clipboard-text-play:before {
  content: "󰱛";
}

.mdi-clipboard-text-play-outline:before {
  content: "󰱜";
}

.mdi-clipboard-text-search:before {
  content: "󱘢";
}

.mdi-clipboard-text-search-outline:before {
  content: "󱘣";
}

.mdi-clippy:before {
  content: "󰅏";
}

.mdi-clock:before {
  content: "󰥔";
}

.mdi-clock-alert:before {
  content: "󰥕";
}

.mdi-clock-alert-outline:before {
  content: "󰗎";
}

.mdi-clock-check:before {
  content: "󰾨";
}

.mdi-clock-check-outline:before {
  content: "󰾩";
}

.mdi-clock-digital:before {
  content: "󰺗";
}

.mdi-clock-edit:before {
  content: "󱦺";
}

.mdi-clock-edit-outline:before {
  content: "󱦻";
}

.mdi-clock-end:before {
  content: "󰅑";
}

.mdi-clock-fast:before {
  content: "󰅒";
}

.mdi-clock-in:before {
  content: "󰅓";
}

.mdi-clock-minus:before {
  content: "󱡣";
}

.mdi-clock-minus-outline:before {
  content: "󱡤";
}

.mdi-clock-out:before {
  content: "󰅔";
}

.mdi-clock-outline:before {
  content: "󰅐";
}

.mdi-clock-plus:before {
  content: "󱡡";
}

.mdi-clock-plus-outline:before {
  content: "󱡢";
}

.mdi-clock-remove:before {
  content: "󱡥";
}

.mdi-clock-remove-outline:before {
  content: "󱡦";
}

.mdi-clock-star-four-points:before {
  content: "󱰩";
}

.mdi-clock-star-four-points-outline:before {
  content: "󱰪";
}

.mdi-clock-start:before {
  content: "󰅕";
}

.mdi-clock-time-eight:before {
  content: "󱑆";
}

.mdi-clock-time-eight-outline:before {
  content: "󱑒";
}

.mdi-clock-time-eleven:before {
  content: "󱑉";
}

.mdi-clock-time-eleven-outline:before {
  content: "󱑕";
}

.mdi-clock-time-five:before {
  content: "󱑃";
}

.mdi-clock-time-five-outline:before {
  content: "󱑏";
}

.mdi-clock-time-four:before {
  content: "󱑂";
}

.mdi-clock-time-four-outline:before {
  content: "󱑎";
}

.mdi-clock-time-nine:before {
  content: "󱑇";
}

.mdi-clock-time-nine-outline:before {
  content: "󱑓";
}

.mdi-clock-time-one:before {
  content: "󱐿";
}

.mdi-clock-time-one-outline:before {
  content: "󱑋";
}

.mdi-clock-time-seven:before {
  content: "󱑅";
}

.mdi-clock-time-seven-outline:before {
  content: "󱑑";
}

.mdi-clock-time-six:before {
  content: "󱑄";
}

.mdi-clock-time-six-outline:before {
  content: "󱑐";
}

.mdi-clock-time-ten:before {
  content: "󱑈";
}

.mdi-clock-time-ten-outline:before {
  content: "󱑔";
}

.mdi-clock-time-three:before {
  content: "󱑁";
}

.mdi-clock-time-three-outline:before {
  content: "󱑍";
}

.mdi-clock-time-twelve:before {
  content: "󱑊";
}

.mdi-clock-time-twelve-outline:before {
  content: "󱑖";
}

.mdi-clock-time-two:before {
  content: "󱑀";
}

.mdi-clock-time-two-outline:before {
  content: "󱑌";
}

.mdi-close:before {
  content: "󰅖";
}

.mdi-close-box:before {
  content: "󰅗";
}

.mdi-close-box-multiple:before {
  content: "󰱝";
}

.mdi-close-box-multiple-outline:before {
  content: "󰱞";
}

.mdi-close-box-outline:before {
  content: "󰅘";
}

.mdi-close-circle:before {
  content: "󰅙";
}

.mdi-close-circle-multiple:before {
  content: "󰘪";
}

.mdi-close-circle-multiple-outline:before {
  content: "󰢃";
}

.mdi-close-circle-outline:before {
  content: "󰅚";
}

.mdi-close-network:before {
  content: "󰅛";
}

.mdi-close-network-outline:before {
  content: "󰱟";
}

.mdi-close-octagon:before {
  content: "󰅜";
}

.mdi-close-octagon-outline:before {
  content: "󰅝";
}

.mdi-close-outline:before {
  content: "󰛉";
}

.mdi-close-thick:before {
  content: "󱎘";
}

.mdi-closed-caption:before {
  content: "󰅞";
}

.mdi-closed-caption-outline:before {
  content: "󰶽";
}

.mdi-cloud:before {
  content: "󰅟";
}

.mdi-cloud-alert:before {
  content: "󰧠";
}

.mdi-cloud-alert-outline:before {
  content: "󱯠";
}

.mdi-cloud-arrow-down:before {
  content: "󱯡";
}

.mdi-cloud-arrow-down-outline:before {
  content: "󱯢";
}

.mdi-cloud-arrow-left:before {
  content: "󱯣";
}

.mdi-cloud-arrow-left-outline:before {
  content: "󱯤";
}

.mdi-cloud-arrow-right:before {
  content: "󱯥";
}

.mdi-cloud-arrow-right-outline:before {
  content: "󱯦";
}

.mdi-cloud-arrow-up:before {
  content: "󱯧";
}

.mdi-cloud-arrow-up-outline:before {
  content: "󱯨";
}

.mdi-cloud-braces:before {
  content: "󰞵";
}

.mdi-cloud-cancel:before {
  content: "󱯩";
}

.mdi-cloud-cancel-outline:before {
  content: "󱯪";
}

.mdi-cloud-check:before {
  content: "󱯫";
}

.mdi-cloud-check-outline:before {
  content: "󱯬";
}

.mdi-cloud-check-variant:before {
  content: "󰅠";
}

.mdi-cloud-check-variant-outline:before {
  content: "󱋌";
}

.mdi-cloud-circle:before {
  content: "󰅡";
}

.mdi-cloud-circle-outline:before {
  content: "󱯭";
}

.mdi-cloud-clock:before {
  content: "󱯮";
}

.mdi-cloud-clock-outline:before {
  content: "󱯯";
}

.mdi-cloud-cog:before {
  content: "󱯰";
}

.mdi-cloud-cog-outline:before {
  content: "󱯱";
}

.mdi-cloud-download:before {
  content: "󰅢";
}

.mdi-cloud-download-outline:before {
  content: "󰭽";
}

.mdi-cloud-key:before {
  content: "󱲡";
}

.mdi-cloud-key-outline:before {
  content: "󱲢";
}

.mdi-cloud-lock:before {
  content: "󱇱";
}

.mdi-cloud-lock-open:before {
  content: "󱯲";
}

.mdi-cloud-lock-open-outline:before {
  content: "󱯳";
}

.mdi-cloud-lock-outline:before {
  content: "󱇲";
}

.mdi-cloud-minus:before {
  content: "󱯴";
}

.mdi-cloud-minus-outline:before {
  content: "󱯵";
}

.mdi-cloud-off:before {
  content: "󱯶";
}

.mdi-cloud-off-outline:before {
  content: "󰅤";
}

.mdi-cloud-outline:before {
  content: "󰅣";
}

.mdi-cloud-percent:before {
  content: "󱨵";
}

.mdi-cloud-percent-outline:before {
  content: "󱨶";
}

.mdi-cloud-plus:before {
  content: "󱯷";
}

.mdi-cloud-plus-outline:before {
  content: "󱯸";
}

.mdi-cloud-print:before {
  content: "󰅥";
}

.mdi-cloud-print-outline:before {
  content: "󰅦";
}

.mdi-cloud-question:before {
  content: "󰨹";
}

.mdi-cloud-question-outline:before {
  content: "󱯹";
}

.mdi-cloud-refresh:before {
  content: "󱯺";
}

.mdi-cloud-refresh-outline:before {
  content: "󱯻";
}

.mdi-cloud-refresh-variant:before {
  content: "󰔪";
}

.mdi-cloud-refresh-variant-outline:before {
  content: "󱯼";
}

.mdi-cloud-remove:before {
  content: "󱯽";
}

.mdi-cloud-remove-outline:before {
  content: "󱯾";
}

.mdi-cloud-search:before {
  content: "󰥖";
}

.mdi-cloud-search-outline:before {
  content: "󰥗";
}

.mdi-cloud-sync:before {
  content: "󰘿";
}

.mdi-cloud-sync-outline:before {
  content: "󱋖";
}

.mdi-cloud-tags:before {
  content: "󰞶";
}

.mdi-cloud-upload:before {
  content: "󰅧";
}

.mdi-cloud-upload-outline:before {
  content: "󰭾";
}

.mdi-clouds:before {
  content: "󱮕";
}

.mdi-clover:before {
  content: "󰠖";
}

.mdi-clover-outline:before {
  content: "󱱢";
}

.mdi-coach-lamp:before {
  content: "󱀠";
}

.mdi-coach-lamp-variant:before {
  content: "󱨷";
}

.mdi-coat-rack:before {
  content: "󱂞";
}

.mdi-code-array:before {
  content: "󰅨";
}

.mdi-code-block-braces:before {
  content: "󱲃";
}

.mdi-code-block-brackets:before {
  content: "󱲄";
}

.mdi-code-block-parentheses:before {
  content: "󱲅";
}

.mdi-code-block-tags:before {
  content: "󱲆";
}

.mdi-code-braces:before {
  content: "󰅩";
}

.mdi-code-braces-box:before {
  content: "󱃖";
}

.mdi-code-brackets:before {
  content: "󰅪";
}

.mdi-code-equal:before {
  content: "󰅫";
}

.mdi-code-greater-than:before {
  content: "󰅬";
}

.mdi-code-greater-than-or-equal:before {
  content: "󰅭";
}

.mdi-code-json:before {
  content: "󰘦";
}

.mdi-code-less-than:before {
  content: "󰅮";
}

.mdi-code-less-than-or-equal:before {
  content: "󰅯";
}

.mdi-code-not-equal:before {
  content: "󰅰";
}

.mdi-code-not-equal-variant:before {
  content: "󰅱";
}

.mdi-code-parentheses:before {
  content: "󰅲";
}

.mdi-code-parentheses-box:before {
  content: "󱃗";
}

.mdi-code-string:before {
  content: "󰅳";
}

.mdi-code-tags:before {
  content: "󰅴";
}

.mdi-code-tags-check:before {
  content: "󰚔";
}

.mdi-codepen:before {
  content: "󰅵";
}

.mdi-coffee:before {
  content: "󰅶";
}

.mdi-coffee-maker:before {
  content: "󱂟";
}

.mdi-coffee-maker-check:before {
  content: "󱤱";
}

.mdi-coffee-maker-check-outline:before {
  content: "󱤲";
}

.mdi-coffee-maker-outline:before {
  content: "󱠛";
}

.mdi-coffee-off:before {
  content: "󰾪";
}

.mdi-coffee-off-outline:before {
  content: "󰾫";
}

.mdi-coffee-outline:before {
  content: "󰛊";
}

.mdi-coffee-to-go:before {
  content: "󰅷";
}

.mdi-coffee-to-go-outline:before {
  content: "󱌎";
}

.mdi-coffin:before {
  content: "󰭿";
}

.mdi-cog:before {
  content: "󰒓";
}

.mdi-cog-box:before {
  content: "󰒔";
}

.mdi-cog-clockwise:before {
  content: "󱇝";
}

.mdi-cog-counterclockwise:before {
  content: "󱇞";
}

.mdi-cog-off:before {
  content: "󱏎";
}

.mdi-cog-off-outline:before {
  content: "󱏏";
}

.mdi-cog-outline:before {
  content: "󰢻";
}

.mdi-cog-pause:before {
  content: "󱤳";
}

.mdi-cog-pause-outline:before {
  content: "󱤴";
}

.mdi-cog-play:before {
  content: "󱤵";
}

.mdi-cog-play-outline:before {
  content: "󱤶";
}

.mdi-cog-refresh:before {
  content: "󱑞";
}

.mdi-cog-refresh-outline:before {
  content: "󱑟";
}

.mdi-cog-stop:before {
  content: "󱤷";
}

.mdi-cog-stop-outline:before {
  content: "󱤸";
}

.mdi-cog-sync:before {
  content: "󱑠";
}

.mdi-cog-sync-outline:before {
  content: "󱑡";
}

.mdi-cog-transfer:before {
  content: "󱁛";
}

.mdi-cog-transfer-outline:before {
  content: "󱁜";
}

.mdi-cogs:before {
  content: "󰣖";
}

.mdi-collage:before {
  content: "󰙀";
}

.mdi-collapse-all:before {
  content: "󰪦";
}

.mdi-collapse-all-outline:before {
  content: "󰪧";
}

.mdi-color-helper:before {
  content: "󰅹";
}

.mdi-comma:before {
  content: "󰸣";
}

.mdi-comma-box:before {
  content: "󰸫";
}

.mdi-comma-box-outline:before {
  content: "󰸤";
}

.mdi-comma-circle:before {
  content: "󰸥";
}

.mdi-comma-circle-outline:before {
  content: "󰸦";
}

.mdi-comment:before {
  content: "󰅺";
}

.mdi-comment-account:before {
  content: "󰅻";
}

.mdi-comment-account-outline:before {
  content: "󰅼";
}

.mdi-comment-alert:before {
  content: "󰅽";
}

.mdi-comment-alert-outline:before {
  content: "󰅾";
}

.mdi-comment-arrow-left:before {
  content: "󰧡";
}

.mdi-comment-arrow-left-outline:before {
  content: "󰧢";
}

.mdi-comment-arrow-right:before {
  content: "󰧣";
}

.mdi-comment-arrow-right-outline:before {
  content: "󰧤";
}

.mdi-comment-bookmark:before {
  content: "󱖮";
}

.mdi-comment-bookmark-outline:before {
  content: "󱖯";
}

.mdi-comment-check:before {
  content: "󰅿";
}

.mdi-comment-check-outline:before {
  content: "󰆀";
}

.mdi-comment-edit:before {
  content: "󱆿";
}

.mdi-comment-edit-outline:before {
  content: "󱋄";
}

.mdi-comment-eye:before {
  content: "󰨺";
}

.mdi-comment-eye-outline:before {
  content: "󰨻";
}

.mdi-comment-flash:before {
  content: "󱖰";
}

.mdi-comment-flash-outline:before {
  content: "󱖱";
}

.mdi-comment-minus:before {
  content: "󱗟";
}

.mdi-comment-minus-outline:before {
  content: "󱗠";
}

.mdi-comment-multiple:before {
  content: "󰡟";
}

.mdi-comment-multiple-outline:before {
  content: "󰆁";
}

.mdi-comment-off:before {
  content: "󱗡";
}

.mdi-comment-off-outline:before {
  content: "󱗢";
}

.mdi-comment-outline:before {
  content: "󰆂";
}

.mdi-comment-plus:before {
  content: "󰧥";
}

.mdi-comment-plus-outline:before {
  content: "󰆃";
}

.mdi-comment-processing:before {
  content: "󰆄";
}

.mdi-comment-processing-outline:before {
  content: "󰆅";
}

.mdi-comment-question:before {
  content: "󰠗";
}

.mdi-comment-question-outline:before {
  content: "󰆆";
}

.mdi-comment-quote:before {
  content: "󱀡";
}

.mdi-comment-quote-outline:before {
  content: "󱀢";
}

.mdi-comment-remove:before {
  content: "󰗞";
}

.mdi-comment-remove-outline:before {
  content: "󰆇";
}

.mdi-comment-search:before {
  content: "󰨼";
}

.mdi-comment-search-outline:before {
  content: "󰨽";
}

.mdi-comment-text:before {
  content: "󰆈";
}

.mdi-comment-text-multiple:before {
  content: "󰡠";
}

.mdi-comment-text-multiple-outline:before {
  content: "󰡡";
}

.mdi-comment-text-outline:before {
  content: "󰆉";
}

.mdi-compare:before {
  content: "󰆊";
}

.mdi-compare-horizontal:before {
  content: "󱒒";
}

.mdi-compare-remove:before {
  content: "󱢳";
}

.mdi-compare-vertical:before {
  content: "󱒓";
}

.mdi-compass:before {
  content: "󰆋";
}

.mdi-compass-off:before {
  content: "󰮀";
}

.mdi-compass-off-outline:before {
  content: "󰮁";
}

.mdi-compass-outline:before {
  content: "󰆌";
}

.mdi-compass-rose:before {
  content: "󱎂";
}

.mdi-compost:before {
  content: "󱨸";
}

.mdi-cone:before {
  content: "󱥌";
}

.mdi-cone-off:before {
  content: "󱥍";
}

.mdi-connection:before {
  content: "󱘖";
}

.mdi-console:before {
  content: "󰆍";
}

.mdi-console-line:before {
  content: "󰞷";
}

.mdi-console-network:before {
  content: "󰢩";
}

.mdi-console-network-outline:before {
  content: "󰱠";
}

.mdi-consolidate:before {
  content: "󱃘";
}

.mdi-contactless-payment:before {
  content: "󰵪";
}

.mdi-contactless-payment-circle:before {
  content: "󰌡";
}

.mdi-contactless-payment-circle-outline:before {
  content: "󰐈";
}

.mdi-contacts:before {
  content: "󰛋";
}

.mdi-contacts-outline:before {
  content: "󰖸";
}

.mdi-contain:before {
  content: "󰨾";
}

.mdi-contain-end:before {
  content: "󰨿";
}

.mdi-contain-start:before {
  content: "󰩀";
}

.mdi-content-copy:before {
  content: "󰆏";
}

.mdi-content-cut:before {
  content: "󰆐";
}

.mdi-content-duplicate:before {
  content: "󰆑";
}

.mdi-content-paste:before {
  content: "󰆒";
}

.mdi-content-save:before {
  content: "󰆓";
}

.mdi-content-save-alert:before {
  content: "󰽂";
}

.mdi-content-save-alert-outline:before {
  content: "󰽃";
}

.mdi-content-save-all:before {
  content: "󰆔";
}

.mdi-content-save-all-outline:before {
  content: "󰽄";
}

.mdi-content-save-check:before {
  content: "󱣪";
}

.mdi-content-save-check-outline:before {
  content: "󱣫";
}

.mdi-content-save-cog:before {
  content: "󱑛";
}

.mdi-content-save-cog-outline:before {
  content: "󱑜";
}

.mdi-content-save-edit:before {
  content: "󰳻";
}

.mdi-content-save-edit-outline:before {
  content: "󰳼";
}

.mdi-content-save-minus:before {
  content: "󱭃";
}

.mdi-content-save-minus-outline:before {
  content: "󱭄";
}

.mdi-content-save-move:before {
  content: "󰸧";
}

.mdi-content-save-move-outline:before {
  content: "󰸨";
}

.mdi-content-save-off:before {
  content: "󱙃";
}

.mdi-content-save-off-outline:before {
  content: "󱙄";
}

.mdi-content-save-outline:before {
  content: "󰠘";
}

.mdi-content-save-plus:before {
  content: "󱭁";
}

.mdi-content-save-plus-outline:before {
  content: "󱭂";
}

.mdi-content-save-settings:before {
  content: "󰘛";
}

.mdi-content-save-settings-outline:before {
  content: "󰬮";
}

.mdi-contrast:before {
  content: "󰆕";
}

.mdi-contrast-box:before {
  content: "󰆖";
}

.mdi-contrast-circle:before {
  content: "󰆗";
}

.mdi-controller:before {
  content: "󰊴";
}

.mdi-controller-classic:before {
  content: "󰮂";
}

.mdi-controller-classic-outline:before {
  content: "󰮃";
}

.mdi-controller-off:before {
  content: "󰊵";
}

.mdi-cookie:before {
  content: "󰆘";
}

.mdi-cookie-alert:before {
  content: "󱛐";
}

.mdi-cookie-alert-outline:before {
  content: "󱛑";
}

.mdi-cookie-check:before {
  content: "󱛒";
}

.mdi-cookie-check-outline:before {
  content: "󱛓";
}

.mdi-cookie-clock:before {
  content: "󱛤";
}

.mdi-cookie-clock-outline:before {
  content: "󱛥";
}

.mdi-cookie-cog:before {
  content: "󱛔";
}

.mdi-cookie-cog-outline:before {
  content: "󱛕";
}

.mdi-cookie-edit:before {
  content: "󱛦";
}

.mdi-cookie-edit-outline:before {
  content: "󱛧";
}

.mdi-cookie-lock:before {
  content: "󱛨";
}

.mdi-cookie-lock-outline:before {
  content: "󱛩";
}

.mdi-cookie-minus:before {
  content: "󱛚";
}

.mdi-cookie-minus-outline:before {
  content: "󱛛";
}

.mdi-cookie-off:before {
  content: "󱛪";
}

.mdi-cookie-off-outline:before {
  content: "󱛫";
}

.mdi-cookie-outline:before {
  content: "󱛞";
}

.mdi-cookie-plus:before {
  content: "󱛖";
}

.mdi-cookie-plus-outline:before {
  content: "󱛗";
}

.mdi-cookie-refresh:before {
  content: "󱛬";
}

.mdi-cookie-refresh-outline:before {
  content: "󱛭";
}

.mdi-cookie-remove:before {
  content: "󱛘";
}

.mdi-cookie-remove-outline:before {
  content: "󱛙";
}

.mdi-cookie-settings:before {
  content: "󱛜";
}

.mdi-cookie-settings-outline:before {
  content: "󱛝";
}

.mdi-coolant-temperature:before {
  content: "󰏈";
}

.mdi-copyleft:before {
  content: "󱤹";
}

.mdi-copyright:before {
  content: "󰗦";
}

.mdi-cordova:before {
  content: "󰥘";
}

.mdi-corn:before {
  content: "󰞸";
}

.mdi-corn-off:before {
  content: "󱏯";
}

.mdi-cosine-wave:before {
  content: "󱑹";
}

.mdi-counter:before {
  content: "󰆙";
}

.mdi-countertop:before {
  content: "󱠜";
}

.mdi-countertop-outline:before {
  content: "󱠝";
}

.mdi-cow:before {
  content: "󰆚";
}

.mdi-cow-off:before {
  content: "󱣼";
}

.mdi-cpu-32-bit:before {
  content: "󰻟";
}

.mdi-cpu-64-bit:before {
  content: "󰻠";
}

.mdi-cradle:before {
  content: "󱦋";
}

.mdi-cradle-outline:before {
  content: "󱦑";
}

.mdi-crane:before {
  content: "󰡢";
}

.mdi-creation:before {
  content: "󰙴";
}

.mdi-creation-outline:before {
  content: "󱰫";
}

.mdi-creative-commons:before {
  content: "󰵫";
}

.mdi-credit-card:before {
  content: "󰿯";
}

.mdi-credit-card-check:before {
  content: "󱏐";
}

.mdi-credit-card-check-outline:before {
  content: "󱏑";
}

.mdi-credit-card-chip:before {
  content: "󱤏";
}

.mdi-credit-card-chip-outline:before {
  content: "󱤐";
}

.mdi-credit-card-clock:before {
  content: "󰻡";
}

.mdi-credit-card-clock-outline:before {
  content: "󰻢";
}

.mdi-credit-card-edit:before {
  content: "󱟗";
}

.mdi-credit-card-edit-outline:before {
  content: "󱟘";
}

.mdi-credit-card-fast:before {
  content: "󱤑";
}

.mdi-credit-card-fast-outline:before {
  content: "󱤒";
}

.mdi-credit-card-lock:before {
  content: "󱣧";
}

.mdi-credit-card-lock-outline:before {
  content: "󱣨";
}

.mdi-credit-card-marker:before {
  content: "󰚨";
}

.mdi-credit-card-marker-outline:before {
  content: "󰶾";
}

.mdi-credit-card-minus:before {
  content: "󰾬";
}

.mdi-credit-card-minus-outline:before {
  content: "󰾭";
}

.mdi-credit-card-multiple:before {
  content: "󰿰";
}

.mdi-credit-card-multiple-outline:before {
  content: "󰆜";
}

.mdi-credit-card-off:before {
  content: "󰿱";
}

.mdi-credit-card-off-outline:before {
  content: "󰗤";
}

.mdi-credit-card-outline:before {
  content: "󰆛";
}

.mdi-credit-card-plus:before {
  content: "󰿲";
}

.mdi-credit-card-plus-outline:before {
  content: "󰙶";
}

.mdi-credit-card-refresh:before {
  content: "󱙅";
}

.mdi-credit-card-refresh-outline:before {
  content: "󱙆";
}

.mdi-credit-card-refund:before {
  content: "󰿳";
}

.mdi-credit-card-refund-outline:before {
  content: "󰪨";
}

.mdi-credit-card-remove:before {
  content: "󰾮";
}

.mdi-credit-card-remove-outline:before {
  content: "󰾯";
}

.mdi-credit-card-scan:before {
  content: "󰿴";
}

.mdi-credit-card-scan-outline:before {
  content: "󰆝";
}

.mdi-credit-card-search:before {
  content: "󱙇";
}

.mdi-credit-card-search-outline:before {
  content: "󱙈";
}

.mdi-credit-card-settings:before {
  content: "󰿵";
}

.mdi-credit-card-settings-outline:before {
  content: "󰣗";
}

.mdi-credit-card-sync:before {
  content: "󱙉";
}

.mdi-credit-card-sync-outline:before {
  content: "󱙊";
}

.mdi-credit-card-wireless:before {
  content: "󰠂";
}

.mdi-credit-card-wireless-off:before {
  content: "󰕺";
}

.mdi-credit-card-wireless-off-outline:before {
  content: "󰕻";
}

.mdi-credit-card-wireless-outline:before {
  content: "󰵬";
}

.mdi-cricket:before {
  content: "󰵭";
}

.mdi-crop:before {
  content: "󰆞";
}

.mdi-crop-free:before {
  content: "󰆟";
}

.mdi-crop-landscape:before {
  content: "󰆠";
}

.mdi-crop-portrait:before {
  content: "󰆡";
}

.mdi-crop-rotate:before {
  content: "󰚖";
}

.mdi-crop-square:before {
  content: "󰆢";
}

.mdi-cross:before {
  content: "󰥓";
}

.mdi-cross-bolnisi:before {
  content: "󰳭";
}

.mdi-cross-celtic:before {
  content: "󰳵";
}

.mdi-cross-outline:before {
  content: "󰳶";
}

.mdi-crosshairs:before {
  content: "󰆣";
}

.mdi-crosshairs-gps:before {
  content: "󰆤";
}

.mdi-crosshairs-off:before {
  content: "󰽅";
}

.mdi-crosshairs-question:before {
  content: "󱄶";
}

.mdi-crowd:before {
  content: "󱥵";
}

.mdi-crown:before {
  content: "󰆥";
}

.mdi-crown-circle:before {
  content: "󱟜";
}

.mdi-crown-circle-outline:before {
  content: "󱟝";
}

.mdi-crown-outline:before {
  content: "󱇐";
}

.mdi-cryengine:before {
  content: "󰥙";
}

.mdi-crystal-ball:before {
  content: "󰬯";
}

.mdi-cube:before {
  content: "󰆦";
}

.mdi-cube-off:before {
  content: "󱐜";
}

.mdi-cube-off-outline:before {
  content: "󱐝";
}

.mdi-cube-outline:before {
  content: "󰆧";
}

.mdi-cube-scan:before {
  content: "󰮄";
}

.mdi-cube-send:before {
  content: "󰆨";
}

.mdi-cube-unfolded:before {
  content: "󰆩";
}

.mdi-cup:before {
  content: "󰆪";
}

.mdi-cup-off:before {
  content: "󰗥";
}

.mdi-cup-off-outline:before {
  content: "󱍽";
}

.mdi-cup-outline:before {
  content: "󱌏";
}

.mdi-cup-water:before {
  content: "󰆫";
}

.mdi-cupboard:before {
  content: "󰽆";
}

.mdi-cupboard-outline:before {
  content: "󰽇";
}

.mdi-cupcake:before {
  content: "󰥚";
}

.mdi-curling:before {
  content: "󰡣";
}

.mdi-currency-bdt:before {
  content: "󰡤";
}

.mdi-currency-brl:before {
  content: "󰮅";
}

.mdi-currency-btc:before {
  content: "󰆬";
}

.mdi-currency-cny:before {
  content: "󰞺";
}

.mdi-currency-eth:before {
  content: "󰞻";
}

.mdi-currency-eur:before {
  content: "󰆭";
}

.mdi-currency-eur-off:before {
  content: "󱌕";
}

.mdi-currency-fra:before {
  content: "󱨹";
}

.mdi-currency-gbp:before {
  content: "󰆮";
}

.mdi-currency-ils:before {
  content: "󰱡";
}

.mdi-currency-inr:before {
  content: "󰆯";
}

.mdi-currency-jpy:before {
  content: "󰞼";
}

.mdi-currency-krw:before {
  content: "󰞽";
}

.mdi-currency-kzt:before {
  content: "󰡥";
}

.mdi-currency-mnt:before {
  content: "󱔒";
}

.mdi-currency-ngn:before {
  content: "󰆰";
}

.mdi-currency-php:before {
  content: "󰧦";
}

.mdi-currency-rial:before {
  content: "󰺜";
}

.mdi-currency-rub:before {
  content: "󰆱";
}

.mdi-currency-rupee:before {
  content: "󱥶";
}

.mdi-currency-sign:before {
  content: "󰞾";
}

.mdi-currency-thb:before {
  content: "󱰅";
}

.mdi-currency-try:before {
  content: "󰆲";
}

.mdi-currency-twd:before {
  content: "󰞿";
}

.mdi-currency-uah:before {
  content: "󱮛";
}

.mdi-currency-usd:before {
  content: "󰇁";
}

.mdi-currency-usd-off:before {
  content: "󰙺";
}

.mdi-current-ac:before {
  content: "󱒀";
}

.mdi-current-dc:before {
  content: "󰥜";
}

.mdi-cursor-default:before {
  content: "󰇀";
}

.mdi-cursor-default-click:before {
  content: "󰳽";
}

.mdi-cursor-default-click-outline:before {
  content: "󰳾";
}

.mdi-cursor-default-gesture:before {
  content: "󱄧";
}

.mdi-cursor-default-gesture-outline:before {
  content: "󱄨";
}

.mdi-cursor-default-outline:before {
  content: "󰆿";
}

.mdi-cursor-move:before {
  content: "󰆾";
}

.mdi-cursor-pointer:before {
  content: "󰆽";
}

.mdi-cursor-text:before {
  content: "󰗧";
}

.mdi-curtains:before {
  content: "󱡆";
}

.mdi-curtains-closed:before {
  content: "󱡇";
}

.mdi-cylinder:before {
  content: "󱥎";
}

.mdi-cylinder-off:before {
  content: "󱥏";
}

.mdi-dance-ballroom:before {
  content: "󱗻";
}

.mdi-dance-pole:before {
  content: "󱕸";
}

.mdi-data-matrix:before {
  content: "󱔼";
}

.mdi-data-matrix-edit:before {
  content: "󱔽";
}

.mdi-data-matrix-minus:before {
  content: "󱔾";
}

.mdi-data-matrix-plus:before {
  content: "󱔿";
}

.mdi-data-matrix-remove:before {
  content: "󱕀";
}

.mdi-data-matrix-scan:before {
  content: "󱕁";
}

.mdi-database:before {
  content: "󰆼";
}

.mdi-database-alert:before {
  content: "󱘺";
}

.mdi-database-alert-outline:before {
  content: "󱘤";
}

.mdi-database-arrow-down:before {
  content: "󱘻";
}

.mdi-database-arrow-down-outline:before {
  content: "󱘥";
}

.mdi-database-arrow-left:before {
  content: "󱘼";
}

.mdi-database-arrow-left-outline:before {
  content: "󱘦";
}

.mdi-database-arrow-right:before {
  content: "󱘽";
}

.mdi-database-arrow-right-outline:before {
  content: "󱘧";
}

.mdi-database-arrow-up:before {
  content: "󱘾";
}

.mdi-database-arrow-up-outline:before {
  content: "󱘨";
}

.mdi-database-check:before {
  content: "󰪩";
}

.mdi-database-check-outline:before {
  content: "󱘩";
}

.mdi-database-clock:before {
  content: "󱘿";
}

.mdi-database-clock-outline:before {
  content: "󱘪";
}

.mdi-database-cog:before {
  content: "󱙋";
}

.mdi-database-cog-outline:before {
  content: "󱙌";
}

.mdi-database-edit:before {
  content: "󰮆";
}

.mdi-database-edit-outline:before {
  content: "󱘫";
}

.mdi-database-export:before {
  content: "󰥞";
}

.mdi-database-export-outline:before {
  content: "󱘬";
}

.mdi-database-eye:before {
  content: "󱤟";
}

.mdi-database-eye-off:before {
  content: "󱤠";
}

.mdi-database-eye-off-outline:before {
  content: "󱤡";
}

.mdi-database-eye-outline:before {
  content: "󱤢";
}

.mdi-database-import:before {
  content: "󰥝";
}

.mdi-database-import-outline:before {
  content: "󱘭";
}

.mdi-database-lock:before {
  content: "󰪪";
}

.mdi-database-lock-outline:before {
  content: "󱘮";
}

.mdi-database-marker:before {
  content: "󱋶";
}

.mdi-database-marker-outline:before {
  content: "󱘯";
}

.mdi-database-minus:before {
  content: "󰆻";
}

.mdi-database-minus-outline:before {
  content: "󱘰";
}

.mdi-database-off:before {
  content: "󱙀";
}

.mdi-database-off-outline:before {
  content: "󱘱";
}

.mdi-database-outline:before {
  content: "󱘲";
}

.mdi-database-plus:before {
  content: "󰆺";
}

.mdi-database-plus-outline:before {
  content: "󱘳";
}

.mdi-database-refresh:before {
  content: "󰗂";
}

.mdi-database-refresh-outline:before {
  content: "󱘴";
}

.mdi-database-remove:before {
  content: "󰴀";
}

.mdi-database-remove-outline:before {
  content: "󱘵";
}

.mdi-database-search:before {
  content: "󰡦";
}

.mdi-database-search-outline:before {
  content: "󱘶";
}

.mdi-database-settings:before {
  content: "󰴁";
}

.mdi-database-settings-outline:before {
  content: "󱘷";
}

.mdi-database-sync:before {
  content: "󰳿";
}

.mdi-database-sync-outline:before {
  content: "󱘸";
}

.mdi-death-star:before {
  content: "󰣘";
}

.mdi-death-star-variant:before {
  content: "󰣙";
}

.mdi-deathly-hallows:before {
  content: "󰮇";
}

.mdi-debian:before {
  content: "󰣚";
}

.mdi-debug-step-into:before {
  content: "󰆹";
}

.mdi-debug-step-out:before {
  content: "󰆸";
}

.mdi-debug-step-over:before {
  content: "󰆷";
}

.mdi-decagram:before {
  content: "󰝬";
}

.mdi-decagram-outline:before {
  content: "󰝭";
}

.mdi-decimal:before {
  content: "󱂡";
}

.mdi-decimal-comma:before {
  content: "󱂢";
}

.mdi-decimal-comma-decrease:before {
  content: "󱂣";
}

.mdi-decimal-comma-increase:before {
  content: "󱂤";
}

.mdi-decimal-decrease:before {
  content: "󰆶";
}

.mdi-decimal-increase:before {
  content: "󰆵";
}

.mdi-delete:before {
  content: "󰆴";
}

.mdi-delete-alert:before {
  content: "󱂥";
}

.mdi-delete-alert-outline:before {
  content: "󱂦";
}

.mdi-delete-circle:before {
  content: "󰚃";
}

.mdi-delete-circle-outline:before {
  content: "󰮈";
}

.mdi-delete-clock:before {
  content: "󱕖";
}

.mdi-delete-clock-outline:before {
  content: "󱕗";
}

.mdi-delete-empty:before {
  content: "󰛌";
}

.mdi-delete-empty-outline:before {
  content: "󰺝";
}

.mdi-delete-forever:before {
  content: "󰗨";
}

.mdi-delete-forever-outline:before {
  content: "󰮉";
}

.mdi-delete-off:before {
  content: "󱂧";
}

.mdi-delete-off-outline:before {
  content: "󱂨";
}

.mdi-delete-outline:before {
  content: "󰧧";
}

.mdi-delete-restore:before {
  content: "󰠙";
}

.mdi-delete-sweep:before {
  content: "󰗩";
}

.mdi-delete-sweep-outline:before {
  content: "󰱢";
}

.mdi-delete-variant:before {
  content: "󰆳";
}

.mdi-delta:before {
  content: "󰇂";
}

.mdi-desk:before {
  content: "󱈹";
}

.mdi-desk-lamp:before {
  content: "󰥟";
}

.mdi-desk-lamp-off:before {
  content: "󱬟";
}

.mdi-desk-lamp-on:before {
  content: "󱬠";
}

.mdi-deskphone:before {
  content: "󰇃";
}

.mdi-desktop-classic:before {
  content: "󰟀";
}

.mdi-desktop-tower:before {
  content: "󰇅";
}

.mdi-desktop-tower-monitor:before {
  content: "󰪫";
}

.mdi-details:before {
  content: "󰇆";
}

.mdi-dev-to:before {
  content: "󰵮";
}

.mdi-developer-board:before {
  content: "󰚗";
}

.mdi-deviantart:before {
  content: "󰇇";
}

.mdi-devices:before {
  content: "󰾰";
}

.mdi-dharmachakra:before {
  content: "󰥋";
}

.mdi-diabetes:before {
  content: "󱄦";
}

.mdi-dialpad:before {
  content: "󰘜";
}

.mdi-diameter:before {
  content: "󰱣";
}

.mdi-diameter-outline:before {
  content: "󰱤";
}

.mdi-diameter-variant:before {
  content: "󰱥";
}

.mdi-diamond:before {
  content: "󰮊";
}

.mdi-diamond-outline:before {
  content: "󰮋";
}

.mdi-diamond-stone:before {
  content: "󰇈";
}

.mdi-diaper-outline:before {
  content: "󱳏";
}

.mdi-dice-1:before {
  content: "󰇊";
}

.mdi-dice-1-outline:before {
  content: "󱅊";
}

.mdi-dice-2:before {
  content: "󰇋";
}

.mdi-dice-2-outline:before {
  content: "󱅋";
}

.mdi-dice-3:before {
  content: "󰇌";
}

.mdi-dice-3-outline:before {
  content: "󱅌";
}

.mdi-dice-4:before {
  content: "󰇍";
}

.mdi-dice-4-outline:before {
  content: "󱅍";
}

.mdi-dice-5:before {
  content: "󰇎";
}

.mdi-dice-5-outline:before {
  content: "󱅎";
}

.mdi-dice-6:before {
  content: "󰇏";
}

.mdi-dice-6-outline:before {
  content: "󱅏";
}

.mdi-dice-d10:before {
  content: "󱅓";
}

.mdi-dice-d10-outline:before {
  content: "󰝯";
}

.mdi-dice-d12:before {
  content: "󱅔";
}

.mdi-dice-d12-outline:before {
  content: "󰡧";
}

.mdi-dice-d20:before {
  content: "󱅕";
}

.mdi-dice-d20-outline:before {
  content: "󰗪";
}

.mdi-dice-d4:before {
  content: "󱅐";
}

.mdi-dice-d4-outline:before {
  content: "󰗫";
}

.mdi-dice-d6:before {
  content: "󱅑";
}

.mdi-dice-d6-outline:before {
  content: "󰗭";
}

.mdi-dice-d8:before {
  content: "󱅒";
}

.mdi-dice-d8-outline:before {
  content: "󰗬";
}

.mdi-dice-multiple:before {
  content: "󰝮";
}

.mdi-dice-multiple-outline:before {
  content: "󱅖";
}

.mdi-digital-ocean:before {
  content: "󱈷";
}

.mdi-dip-switch:before {
  content: "󰟁";
}

.mdi-directions:before {
  content: "󰇐";
}

.mdi-directions-fork:before {
  content: "󰙁";
}

.mdi-disc:before {
  content: "󰗮";
}

.mdi-disc-alert:before {
  content: "󰇑";
}

.mdi-disc-player:before {
  content: "󰥠";
}

.mdi-dishwasher:before {
  content: "󰪬";
}

.mdi-dishwasher-alert:before {
  content: "󱆸";
}

.mdi-dishwasher-off:before {
  content: "󱆹";
}

.mdi-disqus:before {
  content: "󰇒";
}

.mdi-distribute-horizontal-center:before {
  content: "󱇉";
}

.mdi-distribute-horizontal-left:before {
  content: "󱇈";
}

.mdi-distribute-horizontal-right:before {
  content: "󱇊";
}

.mdi-distribute-vertical-bottom:before {
  content: "󱇋";
}

.mdi-distribute-vertical-center:before {
  content: "󱇌";
}

.mdi-distribute-vertical-top:before {
  content: "󱇍";
}

.mdi-diversify:before {
  content: "󱡷";
}

.mdi-diving:before {
  content: "󱥷";
}

.mdi-diving-flippers:before {
  content: "󰶿";
}

.mdi-diving-helmet:before {
  content: "󰷀";
}

.mdi-diving-scuba:before {
  content: "󱭷";
}

.mdi-diving-scuba-flag:before {
  content: "󰷂";
}

.mdi-diving-scuba-mask:before {
  content: "󰷁";
}

.mdi-diving-scuba-tank:before {
  content: "󰷃";
}

.mdi-diving-scuba-tank-multiple:before {
  content: "󰷄";
}

.mdi-diving-snorkel:before {
  content: "󰷅";
}

.mdi-division:before {
  content: "󰇔";
}

.mdi-division-box:before {
  content: "󰇕";
}

.mdi-dlna:before {
  content: "󰩁";
}

.mdi-dna:before {
  content: "󰚄";
}

.mdi-dns:before {
  content: "󰇖";
}

.mdi-dns-outline:before {
  content: "󰮌";
}

.mdi-dock-bottom:before {
  content: "󱂩";
}

.mdi-dock-left:before {
  content: "󱂪";
}

.mdi-dock-right:before {
  content: "󱂫";
}

.mdi-dock-top:before {
  content: "󱔓";
}

.mdi-dock-window:before {
  content: "󱂬";
}

.mdi-docker:before {
  content: "󰡨";
}

.mdi-doctor:before {
  content: "󰩂";
}

.mdi-dog:before {
  content: "󰩃";
}

.mdi-dog-service:before {
  content: "󰪭";
}

.mdi-dog-side:before {
  content: "󰩄";
}

.mdi-dog-side-off:before {
  content: "󱛮";
}

.mdi-dolby:before {
  content: "󰚳";
}

.mdi-dolly:before {
  content: "󰺞";
}

.mdi-dolphin:before {
  content: "󱢴";
}

.mdi-domain:before {
  content: "󰇗";
}

.mdi-domain-off:before {
  content: "󰵯";
}

.mdi-domain-plus:before {
  content: "󱂭";
}

.mdi-domain-remove:before {
  content: "󱂮";
}

.mdi-domain-switch:before {
  content: "󱰬";
}

.mdi-dome-light:before {
  content: "󱐞";
}

.mdi-domino-mask:before {
  content: "󱀣";
}

.mdi-donkey:before {
  content: "󰟂";
}

.mdi-door:before {
  content: "󰠚";
}

.mdi-door-closed:before {
  content: "󰠛";
}

.mdi-door-closed-cancel:before {
  content: "󱲓";
}

.mdi-door-closed-lock:before {
  content: "󱂯";
}

.mdi-door-open:before {
  content: "󰠜";
}

.mdi-door-sliding:before {
  content: "󱠞";
}

.mdi-door-sliding-lock:before {
  content: "󱠟";
}

.mdi-door-sliding-open:before {
  content: "󱠠";
}

.mdi-doorbell:before {
  content: "󱋦";
}

.mdi-doorbell-video:before {
  content: "󰡩";
}

.mdi-dot-net:before {
  content: "󰪮";
}

.mdi-dots-circle:before {
  content: "󱥸";
}

.mdi-dots-grid:before {
  content: "󱗼";
}

.mdi-dots-hexagon:before {
  content: "󱗿";
}

.mdi-dots-horizontal:before {
  content: "󰇘";
}

.mdi-dots-horizontal-circle:before {
  content: "󰟃";
}

.mdi-dots-horizontal-circle-outline:before {
  content: "󰮍";
}

.mdi-dots-square:before {
  content: "󱗽";
}

.mdi-dots-triangle:before {
  content: "󱗾";
}

.mdi-dots-vertical:before {
  content: "󰇙";
}

.mdi-dots-vertical-circle:before {
  content: "󰟄";
}

.mdi-dots-vertical-circle-outline:before {
  content: "󰮎";
}

.mdi-download:before {
  content: "󰇚";
}

.mdi-download-box:before {
  content: "󱑢";
}

.mdi-download-box-outline:before {
  content: "󱑣";
}

.mdi-download-circle:before {
  content: "󱑤";
}

.mdi-download-circle-outline:before {
  content: "󱑥";
}

.mdi-download-lock:before {
  content: "󱌠";
}

.mdi-download-lock-outline:before {
  content: "󱌡";
}

.mdi-download-multiple:before {
  content: "󰧩";
}

.mdi-download-multiple-outline:before {
  content: "󱳐";
}

.mdi-download-network:before {
  content: "󰛴";
}

.mdi-download-network-outline:before {
  content: "󰱦";
}

.mdi-download-off:before {
  content: "󱂰";
}

.mdi-download-off-outline:before {
  content: "󱂱";
}

.mdi-download-outline:before {
  content: "󰮏";
}

.mdi-drag:before {
  content: "󰇛";
}

.mdi-drag-horizontal:before {
  content: "󰇜";
}

.mdi-drag-horizontal-variant:before {
  content: "󱋰";
}

.mdi-drag-variant:before {
  content: "󰮐";
}

.mdi-drag-vertical:before {
  content: "󰇝";
}

.mdi-drag-vertical-variant:before {
  content: "󱋱";
}

.mdi-drama-masks:before {
  content: "󰴂";
}

.mdi-draw:before {
  content: "󰽉";
}

.mdi-draw-pen:before {
  content: "󱦹";
}

.mdi-drawing:before {
  content: "󰇞";
}

.mdi-drawing-box:before {
  content: "󰇟";
}

.mdi-dresser:before {
  content: "󰽊";
}

.mdi-dresser-outline:before {
  content: "󰽋";
}

.mdi-drone:before {
  content: "󰇢";
}

.mdi-dropbox:before {
  content: "󰇣";
}

.mdi-drupal:before {
  content: "󰇤";
}

.mdi-duck:before {
  content: "󰇥";
}

.mdi-dumbbell:before {
  content: "󰇦";
}

.mdi-dump-truck:before {
  content: "󰱧";
}

.mdi-ear-hearing:before {
  content: "󰟅";
}

.mdi-ear-hearing-loop:before {
  content: "󱫮";
}

.mdi-ear-hearing-off:before {
  content: "󰩅";
}

.mdi-earbuds:before {
  content: "󱡏";
}

.mdi-earbuds-off:before {
  content: "󱡐";
}

.mdi-earbuds-off-outline:before {
  content: "󱡑";
}

.mdi-earbuds-outline:before {
  content: "󱡒";
}

.mdi-earth:before {
  content: "󰇧";
}

.mdi-earth-arrow-down:before {
  content: "󱲇";
}

.mdi-earth-arrow-left:before {
  content: "󱲈";
}

.mdi-earth-arrow-right:before {
  content: "󱌑";
}

.mdi-earth-arrow-up:before {
  content: "󱲉";
}

.mdi-earth-box:before {
  content: "󰛍";
}

.mdi-earth-box-minus:before {
  content: "󱐇";
}

.mdi-earth-box-off:before {
  content: "󰛎";
}

.mdi-earth-box-plus:before {
  content: "󱐆";
}

.mdi-earth-box-remove:before {
  content: "󱐈";
}

.mdi-earth-minus:before {
  content: "󱐄";
}

.mdi-earth-off:before {
  content: "󰇨";
}

.mdi-earth-plus:before {
  content: "󱐃";
}

.mdi-earth-remove:before {
  content: "󱐅";
}

.mdi-egg:before {
  content: "󰪯";
}

.mdi-egg-easter:before {
  content: "󰪰";
}

.mdi-egg-fried:before {
  content: "󱡊";
}

.mdi-egg-off:before {
  content: "󱏰";
}

.mdi-egg-off-outline:before {
  content: "󱏱";
}

.mdi-egg-outline:before {
  content: "󱏲";
}

.mdi-eiffel-tower:before {
  content: "󱕫";
}

.mdi-eight-track:before {
  content: "󰧪";
}

.mdi-eject:before {
  content: "󰇪";
}

.mdi-eject-circle:before {
  content: "󱬣";
}

.mdi-eject-circle-outline:before {
  content: "󱬤";
}

.mdi-eject-outline:before {
  content: "󰮑";
}

.mdi-electric-switch:before {
  content: "󰺟";
}

.mdi-electric-switch-closed:before {
  content: "󱃙";
}

.mdi-electron-framework:before {
  content: "󱀤";
}

.mdi-elephant:before {
  content: "󰟆";
}

.mdi-elevation-decline:before {
  content: "󰇫";
}

.mdi-elevation-rise:before {
  content: "󰇬";
}

.mdi-elevator:before {
  content: "󰇭";
}

.mdi-elevator-down:before {
  content: "󱋂";
}

.mdi-elevator-passenger:before {
  content: "󱎁";
}

.mdi-elevator-passenger-off:before {
  content: "󱥹";
}

.mdi-elevator-passenger-off-outline:before {
  content: "󱥺";
}

.mdi-elevator-passenger-outline:before {
  content: "󱥻";
}

.mdi-elevator-up:before {
  content: "󱋁";
}

.mdi-ellipse:before {
  content: "󰺠";
}

.mdi-ellipse-outline:before {
  content: "󰺡";
}

.mdi-email:before {
  content: "󰇮";
}

.mdi-email-alert:before {
  content: "󰛏";
}

.mdi-email-alert-outline:before {
  content: "󰵂";
}

.mdi-email-arrow-left:before {
  content: "󱃚";
}

.mdi-email-arrow-left-outline:before {
  content: "󱃛";
}

.mdi-email-arrow-right:before {
  content: "󱃜";
}

.mdi-email-arrow-right-outline:before {
  content: "󱃝";
}

.mdi-email-box:before {
  content: "󰴃";
}

.mdi-email-check:before {
  content: "󰪱";
}

.mdi-email-check-outline:before {
  content: "󰪲";
}

.mdi-email-edit:before {
  content: "󰻣";
}

.mdi-email-edit-outline:before {
  content: "󰻤";
}

.mdi-email-fast:before {
  content: "󱡯";
}

.mdi-email-fast-outline:before {
  content: "󱡰";
}

.mdi-email-heart-outline:before {
  content: "󱱛";
}

.mdi-email-lock:before {
  content: "󰇱";
}

.mdi-email-lock-outline:before {
  content: "󱭡";
}

.mdi-email-mark-as-unread:before {
  content: "󰮒";
}

.mdi-email-minus:before {
  content: "󰻥";
}

.mdi-email-minus-outline:before {
  content: "󰻦";
}

.mdi-email-multiple:before {
  content: "󰻧";
}

.mdi-email-multiple-outline:before {
  content: "󰻨";
}

.mdi-email-newsletter:before {
  content: "󰾱";
}

.mdi-email-off:before {
  content: "󱏣";
}

.mdi-email-off-outline:before {
  content: "󱏤";
}

.mdi-email-open:before {
  content: "󰇯";
}

.mdi-email-open-heart-outline:before {
  content: "󱱜";
}

.mdi-email-open-multiple:before {
  content: "󰻩";
}

.mdi-email-open-multiple-outline:before {
  content: "󰻪";
}

.mdi-email-open-outline:before {
  content: "󰗯";
}

.mdi-email-outline:before {
  content: "󰇰";
}

.mdi-email-plus:before {
  content: "󰧫";
}

.mdi-email-plus-outline:before {
  content: "󰧬";
}

.mdi-email-remove:before {
  content: "󱙡";
}

.mdi-email-remove-outline:before {
  content: "󱙢";
}

.mdi-email-seal:before {
  content: "󱥛";
}

.mdi-email-seal-outline:before {
  content: "󱥜";
}

.mdi-email-search:before {
  content: "󰥡";
}

.mdi-email-search-outline:before {
  content: "󰥢";
}

.mdi-email-sync:before {
  content: "󱋇";
}

.mdi-email-sync-outline:before {
  content: "󱋈";
}

.mdi-email-variant:before {
  content: "󰗰";
}

.mdi-ember:before {
  content: "󰬰";
}

.mdi-emby:before {
  content: "󰚴";
}

.mdi-emoticon:before {
  content: "󰱨";
}

.mdi-emoticon-angry:before {
  content: "󰱩";
}

.mdi-emoticon-angry-outline:before {
  content: "󰱪";
}

.mdi-emoticon-confused:before {
  content: "󱃞";
}

.mdi-emoticon-confused-outline:before {
  content: "󱃟";
}

.mdi-emoticon-cool:before {
  content: "󰱫";
}

.mdi-emoticon-cool-outline:before {
  content: "󰇳";
}

.mdi-emoticon-cry:before {
  content: "󰱬";
}

.mdi-emoticon-cry-outline:before {
  content: "󰱭";
}

.mdi-emoticon-dead:before {
  content: "󰱮";
}

.mdi-emoticon-dead-outline:before {
  content: "󰚛";
}

.mdi-emoticon-devil:before {
  content: "󰱯";
}

.mdi-emoticon-devil-outline:before {
  content: "󰇴";
}

.mdi-emoticon-excited:before {
  content: "󰱰";
}

.mdi-emoticon-excited-outline:before {
  content: "󰚜";
}

.mdi-emoticon-frown:before {
  content: "󰽌";
}

.mdi-emoticon-frown-outline:before {
  content: "󰽍";
}

.mdi-emoticon-happy:before {
  content: "󰱱";
}

.mdi-emoticon-happy-outline:before {
  content: "󰇵";
}

.mdi-emoticon-kiss:before {
  content: "󰱲";
}

.mdi-emoticon-kiss-outline:before {
  content: "󰱳";
}

.mdi-emoticon-lol:before {
  content: "󱈔";
}

.mdi-emoticon-lol-outline:before {
  content: "󱈕";
}

.mdi-emoticon-minus:before {
  content: "󱲲";
}

.mdi-emoticon-minus-outline:before {
  content: "󱲳";
}

.mdi-emoticon-neutral:before {
  content: "󰱴";
}

.mdi-emoticon-neutral-outline:before {
  content: "󰇶";
}

.mdi-emoticon-outline:before {
  content: "󰇲";
}

.mdi-emoticon-plus:before {
  content: "󱲴";
}

.mdi-emoticon-plus-outline:before {
  content: "󱲵";
}

.mdi-emoticon-poop:before {
  content: "󰇷";
}

.mdi-emoticon-poop-outline:before {
  content: "󰱵";
}

.mdi-emoticon-remove:before {
  content: "󱲶";
}

.mdi-emoticon-remove-outline:before {
  content: "󱲷";
}

.mdi-emoticon-sad:before {
  content: "󰱶";
}

.mdi-emoticon-sad-outline:before {
  content: "󰇸";
}

.mdi-emoticon-sick:before {
  content: "󱕼";
}

.mdi-emoticon-sick-outline:before {
  content: "󱕽";
}

.mdi-emoticon-tongue:before {
  content: "󰇹";
}

.mdi-emoticon-tongue-outline:before {
  content: "󰱷";
}

.mdi-emoticon-wink:before {
  content: "󰱸";
}

.mdi-emoticon-wink-outline:before {
  content: "󰱹";
}

.mdi-engine:before {
  content: "󰇺";
}

.mdi-engine-off:before {
  content: "󰩆";
}

.mdi-engine-off-outline:before {
  content: "󰩇";
}

.mdi-engine-outline:before {
  content: "󰇻";
}

.mdi-epsilon:before {
  content: "󱃠";
}

.mdi-equal:before {
  content: "󰇼";
}

.mdi-equal-box:before {
  content: "󰇽";
}

.mdi-equalizer:before {
  content: "󰺢";
}

.mdi-equalizer-outline:before {
  content: "󰺣";
}

.mdi-eraser:before {
  content: "󰇾";
}

.mdi-eraser-variant:before {
  content: "󰙂";
}

.mdi-escalator:before {
  content: "󰇿";
}

.mdi-escalator-box:before {
  content: "󱎙";
}

.mdi-escalator-down:before {
  content: "󱋀";
}

.mdi-escalator-up:before {
  content: "󱊿";
}

.mdi-eslint:before {
  content: "󰱺";
}

.mdi-et:before {
  content: "󰪳";
}

.mdi-ethereum:before {
  content: "󰡪";
}

.mdi-ethernet:before {
  content: "󰈀";
}

.mdi-ethernet-cable:before {
  content: "󰈁";
}

.mdi-ethernet-cable-off:before {
  content: "󰈂";
}

.mdi-ethernet-off:before {
  content: "󱳑";
}

.mdi-ev-plug-ccs1:before {
  content: "󱔙";
}

.mdi-ev-plug-ccs2:before {
  content: "󱔚";
}

.mdi-ev-plug-chademo:before {
  content: "󱔛";
}

.mdi-ev-plug-tesla:before {
  content: "󱔜";
}

.mdi-ev-plug-type1:before {
  content: "󱔝";
}

.mdi-ev-plug-type2:before {
  content: "󱔞";
}

.mdi-ev-station:before {
  content: "󰗱";
}

.mdi-evernote:before {
  content: "󰈄";
}

.mdi-excavator:before {
  content: "󱀥";
}

.mdi-exclamation:before {
  content: "󰈅";
}

.mdi-exclamation-thick:before {
  content: "󱈸";
}

.mdi-exit-run:before {
  content: "󰩈";
}

.mdi-exit-to-app:before {
  content: "󰈆";
}

.mdi-expand-all:before {
  content: "󰪴";
}

.mdi-expand-all-outline:before {
  content: "󰪵";
}

.mdi-expansion-card:before {
  content: "󰢮";
}

.mdi-expansion-card-variant:before {
  content: "󰾲";
}

.mdi-exponent:before {
  content: "󰥣";
}

.mdi-exponent-box:before {
  content: "󰥤";
}

.mdi-export:before {
  content: "󰈇";
}

.mdi-export-variant:before {
  content: "󰮓";
}

.mdi-eye:before {
  content: "󰈈";
}

.mdi-eye-arrow-left:before {
  content: "󱣽";
}

.mdi-eye-arrow-left-outline:before {
  content: "󱣾";
}

.mdi-eye-arrow-right:before {
  content: "󱣿";
}

.mdi-eye-arrow-right-outline:before {
  content: "󱤀";
}

.mdi-eye-check:before {
  content: "󰴄";
}

.mdi-eye-check-outline:before {
  content: "󰴅";
}

.mdi-eye-circle:before {
  content: "󰮔";
}

.mdi-eye-circle-outline:before {
  content: "󰮕";
}

.mdi-eye-closed:before {
  content: "󱲣";
}

.mdi-eye-lock:before {
  content: "󱰆";
}

.mdi-eye-lock-open:before {
  content: "󱰇";
}

.mdi-eye-lock-open-outline:before {
  content: "󱰈";
}

.mdi-eye-lock-outline:before {
  content: "󱰉";
}

.mdi-eye-minus:before {
  content: "󱀦";
}

.mdi-eye-minus-outline:before {
  content: "󱀧";
}

.mdi-eye-off:before {
  content: "󰈉";
}

.mdi-eye-off-outline:before {
  content: "󰛑";
}

.mdi-eye-outline:before {
  content: "󰛐";
}

.mdi-eye-plus:before {
  content: "󰡫";
}

.mdi-eye-plus-outline:before {
  content: "󰡬";
}

.mdi-eye-refresh:before {
  content: "󱥼";
}

.mdi-eye-refresh-outline:before {
  content: "󱥽";
}

.mdi-eye-remove:before {
  content: "󱗣";
}

.mdi-eye-remove-outline:before {
  content: "󱗤";
}

.mdi-eye-settings:before {
  content: "󰡭";
}

.mdi-eye-settings-outline:before {
  content: "󰡮";
}

.mdi-eyedropper:before {
  content: "󰈊";
}

.mdi-eyedropper-minus:before {
  content: "󱏝";
}

.mdi-eyedropper-off:before {
  content: "󱏟";
}

.mdi-eyedropper-plus:before {
  content: "󱏜";
}

.mdi-eyedropper-remove:before {
  content: "󱏞";
}

.mdi-eyedropper-variant:before {
  content: "󰈋";
}

.mdi-face-agent:before {
  content: "󰵰";
}

.mdi-face-man:before {
  content: "󰙃";
}

.mdi-face-man-outline:before {
  content: "󰮖";
}

.mdi-face-man-profile:before {
  content: "󰙄";
}

.mdi-face-man-shimmer:before {
  content: "󱗌";
}

.mdi-face-man-shimmer-outline:before {
  content: "󱗍";
}

.mdi-face-mask:before {
  content: "󱖆";
}

.mdi-face-mask-outline:before {
  content: "󱖇";
}

.mdi-face-recognition:before {
  content: "󰱻";
}

.mdi-face-woman:before {
  content: "󱁷";
}

.mdi-face-woman-outline:before {
  content: "󱁸";
}

.mdi-face-woman-profile:before {
  content: "󱁶";
}

.mdi-face-woman-shimmer:before {
  content: "󱗎";
}

.mdi-face-woman-shimmer-outline:before {
  content: "󱗏";
}

.mdi-facebook:before {
  content: "󰈌";
}

.mdi-facebook-gaming:before {
  content: "󰟝";
}

.mdi-facebook-messenger:before {
  content: "󰈎";
}

.mdi-facebook-workplace:before {
  content: "󰬱";
}

.mdi-factory:before {
  content: "󰈏";
}

.mdi-family-tree:before {
  content: "󱘎";
}

.mdi-fan:before {
  content: "󰈐";
}

.mdi-fan-alert:before {
  content: "󱑬";
}

.mdi-fan-auto:before {
  content: "󱜝";
}

.mdi-fan-chevron-down:before {
  content: "󱑭";
}

.mdi-fan-chevron-up:before {
  content: "󱑮";
}

.mdi-fan-clock:before {
  content: "󱨺";
}

.mdi-fan-minus:before {
  content: "󱑰";
}

.mdi-fan-off:before {
  content: "󰠝";
}

.mdi-fan-plus:before {
  content: "󱑯";
}

.mdi-fan-remove:before {
  content: "󱑱";
}

.mdi-fan-speed-1:before {
  content: "󱑲";
}

.mdi-fan-speed-2:before {
  content: "󱑳";
}

.mdi-fan-speed-3:before {
  content: "󱑴";
}

.mdi-fast-forward:before {
  content: "󰈑";
}

.mdi-fast-forward-10:before {
  content: "󰵱";
}

.mdi-fast-forward-15:before {
  content: "󱤺";
}

.mdi-fast-forward-30:before {
  content: "󰴆";
}

.mdi-fast-forward-45:before {
  content: "󱬒";
}

.mdi-fast-forward-5:before {
  content: "󱇸";
}

.mdi-fast-forward-60:before {
  content: "󱘋";
}

.mdi-fast-forward-outline:before {
  content: "󰛒";
}

.mdi-faucet:before {
  content: "󱬩";
}

.mdi-faucet-variant:before {
  content: "󱬪";
}

.mdi-fax:before {
  content: "󰈒";
}

.mdi-feather:before {
  content: "󰛓";
}

.mdi-feature-search:before {
  content: "󰩉";
}

.mdi-feature-search-outline:before {
  content: "󰩊";
}

.mdi-fedora:before {
  content: "󰣛";
}

.mdi-fence:before {
  content: "󱞚";
}

.mdi-fence-electric:before {
  content: "󱟶";
}

.mdi-fencing:before {
  content: "󱓁";
}

.mdi-ferris-wheel:before {
  content: "󰺤";
}

.mdi-ferry:before {
  content: "󰈓";
}

.mdi-file:before {
  content: "󰈔";
}

.mdi-file-account:before {
  content: "󰜻";
}

.mdi-file-account-outline:before {
  content: "󱀨";
}

.mdi-file-alert:before {
  content: "󰩋";
}

.mdi-file-alert-outline:before {
  content: "󰩌";
}

.mdi-file-arrow-left-right:before {
  content: "󱪓";
}

.mdi-file-arrow-left-right-outline:before {
  content: "󱪔";
}

.mdi-file-arrow-up-down:before {
  content: "󱪕";
}

.mdi-file-arrow-up-down-outline:before {
  content: "󱪖";
}

.mdi-file-cabinet:before {
  content: "󰪶";
}

.mdi-file-cad:before {
  content: "󰻫";
}

.mdi-file-cad-box:before {
  content: "󰻬";
}

.mdi-file-cancel:before {
  content: "󰷆";
}

.mdi-file-cancel-outline:before {
  content: "󰷇";
}

.mdi-file-certificate:before {
  content: "󱆆";
}

.mdi-file-certificate-outline:before {
  content: "󱆇";
}

.mdi-file-chart:before {
  content: "󰈕";
}

.mdi-file-chart-check:before {
  content: "󱧆";
}

.mdi-file-chart-check-outline:before {
  content: "󱧇";
}

.mdi-file-chart-outline:before {
  content: "󱀩";
}

.mdi-file-check:before {
  content: "󰈖";
}

.mdi-file-check-outline:before {
  content: "󰸩";
}

.mdi-file-clock:before {
  content: "󱋡";
}

.mdi-file-clock-outline:before {
  content: "󱋢";
}

.mdi-file-cloud:before {
  content: "󰈗";
}

.mdi-file-cloud-outline:before {
  content: "󱀪";
}

.mdi-file-code:before {
  content: "󰈮";
}

.mdi-file-code-outline:before {
  content: "󱀫";
}

.mdi-file-cog:before {
  content: "󱁻";
}

.mdi-file-cog-outline:before {
  content: "󱁼";
}

.mdi-file-compare:before {
  content: "󰢪";
}

.mdi-file-delimited:before {
  content: "󰈘";
}

.mdi-file-delimited-outline:before {
  content: "󰺥";
}

.mdi-file-document:before {
  content: "󰈙";
}

.mdi-file-document-alert:before {
  content: "󱪗";
}

.mdi-file-document-alert-outline:before {
  content: "󱪘";
}

.mdi-file-document-arrow-right:before {
  content: "󱰏";
}

.mdi-file-document-arrow-right-outline:before {
  content: "󱰐";
}

.mdi-file-document-check:before {
  content: "󱪙";
}

.mdi-file-document-check-outline:before {
  content: "󱪚";
}

.mdi-file-document-edit:before {
  content: "󰷈";
}

.mdi-file-document-edit-outline:before {
  content: "󰷉";
}

.mdi-file-document-minus:before {
  content: "󱪛";
}

.mdi-file-document-minus-outline:before {
  content: "󱪜";
}

.mdi-file-document-multiple:before {
  content: "󱔗";
}

.mdi-file-document-multiple-outline:before {
  content: "󱔘";
}

.mdi-file-document-outline:before {
  content: "󰧮";
}

.mdi-file-document-plus:before {
  content: "󱪝";
}

.mdi-file-document-plus-outline:before {
  content: "󱪞";
}

.mdi-file-document-refresh:before {
  content: "󱱺";
}

.mdi-file-document-refresh-outline:before {
  content: "󱱻";
}

.mdi-file-document-remove:before {
  content: "󱪟";
}

.mdi-file-document-remove-outline:before {
  content: "󱪠";
}

.mdi-file-download:before {
  content: "󰥥";
}

.mdi-file-download-outline:before {
  content: "󰥦";
}

.mdi-file-edit:before {
  content: "󱇧";
}

.mdi-file-edit-outline:before {
  content: "󱇨";
}

.mdi-file-excel:before {
  content: "󰈛";
}

.mdi-file-excel-box:before {
  content: "󰈜";
}

.mdi-file-excel-box-outline:before {
  content: "󱀬";
}

.mdi-file-excel-outline:before {
  content: "󱀭";
}

.mdi-file-export:before {
  content: "󰈝";
}

.mdi-file-export-outline:before {
  content: "󱀮";
}

.mdi-file-eye:before {
  content: "󰷊";
}

.mdi-file-eye-outline:before {
  content: "󰷋";
}

.mdi-file-find:before {
  content: "󰈞";
}

.mdi-file-find-outline:before {
  content: "󰮗";
}

.mdi-file-gif-box:before {
  content: "󰵸";
}

.mdi-file-hidden:before {
  content: "󰘓";
}

.mdi-file-image:before {
  content: "󰈟";
}

.mdi-file-image-marker:before {
  content: "󱝲";
}

.mdi-file-image-marker-outline:before {
  content: "󱝳";
}

.mdi-file-image-minus:before {
  content: "󱤻";
}

.mdi-file-image-minus-outline:before {
  content: "󱤼";
}

.mdi-file-image-outline:before {
  content: "󰺰";
}

.mdi-file-image-plus:before {
  content: "󱤽";
}

.mdi-file-image-plus-outline:before {
  content: "󱤾";
}

.mdi-file-image-remove:before {
  content: "󱤿";
}

.mdi-file-image-remove-outline:before {
  content: "󱥀";
}

.mdi-file-import:before {
  content: "󰈠";
}

.mdi-file-import-outline:before {
  content: "󱀯";
}

.mdi-file-jpg-box:before {
  content: "󰈥";
}

.mdi-file-key:before {
  content: "󱆄";
}

.mdi-file-key-outline:before {
  content: "󱆅";
}

.mdi-file-link:before {
  content: "󱅷";
}

.mdi-file-link-outline:before {
  content: "󱅸";
}

.mdi-file-lock:before {
  content: "󰈡";
}

.mdi-file-lock-open:before {
  content: "󱧈";
}

.mdi-file-lock-open-outline:before {
  content: "󱧉";
}

.mdi-file-lock-outline:before {
  content: "󱀰";
}

.mdi-file-marker:before {
  content: "󱝴";
}

.mdi-file-marker-outline:before {
  content: "󱝵";
}

.mdi-file-minus:before {
  content: "󱪡";
}

.mdi-file-minus-outline:before {
  content: "󱪢";
}

.mdi-file-move:before {
  content: "󰪹";
}

.mdi-file-move-outline:before {
  content: "󱀱";
}

.mdi-file-multiple:before {
  content: "󰈢";
}

.mdi-file-multiple-outline:before {
  content: "󱀲";
}

.mdi-file-music:before {
  content: "󰈣";
}

.mdi-file-music-outline:before {
  content: "󰸪";
}

.mdi-file-outline:before {
  content: "󰈤";
}

.mdi-file-pdf-box:before {
  content: "󰈦";
}

.mdi-file-percent:before {
  content: "󰠞";
}

.mdi-file-percent-outline:before {
  content: "󱀳";
}

.mdi-file-phone:before {
  content: "󱅹";
}

.mdi-file-phone-outline:before {
  content: "󱅺";
}

.mdi-file-plus:before {
  content: "󰝒";
}

.mdi-file-plus-outline:before {
  content: "󰻭";
}

.mdi-file-png-box:before {
  content: "󰸭";
}

.mdi-file-powerpoint:before {
  content: "󰈧";
}

.mdi-file-powerpoint-box:before {
  content: "󰈨";
}

.mdi-file-powerpoint-box-outline:before {
  content: "󱀴";
}

.mdi-file-powerpoint-outline:before {
  content: "󱀵";
}

.mdi-file-presentation-box:before {
  content: "󰈩";
}

.mdi-file-question:before {
  content: "󰡯";
}

.mdi-file-question-outline:before {
  content: "󱀶";
}

.mdi-file-refresh:before {
  content: "󰤘";
}

.mdi-file-refresh-outline:before {
  content: "󰕁";
}

.mdi-file-remove:before {
  content: "󰮘";
}

.mdi-file-remove-outline:before {
  content: "󱀷";
}

.mdi-file-replace:before {
  content: "󰬲";
}

.mdi-file-replace-outline:before {
  content: "󰬳";
}

.mdi-file-restore:before {
  content: "󰙰";
}

.mdi-file-restore-outline:before {
  content: "󱀸";
}

.mdi-file-rotate-left:before {
  content: "󱨻";
}

.mdi-file-rotate-left-outline:before {
  content: "󱨼";
}

.mdi-file-rotate-right:before {
  content: "󱨽";
}

.mdi-file-rotate-right-outline:before {
  content: "󱨾";
}

.mdi-file-search:before {
  content: "󰱼";
}

.mdi-file-search-outline:before {
  content: "󰱽";
}

.mdi-file-send:before {
  content: "󰈪";
}

.mdi-file-send-outline:before {
  content: "󱀹";
}

.mdi-file-settings:before {
  content: "󱁹";
}

.mdi-file-settings-outline:before {
  content: "󱁺";
}

.mdi-file-sign:before {
  content: "󱧃";
}

.mdi-file-star:before {
  content: "󱀺";
}

.mdi-file-star-four-points:before {
  content: "󱰭";
}

.mdi-file-star-four-points-outline:before {
  content: "󱰮";
}

.mdi-file-star-outline:before {
  content: "󱀻";
}

.mdi-file-swap:before {
  content: "󰾴";
}

.mdi-file-swap-outline:before {
  content: "󰾵";
}

.mdi-file-sync:before {
  content: "󱈖";
}

.mdi-file-sync-outline:before {
  content: "󱈗";
}

.mdi-file-table:before {
  content: "󰱾";
}

.mdi-file-table-box:before {
  content: "󱃡";
}

.mdi-file-table-box-multiple:before {
  content: "󱃢";
}

.mdi-file-table-box-multiple-outline:before {
  content: "󱃣";
}

.mdi-file-table-box-outline:before {
  content: "󱃤";
}

.mdi-file-table-outline:before {
  content: "󰱿";
}

.mdi-file-tree:before {
  content: "󰙅";
}

.mdi-file-tree-outline:before {
  content: "󱏒";
}

.mdi-file-undo:before {
  content: "󰣜";
}

.mdi-file-undo-outline:before {
  content: "󱀼";
}

.mdi-file-upload:before {
  content: "󰩍";
}

.mdi-file-upload-outline:before {
  content: "󰩎";
}

.mdi-file-video:before {
  content: "󰈫";
}

.mdi-file-video-outline:before {
  content: "󰸬";
}

.mdi-file-word:before {
  content: "󰈬";
}

.mdi-file-word-box:before {
  content: "󰈭";
}

.mdi-file-word-box-outline:before {
  content: "󱀽";
}

.mdi-file-word-outline:before {
  content: "󱀾";
}

.mdi-file-xml-box:before {
  content: "󱭋";
}

.mdi-film:before {
  content: "󰈯";
}

.mdi-filmstrip:before {
  content: "󰈰";
}

.mdi-filmstrip-box:before {
  content: "󰌲";
}

.mdi-filmstrip-box-multiple:before {
  content: "󰴘";
}

.mdi-filmstrip-off:before {
  content: "󰈱";
}

.mdi-filter:before {
  content: "󰈲";
}

.mdi-filter-check:before {
  content: "󱣬";
}

.mdi-filter-check-outline:before {
  content: "󱣭";
}

.mdi-filter-cog:before {
  content: "󱪣";
}

.mdi-filter-cog-outline:before {
  content: "󱪤";
}

.mdi-filter-menu:before {
  content: "󱃥";
}

.mdi-filter-menu-outline:before {
  content: "󱃦";
}

.mdi-filter-minus:before {
  content: "󰻮";
}

.mdi-filter-minus-outline:before {
  content: "󰻯";
}

.mdi-filter-multiple:before {
  content: "󱨿";
}

.mdi-filter-multiple-outline:before {
  content: "󱩀";
}

.mdi-filter-off:before {
  content: "󱓯";
}

.mdi-filter-off-outline:before {
  content: "󱓰";
}

.mdi-filter-outline:before {
  content: "󰈳";
}

.mdi-filter-plus:before {
  content: "󰻰";
}

.mdi-filter-plus-outline:before {
  content: "󰻱";
}

.mdi-filter-remove:before {
  content: "󰈴";
}

.mdi-filter-remove-outline:before {
  content: "󰈵";
}

.mdi-filter-settings:before {
  content: "󱪥";
}

.mdi-filter-settings-outline:before {
  content: "󱪦";
}

.mdi-filter-variant:before {
  content: "󰈶";
}

.mdi-filter-variant-minus:before {
  content: "󱄒";
}

.mdi-filter-variant-plus:before {
  content: "󱄓";
}

.mdi-filter-variant-remove:before {
  content: "󱀿";
}

.mdi-finance:before {
  content: "󰠟";
}

.mdi-find-replace:before {
  content: "󰛔";
}

.mdi-fingerprint:before {
  content: "󰈷";
}

.mdi-fingerprint-off:before {
  content: "󰺱";
}

.mdi-fire:before {
  content: "󰈸";
}

.mdi-fire-alert:before {
  content: "󱗗";
}

.mdi-fire-circle:before {
  content: "󱠇";
}

.mdi-fire-extinguisher:before {
  content: "󰻲";
}

.mdi-fire-hydrant:before {
  content: "󱄷";
}

.mdi-fire-hydrant-alert:before {
  content: "󱄸";
}

.mdi-fire-hydrant-off:before {
  content: "󱄹";
}

.mdi-fire-off:before {
  content: "󱜢";
}

.mdi-fire-station:before {
  content: "󱳃";
}

.mdi-fire-truck:before {
  content: "󰢫";
}

.mdi-firebase:before {
  content: "󰥧";
}

.mdi-firefox:before {
  content: "󰈹";
}

.mdi-fireplace:before {
  content: "󰸮";
}

.mdi-fireplace-off:before {
  content: "󰸯";
}

.mdi-firewire:before {
  content: "󰖾";
}

.mdi-firework:before {
  content: "󰸰";
}

.mdi-firework-off:before {
  content: "󱜣";
}

.mdi-fish:before {
  content: "󰈺";
}

.mdi-fish-off:before {
  content: "󱏳";
}

.mdi-fishbowl:before {
  content: "󰻳";
}

.mdi-fishbowl-outline:before {
  content: "󰻴";
}

.mdi-fit-to-page:before {
  content: "󰻵";
}

.mdi-fit-to-page-outline:before {
  content: "󰻶";
}

.mdi-fit-to-screen:before {
  content: "󱣴";
}

.mdi-fit-to-screen-outline:before {
  content: "󱣵";
}

.mdi-flag:before {
  content: "󰈻";
}

.mdi-flag-checkered:before {
  content: "󰈼";
}

.mdi-flag-minus:before {
  content: "󰮙";
}

.mdi-flag-minus-outline:before {
  content: "󱂲";
}

.mdi-flag-off:before {
  content: "󱣮";
}

.mdi-flag-off-outline:before {
  content: "󱣯";
}

.mdi-flag-outline:before {
  content: "󰈽";
}

.mdi-flag-plus:before {
  content: "󰮚";
}

.mdi-flag-plus-outline:before {
  content: "󱂳";
}

.mdi-flag-remove:before {
  content: "󰮛";
}

.mdi-flag-remove-outline:before {
  content: "󱂴";
}

.mdi-flag-triangle:before {
  content: "󰈿";
}

.mdi-flag-variant:before {
  content: "󰉀";
}

.mdi-flag-variant-minus:before {
  content: "󱮴";
}

.mdi-flag-variant-minus-outline:before {
  content: "󱮵";
}

.mdi-flag-variant-off:before {
  content: "󱮰";
}

.mdi-flag-variant-off-outline:before {
  content: "󱮱";
}

.mdi-flag-variant-outline:before {
  content: "󰈾";
}

.mdi-flag-variant-plus:before {
  content: "󱮲";
}

.mdi-flag-variant-plus-outline:before {
  content: "󱮳";
}

.mdi-flag-variant-remove:before {
  content: "󱮶";
}

.mdi-flag-variant-remove-outline:before {
  content: "󱮷";
}

.mdi-flare:before {
  content: "󰵲";
}

.mdi-flash:before {
  content: "󰉁";
}

.mdi-flash-alert:before {
  content: "󰻷";
}

.mdi-flash-alert-outline:before {
  content: "󰻸";
}

.mdi-flash-auto:before {
  content: "󰉂";
}

.mdi-flash-off:before {
  content: "󰉃";
}

.mdi-flash-off-outline:before {
  content: "󱭅";
}

.mdi-flash-outline:before {
  content: "󰛕";
}

.mdi-flash-red-eye:before {
  content: "󰙻";
}

.mdi-flash-triangle:before {
  content: "󱬝";
}

.mdi-flash-triangle-outline:before {
  content: "󱬞";
}

.mdi-flashlight:before {
  content: "󰉄";
}

.mdi-flashlight-off:before {
  content: "󰉅";
}

.mdi-flask:before {
  content: "󰂓";
}

.mdi-flask-empty:before {
  content: "󰂔";
}

.mdi-flask-empty-minus:before {
  content: "󱈺";
}

.mdi-flask-empty-minus-outline:before {
  content: "󱈻";
}

.mdi-flask-empty-off:before {
  content: "󱏴";
}

.mdi-flask-empty-off-outline:before {
  content: "󱏵";
}

.mdi-flask-empty-outline:before {
  content: "󰂕";
}

.mdi-flask-empty-plus:before {
  content: "󱈼";
}

.mdi-flask-empty-plus-outline:before {
  content: "󱈽";
}

.mdi-flask-empty-remove:before {
  content: "󱈾";
}

.mdi-flask-empty-remove-outline:before {
  content: "󱈿";
}

.mdi-flask-minus:before {
  content: "󱉀";
}

.mdi-flask-minus-outline:before {
  content: "󱉁";
}

.mdi-flask-off:before {
  content: "󱏶";
}

.mdi-flask-off-outline:before {
  content: "󱏷";
}

.mdi-flask-outline:before {
  content: "󰂖";
}

.mdi-flask-plus:before {
  content: "󱉂";
}

.mdi-flask-plus-outline:before {
  content: "󱉃";
}

.mdi-flask-remove:before {
  content: "󱉄";
}

.mdi-flask-remove-outline:before {
  content: "󱉅";
}

.mdi-flask-round-bottom:before {
  content: "󱉋";
}

.mdi-flask-round-bottom-empty:before {
  content: "󱉌";
}

.mdi-flask-round-bottom-empty-outline:before {
  content: "󱉍";
}

.mdi-flask-round-bottom-outline:before {
  content: "󱉎";
}

.mdi-fleur-de-lis:before {
  content: "󱌃";
}

.mdi-flip-horizontal:before {
  content: "󱃧";
}

.mdi-flip-to-back:before {
  content: "󰉇";
}

.mdi-flip-to-front:before {
  content: "󰉈";
}

.mdi-flip-vertical:before {
  content: "󱃨";
}

.mdi-floor-lamp:before {
  content: "󰣝";
}

.mdi-floor-lamp-dual:before {
  content: "󱁀";
}

.mdi-floor-lamp-dual-outline:before {
  content: "󱟎";
}

.mdi-floor-lamp-outline:before {
  content: "󱟈";
}

.mdi-floor-lamp-torchiere:before {
  content: "󱝇";
}

.mdi-floor-lamp-torchiere-outline:before {
  content: "󱟖";
}

.mdi-floor-lamp-torchiere-variant:before {
  content: "󱁁";
}

.mdi-floor-lamp-torchiere-variant-outline:before {
  content: "󱟏";
}

.mdi-floor-plan:before {
  content: "󰠡";
}

.mdi-floppy:before {
  content: "󰉉";
}

.mdi-floppy-variant:before {
  content: "󰧯";
}

.mdi-flower:before {
  content: "󰉊";
}

.mdi-flower-outline:before {
  content: "󰧰";
}

.mdi-flower-pollen:before {
  content: "󱢅";
}

.mdi-flower-pollen-outline:before {
  content: "󱢆";
}

.mdi-flower-poppy:before {
  content: "󰴈";
}

.mdi-flower-tulip:before {
  content: "󰧱";
}

.mdi-flower-tulip-outline:before {
  content: "󰧲";
}

.mdi-focus-auto:before {
  content: "󰽎";
}

.mdi-focus-field:before {
  content: "󰽏";
}

.mdi-focus-field-horizontal:before {
  content: "󰽐";
}

.mdi-focus-field-vertical:before {
  content: "󰽑";
}

.mdi-folder:before {
  content: "󰉋";
}

.mdi-folder-account:before {
  content: "󰉌";
}

.mdi-folder-account-outline:before {
  content: "󰮜";
}

.mdi-folder-alert:before {
  content: "󰷌";
}

.mdi-folder-alert-outline:before {
  content: "󰷍";
}

.mdi-folder-arrow-down:before {
  content: "󱧨";
}

.mdi-folder-arrow-down-outline:before {
  content: "󱧩";
}

.mdi-folder-arrow-left:before {
  content: "󱧪";
}

.mdi-folder-arrow-left-outline:before {
  content: "󱧫";
}

.mdi-folder-arrow-left-right:before {
  content: "󱧬";
}

.mdi-folder-arrow-left-right-outline:before {
  content: "󱧭";
}

.mdi-folder-arrow-right:before {
  content: "󱧮";
}

.mdi-folder-arrow-right-outline:before {
  content: "󱧯";
}

.mdi-folder-arrow-up:before {
  content: "󱧰";
}

.mdi-folder-arrow-up-down:before {
  content: "󱧱";
}

.mdi-folder-arrow-up-down-outline:before {
  content: "󱧲";
}

.mdi-folder-arrow-up-outline:before {
  content: "󱧳";
}

.mdi-folder-cancel:before {
  content: "󱧴";
}

.mdi-folder-cancel-outline:before {
  content: "󱧵";
}

.mdi-folder-check:before {
  content: "󱥾";
}

.mdi-folder-check-outline:before {
  content: "󱥿";
}

.mdi-folder-clock:before {
  content: "󰪺";
}

.mdi-folder-clock-outline:before {
  content: "󰪻";
}

.mdi-folder-cog:before {
  content: "󱁿";
}

.mdi-folder-cog-outline:before {
  content: "󱂀";
}

.mdi-folder-download:before {
  content: "󰉍";
}

.mdi-folder-download-outline:before {
  content: "󱃩";
}

.mdi-folder-edit:before {
  content: "󰣞";
}

.mdi-folder-edit-outline:before {
  content: "󰷎";
}

.mdi-folder-eye:before {
  content: "󱞊";
}

.mdi-folder-eye-outline:before {
  content: "󱞋";
}

.mdi-folder-file:before {
  content: "󱧶";
}

.mdi-folder-file-outline:before {
  content: "󱧷";
}

.mdi-folder-google-drive:before {
  content: "󰉎";
}

.mdi-folder-heart:before {
  content: "󱃪";
}

.mdi-folder-heart-outline:before {
  content: "󱃫";
}

.mdi-folder-hidden:before {
  content: "󱞞";
}

.mdi-folder-home:before {
  content: "󱂵";
}

.mdi-folder-home-outline:before {
  content: "󱂶";
}

.mdi-folder-image:before {
  content: "󰉏";
}

.mdi-folder-information:before {
  content: "󱂷";
}

.mdi-folder-information-outline:before {
  content: "󱂸";
}

.mdi-folder-key:before {
  content: "󰢬";
}

.mdi-folder-key-network:before {
  content: "󰢭";
}

.mdi-folder-key-network-outline:before {
  content: "󰲀";
}

.mdi-folder-key-outline:before {
  content: "󱃬";
}

.mdi-folder-lock:before {
  content: "󰉐";
}

.mdi-folder-lock-open:before {
  content: "󰉑";
}

.mdi-folder-lock-open-outline:before {
  content: "󱪧";
}

.mdi-folder-lock-outline:before {
  content: "󱪨";
}

.mdi-folder-marker:before {
  content: "󱉭";
}

.mdi-folder-marker-outline:before {
  content: "󱉮";
}

.mdi-folder-minus:before {
  content: "󱭉";
}

.mdi-folder-minus-outline:before {
  content: "󱭊";
}

.mdi-folder-move:before {
  content: "󰉒";
}

.mdi-folder-move-outline:before {
  content: "󱉆";
}

.mdi-folder-multiple:before {
  content: "󰉓";
}

.mdi-folder-multiple-image:before {
  content: "󰉔";
}

.mdi-folder-multiple-outline:before {
  content: "󰉕";
}

.mdi-folder-multiple-plus:before {
  content: "󱑾";
}

.mdi-folder-multiple-plus-outline:before {
  content: "󱑿";
}

.mdi-folder-music:before {
  content: "󱍙";
}

.mdi-folder-music-outline:before {
  content: "󱍚";
}

.mdi-folder-network:before {
  content: "󰡰";
}

.mdi-folder-network-outline:before {
  content: "󰲁";
}

.mdi-folder-off:before {
  content: "󱧸";
}

.mdi-folder-off-outline:before {
  content: "󱧹";
}

.mdi-folder-open:before {
  content: "󰝰";
}

.mdi-folder-open-outline:before {
  content: "󰷏";
}

.mdi-folder-outline:before {
  content: "󰉖";
}

.mdi-folder-play:before {
  content: "󱧺";
}

.mdi-folder-play-outline:before {
  content: "󱧻";
}

.mdi-folder-plus:before {
  content: "󰉗";
}

.mdi-folder-plus-outline:before {
  content: "󰮝";
}

.mdi-folder-pound:before {
  content: "󰴉";
}

.mdi-folder-pound-outline:before {
  content: "󰴊";
}

.mdi-folder-question:before {
  content: "󱧊";
}

.mdi-folder-question-outline:before {
  content: "󱧋";
}

.mdi-folder-refresh:before {
  content: "󰝉";
}

.mdi-folder-refresh-outline:before {
  content: "󰕂";
}

.mdi-folder-remove:before {
  content: "󰉘";
}

.mdi-folder-remove-outline:before {
  content: "󰮞";
}

.mdi-folder-search:before {
  content: "󰥨";
}

.mdi-folder-search-outline:before {
  content: "󰥩";
}

.mdi-folder-settings:before {
  content: "󱁽";
}

.mdi-folder-settings-outline:before {
  content: "󱁾";
}

.mdi-folder-star:before {
  content: "󰚝";
}

.mdi-folder-star-multiple:before {
  content: "󱏓";
}

.mdi-folder-star-multiple-outline:before {
  content: "󱏔";
}

.mdi-folder-star-outline:before {
  content: "󰮟";
}

.mdi-folder-swap:before {
  content: "󰾶";
}

.mdi-folder-swap-outline:before {
  content: "󰾷";
}

.mdi-folder-sync:before {
  content: "󰴋";
}

.mdi-folder-sync-outline:before {
  content: "󰴌";
}

.mdi-folder-table:before {
  content: "󱋣";
}

.mdi-folder-table-outline:before {
  content: "󱋤";
}

.mdi-folder-text:before {
  content: "󰲂";
}

.mdi-folder-text-outline:before {
  content: "󰲃";
}

.mdi-folder-upload:before {
  content: "󰉙";
}

.mdi-folder-upload-outline:before {
  content: "󱃭";
}

.mdi-folder-wrench:before {
  content: "󱧼";
}

.mdi-folder-wrench-outline:before {
  content: "󱧽";
}

.mdi-folder-zip:before {
  content: "󰛫";
}

.mdi-folder-zip-outline:before {
  content: "󰞹";
}

.mdi-font-awesome:before {
  content: "󰀺";
}

.mdi-food:before {
  content: "󰉚";
}

.mdi-food-apple:before {
  content: "󰉛";
}

.mdi-food-apple-outline:before {
  content: "󰲄";
}

.mdi-food-croissant:before {
  content: "󰟈";
}

.mdi-food-drumstick:before {
  content: "󱐟";
}

.mdi-food-drumstick-off:before {
  content: "󱑨";
}

.mdi-food-drumstick-off-outline:before {
  content: "󱑩";
}

.mdi-food-drumstick-outline:before {
  content: "󱐠";
}

.mdi-food-fork-drink:before {
  content: "󰗲";
}

.mdi-food-halal:before {
  content: "󱕲";
}

.mdi-food-hot-dog:before {
  content: "󱡋";
}

.mdi-food-kosher:before {
  content: "󱕳";
}

.mdi-food-off:before {
  content: "󰗳";
}

.mdi-food-off-outline:before {
  content: "󱤕";
}

.mdi-food-outline:before {
  content: "󱤖";
}

.mdi-food-steak:before {
  content: "󱑪";
}

.mdi-food-steak-off:before {
  content: "󱑫";
}

.mdi-food-takeout-box:before {
  content: "󱠶";
}

.mdi-food-takeout-box-outline:before {
  content: "󱠷";
}

.mdi-food-turkey:before {
  content: "󱜜";
}

.mdi-food-variant:before {
  content: "󰉜";
}

.mdi-food-variant-off:before {
  content: "󱏥";
}

.mdi-foot-print:before {
  content: "󰽒";
}

.mdi-football:before {
  content: "󰉝";
}

.mdi-football-australian:before {
  content: "󰉞";
}

.mdi-football-helmet:before {
  content: "󰉟";
}

.mdi-forest:before {
  content: "󱢗";
}

.mdi-forest-outline:before {
  content: "󱱣";
}

.mdi-forklift:before {
  content: "󰟉";
}

.mdi-form-dropdown:before {
  content: "󱐀";
}

.mdi-form-select:before {
  content: "󱐁";
}

.mdi-form-textarea:before {
  content: "󱂕";
}

.mdi-form-textbox:before {
  content: "󰘎";
}

.mdi-form-textbox-lock:before {
  content: "󱍝";
}

.mdi-form-textbox-password:before {
  content: "󰟵";
}

.mdi-format-align-bottom:before {
  content: "󰝓";
}

.mdi-format-align-center:before {
  content: "󰉠";
}

.mdi-format-align-justify:before {
  content: "󰉡";
}

.mdi-format-align-left:before {
  content: "󰉢";
}

.mdi-format-align-middle:before {
  content: "󰝔";
}

.mdi-format-align-right:before {
  content: "󰉣";
}

.mdi-format-align-top:before {
  content: "󰝕";
}

.mdi-format-annotation-minus:before {
  content: "󰪼";
}

.mdi-format-annotation-plus:before {
  content: "󰙆";
}

.mdi-format-bold:before {
  content: "󰉤";
}

.mdi-format-clear:before {
  content: "󰉥";
}

.mdi-format-color-fill:before {
  content: "󰉦";
}

.mdi-format-color-highlight:before {
  content: "󰸱";
}

.mdi-format-color-marker-cancel:before {
  content: "󱌓";
}

.mdi-format-color-text:before {
  content: "󰚞";
}

.mdi-format-columns:before {
  content: "󰣟";
}

.mdi-format-float-center:before {
  content: "󰉧";
}

.mdi-format-float-left:before {
  content: "󰉨";
}

.mdi-format-float-none:before {
  content: "󰉩";
}

.mdi-format-float-right:before {
  content: "󰉪";
}

.mdi-format-font:before {
  content: "󰛖";
}

.mdi-format-font-size-decrease:before {
  content: "󰧳";
}

.mdi-format-font-size-increase:before {
  content: "󰧴";
}

.mdi-format-header-1:before {
  content: "󰉫";
}

.mdi-format-header-2:before {
  content: "󰉬";
}

.mdi-format-header-3:before {
  content: "󰉭";
}

.mdi-format-header-4:before {
  content: "󰉮";
}

.mdi-format-header-5:before {
  content: "󰉯";
}

.mdi-format-header-6:before {
  content: "󰉰";
}

.mdi-format-header-decrease:before {
  content: "󰉱";
}

.mdi-format-header-equal:before {
  content: "󰉲";
}

.mdi-format-header-increase:before {
  content: "󰉳";
}

.mdi-format-header-pound:before {
  content: "󰉴";
}

.mdi-format-horizontal-align-center:before {
  content: "󰘞";
}

.mdi-format-horizontal-align-left:before {
  content: "󰘟";
}

.mdi-format-horizontal-align-right:before {
  content: "󰘠";
}

.mdi-format-indent-decrease:before {
  content: "󰉵";
}

.mdi-format-indent-increase:before {
  content: "󰉶";
}

.mdi-format-italic:before {
  content: "󰉷";
}

.mdi-format-letter-case:before {
  content: "󰬴";
}

.mdi-format-letter-case-lower:before {
  content: "󰬵";
}

.mdi-format-letter-case-upper:before {
  content: "󰬶";
}

.mdi-format-letter-ends-with:before {
  content: "󰾸";
}

.mdi-format-letter-matches:before {
  content: "󰾹";
}

.mdi-format-letter-spacing:before {
  content: "󱥖";
}

.mdi-format-letter-spacing-variant:before {
  content: "󱫻";
}

.mdi-format-letter-starts-with:before {
  content: "󰾺";
}

.mdi-format-line-height:before {
  content: "󱫼";
}

.mdi-format-line-spacing:before {
  content: "󰉸";
}

.mdi-format-line-style:before {
  content: "󰗈";
}

.mdi-format-line-weight:before {
  content: "󰗉";
}

.mdi-format-list-bulleted:before {
  content: "󰉹";
}

.mdi-format-list-bulleted-square:before {
  content: "󰷐";
}

.mdi-format-list-bulleted-triangle:before {
  content: "󰺲";
}

.mdi-format-list-bulleted-type:before {
  content: "󰉺";
}

.mdi-format-list-checkbox:before {
  content: "󰥪";
}

.mdi-format-list-checks:before {
  content: "󰝖";
}

.mdi-format-list-group:before {
  content: "󱡠";
}

.mdi-format-list-group-plus:before {
  content: "󱭖";
}

.mdi-format-list-numbered:before {
  content: "󰉻";
}

.mdi-format-list-numbered-rtl:before {
  content: "󰴍";
}

.mdi-format-list-text:before {
  content: "󱉯";
}

.mdi-format-overline:before {
  content: "󰺳";
}

.mdi-format-page-break:before {
  content: "󰛗";
}

.mdi-format-page-split:before {
  content: "󱤗";
}

.mdi-format-paint:before {
  content: "󰉼";
}

.mdi-format-paragraph:before {
  content: "󰉽";
}

.mdi-format-paragraph-spacing:before {
  content: "󱫽";
}

.mdi-format-pilcrow:before {
  content: "󰛘";
}

.mdi-format-pilcrow-arrow-left:before {
  content: "󰊆";
}

.mdi-format-pilcrow-arrow-right:before {
  content: "󰊅";
}

.mdi-format-quote-close:before {
  content: "󰉾";
}

.mdi-format-quote-close-outline:before {
  content: "󱆨";
}

.mdi-format-quote-open:before {
  content: "󰝗";
}

.mdi-format-quote-open-outline:before {
  content: "󱆧";
}

.mdi-format-rotate-90:before {
  content: "󰚪";
}

.mdi-format-section:before {
  content: "󰚟";
}

.mdi-format-size:before {
  content: "󰉿";
}

.mdi-format-strikethrough:before {
  content: "󰊀";
}

.mdi-format-strikethrough-variant:before {
  content: "󰊁";
}

.mdi-format-subscript:before {
  content: "󰊂";
}

.mdi-format-superscript:before {
  content: "󰊃";
}

.mdi-format-text:before {
  content: "󰊄";
}

.mdi-format-text-rotation-angle-down:before {
  content: "󰾻";
}

.mdi-format-text-rotation-angle-up:before {
  content: "󰾼";
}

.mdi-format-text-rotation-down:before {
  content: "󰵳";
}

.mdi-format-text-rotation-down-vertical:before {
  content: "󰾽";
}

.mdi-format-text-rotation-none:before {
  content: "󰵴";
}

.mdi-format-text-rotation-up:before {
  content: "󰾾";
}

.mdi-format-text-rotation-vertical:before {
  content: "󰾿";
}

.mdi-format-text-variant:before {
  content: "󰸲";
}

.mdi-format-text-variant-outline:before {
  content: "󱔏";
}

.mdi-format-text-wrapping-clip:before {
  content: "󰴎";
}

.mdi-format-text-wrapping-overflow:before {
  content: "󰴏";
}

.mdi-format-text-wrapping-wrap:before {
  content: "󰴐";
}

.mdi-format-textbox:before {
  content: "󰴑";
}

.mdi-format-title:before {
  content: "󰗴";
}

.mdi-format-underline:before {
  content: "󰊇";
}

.mdi-format-underline-wavy:before {
  content: "󱣩";
}

.mdi-format-vertical-align-bottom:before {
  content: "󰘡";
}

.mdi-format-vertical-align-center:before {
  content: "󰘢";
}

.mdi-format-vertical-align-top:before {
  content: "󰘣";
}

.mdi-format-wrap-inline:before {
  content: "󰊈";
}

.mdi-format-wrap-square:before {
  content: "󰊉";
}

.mdi-format-wrap-tight:before {
  content: "󰊊";
}

.mdi-format-wrap-top-bottom:before {
  content: "󰊋";
}

.mdi-forum:before {
  content: "󰊌";
}

.mdi-forum-minus:before {
  content: "󱪩";
}

.mdi-forum-minus-outline:before {
  content: "󱪪";
}

.mdi-forum-outline:before {
  content: "󰠢";
}

.mdi-forum-plus:before {
  content: "󱪫";
}

.mdi-forum-plus-outline:before {
  content: "󱪬";
}

.mdi-forum-remove:before {
  content: "󱪭";
}

.mdi-forum-remove-outline:before {
  content: "󱪮";
}

.mdi-forward:before {
  content: "󰊍";
}

.mdi-forwardburger:before {
  content: "󰵵";
}

.mdi-fountain:before {
  content: "󰥫";
}

.mdi-fountain-pen:before {
  content: "󰴒";
}

.mdi-fountain-pen-tip:before {
  content: "󰴓";
}

.mdi-fraction-one-half:before {
  content: "󱦒";
}

.mdi-freebsd:before {
  content: "󰣠";
}

.mdi-french-fries:before {
  content: "󱥗";
}

.mdi-frequently-asked-questions:before {
  content: "󰺴";
}

.mdi-fridge:before {
  content: "󰊐";
}

.mdi-fridge-alert:before {
  content: "󱆱";
}

.mdi-fridge-alert-outline:before {
  content: "󱆲";
}

.mdi-fridge-bottom:before {
  content: "󰊒";
}

.mdi-fridge-industrial:before {
  content: "󱗮";
}

.mdi-fridge-industrial-alert:before {
  content: "󱗯";
}

.mdi-fridge-industrial-alert-outline:before {
  content: "󱗰";
}

.mdi-fridge-industrial-off:before {
  content: "󱗱";
}

.mdi-fridge-industrial-off-outline:before {
  content: "󱗲";
}

.mdi-fridge-industrial-outline:before {
  content: "󱗳";
}

.mdi-fridge-off:before {
  content: "󱆯";
}

.mdi-fridge-off-outline:before {
  content: "󱆰";
}

.mdi-fridge-outline:before {
  content: "󰊏";
}

.mdi-fridge-top:before {
  content: "󰊑";
}

.mdi-fridge-variant:before {
  content: "󱗴";
}

.mdi-fridge-variant-alert:before {
  content: "󱗵";
}

.mdi-fridge-variant-alert-outline:before {
  content: "󱗶";
}

.mdi-fridge-variant-off:before {
  content: "󱗷";
}

.mdi-fridge-variant-off-outline:before {
  content: "󱗸";
}

.mdi-fridge-variant-outline:before {
  content: "󱗹";
}

.mdi-fruit-cherries:before {
  content: "󱁂";
}

.mdi-fruit-cherries-off:before {
  content: "󱏸";
}

.mdi-fruit-citrus:before {
  content: "󱁃";
}

.mdi-fruit-citrus-off:before {
  content: "󱏹";
}

.mdi-fruit-grapes:before {
  content: "󱁄";
}

.mdi-fruit-grapes-outline:before {
  content: "󱁅";
}

.mdi-fruit-pear:before {
  content: "󱨎";
}

.mdi-fruit-pineapple:before {
  content: "󱁆";
}

.mdi-fruit-watermelon:before {
  content: "󱁇";
}

.mdi-fuel:before {
  content: "󰟊";
}

.mdi-fuel-cell:before {
  content: "󱢵";
}

.mdi-fullscreen:before {
  content: "󰊓";
}

.mdi-fullscreen-exit:before {
  content: "󰊔";
}

.mdi-function:before {
  content: "󰊕";
}

.mdi-function-variant:before {
  content: "󰡱";
}

.mdi-furigana-horizontal:before {
  content: "󱂁";
}

.mdi-furigana-vertical:before {
  content: "󱂂";
}

.mdi-fuse:before {
  content: "󰲅";
}

.mdi-fuse-alert:before {
  content: "󱐭";
}

.mdi-fuse-blade:before {
  content: "󰲆";
}

.mdi-fuse-off:before {
  content: "󱐬";
}

.mdi-gamepad:before {
  content: "󰊖";
}

.mdi-gamepad-circle:before {
  content: "󰸳";
}

.mdi-gamepad-circle-down:before {
  content: "󰸴";
}

.mdi-gamepad-circle-left:before {
  content: "󰸵";
}

.mdi-gamepad-circle-outline:before {
  content: "󰸶";
}

.mdi-gamepad-circle-right:before {
  content: "󰸷";
}

.mdi-gamepad-circle-up:before {
  content: "󰸸";
}

.mdi-gamepad-down:before {
  content: "󰸹";
}

.mdi-gamepad-left:before {
  content: "󰸺";
}

.mdi-gamepad-outline:before {
  content: "󱤙";
}

.mdi-gamepad-right:before {
  content: "󰸻";
}

.mdi-gamepad-round:before {
  content: "󰸼";
}

.mdi-gamepad-round-down:before {
  content: "󰸽";
}

.mdi-gamepad-round-left:before {
  content: "󰸾";
}

.mdi-gamepad-round-outline:before {
  content: "󰸿";
}

.mdi-gamepad-round-right:before {
  content: "󰹀";
}

.mdi-gamepad-round-up:before {
  content: "󰹁";
}

.mdi-gamepad-square:before {
  content: "󰺵";
}

.mdi-gamepad-square-outline:before {
  content: "󰺶";
}

.mdi-gamepad-up:before {
  content: "󰹂";
}

.mdi-gamepad-variant:before {
  content: "󰊗";
}

.mdi-gamepad-variant-outline:before {
  content: "󰺷";
}

.mdi-gamma:before {
  content: "󱃮";
}

.mdi-gantry-crane:before {
  content: "󰷑";
}

.mdi-garage:before {
  content: "󰛙";
}

.mdi-garage-alert:before {
  content: "󰡲";
}

.mdi-garage-alert-variant:before {
  content: "󱋕";
}

.mdi-garage-lock:before {
  content: "󱟻";
}

.mdi-garage-open:before {
  content: "󰛚";
}

.mdi-garage-open-variant:before {
  content: "󱋔";
}

.mdi-garage-variant:before {
  content: "󱋓";
}

.mdi-garage-variant-lock:before {
  content: "󱟼";
}

.mdi-gas-burner:before {
  content: "󱨛";
}

.mdi-gas-cylinder:before {
  content: "󰙇";
}

.mdi-gas-station:before {
  content: "󰊘";
}

.mdi-gas-station-in-use:before {
  content: "󱳄";
}

.mdi-gas-station-in-use-outline:before {
  content: "󱳅";
}

.mdi-gas-station-off:before {
  content: "󱐉";
}

.mdi-gas-station-off-outline:before {
  content: "󱐊";
}

.mdi-gas-station-outline:before {
  content: "󰺸";
}

.mdi-gate:before {
  content: "󰊙";
}

.mdi-gate-alert:before {
  content: "󱟸";
}

.mdi-gate-and:before {
  content: "󰣡";
}

.mdi-gate-arrow-left:before {
  content: "󱟷";
}

.mdi-gate-arrow-right:before {
  content: "󱅩";
}

.mdi-gate-buffer:before {
  content: "󱫾";
}

.mdi-gate-nand:before {
  content: "󰣢";
}

.mdi-gate-nor:before {
  content: "󰣣";
}

.mdi-gate-not:before {
  content: "󰣤";
}

.mdi-gate-open:before {
  content: "󱅪";
}

.mdi-gate-or:before {
  content: "󰣥";
}

.mdi-gate-xnor:before {
  content: "󰣦";
}

.mdi-gate-xor:before {
  content: "󰣧";
}

.mdi-gatsby:before {
  content: "󰹃";
}

.mdi-gauge:before {
  content: "󰊚";
}

.mdi-gauge-empty:before {
  content: "󰡳";
}

.mdi-gauge-full:before {
  content: "󰡴";
}

.mdi-gauge-low:before {
  content: "󰡵";
}

.mdi-gavel:before {
  content: "󰊛";
}

.mdi-gender-female:before {
  content: "󰊜";
}

.mdi-gender-male:before {
  content: "󰊝";
}

.mdi-gender-male-female:before {
  content: "󰊞";
}

.mdi-gender-male-female-variant:before {
  content: "󱄿";
}

.mdi-gender-non-binary:before {
  content: "󱅀";
}

.mdi-gender-transgender:before {
  content: "󰊟";
}

.mdi-generator-mobile:before {
  content: "󱲊";
}

.mdi-generator-portable:before {
  content: "󱲋";
}

.mdi-generator-stationary:before {
  content: "󱲌";
}

.mdi-gentoo:before {
  content: "󰣨";
}

.mdi-gesture:before {
  content: "󰟋";
}

.mdi-gesture-double-tap:before {
  content: "󰜼";
}

.mdi-gesture-pinch:before {
  content: "󰪽";
}

.mdi-gesture-spread:before {
  content: "󰪾";
}

.mdi-gesture-swipe:before {
  content: "󰵶";
}

.mdi-gesture-swipe-down:before {
  content: "󰜽";
}

.mdi-gesture-swipe-horizontal:before {
  content: "󰪿";
}

.mdi-gesture-swipe-left:before {
  content: "󰜾";
}

.mdi-gesture-swipe-right:before {
  content: "󰜿";
}

.mdi-gesture-swipe-up:before {
  content: "󰝀";
}

.mdi-gesture-swipe-vertical:before {
  content: "󰫀";
}

.mdi-gesture-tap:before {
  content: "󰝁";
}

.mdi-gesture-tap-box:before {
  content: "󱊩";
}

.mdi-gesture-tap-button:before {
  content: "󱊨";
}

.mdi-gesture-tap-hold:before {
  content: "󰵷";
}

.mdi-gesture-two-double-tap:before {
  content: "󰝂";
}

.mdi-gesture-two-tap:before {
  content: "󰝃";
}

.mdi-ghost:before {
  content: "󰊠";
}

.mdi-ghost-off:before {
  content: "󰧵";
}

.mdi-ghost-off-outline:before {
  content: "󱙜";
}

.mdi-ghost-outline:before {
  content: "󱙝";
}

.mdi-gift:before {
  content: "󰹄";
}

.mdi-gift-off:before {
  content: "󱛯";
}

.mdi-gift-off-outline:before {
  content: "󱛰";
}

.mdi-gift-open:before {
  content: "󱛱";
}

.mdi-gift-open-outline:before {
  content: "󱛲";
}

.mdi-gift-outline:before {
  content: "󰊡";
}

.mdi-git:before {
  content: "󰊢";
}

.mdi-github:before {
  content: "󰊤";
}

.mdi-gitlab:before {
  content: "󰮠";
}

.mdi-glass-cocktail:before {
  content: "󰍖";
}

.mdi-glass-cocktail-off:before {
  content: "󱗦";
}

.mdi-glass-flute:before {
  content: "󰊥";
}

.mdi-glass-fragile:before {
  content: "󱡳";
}

.mdi-glass-mug:before {
  content: "󰊦";
}

.mdi-glass-mug-off:before {
  content: "󱗧";
}

.mdi-glass-mug-variant:before {
  content: "󱄖";
}

.mdi-glass-mug-variant-off:before {
  content: "󱗨";
}

.mdi-glass-pint-outline:before {
  content: "󱌍";
}

.mdi-glass-stange:before {
  content: "󰊧";
}

.mdi-glass-tulip:before {
  content: "󰊨";
}

.mdi-glass-wine:before {
  content: "󰡶";
}

.mdi-glasses:before {
  content: "󰊪";
}

.mdi-globe-light:before {
  content: "󰙯";
}

.mdi-globe-light-outline:before {
  content: "󱋗";
}

.mdi-globe-model:before {
  content: "󰣩";
}

.mdi-gmail:before {
  content: "󰊫";
}

.mdi-gnome:before {
  content: "󰊬";
}

.mdi-go-kart:before {
  content: "󰵹";
}

.mdi-go-kart-track:before {
  content: "󰵺";
}

.mdi-gog:before {
  content: "󰮡";
}

.mdi-gold:before {
  content: "󱉏";
}

.mdi-golf:before {
  content: "󰠣";
}

.mdi-golf-cart:before {
  content: "󱆤";
}

.mdi-golf-tee:before {
  content: "󱂃";
}

.mdi-gondola:before {
  content: "󰚆";
}

.mdi-goodreads:before {
  content: "󰵻";
}

.mdi-google:before {
  content: "󰊭";
}

.mdi-google-ads:before {
  content: "󰲇";
}

.mdi-google-analytics:before {
  content: "󰟌";
}

.mdi-google-assistant:before {
  content: "󰟍";
}

.mdi-google-cardboard:before {
  content: "󰊮";
}

.mdi-google-chrome:before {
  content: "󰊯";
}

.mdi-google-circles:before {
  content: "󰊰";
}

.mdi-google-circles-communities:before {
  content: "󰊱";
}

.mdi-google-circles-extended:before {
  content: "󰊲";
}

.mdi-google-circles-group:before {
  content: "󰊳";
}

.mdi-google-classroom:before {
  content: "󰋀";
}

.mdi-google-cloud:before {
  content: "󱇶";
}

.mdi-google-downasaur:before {
  content: "󱍢";
}

.mdi-google-drive:before {
  content: "󰊶";
}

.mdi-google-earth:before {
  content: "󰊷";
}

.mdi-google-fit:before {
  content: "󰥬";
}

.mdi-google-glass:before {
  content: "󰊸";
}

.mdi-google-hangouts:before {
  content: "󰋉";
}

.mdi-google-keep:before {
  content: "󰛜";
}

.mdi-google-lens:before {
  content: "󰧶";
}

.mdi-google-maps:before {
  content: "󰗵";
}

.mdi-google-my-business:before {
  content: "󱁈";
}

.mdi-google-nearby:before {
  content: "󰊹";
}

.mdi-google-play:before {
  content: "󰊼";
}

.mdi-google-plus:before {
  content: "󰊽";
}

.mdi-google-podcast:before {
  content: "󰺹";
}

.mdi-google-spreadsheet:before {
  content: "󰧷";
}

.mdi-google-street-view:before {
  content: "󰲈";
}

.mdi-google-translate:before {
  content: "󰊿";
}

.mdi-gradient-horizontal:before {
  content: "󱝊";
}

.mdi-gradient-vertical:before {
  content: "󰚠";
}

.mdi-grain:before {
  content: "󰵼";
}

.mdi-graph:before {
  content: "󱁉";
}

.mdi-graph-outline:before {
  content: "󱁊";
}

.mdi-graphql:before {
  content: "󰡷";
}

.mdi-grass:before {
  content: "󱔐";
}

.mdi-grave-stone:before {
  content: "󰮢";
}

.mdi-grease-pencil:before {
  content: "󰙈";
}

.mdi-greater-than:before {
  content: "󰥭";
}

.mdi-greater-than-or-equal:before {
  content: "󰥮";
}

.mdi-greenhouse:before {
  content: "󰀭";
}

.mdi-grid:before {
  content: "󰋁";
}

.mdi-grid-large:before {
  content: "󰝘";
}

.mdi-grid-off:before {
  content: "󰋂";
}

.mdi-grill:before {
  content: "󰹅";
}

.mdi-grill-outline:before {
  content: "󱆊";
}

.mdi-group:before {
  content: "󰋃";
}

.mdi-guitar-acoustic:before {
  content: "󰝱";
}

.mdi-guitar-electric:before {
  content: "󰋄";
}

.mdi-guitar-pick:before {
  content: "󰋅";
}

.mdi-guitar-pick-outline:before {
  content: "󰋆";
}

.mdi-guy-fawkes-mask:before {
  content: "󰠥";
}

.mdi-gymnastics:before {
  content: "󱩁";
}

.mdi-hail:before {
  content: "󰫁";
}

.mdi-hair-dryer:before {
  content: "󱃯";
}

.mdi-hair-dryer-outline:before {
  content: "󱃰";
}

.mdi-halloween:before {
  content: "󰮣";
}

.mdi-hamburger:before {
  content: "󰚅";
}

.mdi-hamburger-check:before {
  content: "󱝶";
}

.mdi-hamburger-minus:before {
  content: "󱝷";
}

.mdi-hamburger-off:before {
  content: "󱝸";
}

.mdi-hamburger-plus:before {
  content: "󱝹";
}

.mdi-hamburger-remove:before {
  content: "󱝺";
}

.mdi-hammer:before {
  content: "󰣪";
}

.mdi-hammer-screwdriver:before {
  content: "󱌢";
}

.mdi-hammer-sickle:before {
  content: "󱢇";
}

.mdi-hammer-wrench:before {
  content: "󱌣";
}

.mdi-hand-back-left:before {
  content: "󰹆";
}

.mdi-hand-back-left-off:before {
  content: "󱠰";
}

.mdi-hand-back-left-off-outline:before {
  content: "󱠲";
}

.mdi-hand-back-left-outline:before {
  content: "󱠬";
}

.mdi-hand-back-right:before {
  content: "󰹇";
}

.mdi-hand-back-right-off:before {
  content: "󱠱";
}

.mdi-hand-back-right-off-outline:before {
  content: "󱠳";
}

.mdi-hand-back-right-outline:before {
  content: "󱠭";
}

.mdi-hand-clap:before {
  content: "󱥋";
}

.mdi-hand-clap-off:before {
  content: "󱩂";
}

.mdi-hand-coin:before {
  content: "󱢏";
}

.mdi-hand-coin-outline:before {
  content: "󱢐";
}

.mdi-hand-cycle:before {
  content: "󱮜";
}

.mdi-hand-extended:before {
  content: "󱢶";
}

.mdi-hand-extended-outline:before {
  content: "󱢷";
}

.mdi-hand-front-left:before {
  content: "󱠫";
}

.mdi-hand-front-left-outline:before {
  content: "󱠮";
}

.mdi-hand-front-right:before {
  content: "󰩏";
}

.mdi-hand-front-right-outline:before {
  content: "󱠯";
}

.mdi-hand-heart:before {
  content: "󱃱";
}

.mdi-hand-heart-outline:before {
  content: "󱕾";
}

.mdi-hand-okay:before {
  content: "󰩐";
}

.mdi-hand-peace:before {
  content: "󰩑";
}

.mdi-hand-peace-variant:before {
  content: "󰩒";
}

.mdi-hand-pointing-down:before {
  content: "󰩓";
}

.mdi-hand-pointing-left:before {
  content: "󰩔";
}

.mdi-hand-pointing-right:before {
  content: "󰋇";
}

.mdi-hand-pointing-up:before {
  content: "󰩕";
}

.mdi-hand-saw:before {
  content: "󰹈";
}

.mdi-hand-wash:before {
  content: "󱕿";
}

.mdi-hand-wash-outline:before {
  content: "󱖀";
}

.mdi-hand-water:before {
  content: "󱎟";
}

.mdi-hand-wave:before {
  content: "󱠡";
}

.mdi-hand-wave-outline:before {
  content: "󱠢";
}

.mdi-handball:before {
  content: "󰽓";
}

.mdi-handcuffs:before {
  content: "󱄾";
}

.mdi-hands-pray:before {
  content: "󰕹";
}

.mdi-handshake:before {
  content: "󱈘";
}

.mdi-handshake-outline:before {
  content: "󱖡";
}

.mdi-hanger:before {
  content: "󰋈";
}

.mdi-hard-hat:before {
  content: "󰥯";
}

.mdi-harddisk:before {
  content: "󰋊";
}

.mdi-harddisk-plus:before {
  content: "󱁋";
}

.mdi-harddisk-remove:before {
  content: "󱁌";
}

.mdi-hat-fedora:before {
  content: "󰮤";
}

.mdi-hazard-lights:before {
  content: "󰲉";
}

.mdi-hdmi-port:before {
  content: "󱮸";
}

.mdi-hdr:before {
  content: "󰵽";
}

.mdi-hdr-off:before {
  content: "󰵾";
}

.mdi-head:before {
  content: "󱍞";
}

.mdi-head-alert:before {
  content: "󱌸";
}

.mdi-head-alert-outline:before {
  content: "󱌹";
}

.mdi-head-check:before {
  content: "󱌺";
}

.mdi-head-check-outline:before {
  content: "󱌻";
}

.mdi-head-cog:before {
  content: "󱌼";
}

.mdi-head-cog-outline:before {
  content: "󱌽";
}

.mdi-head-dots-horizontal:before {
  content: "󱌾";
}

.mdi-head-dots-horizontal-outline:before {
  content: "󱌿";
}

.mdi-head-flash:before {
  content: "󱍀";
}

.mdi-head-flash-outline:before {
  content: "󱍁";
}

.mdi-head-heart:before {
  content: "󱍂";
}

.mdi-head-heart-outline:before {
  content: "󱍃";
}

.mdi-head-lightbulb:before {
  content: "󱍄";
}

.mdi-head-lightbulb-outline:before {
  content: "󱍅";
}

.mdi-head-minus:before {
  content: "󱍆";
}

.mdi-head-minus-outline:before {
  content: "󱍇";
}

.mdi-head-outline:before {
  content: "󱍟";
}

.mdi-head-plus:before {
  content: "󱍈";
}

.mdi-head-plus-outline:before {
  content: "󱍉";
}

.mdi-head-question:before {
  content: "󱍊";
}

.mdi-head-question-outline:before {
  content: "󱍋";
}

.mdi-head-remove:before {
  content: "󱍌";
}

.mdi-head-remove-outline:before {
  content: "󱍍";
}

.mdi-head-snowflake:before {
  content: "󱍎";
}

.mdi-head-snowflake-outline:before {
  content: "󱍏";
}

.mdi-head-sync:before {
  content: "󱍐";
}

.mdi-head-sync-outline:before {
  content: "󱍑";
}

.mdi-headphones:before {
  content: "󰋋";
}

.mdi-headphones-bluetooth:before {
  content: "󰥰";
}

.mdi-headphones-box:before {
  content: "󰋌";
}

.mdi-headphones-off:before {
  content: "󰟎";
}

.mdi-headphones-settings:before {
  content: "󰋍";
}

.mdi-headset:before {
  content: "󰋎";
}

.mdi-headset-dock:before {
  content: "󰋏";
}

.mdi-headset-off:before {
  content: "󰋐";
}

.mdi-heart:before {
  content: "󰋑";
}

.mdi-heart-box:before {
  content: "󰋒";
}

.mdi-heart-box-outline:before {
  content: "󰋓";
}

.mdi-heart-broken:before {
  content: "󰋔";
}

.mdi-heart-broken-outline:before {
  content: "󰴔";
}

.mdi-heart-circle:before {
  content: "󰥱";
}

.mdi-heart-circle-outline:before {
  content: "󰥲";
}

.mdi-heart-cog:before {
  content: "󱙣";
}

.mdi-heart-cog-outline:before {
  content: "󱙤";
}

.mdi-heart-flash:before {
  content: "󰻹";
}

.mdi-heart-half:before {
  content: "󰛟";
}

.mdi-heart-half-full:before {
  content: "󰛞";
}

.mdi-heart-half-outline:before {
  content: "󰛠";
}

.mdi-heart-minus:before {
  content: "󱐯";
}

.mdi-heart-minus-outline:before {
  content: "󱐲";
}

.mdi-heart-multiple:before {
  content: "󰩖";
}

.mdi-heart-multiple-outline:before {
  content: "󰩗";
}

.mdi-heart-off:before {
  content: "󰝙";
}

.mdi-heart-off-outline:before {
  content: "󱐴";
}

.mdi-heart-outline:before {
  content: "󰋕";
}

.mdi-heart-plus:before {
  content: "󱐮";
}

.mdi-heart-plus-outline:before {
  content: "󱐱";
}

.mdi-heart-pulse:before {
  content: "󰗶";
}

.mdi-heart-remove:before {
  content: "󱐰";
}

.mdi-heart-remove-outline:before {
  content: "󱐳";
}

.mdi-heart-search:before {
  content: "󱲍";
}

.mdi-heart-settings:before {
  content: "󱙥";
}

.mdi-heart-settings-outline:before {
  content: "󱙦";
}

.mdi-heat-pump:before {
  content: "󱩃";
}

.mdi-heat-pump-outline:before {
  content: "󱩄";
}

.mdi-heat-wave:before {
  content: "󱩅";
}

.mdi-heating-coil:before {
  content: "󱪯";
}

.mdi-helicopter:before {
  content: "󰫂";
}

.mdi-help:before {
  content: "󰋖";
}

.mdi-help-box:before {
  content: "󰞋";
}

.mdi-help-box-multiple:before {
  content: "󱰊";
}

.mdi-help-box-multiple-outline:before {
  content: "󱰋";
}

.mdi-help-box-outline:before {
  content: "󱰌";
}

.mdi-help-circle:before {
  content: "󰋗";
}

.mdi-help-circle-outline:before {
  content: "󰘥";
}

.mdi-help-network:before {
  content: "󰛵";
}

.mdi-help-network-outline:before {
  content: "󰲊";
}

.mdi-help-rhombus:before {
  content: "󰮥";
}

.mdi-help-rhombus-outline:before {
  content: "󰮦";
}

.mdi-hexadecimal:before {
  content: "󱊧";
}

.mdi-hexagon:before {
  content: "󰋘";
}

.mdi-hexagon-multiple:before {
  content: "󰛡";
}

.mdi-hexagon-multiple-outline:before {
  content: "󱃲";
}

.mdi-hexagon-outline:before {
  content: "󰋙";
}

.mdi-hexagon-slice-1:before {
  content: "󰫃";
}

.mdi-hexagon-slice-2:before {
  content: "󰫄";
}

.mdi-hexagon-slice-3:before {
  content: "󰫅";
}

.mdi-hexagon-slice-4:before {
  content: "󰫆";
}

.mdi-hexagon-slice-5:before {
  content: "󰫇";
}

.mdi-hexagon-slice-6:before {
  content: "󰫈";
}

.mdi-hexagram:before {
  content: "󰫉";
}

.mdi-hexagram-outline:before {
  content: "󰫊";
}

.mdi-high-definition:before {
  content: "󰟏";
}

.mdi-high-definition-box:before {
  content: "󰡸";
}

.mdi-highway:before {
  content: "󰗷";
}

.mdi-hiking:before {
  content: "󰵿";
}

.mdi-history:before {
  content: "󰋚";
}

.mdi-hockey-puck:before {
  content: "󰡹";
}

.mdi-hockey-sticks:before {
  content: "󰡺";
}

.mdi-hololens:before {
  content: "󰋛";
}

.mdi-home:before {
  content: "󰋜";
}

.mdi-home-account:before {
  content: "󰠦";
}

.mdi-home-alert:before {
  content: "󰡻";
}

.mdi-home-alert-outline:before {
  content: "󱗐";
}

.mdi-home-analytics:before {
  content: "󰺺";
}

.mdi-home-assistant:before {
  content: "󰟐";
}

.mdi-home-automation:before {
  content: "󰟑";
}

.mdi-home-battery:before {
  content: "󱤁";
}

.mdi-home-battery-outline:before {
  content: "󱤂";
}

.mdi-home-circle:before {
  content: "󰟒";
}

.mdi-home-circle-outline:before {
  content: "󱁍";
}

.mdi-home-city:before {
  content: "󰴕";
}

.mdi-home-city-outline:before {
  content: "󰴖";
}

.mdi-home-clock:before {
  content: "󱨒";
}

.mdi-home-clock-outline:before {
  content: "󱨓";
}

.mdi-home-edit:before {
  content: "󱅙";
}

.mdi-home-edit-outline:before {
  content: "󱅚";
}

.mdi-home-export-outline:before {
  content: "󰾛";
}

.mdi-home-flood:before {
  content: "󰻺";
}

.mdi-home-floor-0:before {
  content: "󰷒";
}

.mdi-home-floor-1:before {
  content: "󰶀";
}

.mdi-home-floor-2:before {
  content: "󰶁";
}

.mdi-home-floor-3:before {
  content: "󰶂";
}

.mdi-home-floor-a:before {
  content: "󰶃";
}

.mdi-home-floor-b:before {
  content: "󰶄";
}

.mdi-home-floor-g:before {
  content: "󰶅";
}

.mdi-home-floor-l:before {
  content: "󰶆";
}

.mdi-home-floor-negative-1:before {
  content: "󰷓";
}

.mdi-home-group:before {
  content: "󰷔";
}

.mdi-home-group-minus:before {
  content: "󱧁";
}

.mdi-home-group-plus:before {
  content: "󱧀";
}

.mdi-home-group-remove:before {
  content: "󱧂";
}

.mdi-home-heart:before {
  content: "󰠧";
}

.mdi-home-import-outline:before {
  content: "󰾜";
}

.mdi-home-lightbulb:before {
  content: "󱉑";
}

.mdi-home-lightbulb-outline:before {
  content: "󱉒";
}

.mdi-home-lightning-bolt:before {
  content: "󱤃";
}

.mdi-home-lightning-bolt-outline:before {
  content: "󱤄";
}

.mdi-home-lock:before {
  content: "󰣫";
}

.mdi-home-lock-open:before {
  content: "󰣬";
}

.mdi-home-map-marker:before {
  content: "󰗸";
}

.mdi-home-minus:before {
  content: "󰥴";
}

.mdi-home-minus-outline:before {
  content: "󱏕";
}

.mdi-home-modern:before {
  content: "󰋝";
}

.mdi-home-off:before {
  content: "󱩆";
}

.mdi-home-off-outline:before {
  content: "󱩇";
}

.mdi-home-outline:before {
  content: "󰚡";
}

.mdi-home-percent:before {
  content: "󱱼";
}

.mdi-home-percent-outline:before {
  content: "󱱽";
}

.mdi-home-plus:before {
  content: "󰥵";
}

.mdi-home-plus-outline:before {
  content: "󱏖";
}

.mdi-home-remove:before {
  content: "󱉇";
}

.mdi-home-remove-outline:before {
  content: "󱏗";
}

.mdi-home-roof:before {
  content: "󱄫";
}

.mdi-home-search:before {
  content: "󱎰";
}

.mdi-home-search-outline:before {
  content: "󱎱";
}

.mdi-home-silo:before {
  content: "󱮠";
}

.mdi-home-silo-outline:before {
  content: "󱮡";
}

.mdi-home-sound-in:before {
  content: "󱰯";
}

.mdi-home-sound-in-outline:before {
  content: "󱰰";
}

.mdi-home-sound-out:before {
  content: "󱰱";
}

.mdi-home-sound-out-outline:before {
  content: "󱰲";
}

.mdi-home-switch:before {
  content: "󱞔";
}

.mdi-home-switch-outline:before {
  content: "󱞕";
}

.mdi-home-thermometer:before {
  content: "󰽔";
}

.mdi-home-thermometer-outline:before {
  content: "󰽕";
}

.mdi-home-variant:before {
  content: "󰋞";
}

.mdi-home-variant-outline:before {
  content: "󰮧";
}

.mdi-hook:before {
  content: "󰛢";
}

.mdi-hook-off:before {
  content: "󰛣";
}

.mdi-hoop-house:before {
  content: "󰹖";
}

.mdi-hops:before {
  content: "󰋟";
}

.mdi-horizontal-rotate-clockwise:before {
  content: "󱃳";
}

.mdi-horizontal-rotate-counterclockwise:before {
  content: "󱃴";
}

.mdi-horse:before {
  content: "󱖿";
}

.mdi-horse-human:before {
  content: "󱗀";
}

.mdi-horse-variant:before {
  content: "󱗁";
}

.mdi-horse-variant-fast:before {
  content: "󱡮";
}

.mdi-horseshoe:before {
  content: "󰩘";
}

.mdi-hospital:before {
  content: "󰿶";
}

.mdi-hospital-box:before {
  content: "󰋠";
}

.mdi-hospital-box-outline:before {
  content: "󰿷";
}

.mdi-hospital-building:before {
  content: "󰋡";
}

.mdi-hospital-marker:before {
  content: "󰋢";
}

.mdi-hot-tub:before {
  content: "󰠨";
}

.mdi-hours-12:before {
  content: "󱲔";
}

.mdi-hours-24:before {
  content: "󱑸";
}

.mdi-hub:before {
  content: "󱲕";
}

.mdi-hub-outline:before {
  content: "󱲖";
}

.mdi-hubspot:before {
  content: "󰴗";
}

.mdi-hulu:before {
  content: "󰠩";
}

.mdi-human:before {
  content: "󰋦";
}

.mdi-human-baby-changing-table:before {
  content: "󱎋";
}

.mdi-human-cane:before {
  content: "󱖁";
}

.mdi-human-capacity-decrease:before {
  content: "󱖛";
}

.mdi-human-capacity-increase:before {
  content: "󱖜";
}

.mdi-human-child:before {
  content: "󰋧";
}

.mdi-human-dolly:before {
  content: "󱦀";
}

.mdi-human-edit:before {
  content: "󱓨";
}

.mdi-human-female:before {
  content: "󰙉";
}

.mdi-human-female-boy:before {
  content: "󰩙";
}

.mdi-human-female-dance:before {
  content: "󱗉";
}

.mdi-human-female-female:before {
  content: "󰩚";
}

.mdi-human-female-female-child:before {
  content: "󱲎";
}

.mdi-human-female-girl:before {
  content: "󰩛";
}

.mdi-human-greeting:before {
  content: "󱟄";
}

.mdi-human-greeting-proximity:before {
  content: "󱖝";
}

.mdi-human-greeting-variant:before {
  content: "󰙊";
}

.mdi-human-handsdown:before {
  content: "󰙋";
}

.mdi-human-handsup:before {
  content: "󰙌";
}

.mdi-human-male:before {
  content: "󰙍";
}

.mdi-human-male-board:before {
  content: "󰢐";
}

.mdi-human-male-board-poll:before {
  content: "󰡆";
}

.mdi-human-male-boy:before {
  content: "󰩜";
}

.mdi-human-male-child:before {
  content: "󱎌";
}

.mdi-human-male-female:before {
  content: "󰋨";
}

.mdi-human-male-female-child:before {
  content: "󱠣";
}

.mdi-human-male-girl:before {
  content: "󰩝";
}

.mdi-human-male-height:before {
  content: "󰻻";
}

.mdi-human-male-height-variant:before {
  content: "󰻼";
}

.mdi-human-male-male:before {
  content: "󰩞";
}

.mdi-human-male-male-child:before {
  content: "󱲏";
}

.mdi-human-non-binary:before {
  content: "󱡈";
}

.mdi-human-pregnant:before {
  content: "󰗏";
}

.mdi-human-queue:before {
  content: "󱕱";
}

.mdi-human-scooter:before {
  content: "󱇩";
}

.mdi-human-walker:before {
  content: "󱭱";
}

.mdi-human-wheelchair:before {
  content: "󱎍";
}

.mdi-human-white-cane:before {
  content: "󱦁";
}

.mdi-humble-bundle:before {
  content: "󰝄";
}

.mdi-hvac:before {
  content: "󱍒";
}

.mdi-hvac-off:before {
  content: "󱖞";
}

.mdi-hydraulic-oil-level:before {
  content: "󱌤";
}

.mdi-hydraulic-oil-temperature:before {
  content: "󱌥";
}

.mdi-hydro-power:before {
  content: "󱋥";
}

.mdi-hydrogen-station:before {
  content: "󱢔";
}

.mdi-ice-cream:before {
  content: "󰠪";
}

.mdi-ice-cream-off:before {
  content: "󰹒";
}

.mdi-ice-pop:before {
  content: "󰻽";
}

.mdi-id-card:before {
  content: "󰿀";
}

.mdi-identifier:before {
  content: "󰻾";
}

.mdi-ideogram-cjk:before {
  content: "󱌱";
}

.mdi-ideogram-cjk-variant:before {
  content: "󱌲";
}

.mdi-image:before {
  content: "󰋩";
}

.mdi-image-album:before {
  content: "󰋪";
}

.mdi-image-area:before {
  content: "󰋫";
}

.mdi-image-area-close:before {
  content: "󰋬";
}

.mdi-image-auto-adjust:before {
  content: "󰿁";
}

.mdi-image-broken:before {
  content: "󰋭";
}

.mdi-image-broken-variant:before {
  content: "󰋮";
}

.mdi-image-check:before {
  content: "󱬥";
}

.mdi-image-check-outline:before {
  content: "󱬦";
}

.mdi-image-edit:before {
  content: "󱇣";
}

.mdi-image-edit-outline:before {
  content: "󱇤";
}

.mdi-image-filter-black-white:before {
  content: "󰋰";
}

.mdi-image-filter-center-focus:before {
  content: "󰋱";
}

.mdi-image-filter-center-focus-strong:before {
  content: "󰻿";
}

.mdi-image-filter-center-focus-strong-outline:before {
  content: "󰼀";
}

.mdi-image-filter-center-focus-weak:before {
  content: "󰋲";
}

.mdi-image-filter-drama:before {
  content: "󰋳";
}

.mdi-image-filter-drama-outline:before {
  content: "󱯿";
}

.mdi-image-filter-frames:before {
  content: "󰋴";
}

.mdi-image-filter-hdr:before {
  content: "󰋵";
}

.mdi-image-filter-hdr-outline:before {
  content: "󱱤";
}

.mdi-image-filter-none:before {
  content: "󰋶";
}

.mdi-image-filter-tilt-shift:before {
  content: "󰋷";
}

.mdi-image-filter-vintage:before {
  content: "󰋸";
}

.mdi-image-frame:before {
  content: "󰹉";
}

.mdi-image-lock:before {
  content: "󱪰";
}

.mdi-image-lock-outline:before {
  content: "󱪱";
}

.mdi-image-marker:before {
  content: "󱝻";
}

.mdi-image-marker-outline:before {
  content: "󱝼";
}

.mdi-image-minus:before {
  content: "󱐙";
}

.mdi-image-minus-outline:before {
  content: "󱭇";
}

.mdi-image-move:before {
  content: "󰧸";
}

.mdi-image-multiple:before {
  content: "󰋹";
}

.mdi-image-multiple-outline:before {
  content: "󰋯";
}

.mdi-image-off:before {
  content: "󰠫";
}

.mdi-image-off-outline:before {
  content: "󱇑";
}

.mdi-image-outline:before {
  content: "󰥶";
}

.mdi-image-plus:before {
  content: "󰡼";
}

.mdi-image-plus-outline:before {
  content: "󱭆";
}

.mdi-image-refresh:before {
  content: "󱧾";
}

.mdi-image-refresh-outline:before {
  content: "󱧿";
}

.mdi-image-remove:before {
  content: "󱐘";
}

.mdi-image-remove-outline:before {
  content: "󱭈";
}

.mdi-image-search:before {
  content: "󰥷";
}

.mdi-image-search-outline:before {
  content: "󰥸";
}

.mdi-image-size-select-actual:before {
  content: "󰲍";
}

.mdi-image-size-select-large:before {
  content: "󰲎";
}

.mdi-image-size-select-small:before {
  content: "󰲏";
}

.mdi-image-sync:before {
  content: "󱨀";
}

.mdi-image-sync-outline:before {
  content: "󱨁";
}

.mdi-image-text:before {
  content: "󱘍";
}

.mdi-import:before {
  content: "󰋺";
}

.mdi-inbox:before {
  content: "󰚇";
}

.mdi-inbox-arrow-down:before {
  content: "󰋻";
}

.mdi-inbox-arrow-down-outline:before {
  content: "󱉰";
}

.mdi-inbox-arrow-up:before {
  content: "󰏑";
}

.mdi-inbox-arrow-up-outline:before {
  content: "󱉱";
}

.mdi-inbox-full:before {
  content: "󱉲";
}

.mdi-inbox-full-outline:before {
  content: "󱉳";
}

.mdi-inbox-multiple:before {
  content: "󰢰";
}

.mdi-inbox-multiple-outline:before {
  content: "󰮨";
}

.mdi-inbox-outline:before {
  content: "󱉴";
}

.mdi-inbox-remove:before {
  content: "󱖟";
}

.mdi-inbox-remove-outline:before {
  content: "󱖠";
}

.mdi-incognito:before {
  content: "󰗹";
}

.mdi-incognito-circle:before {
  content: "󱐡";
}

.mdi-incognito-circle-off:before {
  content: "󱐢";
}

.mdi-incognito-off:before {
  content: "󰁵";
}

.mdi-induction:before {
  content: "󱡌";
}

.mdi-infinity:before {
  content: "󰛤";
}

.mdi-information:before {
  content: "󰋼";
}

.mdi-information-box:before {
  content: "󱱥";
}

.mdi-information-box-outline:before {
  content: "󱱦";
}

.mdi-information-off:before {
  content: "󱞌";
}

.mdi-information-off-outline:before {
  content: "󱞍";
}

.mdi-information-outline:before {
  content: "󰋽";
}

.mdi-information-slab-box:before {
  content: "󱱧";
}

.mdi-information-slab-box-outline:before {
  content: "󱱨";
}

.mdi-information-slab-circle:before {
  content: "󱱩";
}

.mdi-information-slab-circle-outline:before {
  content: "󱱪";
}

.mdi-information-slab-symbol:before {
  content: "󱱫";
}

.mdi-information-symbol:before {
  content: "󱱬";
}

.mdi-information-variant:before {
  content: "󰙎";
}

.mdi-information-variant-box:before {
  content: "󱱭";
}

.mdi-information-variant-box-outline:before {
  content: "󱱮";
}

.mdi-information-variant-circle:before {
  content: "󱱯";
}

.mdi-information-variant-circle-outline:before {
  content: "󱱰";
}

.mdi-instagram:before {
  content: "󰋾";
}

.mdi-instrument-triangle:before {
  content: "󱁎";
}

.mdi-integrated-circuit-chip:before {
  content: "󱤓";
}

.mdi-invert-colors:before {
  content: "󰌁";
}

.mdi-invert-colors-off:before {
  content: "󰹊";
}

.mdi-invoice:before {
  content: "󱳒";
}

.mdi-invoice-arrow-left:before {
  content: "󱳓";
}

.mdi-invoice-arrow-left-outline:before {
  content: "󱳔";
}

.mdi-invoice-arrow-right:before {
  content: "󱳕";
}

.mdi-invoice-arrow-right-outline:before {
  content: "󱳖";
}

.mdi-invoice-check:before {
  content: "󱳗";
}

.mdi-invoice-check-outline:before {
  content: "󱳘";
}

.mdi-invoice-clock:before {
  content: "󱳙";
}

.mdi-invoice-clock-outline:before {
  content: "󱳚";
}

.mdi-invoice-edit:before {
  content: "󱳛";
}

.mdi-invoice-edit-outline:before {
  content: "󱳜";
}

.mdi-invoice-export-outline:before {
  content: "󱳝";
}

.mdi-invoice-fast:before {
  content: "󱳞";
}

.mdi-invoice-fast-outline:before {
  content: "󱳟";
}

.mdi-invoice-import:before {
  content: "󱳠";
}

.mdi-invoice-import-outline:before {
  content: "󱳡";
}

.mdi-invoice-list:before {
  content: "󱳢";
}

.mdi-invoice-list-outline:before {
  content: "󱳣";
}

.mdi-invoice-minus:before {
  content: "󱳤";
}

.mdi-invoice-minus-outline:before {
  content: "󱳥";
}

.mdi-invoice-multiple:before {
  content: "󱳦";
}

.mdi-invoice-multiple-outline:before {
  content: "󱳧";
}

.mdi-invoice-outline:before {
  content: "󱳨";
}

.mdi-invoice-plus:before {
  content: "󱳩";
}

.mdi-invoice-plus-outline:before {
  content: "󱳪";
}

.mdi-invoice-remove:before {
  content: "󱳫";
}

.mdi-invoice-remove-outline:before {
  content: "󱳬";
}

.mdi-invoice-send:before {
  content: "󱳭";
}

.mdi-invoice-send-outline:before {
  content: "󱳮";
}

.mdi-invoice-text:before {
  content: "󱳯";
}

.mdi-invoice-text-arrow-left:before {
  content: "󱳰";
}

.mdi-invoice-text-arrow-left-outline:before {
  content: "󱳱";
}

.mdi-invoice-text-arrow-right:before {
  content: "󱳲";
}

.mdi-invoice-text-arrow-right-outline:before {
  content: "󱳳";
}

.mdi-invoice-text-check:before {
  content: "󱳴";
}

.mdi-invoice-text-check-outline:before {
  content: "󱳵";
}

.mdi-invoice-text-clock:before {
  content: "󱳶";
}

.mdi-invoice-text-clock-outline:before {
  content: "󱳷";
}

.mdi-invoice-text-edit:before {
  content: "󱳸";
}

.mdi-invoice-text-edit-outline:before {
  content: "󱳹";
}

.mdi-invoice-text-fast:before {
  content: "󱳺";
}

.mdi-invoice-text-fast-outline:before {
  content: "󱳻";
}

.mdi-invoice-text-minus:before {
  content: "󱳼";
}

.mdi-invoice-text-minus-outline:before {
  content: "󱳽";
}

.mdi-invoice-text-multiple:before {
  content: "󱳾";
}

.mdi-invoice-text-multiple-outline:before {
  content: "󱳿";
}

.mdi-invoice-text-outline:before {
  content: "󱴀";
}

.mdi-invoice-text-plus:before {
  content: "󱴁";
}

.mdi-invoice-text-plus-outline:before {
  content: "󱴂";
}

.mdi-invoice-text-remove:before {
  content: "󱴃";
}

.mdi-invoice-text-remove-outline:before {
  content: "󱴄";
}

.mdi-invoice-text-send:before {
  content: "󱴅";
}

.mdi-invoice-text-send-outline:before {
  content: "󱴆";
}

.mdi-iobroker:before {
  content: "󱋨";
}

.mdi-ip:before {
  content: "󰩟";
}

.mdi-ip-network:before {
  content: "󰩠";
}

.mdi-ip-network-outline:before {
  content: "󰲐";
}

.mdi-ip-outline:before {
  content: "󱦂";
}

.mdi-ipod:before {
  content: "󰲑";
}

.mdi-iron:before {
  content: "󱠤";
}

.mdi-iron-board:before {
  content: "󱠸";
}

.mdi-iron-outline:before {
  content: "󱠥";
}

.mdi-island:before {
  content: "󱁏";
}

.mdi-island-variant:before {
  content: "󱳆";
}

.mdi-iv-bag:before {
  content: "󱂹";
}

.mdi-jabber:before {
  content: "󰷕";
}

.mdi-jeepney:before {
  content: "󰌂";
}

.mdi-jellyfish:before {
  content: "󰼁";
}

.mdi-jellyfish-outline:before {
  content: "󰼂";
}

.mdi-jira:before {
  content: "󰌃";
}

.mdi-jquery:before {
  content: "󰡽";
}

.mdi-jsfiddle:before {
  content: "󰌄";
}

.mdi-jump-rope:before {
  content: "󱋿";
}

.mdi-kabaddi:before {
  content: "󰶇";
}

.mdi-kangaroo:before {
  content: "󱕘";
}

.mdi-karate:before {
  content: "󰠬";
}

.mdi-kayaking:before {
  content: "󰢯";
}

.mdi-keg:before {
  content: "󰌅";
}

.mdi-kettle:before {
  content: "󰗺";
}

.mdi-kettle-alert:before {
  content: "󱌗";
}

.mdi-kettle-alert-outline:before {
  content: "󱌘";
}

.mdi-kettle-off:before {
  content: "󱌛";
}

.mdi-kettle-off-outline:before {
  content: "󱌜";
}

.mdi-kettle-outline:before {
  content: "󰽖";
}

.mdi-kettle-pour-over:before {
  content: "󱜼";
}

.mdi-kettle-steam:before {
  content: "󱌙";
}

.mdi-kettle-steam-outline:before {
  content: "󱌚";
}

.mdi-kettlebell:before {
  content: "󱌀";
}

.mdi-key:before {
  content: "󰌆";
}

.mdi-key-alert:before {
  content: "󱦃";
}

.mdi-key-alert-outline:before {
  content: "󱦄";
}

.mdi-key-arrow-right:before {
  content: "󱌒";
}

.mdi-key-chain:before {
  content: "󱕴";
}

.mdi-key-chain-variant:before {
  content: "󱕵";
}

.mdi-key-change:before {
  content: "󰌇";
}

.mdi-key-link:before {
  content: "󱆟";
}

.mdi-key-minus:before {
  content: "󰌈";
}

.mdi-key-outline:before {
  content: "󰷖";
}

.mdi-key-plus:before {
  content: "󰌉";
}

.mdi-key-remove:before {
  content: "󰌊";
}

.mdi-key-star:before {
  content: "󱆞";
}

.mdi-key-variant:before {
  content: "󰌋";
}

.mdi-key-wireless:before {
  content: "󰿂";
}

.mdi-keyboard:before {
  content: "󰌌";
}

.mdi-keyboard-backspace:before {
  content: "󰌍";
}

.mdi-keyboard-caps:before {
  content: "󰌎";
}

.mdi-keyboard-close:before {
  content: "󰌏";
}

.mdi-keyboard-close-outline:before {
  content: "󱰀";
}

.mdi-keyboard-esc:before {
  content: "󱊷";
}

.mdi-keyboard-f1:before {
  content: "󱊫";
}

.mdi-keyboard-f10:before {
  content: "󱊴";
}

.mdi-keyboard-f11:before {
  content: "󱊵";
}

.mdi-keyboard-f12:before {
  content: "󱊶";
}

.mdi-keyboard-f2:before {
  content: "󱊬";
}

.mdi-keyboard-f3:before {
  content: "󱊭";
}

.mdi-keyboard-f4:before {
  content: "󱊮";
}

.mdi-keyboard-f5:before {
  content: "󱊯";
}

.mdi-keyboard-f6:before {
  content: "󱊰";
}

.mdi-keyboard-f7:before {
  content: "󱊱";
}

.mdi-keyboard-f8:before {
  content: "󱊲";
}

.mdi-keyboard-f9:before {
  content: "󱊳";
}

.mdi-keyboard-off:before {
  content: "󰌐";
}

.mdi-keyboard-off-outline:before {
  content: "󰹋";
}

.mdi-keyboard-outline:before {
  content: "󰥻";
}

.mdi-keyboard-return:before {
  content: "󰌑";
}

.mdi-keyboard-settings:before {
  content: "󰧹";
}

.mdi-keyboard-settings-outline:before {
  content: "󰧺";
}

.mdi-keyboard-space:before {
  content: "󱁐";
}

.mdi-keyboard-tab:before {
  content: "󰌒";
}

.mdi-keyboard-tab-reverse:before {
  content: "󰌥";
}

.mdi-keyboard-variant:before {
  content: "󰌓";
}

.mdi-khanda:before {
  content: "󱃽";
}

.mdi-kickstarter:before {
  content: "󰝅";
}

.mdi-kite:before {
  content: "󱦅";
}

.mdi-kite-outline:before {
  content: "󱦆";
}

.mdi-kitesurfing:before {
  content: "󱝄";
}

.mdi-klingon:before {
  content: "󱍛";
}

.mdi-knife:before {
  content: "󰧻";
}

.mdi-knife-military:before {
  content: "󰧼";
}

.mdi-knob:before {
  content: "󱮖";
}

.mdi-koala:before {
  content: "󱜿";
}

.mdi-kodi:before {
  content: "󰌔";
}

.mdi-kubernetes:before {
  content: "󱃾";
}

.mdi-label:before {
  content: "󰌕";
}

.mdi-label-multiple:before {
  content: "󱍵";
}

.mdi-label-multiple-outline:before {
  content: "󱍶";
}

.mdi-label-off:before {
  content: "󰫋";
}

.mdi-label-off-outline:before {
  content: "󰫌";
}

.mdi-label-outline:before {
  content: "󰌖";
}

.mdi-label-percent:before {
  content: "󱋪";
}

.mdi-label-percent-outline:before {
  content: "󱋫";
}

.mdi-label-variant:before {
  content: "󰫍";
}

.mdi-label-variant-outline:before {
  content: "󰫎";
}

.mdi-ladder:before {
  content: "󱖢";
}

.mdi-ladybug:before {
  content: "󰠭";
}

.mdi-lambda:before {
  content: "󰘧";
}

.mdi-lamp:before {
  content: "󰚵";
}

.mdi-lamp-outline:before {
  content: "󱟐";
}

.mdi-lamps:before {
  content: "󱕶";
}

.mdi-lamps-outline:before {
  content: "󱟑";
}

.mdi-lan:before {
  content: "󰌗";
}

.mdi-lan-check:before {
  content: "󱊪";
}

.mdi-lan-connect:before {
  content: "󰌘";
}

.mdi-lan-disconnect:before {
  content: "󰌙";
}

.mdi-lan-pending:before {
  content: "󰌚";
}

.mdi-land-fields:before {
  content: "󱪲";
}

.mdi-land-plots:before {
  content: "󱪳";
}

.mdi-land-plots-circle:before {
  content: "󱪴";
}

.mdi-land-plots-circle-variant:before {
  content: "󱪵";
}

.mdi-land-plots-marker:before {
  content: "󱱝";
}

.mdi-land-rows-horizontal:before {
  content: "󱪶";
}

.mdi-land-rows-vertical:before {
  content: "󱪷";
}

.mdi-landslide:before {
  content: "󱩈";
}

.mdi-landslide-outline:before {
  content: "󱩉";
}

.mdi-language-c:before {
  content: "󰙱";
}

.mdi-language-cpp:before {
  content: "󰙲";
}

.mdi-language-csharp:before {
  content: "󰌛";
}

.mdi-language-css3:before {
  content: "󰌜";
}

.mdi-language-fortran:before {
  content: "󱈚";
}

.mdi-language-go:before {
  content: "󰟓";
}

.mdi-language-haskell:before {
  content: "󰲒";
}

.mdi-language-html5:before {
  content: "󰌝";
}

.mdi-language-java:before {
  content: "󰬷";
}

.mdi-language-javascript:before {
  content: "󰌞";
}

.mdi-language-kotlin:before {
  content: "󱈙";
}

.mdi-language-lua:before {
  content: "󰢱";
}

.mdi-language-markdown:before {
  content: "󰍔";
}

.mdi-language-markdown-outline:before {
  content: "󰽛";
}

.mdi-language-php:before {
  content: "󰌟";
}

.mdi-language-python:before {
  content: "󰌠";
}

.mdi-language-r:before {
  content: "󰟔";
}

.mdi-language-ruby:before {
  content: "󰴭";
}

.mdi-language-ruby-on-rails:before {
  content: "󰫏";
}

.mdi-language-rust:before {
  content: "󱘗";
}

.mdi-language-swift:before {
  content: "󰛥";
}

.mdi-language-typescript:before {
  content: "󰛦";
}

.mdi-language-xaml:before {
  content: "󰙳";
}

.mdi-laptop:before {
  content: "󰌢";
}

.mdi-laptop-account:before {
  content: "󱩊";
}

.mdi-laptop-off:before {
  content: "󰛧";
}

.mdi-laravel:before {
  content: "󰫐";
}

.mdi-laser-pointer:before {
  content: "󱒄";
}

.mdi-lasso:before {
  content: "󰼃";
}

.mdi-lastpass:before {
  content: "󰑆";
}

.mdi-latitude:before {
  content: "󰽗";
}

.mdi-launch:before {
  content: "󰌧";
}

.mdi-lava-lamp:before {
  content: "󰟕";
}

.mdi-layers:before {
  content: "󰌨";
}

.mdi-layers-edit:before {
  content: "󱢒";
}

.mdi-layers-minus:before {
  content: "󰹌";
}

.mdi-layers-off:before {
  content: "󰌩";
}

.mdi-layers-off-outline:before {
  content: "󰧽";
}

.mdi-layers-outline:before {
  content: "󰧾";
}

.mdi-layers-plus:before {
  content: "󰹍";
}

.mdi-layers-remove:before {
  content: "󰹎";
}

.mdi-layers-search:before {
  content: "󱈆";
}

.mdi-layers-search-outline:before {
  content: "󱈇";
}

.mdi-layers-triple:before {
  content: "󰽘";
}

.mdi-layers-triple-outline:before {
  content: "󰽙";
}

.mdi-lead-pencil:before {
  content: "󰙏";
}

.mdi-leaf:before {
  content: "󰌪";
}

.mdi-leaf-circle:before {
  content: "󱤅";
}

.mdi-leaf-circle-outline:before {
  content: "󱤆";
}

.mdi-leaf-maple:before {
  content: "󰲓";
}

.mdi-leaf-maple-off:before {
  content: "󱋚";
}

.mdi-leaf-off:before {
  content: "󱋙";
}

.mdi-leak:before {
  content: "󰷗";
}

.mdi-leak-off:before {
  content: "󰷘";
}

.mdi-lectern:before {
  content: "󱫰";
}

.mdi-led-off:before {
  content: "󰌫";
}

.mdi-led-on:before {
  content: "󰌬";
}

.mdi-led-outline:before {
  content: "󰌭";
}

.mdi-led-strip:before {
  content: "󰟖";
}

.mdi-led-strip-variant:before {
  content: "󱁑";
}

.mdi-led-strip-variant-off:before {
  content: "󱩋";
}

.mdi-led-variant-off:before {
  content: "󰌮";
}

.mdi-led-variant-on:before {
  content: "󰌯";
}

.mdi-led-variant-outline:before {
  content: "󰌰";
}

.mdi-leek:before {
  content: "󱅽";
}

.mdi-less-than:before {
  content: "󰥼";
}

.mdi-less-than-or-equal:before {
  content: "󰥽";
}

.mdi-library:before {
  content: "󰌱";
}

.mdi-library-outline:before {
  content: "󱨢";
}

.mdi-library-shelves:before {
  content: "󰮩";
}

.mdi-license:before {
  content: "󰿃";
}

.mdi-lifebuoy:before {
  content: "󰡾";
}

.mdi-light-flood-down:before {
  content: "󱦇";
}

.mdi-light-flood-up:before {
  content: "󱦈";
}

.mdi-light-recessed:before {
  content: "󱞛";
}

.mdi-light-switch:before {
  content: "󰥾";
}

.mdi-light-switch-off:before {
  content: "󱨤";
}

.mdi-lightbulb:before {
  content: "󰌵";
}

.mdi-lightbulb-alert:before {
  content: "󱧡";
}

.mdi-lightbulb-alert-outline:before {
  content: "󱧢";
}

.mdi-lightbulb-auto:before {
  content: "󱠀";
}

.mdi-lightbulb-auto-outline:before {
  content: "󱠁";
}

.mdi-lightbulb-cfl:before {
  content: "󱈈";
}

.mdi-lightbulb-cfl-off:before {
  content: "󱈉";
}

.mdi-lightbulb-cfl-spiral:before {
  content: "󱉵";
}

.mdi-lightbulb-cfl-spiral-off:before {
  content: "󱋃";
}

.mdi-lightbulb-fluorescent-tube:before {
  content: "󱠄";
}

.mdi-lightbulb-fluorescent-tube-outline:before {
  content: "󱠅";
}

.mdi-lightbulb-group:before {
  content: "󱉓";
}

.mdi-lightbulb-group-off:before {
  content: "󱋍";
}

.mdi-lightbulb-group-off-outline:before {
  content: "󱋎";
}

.mdi-lightbulb-group-outline:before {
  content: "󱉔";
}

.mdi-lightbulb-multiple:before {
  content: "󱉕";
}

.mdi-lightbulb-multiple-off:before {
  content: "󱋏";
}

.mdi-lightbulb-multiple-off-outline:before {
  content: "󱋐";
}

.mdi-lightbulb-multiple-outline:before {
  content: "󱉖";
}

.mdi-lightbulb-night:before {
  content: "󱩌";
}

.mdi-lightbulb-night-outline:before {
  content: "󱩍";
}

.mdi-lightbulb-off:before {
  content: "󰹏";
}

.mdi-lightbulb-off-outline:before {
  content: "󰹐";
}

.mdi-lightbulb-on:before {
  content: "󰛨";
}

.mdi-lightbulb-on-10:before {
  content: "󱩎";
}

.mdi-lightbulb-on-20:before {
  content: "󱩏";
}

.mdi-lightbulb-on-30:before {
  content: "󱩐";
}

.mdi-lightbulb-on-40:before {
  content: "󱩑";
}

.mdi-lightbulb-on-50:before {
  content: "󱩒";
}

.mdi-lightbulb-on-60:before {
  content: "󱩓";
}

.mdi-lightbulb-on-70:before {
  content: "󱩔";
}

.mdi-lightbulb-on-80:before {
  content: "󱩕";
}

.mdi-lightbulb-on-90:before {
  content: "󱩖";
}

.mdi-lightbulb-on-outline:before {
  content: "󰛩";
}

.mdi-lightbulb-outline:before {
  content: "󰌶";
}

.mdi-lightbulb-question:before {
  content: "󱧣";
}

.mdi-lightbulb-question-outline:before {
  content: "󱧤";
}

.mdi-lightbulb-spot:before {
  content: "󱟴";
}

.mdi-lightbulb-spot-off:before {
  content: "󱟵";
}

.mdi-lightbulb-variant:before {
  content: "󱠂";
}

.mdi-lightbulb-variant-outline:before {
  content: "󱠃";
}

.mdi-lighthouse:before {
  content: "󰧿";
}

.mdi-lighthouse-on:before {
  content: "󰨀";
}

.mdi-lightning-bolt:before {
  content: "󱐋";
}

.mdi-lightning-bolt-circle:before {
  content: "󰠠";
}

.mdi-lightning-bolt-outline:before {
  content: "󱐌";
}

.mdi-line-scan:before {
  content: "󰘤";
}

.mdi-lingerie:before {
  content: "󱑶";
}

.mdi-link:before {
  content: "󰌷";
}

.mdi-link-box:before {
  content: "󰴚";
}

.mdi-link-box-outline:before {
  content: "󰴛";
}

.mdi-link-box-variant:before {
  content: "󰴜";
}

.mdi-link-box-variant-outline:before {
  content: "󰴝";
}

.mdi-link-circle:before {
  content: "󱲬";
}

.mdi-link-circle-outline:before {
  content: "󱲭";
}

.mdi-link-edit:before {
  content: "󱲮";
}

.mdi-link-lock:before {
  content: "󱂺";
}

.mdi-link-off:before {
  content: "󰌸";
}

.mdi-link-plus:before {
  content: "󰲔";
}

.mdi-link-variant:before {
  content: "󰌹";
}

.mdi-link-variant-minus:before {
  content: "󱃿";
}

.mdi-link-variant-off:before {
  content: "󰌺";
}

.mdi-link-variant-plus:before {
  content: "󱄀";
}

.mdi-link-variant-remove:before {
  content: "󱄁";
}

.mdi-linkedin:before {
  content: "󰌻";
}

.mdi-linux:before {
  content: "󰌽";
}

.mdi-linux-mint:before {
  content: "󰣭";
}

.mdi-lipstick:before {
  content: "󱎵";
}

.mdi-liquid-spot:before {
  content: "󱠦";
}

.mdi-liquor:before {
  content: "󱤞";
}

.mdi-list-box:before {
  content: "󱭻";
}

.mdi-list-box-outline:before {
  content: "󱭼";
}

.mdi-list-status:before {
  content: "󱖫";
}

.mdi-litecoin:before {
  content: "󰩡";
}

.mdi-loading:before {
  content: "󰝲";
}

.mdi-location-enter:before {
  content: "󰿄";
}

.mdi-location-exit:before {
  content: "󰿅";
}

.mdi-lock:before {
  content: "󰌾";
}

.mdi-lock-alert:before {
  content: "󰣮";
}

.mdi-lock-alert-outline:before {
  content: "󱗑";
}

.mdi-lock-check:before {
  content: "󱎚";
}

.mdi-lock-check-outline:before {
  content: "󱚨";
}

.mdi-lock-clock:before {
  content: "󰥿";
}

.mdi-lock-minus:before {
  content: "󱚩";
}

.mdi-lock-minus-outline:before {
  content: "󱚪";
}

.mdi-lock-off:before {
  content: "󱙱";
}

.mdi-lock-off-outline:before {
  content: "󱙲";
}

.mdi-lock-open:before {
  content: "󰌿";
}

.mdi-lock-open-alert:before {
  content: "󱎛";
}

.mdi-lock-open-alert-outline:before {
  content: "󱗒";
}

.mdi-lock-open-check:before {
  content: "󱎜";
}

.mdi-lock-open-check-outline:before {
  content: "󱚫";
}

.mdi-lock-open-minus:before {
  content: "󱚬";
}

.mdi-lock-open-minus-outline:before {
  content: "󱚭";
}

.mdi-lock-open-outline:before {
  content: "󰍀";
}

.mdi-lock-open-plus:before {
  content: "󱚮";
}

.mdi-lock-open-plus-outline:before {
  content: "󱚯";
}

.mdi-lock-open-remove:before {
  content: "󱚰";
}

.mdi-lock-open-remove-outline:before {
  content: "󱚱";
}

.mdi-lock-open-variant:before {
  content: "󰿆";
}

.mdi-lock-open-variant-outline:before {
  content: "󰿇";
}

.mdi-lock-outline:before {
  content: "󰍁";
}

.mdi-lock-pattern:before {
  content: "󰛪";
}

.mdi-lock-percent:before {
  content: "󱰒";
}

.mdi-lock-percent-open:before {
  content: "󱰓";
}

.mdi-lock-percent-open-outline:before {
  content: "󱰔";
}

.mdi-lock-percent-open-variant:before {
  content: "󱰕";
}

.mdi-lock-percent-open-variant-outline:before {
  content: "󱰖";
}

.mdi-lock-percent-outline:before {
  content: "󱰗";
}

.mdi-lock-plus:before {
  content: "󰗻";
}

.mdi-lock-plus-outline:before {
  content: "󱚲";
}

.mdi-lock-question:before {
  content: "󰣯";
}

.mdi-lock-remove:before {
  content: "󱚳";
}

.mdi-lock-remove-outline:before {
  content: "󱚴";
}

.mdi-lock-reset:before {
  content: "󰝳";
}

.mdi-lock-smart:before {
  content: "󰢲";
}

.mdi-locker:before {
  content: "󰟗";
}

.mdi-locker-multiple:before {
  content: "󰟘";
}

.mdi-login:before {
  content: "󰍂";
}

.mdi-login-variant:before {
  content: "󰗼";
}

.mdi-logout:before {
  content: "󰍃";
}

.mdi-logout-variant:before {
  content: "󰗽";
}

.mdi-longitude:before {
  content: "󰽚";
}

.mdi-looks:before {
  content: "󰍄";
}

.mdi-lotion:before {
  content: "󱖂";
}

.mdi-lotion-outline:before {
  content: "󱖃";
}

.mdi-lotion-plus:before {
  content: "󱖄";
}

.mdi-lotion-plus-outline:before {
  content: "󱖅";
}

.mdi-loupe:before {
  content: "󰍅";
}

.mdi-lumx:before {
  content: "󰍆";
}

.mdi-lungs:before {
  content: "󱂄";
}

.mdi-mace:before {
  content: "󱡃";
}

.mdi-magazine-pistol:before {
  content: "󰌤";
}

.mdi-magazine-rifle:before {
  content: "󰌣";
}

.mdi-magic-staff:before {
  content: "󱡄";
}

.mdi-magnet:before {
  content: "󰍇";
}

.mdi-magnet-on:before {
  content: "󰍈";
}

.mdi-magnify:before {
  content: "󰍉";
}

.mdi-magnify-close:before {
  content: "󰦀";
}

.mdi-magnify-expand:before {
  content: "󱡴";
}

.mdi-magnify-minus:before {
  content: "󰍊";
}

.mdi-magnify-minus-cursor:before {
  content: "󰩢";
}

.mdi-magnify-minus-outline:before {
  content: "󰛬";
}

.mdi-magnify-plus:before {
  content: "󰍋";
}

.mdi-magnify-plus-cursor:before {
  content: "󰩣";
}

.mdi-magnify-plus-outline:before {
  content: "󰛭";
}

.mdi-magnify-remove-cursor:before {
  content: "󱈌";
}

.mdi-magnify-remove-outline:before {
  content: "󱈍";
}

.mdi-magnify-scan:before {
  content: "󱉶";
}

.mdi-mail:before {
  content: "󰺻";
}

.mdi-mailbox:before {
  content: "󰛮";
}

.mdi-mailbox-open:before {
  content: "󰶈";
}

.mdi-mailbox-open-outline:before {
  content: "󰶉";
}

.mdi-mailbox-open-up:before {
  content: "󰶊";
}

.mdi-mailbox-open-up-outline:before {
  content: "󰶋";
}

.mdi-mailbox-outline:before {
  content: "󰶌";
}

.mdi-mailbox-up:before {
  content: "󰶍";
}

.mdi-mailbox-up-outline:before {
  content: "󰶎";
}

.mdi-manjaro:before {
  content: "󱘊";
}

.mdi-map:before {
  content: "󰍍";
}

.mdi-map-check:before {
  content: "󰺼";
}

.mdi-map-check-outline:before {
  content: "󰺽";
}

.mdi-map-clock:before {
  content: "󰴞";
}

.mdi-map-clock-outline:before {
  content: "󰴟";
}

.mdi-map-legend:before {
  content: "󰨁";
}

.mdi-map-marker:before {
  content: "󰍎";
}

.mdi-map-marker-account:before {
  content: "󱣣";
}

.mdi-map-marker-account-outline:before {
  content: "󱣤";
}

.mdi-map-marker-alert:before {
  content: "󰼅";
}

.mdi-map-marker-alert-outline:before {
  content: "󰼆";
}

.mdi-map-marker-check:before {
  content: "󰲕";
}

.mdi-map-marker-check-outline:before {
  content: "󱋻";
}

.mdi-map-marker-circle:before {
  content: "󰍏";
}

.mdi-map-marker-distance:before {
  content: "󰣰";
}

.mdi-map-marker-down:before {
  content: "󱄂";
}

.mdi-map-marker-left:before {
  content: "󱋛";
}

.mdi-map-marker-left-outline:before {
  content: "󱋝";
}

.mdi-map-marker-minus:before {
  content: "󰙐";
}

.mdi-map-marker-minus-outline:before {
  content: "󱋹";
}

.mdi-map-marker-multiple:before {
  content: "󰍐";
}

.mdi-map-marker-multiple-outline:before {
  content: "󱉷";
}

.mdi-map-marker-off:before {
  content: "󰍑";
}

.mdi-map-marker-off-outline:before {
  content: "󱋽";
}

.mdi-map-marker-outline:before {
  content: "󰟙";
}

.mdi-map-marker-path:before {
  content: "󰴠";
}

.mdi-map-marker-plus:before {
  content: "󰙑";
}

.mdi-map-marker-plus-outline:before {
  content: "󱋸";
}

.mdi-map-marker-question:before {
  content: "󰼇";
}

.mdi-map-marker-question-outline:before {
  content: "󰼈";
}

.mdi-map-marker-radius:before {
  content: "󰍒";
}

.mdi-map-marker-radius-outline:before {
  content: "󱋼";
}

.mdi-map-marker-remove:before {
  content: "󰼉";
}

.mdi-map-marker-remove-outline:before {
  content: "󱋺";
}

.mdi-map-marker-remove-variant:before {
  content: "󰼊";
}

.mdi-map-marker-right:before {
  content: "󱋜";
}

.mdi-map-marker-right-outline:before {
  content: "󱋞";
}

.mdi-map-marker-star:before {
  content: "󱘈";
}

.mdi-map-marker-star-outline:before {
  content: "󱘉";
}

.mdi-map-marker-up:before {
  content: "󱄃";
}

.mdi-map-minus:before {
  content: "󰦁";
}

.mdi-map-outline:before {
  content: "󰦂";
}

.mdi-map-plus:before {
  content: "󰦃";
}

.mdi-map-search:before {
  content: "󰦄";
}

.mdi-map-search-outline:before {
  content: "󰦅";
}

.mdi-mapbox:before {
  content: "󰮪";
}

.mdi-margin:before {
  content: "󰍓";
}

.mdi-marker:before {
  content: "󰙒";
}

.mdi-marker-cancel:before {
  content: "󰷙";
}

.mdi-marker-check:before {
  content: "󰍕";
}

.mdi-mastodon:before {
  content: "󰫑";
}

.mdi-material-design:before {
  content: "󰦆";
}

.mdi-material-ui:before {
  content: "󰍗";
}

.mdi-math-compass:before {
  content: "󰍘";
}

.mdi-math-cos:before {
  content: "󰲖";
}

.mdi-math-integral:before {
  content: "󰿈";
}

.mdi-math-integral-box:before {
  content: "󰿉";
}

.mdi-math-log:before {
  content: "󱂅";
}

.mdi-math-norm:before {
  content: "󰿊";
}

.mdi-math-norm-box:before {
  content: "󰿋";
}

.mdi-math-sin:before {
  content: "󰲗";
}

.mdi-math-tan:before {
  content: "󰲘";
}

.mdi-matrix:before {
  content: "󰘨";
}

.mdi-medal:before {
  content: "󰦇";
}

.mdi-medal-outline:before {
  content: "󱌦";
}

.mdi-medical-bag:before {
  content: "󰛯";
}

.mdi-medical-cotton-swab:before {
  content: "󱪸";
}

.mdi-medication:before {
  content: "󱬔";
}

.mdi-medication-outline:before {
  content: "󱬕";
}

.mdi-meditation:before {
  content: "󱅻";
}

.mdi-memory:before {
  content: "󰍛";
}

.mdi-memory-arrow-down:before {
  content: "󱲦";
}

.mdi-menorah:before {
  content: "󱟔";
}

.mdi-menorah-fire:before {
  content: "󱟕";
}

.mdi-menu:before {
  content: "󰍜";
}

.mdi-menu-close:before {
  content: "󱲐";
}

.mdi-menu-down:before {
  content: "󰍝";
}

.mdi-menu-down-outline:before {
  content: "󰚶";
}

.mdi-menu-left:before {
  content: "󰍞";
}

.mdi-menu-left-outline:before {
  content: "󰨂";
}

.mdi-menu-open:before {
  content: "󰮫";
}

.mdi-menu-right:before {
  content: "󰍟";
}

.mdi-menu-right-outline:before {
  content: "󰨃";
}

.mdi-menu-swap:before {
  content: "󰩤";
}

.mdi-menu-swap-outline:before {
  content: "󰩥";
}

.mdi-menu-up:before {
  content: "󰍠";
}

.mdi-menu-up-outline:before {
  content: "󰚷";
}

.mdi-merge:before {
  content: "󰽜";
}

.mdi-message:before {
  content: "󰍡";
}

.mdi-message-alert:before {
  content: "󰍢";
}

.mdi-message-alert-outline:before {
  content: "󰨄";
}

.mdi-message-arrow-left:before {
  content: "󱋲";
}

.mdi-message-arrow-left-outline:before {
  content: "󱋳";
}

.mdi-message-arrow-right:before {
  content: "󱋴";
}

.mdi-message-arrow-right-outline:before {
  content: "󱋵";
}

.mdi-message-badge:before {
  content: "󱥁";
}

.mdi-message-badge-outline:before {
  content: "󱥂";
}

.mdi-message-bookmark:before {
  content: "󱖬";
}

.mdi-message-bookmark-outline:before {
  content: "󱖭";
}

.mdi-message-bulleted:before {
  content: "󰚢";
}

.mdi-message-bulleted-off:before {
  content: "󰚣";
}

.mdi-message-check:before {
  content: "󱮊";
}

.mdi-message-check-outline:before {
  content: "󱮋";
}

.mdi-message-cog:before {
  content: "󰛱";
}

.mdi-message-cog-outline:before {
  content: "󱅲";
}

.mdi-message-draw:before {
  content: "󰍣";
}

.mdi-message-fast:before {
  content: "󱧌";
}

.mdi-message-fast-outline:before {
  content: "󱧍";
}

.mdi-message-flash:before {
  content: "󱖩";
}

.mdi-message-flash-outline:before {
  content: "󱖪";
}

.mdi-message-image:before {
  content: "󰍤";
}

.mdi-message-image-outline:before {
  content: "󱅬";
}

.mdi-message-lock:before {
  content: "󰿌";
}

.mdi-message-lock-outline:before {
  content: "󱅭";
}

.mdi-message-minus:before {
  content: "󱅮";
}

.mdi-message-minus-outline:before {
  content: "󱅯";
}

.mdi-message-off:before {
  content: "󱙍";
}

.mdi-message-off-outline:before {
  content: "󱙎";
}

.mdi-message-outline:before {
  content: "󰍥";
}

.mdi-message-plus:before {
  content: "󰙓";
}

.mdi-message-plus-outline:before {
  content: "󱂻";
}

.mdi-message-processing:before {
  content: "󰍦";
}

.mdi-message-processing-outline:before {
  content: "󱅰";
}

.mdi-message-question:before {
  content: "󱜺";
}

.mdi-message-question-outline:before {
  content: "󱜻";
}

.mdi-message-reply:before {
  content: "󰍧";
}

.mdi-message-reply-outline:before {
  content: "󱜽";
}

.mdi-message-reply-text:before {
  content: "󰍨";
}

.mdi-message-reply-text-outline:before {
  content: "󱜾";
}

.mdi-message-settings:before {
  content: "󰛰";
}

.mdi-message-settings-outline:before {
  content: "󱅱";
}

.mdi-message-star:before {
  content: "󰚚";
}

.mdi-message-star-outline:before {
  content: "󱉐";
}

.mdi-message-text:before {
  content: "󰍩";
}

.mdi-message-text-clock:before {
  content: "󱅳";
}

.mdi-message-text-clock-outline:before {
  content: "󱅴";
}

.mdi-message-text-fast:before {
  content: "󱧎";
}

.mdi-message-text-fast-outline:before {
  content: "󱧏";
}

.mdi-message-text-lock:before {
  content: "󰿍";
}

.mdi-message-text-lock-outline:before {
  content: "󱅵";
}

.mdi-message-text-outline:before {
  content: "󰍪";
}

.mdi-message-video:before {
  content: "󰍫";
}

.mdi-meteor:before {
  content: "󰘩";
}

.mdi-meter-electric:before {
  content: "󱩗";
}

.mdi-meter-electric-outline:before {
  content: "󱩘";
}

.mdi-meter-gas:before {
  content: "󱩙";
}

.mdi-meter-gas-outline:before {
  content: "󱩚";
}

.mdi-metronome:before {
  content: "󰟚";
}

.mdi-metronome-tick:before {
  content: "󰟛";
}

.mdi-micro-sd:before {
  content: "󰟜";
}

.mdi-microphone:before {
  content: "󰍬";
}

.mdi-microphone-message:before {
  content: "󰔊";
}

.mdi-microphone-message-off:before {
  content: "󰔋";
}

.mdi-microphone-minus:before {
  content: "󰢳";
}

.mdi-microphone-off:before {
  content: "󰍭";
}

.mdi-microphone-outline:before {
  content: "󰍮";
}

.mdi-microphone-plus:before {
  content: "󰢴";
}

.mdi-microphone-question:before {
  content: "󱦉";
}

.mdi-microphone-question-outline:before {
  content: "󱦊";
}

.mdi-microphone-settings:before {
  content: "󰍯";
}

.mdi-microphone-variant:before {
  content: "󰍰";
}

.mdi-microphone-variant-off:before {
  content: "󰍱";
}

.mdi-microscope:before {
  content: "󰙔";
}

.mdi-microsoft:before {
  content: "󰍲";
}

.mdi-microsoft-access:before {
  content: "󱎎";
}

.mdi-microsoft-azure:before {
  content: "󰠅";
}

.mdi-microsoft-azure-devops:before {
  content: "󰿕";
}

.mdi-microsoft-bing:before {
  content: "󰂤";
}

.mdi-microsoft-dynamics-365:before {
  content: "󰦈";
}

.mdi-microsoft-edge:before {
  content: "󰇩";
}

.mdi-microsoft-excel:before {
  content: "󱎏";
}

.mdi-microsoft-internet-explorer:before {
  content: "󰌀";
}

.mdi-microsoft-office:before {
  content: "󰏆";
}

.mdi-microsoft-onedrive:before {
  content: "󰏊";
}

.mdi-microsoft-onenote:before {
  content: "󰝇";
}

.mdi-microsoft-outlook:before {
  content: "󰴢";
}

.mdi-microsoft-powerpoint:before {
  content: "󱎐";
}

.mdi-microsoft-sharepoint:before {
  content: "󱎑";
}

.mdi-microsoft-teams:before {
  content: "󰊻";
}

.mdi-microsoft-visual-studio:before {
  content: "󰘐";
}

.mdi-microsoft-visual-studio-code:before {
  content: "󰨞";
}

.mdi-microsoft-windows:before {
  content: "󰖳";
}

.mdi-microsoft-windows-classic:before {
  content: "󰨡";
}

.mdi-microsoft-word:before {
  content: "󱎒";
}

.mdi-microsoft-xbox:before {
  content: "󰖹";
}

.mdi-microsoft-xbox-controller:before {
  content: "󰖺";
}

.mdi-microsoft-xbox-controller-battery-alert:before {
  content: "󰝋";
}

.mdi-microsoft-xbox-controller-battery-charging:before {
  content: "󰨢";
}

.mdi-microsoft-xbox-controller-battery-empty:before {
  content: "󰝌";
}

.mdi-microsoft-xbox-controller-battery-full:before {
  content: "󰝍";
}

.mdi-microsoft-xbox-controller-battery-low:before {
  content: "󰝎";
}

.mdi-microsoft-xbox-controller-battery-medium:before {
  content: "󰝏";
}

.mdi-microsoft-xbox-controller-battery-unknown:before {
  content: "󰝐";
}

.mdi-microsoft-xbox-controller-menu:before {
  content: "󰹯";
}

.mdi-microsoft-xbox-controller-off:before {
  content: "󰖻";
}

.mdi-microsoft-xbox-controller-view:before {
  content: "󰹰";
}

.mdi-microwave:before {
  content: "󰲙";
}

.mdi-microwave-off:before {
  content: "󱐣";
}

.mdi-middleware:before {
  content: "󰽝";
}

.mdi-middleware-outline:before {
  content: "󰽞";
}

.mdi-midi:before {
  content: "󰣱";
}

.mdi-midi-port:before {
  content: "󰣲";
}

.mdi-mine:before {
  content: "󰷚";
}

.mdi-minecraft:before {
  content: "󰍳";
}

.mdi-mini-sd:before {
  content: "󰨅";
}

.mdi-minidisc:before {
  content: "󰨆";
}

.mdi-minus:before {
  content: "󰍴";
}

.mdi-minus-box:before {
  content: "󰍵";
}

.mdi-minus-box-multiple:before {
  content: "󱅁";
}

.mdi-minus-box-multiple-outline:before {
  content: "󱅂";
}

.mdi-minus-box-outline:before {
  content: "󰛲";
}

.mdi-minus-circle:before {
  content: "󰍶";
}

.mdi-minus-circle-multiple:before {
  content: "󰍚";
}

.mdi-minus-circle-multiple-outline:before {
  content: "󰫓";
}

.mdi-minus-circle-off:before {
  content: "󱑙";
}

.mdi-minus-circle-off-outline:before {
  content: "󱑚";
}

.mdi-minus-circle-outline:before {
  content: "󰍷";
}

.mdi-minus-network:before {
  content: "󰍸";
}

.mdi-minus-network-outline:before {
  content: "󰲚";
}

.mdi-minus-thick:before {
  content: "󱘹";
}

.mdi-mirror:before {
  content: "󱇽";
}

.mdi-mirror-rectangle:before {
  content: "󱞟";
}

.mdi-mirror-variant:before {
  content: "󱞠";
}

.mdi-mixed-martial-arts:before {
  content: "󰶏";
}

.mdi-mixed-reality:before {
  content: "󰡿";
}

.mdi-molecule:before {
  content: "󰮬";
}

.mdi-molecule-co:before {
  content: "󱋾";
}

.mdi-molecule-co2:before {
  content: "󰟤";
}

.mdi-monitor:before {
  content: "󰍹";
}

.mdi-monitor-account:before {
  content: "󱩛";
}

.mdi-monitor-arrow-down:before {
  content: "󱧐";
}

.mdi-monitor-arrow-down-variant:before {
  content: "󱧑";
}

.mdi-monitor-cellphone:before {
  content: "󰦉";
}

.mdi-monitor-cellphone-star:before {
  content: "󰦊";
}

.mdi-monitor-dashboard:before {
  content: "󰨇";
}

.mdi-monitor-edit:before {
  content: "󱋆";
}

.mdi-monitor-eye:before {
  content: "󱎴";
}

.mdi-monitor-lock:before {
  content: "󰷛";
}

.mdi-monitor-multiple:before {
  content: "󰍺";
}

.mdi-monitor-off:before {
  content: "󰶐";
}

.mdi-monitor-screenshot:before {
  content: "󰹑";
}

.mdi-monitor-share:before {
  content: "󱒃";
}

.mdi-monitor-shimmer:before {
  content: "󱄄";
}

.mdi-monitor-small:before {
  content: "󱡶";
}

.mdi-monitor-speaker:before {
  content: "󰽟";
}

.mdi-monitor-speaker-off:before {
  content: "󰽠";
}

.mdi-monitor-star:before {
  content: "󰷜";
}

.mdi-monitor-vertical:before {
  content: "󱰳";
}

.mdi-moon-first-quarter:before {
  content: "󰽡";
}

.mdi-moon-full:before {
  content: "󰽢";
}

.mdi-moon-last-quarter:before {
  content: "󰽣";
}

.mdi-moon-new:before {
  content: "󰽤";
}

.mdi-moon-waning-crescent:before {
  content: "󰽥";
}

.mdi-moon-waning-gibbous:before {
  content: "󰽦";
}

.mdi-moon-waxing-crescent:before {
  content: "󰽧";
}

.mdi-moon-waxing-gibbous:before {
  content: "󰽨";
}

.mdi-moped:before {
  content: "󱂆";
}

.mdi-moped-electric:before {
  content: "󱖷";
}

.mdi-moped-electric-outline:before {
  content: "󱖸";
}

.mdi-moped-outline:before {
  content: "󱖹";
}

.mdi-more:before {
  content: "󰍻";
}

.mdi-mortar-pestle:before {
  content: "󱝈";
}

.mdi-mortar-pestle-plus:before {
  content: "󰏱";
}

.mdi-mosque:before {
  content: "󰵅";
}

.mdi-mosque-outline:before {
  content: "󱠧";
}

.mdi-mother-heart:before {
  content: "󱌔";
}

.mdi-mother-nurse:before {
  content: "󰴡";
}

.mdi-motion:before {
  content: "󱖲";
}

.mdi-motion-outline:before {
  content: "󱖳";
}

.mdi-motion-pause:before {
  content: "󱖐";
}

.mdi-motion-pause-outline:before {
  content: "󱖒";
}

.mdi-motion-play:before {
  content: "󱖏";
}

.mdi-motion-play-outline:before {
  content: "󱖑";
}

.mdi-motion-sensor:before {
  content: "󰶑";
}

.mdi-motion-sensor-off:before {
  content: "󱐵";
}

.mdi-motorbike:before {
  content: "󰍼";
}

.mdi-motorbike-electric:before {
  content: "󱖺";
}

.mdi-motorbike-off:before {
  content: "󱬖";
}

.mdi-mouse:before {
  content: "󰍽";
}

.mdi-mouse-bluetooth:before {
  content: "󰦋";
}

.mdi-mouse-left-click:before {
  content: "󱴇";
}

.mdi-mouse-left-click-outline:before {
  content: "󱴈";
}

.mdi-mouse-move-down:before {
  content: "󱕐";
}

.mdi-mouse-move-up:before {
  content: "󱕑";
}

.mdi-mouse-move-vertical:before {
  content: "󱕒";
}

.mdi-mouse-off:before {
  content: "󰍾";
}

.mdi-mouse-outline:before {
  content: "󱴉";
}

.mdi-mouse-right-click:before {
  content: "󱴊";
}

.mdi-mouse-right-click-outline:before {
  content: "󱴋";
}

.mdi-mouse-scroll-wheel:before {
  content: "󱴌";
}

.mdi-mouse-variant:before {
  content: "󰍿";
}

.mdi-mouse-variant-off:before {
  content: "󰎀";
}

.mdi-move-resize:before {
  content: "󰙕";
}

.mdi-move-resize-variant:before {
  content: "󰙖";
}

.mdi-movie:before {
  content: "󰎁";
}

.mdi-movie-check:before {
  content: "󱛳";
}

.mdi-movie-check-outline:before {
  content: "󱛴";
}

.mdi-movie-cog:before {
  content: "󱛵";
}

.mdi-movie-cog-outline:before {
  content: "󱛶";
}

.mdi-movie-edit:before {
  content: "󱄢";
}

.mdi-movie-edit-outline:before {
  content: "󱄣";
}

.mdi-movie-filter:before {
  content: "󱄤";
}

.mdi-movie-filter-outline:before {
  content: "󱄥";
}

.mdi-movie-minus:before {
  content: "󱛷";
}

.mdi-movie-minus-outline:before {
  content: "󱛸";
}

.mdi-movie-off:before {
  content: "󱛹";
}

.mdi-movie-off-outline:before {
  content: "󱛺";
}

.mdi-movie-open:before {
  content: "󰿎";
}

.mdi-movie-open-check:before {
  content: "󱛻";
}

.mdi-movie-open-check-outline:before {
  content: "󱛼";
}

.mdi-movie-open-cog:before {
  content: "󱛽";
}

.mdi-movie-open-cog-outline:before {
  content: "󱛾";
}

.mdi-movie-open-edit:before {
  content: "󱛿";
}

.mdi-movie-open-edit-outline:before {
  content: "󱜀";
}

.mdi-movie-open-minus:before {
  content: "󱜁";
}

.mdi-movie-open-minus-outline:before {
  content: "󱜂";
}

.mdi-movie-open-off:before {
  content: "󱜃";
}

.mdi-movie-open-off-outline:before {
  content: "󱜄";
}

.mdi-movie-open-outline:before {
  content: "󰿏";
}

.mdi-movie-open-play:before {
  content: "󱜅";
}

.mdi-movie-open-play-outline:before {
  content: "󱜆";
}

.mdi-movie-open-plus:before {
  content: "󱜇";
}

.mdi-movie-open-plus-outline:before {
  content: "󱜈";
}

.mdi-movie-open-remove:before {
  content: "󱜉";
}

.mdi-movie-open-remove-outline:before {
  content: "󱜊";
}

.mdi-movie-open-settings:before {
  content: "󱜋";
}

.mdi-movie-open-settings-outline:before {
  content: "󱜌";
}

.mdi-movie-open-star:before {
  content: "󱜍";
}

.mdi-movie-open-star-outline:before {
  content: "󱜎";
}

.mdi-movie-outline:before {
  content: "󰷝";
}

.mdi-movie-play:before {
  content: "󱜏";
}

.mdi-movie-play-outline:before {
  content: "󱜐";
}

.mdi-movie-plus:before {
  content: "󱜑";
}

.mdi-movie-plus-outline:before {
  content: "󱜒";
}

.mdi-movie-remove:before {
  content: "󱜓";
}

.mdi-movie-remove-outline:before {
  content: "󱜔";
}

.mdi-movie-roll:before {
  content: "󰟞";
}

.mdi-movie-search:before {
  content: "󱇒";
}

.mdi-movie-search-outline:before {
  content: "󱇓";
}

.mdi-movie-settings:before {
  content: "󱜕";
}

.mdi-movie-settings-outline:before {
  content: "󱜖";
}

.mdi-movie-star:before {
  content: "󱜗";
}

.mdi-movie-star-outline:before {
  content: "󱜘";
}

.mdi-mower:before {
  content: "󱙯";
}

.mdi-mower-bag:before {
  content: "󱙰";
}

.mdi-mower-bag-on:before {
  content: "󱭠";
}

.mdi-mower-on:before {
  content: "󱭟";
}

.mdi-muffin:before {
  content: "󰦌";
}

.mdi-multicast:before {
  content: "󱢓";
}

.mdi-multimedia:before {
  content: "󱮗";
}

.mdi-multiplication:before {
  content: "󰎂";
}

.mdi-multiplication-box:before {
  content: "󰎃";
}

.mdi-mushroom:before {
  content: "󰟟";
}

.mdi-mushroom-off:before {
  content: "󱏺";
}

.mdi-mushroom-off-outline:before {
  content: "󱏻";
}

.mdi-mushroom-outline:before {
  content: "󰟠";
}

.mdi-music:before {
  content: "󰝚";
}

.mdi-music-accidental-double-flat:before {
  content: "󰽩";
}

.mdi-music-accidental-double-sharp:before {
  content: "󰽪";
}

.mdi-music-accidental-flat:before {
  content: "󰽫";
}

.mdi-music-accidental-natural:before {
  content: "󰽬";
}

.mdi-music-accidental-sharp:before {
  content: "󰽭";
}

.mdi-music-box:before {
  content: "󰎄";
}

.mdi-music-box-multiple:before {
  content: "󰌳";
}

.mdi-music-box-multiple-outline:before {
  content: "󰼄";
}

.mdi-music-box-outline:before {
  content: "󰎅";
}

.mdi-music-circle:before {
  content: "󰎆";
}

.mdi-music-circle-outline:before {
  content: "󰫔";
}

.mdi-music-clef-alto:before {
  content: "󰽮";
}

.mdi-music-clef-bass:before {
  content: "󰽯";
}

.mdi-music-clef-treble:before {
  content: "󰽰";
}

.mdi-music-note:before {
  content: "󰎇";
}

.mdi-music-note-bluetooth:before {
  content: "󰗾";
}

.mdi-music-note-bluetooth-off:before {
  content: "󰗿";
}

.mdi-music-note-eighth:before {
  content: "󰎈";
}

.mdi-music-note-eighth-dotted:before {
  content: "󰽱";
}

.mdi-music-note-half:before {
  content: "󰎉";
}

.mdi-music-note-half-dotted:before {
  content: "󰽲";
}

.mdi-music-note-minus:before {
  content: "󱮉";
}

.mdi-music-note-off:before {
  content: "󰎊";
}

.mdi-music-note-off-outline:before {
  content: "󰽳";
}

.mdi-music-note-outline:before {
  content: "󰽴";
}

.mdi-music-note-plus:before {
  content: "󰷞";
}

.mdi-music-note-quarter:before {
  content: "󰎋";
}

.mdi-music-note-quarter-dotted:before {
  content: "󰽵";
}

.mdi-music-note-sixteenth:before {
  content: "󰎌";
}

.mdi-music-note-sixteenth-dotted:before {
  content: "󰽶";
}

.mdi-music-note-whole:before {
  content: "󰎍";
}

.mdi-music-note-whole-dotted:before {
  content: "󰽷";
}

.mdi-music-off:before {
  content: "󰝛";
}

.mdi-music-rest-eighth:before {
  content: "󰽸";
}

.mdi-music-rest-half:before {
  content: "󰽹";
}

.mdi-music-rest-quarter:before {
  content: "󰽺";
}

.mdi-music-rest-sixteenth:before {
  content: "󰽻";
}

.mdi-music-rest-whole:before {
  content: "󰽼";
}

.mdi-mustache:before {
  content: "󱗞";
}

.mdi-nail:before {
  content: "󰷟";
}

.mdi-nas:before {
  content: "󰣳";
}

.mdi-nativescript:before {
  content: "󰢀";
}

.mdi-nature:before {
  content: "󰎎";
}

.mdi-nature-outline:before {
  content: "󱱱";
}

.mdi-nature-people:before {
  content: "󰎏";
}

.mdi-nature-people-outline:before {
  content: "󱱲";
}

.mdi-navigation:before {
  content: "󰎐";
}

.mdi-navigation-outline:before {
  content: "󱘇";
}

.mdi-navigation-variant:before {
  content: "󱣰";
}

.mdi-navigation-variant-outline:before {
  content: "󱣱";
}

.mdi-near-me:before {
  content: "󰗍";
}

.mdi-necklace:before {
  content: "󰼋";
}

.mdi-needle:before {
  content: "󰎑";
}

.mdi-needle-off:before {
  content: "󱧒";
}

.mdi-netflix:before {
  content: "󰝆";
}

.mdi-network:before {
  content: "󰛳";
}

.mdi-network-off:before {
  content: "󰲛";
}

.mdi-network-off-outline:before {
  content: "󰲜";
}

.mdi-network-outline:before {
  content: "󰲝";
}

.mdi-network-pos:before {
  content: "󱫋";
}

.mdi-network-strength-1:before {
  content: "󰣴";
}

.mdi-network-strength-1-alert:before {
  content: "󰣵";
}

.mdi-network-strength-2:before {
  content: "󰣶";
}

.mdi-network-strength-2-alert:before {
  content: "󰣷";
}

.mdi-network-strength-3:before {
  content: "󰣸";
}

.mdi-network-strength-3-alert:before {
  content: "󰣹";
}

.mdi-network-strength-4:before {
  content: "󰣺";
}

.mdi-network-strength-4-alert:before {
  content: "󰣻";
}

.mdi-network-strength-4-cog:before {
  content: "󱤚";
}

.mdi-network-strength-off:before {
  content: "󰣼";
}

.mdi-network-strength-off-outline:before {
  content: "󰣽";
}

.mdi-network-strength-outline:before {
  content: "󰣾";
}

.mdi-new-box:before {
  content: "󰎔";
}

.mdi-newspaper:before {
  content: "󰎕";
}

.mdi-newspaper-check:before {
  content: "󱥃";
}

.mdi-newspaper-minus:before {
  content: "󰼌";
}

.mdi-newspaper-plus:before {
  content: "󰼍";
}

.mdi-newspaper-remove:before {
  content: "󱥄";
}

.mdi-newspaper-variant:before {
  content: "󱀁";
}

.mdi-newspaper-variant-multiple:before {
  content: "󱀂";
}

.mdi-newspaper-variant-multiple-outline:before {
  content: "󱀃";
}

.mdi-newspaper-variant-outline:before {
  content: "󱀄";
}

.mdi-nfc:before {
  content: "󰎖";
}

.mdi-nfc-search-variant:before {
  content: "󰹓";
}

.mdi-nfc-tap:before {
  content: "󰎗";
}

.mdi-nfc-variant:before {
  content: "󰎘";
}

.mdi-nfc-variant-off:before {
  content: "󰹔";
}

.mdi-ninja:before {
  content: "󰝴";
}

.mdi-nintendo-game-boy:before {
  content: "󱎓";
}

.mdi-nintendo-switch:before {
  content: "󰟡";
}

.mdi-nintendo-wii:before {
  content: "󰖫";
}

.mdi-nintendo-wiiu:before {
  content: "󰜭";
}

.mdi-nix:before {
  content: "󱄅";
}

.mdi-nodejs:before {
  content: "󰎙";
}

.mdi-noodles:before {
  content: "󱅾";
}

.mdi-not-equal:before {
  content: "󰦍";
}

.mdi-not-equal-variant:before {
  content: "󰦎";
}

.mdi-note:before {
  content: "󰎚";
}

.mdi-note-alert:before {
  content: "󱝽";
}

.mdi-note-alert-outline:before {
  content: "󱝾";
}

.mdi-note-check:before {
  content: "󱝿";
}

.mdi-note-check-outline:before {
  content: "󱞀";
}

.mdi-note-edit:before {
  content: "󱞁";
}

.mdi-note-edit-outline:before {
  content: "󱞂";
}

.mdi-note-minus:before {
  content: "󱙏";
}

.mdi-note-minus-outline:before {
  content: "󱙐";
}

.mdi-note-multiple:before {
  content: "󰚸";
}

.mdi-note-multiple-outline:before {
  content: "󰚹";
}

.mdi-note-off:before {
  content: "󱞃";
}

.mdi-note-off-outline:before {
  content: "󱞄";
}

.mdi-note-outline:before {
  content: "󰎛";
}

.mdi-note-plus:before {
  content: "󰎜";
}

.mdi-note-plus-outline:before {
  content: "󰎝";
}

.mdi-note-remove:before {
  content: "󱙑";
}

.mdi-note-remove-outline:before {
  content: "󱙒";
}

.mdi-note-search:before {
  content: "󱙓";
}

.mdi-note-search-outline:before {
  content: "󱙔";
}

.mdi-note-text:before {
  content: "󰎞";
}

.mdi-note-text-outline:before {
  content: "󱇗";
}

.mdi-notebook:before {
  content: "󰠮";
}

.mdi-notebook-check:before {
  content: "󱓵";
}

.mdi-notebook-check-outline:before {
  content: "󱓶";
}

.mdi-notebook-edit:before {
  content: "󱓧";
}

.mdi-notebook-edit-outline:before {
  content: "󱓩";
}

.mdi-notebook-heart:before {
  content: "󱨋";
}

.mdi-notebook-heart-outline:before {
  content: "󱨌";
}

.mdi-notebook-minus:before {
  content: "󱘐";
}

.mdi-notebook-minus-outline:before {
  content: "󱘑";
}

.mdi-notebook-multiple:before {
  content: "󰹕";
}

.mdi-notebook-outline:before {
  content: "󰺿";
}

.mdi-notebook-plus:before {
  content: "󱘒";
}

.mdi-notebook-plus-outline:before {
  content: "󱘓";
}

.mdi-notebook-remove:before {
  content: "󱘔";
}

.mdi-notebook-remove-outline:before {
  content: "󱘕";
}

.mdi-notification-clear-all:before {
  content: "󰎟";
}

.mdi-npm:before {
  content: "󰛷";
}

.mdi-nuke:before {
  content: "󰚤";
}

.mdi-null:before {
  content: "󰟢";
}

.mdi-numeric:before {
  content: "󰎠";
}

.mdi-numeric-0:before {
  content: "󰬹";
}

.mdi-numeric-0-box:before {
  content: "󰎡";
}

.mdi-numeric-0-box-multiple:before {
  content: "󰼎";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "󰎢";
}

.mdi-numeric-0-box-outline:before {
  content: "󰎣";
}

.mdi-numeric-0-circle:before {
  content: "󰲞";
}

.mdi-numeric-0-circle-outline:before {
  content: "󰲟";
}

.mdi-numeric-1:before {
  content: "󰬺";
}

.mdi-numeric-1-box:before {
  content: "󰎤";
}

.mdi-numeric-1-box-multiple:before {
  content: "󰼏";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "󰎥";
}

.mdi-numeric-1-box-outline:before {
  content: "󰎦";
}

.mdi-numeric-1-circle:before {
  content: "󰲠";
}

.mdi-numeric-1-circle-outline:before {
  content: "󰲡";
}

.mdi-numeric-10:before {
  content: "󰿩";
}

.mdi-numeric-10-box:before {
  content: "󰽽";
}

.mdi-numeric-10-box-multiple:before {
  content: "󰿪";
}

.mdi-numeric-10-box-multiple-outline:before {
  content: "󰿫";
}

.mdi-numeric-10-box-outline:before {
  content: "󰽾";
}

.mdi-numeric-10-circle:before {
  content: "󰿬";
}

.mdi-numeric-10-circle-outline:before {
  content: "󰿭";
}

.mdi-numeric-2:before {
  content: "󰬻";
}

.mdi-numeric-2-box:before {
  content: "󰎧";
}

.mdi-numeric-2-box-multiple:before {
  content: "󰼐";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "󰎨";
}

.mdi-numeric-2-box-outline:before {
  content: "󰎩";
}

.mdi-numeric-2-circle:before {
  content: "󰲢";
}

.mdi-numeric-2-circle-outline:before {
  content: "󰲣";
}

.mdi-numeric-3:before {
  content: "󰬼";
}

.mdi-numeric-3-box:before {
  content: "󰎪";
}

.mdi-numeric-3-box-multiple:before {
  content: "󰼑";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "󰎫";
}

.mdi-numeric-3-box-outline:before {
  content: "󰎬";
}

.mdi-numeric-3-circle:before {
  content: "󰲤";
}

.mdi-numeric-3-circle-outline:before {
  content: "󰲥";
}

.mdi-numeric-4:before {
  content: "󰬽";
}

.mdi-numeric-4-box:before {
  content: "󰎭";
}

.mdi-numeric-4-box-multiple:before {
  content: "󰼒";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "󰎲";
}

.mdi-numeric-4-box-outline:before {
  content: "󰎮";
}

.mdi-numeric-4-circle:before {
  content: "󰲦";
}

.mdi-numeric-4-circle-outline:before {
  content: "󰲧";
}

.mdi-numeric-5:before {
  content: "󰬾";
}

.mdi-numeric-5-box:before {
  content: "󰎱";
}

.mdi-numeric-5-box-multiple:before {
  content: "󰼓";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "󰎯";
}

.mdi-numeric-5-box-outline:before {
  content: "󰎰";
}

.mdi-numeric-5-circle:before {
  content: "󰲨";
}

.mdi-numeric-5-circle-outline:before {
  content: "󰲩";
}

.mdi-numeric-6:before {
  content: "󰬿";
}

.mdi-numeric-6-box:before {
  content: "󰎳";
}

.mdi-numeric-6-box-multiple:before {
  content: "󰼔";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "󰎴";
}

.mdi-numeric-6-box-outline:before {
  content: "󰎵";
}

.mdi-numeric-6-circle:before {
  content: "󰲪";
}

.mdi-numeric-6-circle-outline:before {
  content: "󰲫";
}

.mdi-numeric-7:before {
  content: "󰭀";
}

.mdi-numeric-7-box:before {
  content: "󰎶";
}

.mdi-numeric-7-box-multiple:before {
  content: "󰼕";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "󰎷";
}

.mdi-numeric-7-box-outline:before {
  content: "󰎸";
}

.mdi-numeric-7-circle:before {
  content: "󰲬";
}

.mdi-numeric-7-circle-outline:before {
  content: "󰲭";
}

.mdi-numeric-8:before {
  content: "󰭁";
}

.mdi-numeric-8-box:before {
  content: "󰎹";
}

.mdi-numeric-8-box-multiple:before {
  content: "󰼖";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "󰎺";
}

.mdi-numeric-8-box-outline:before {
  content: "󰎻";
}

.mdi-numeric-8-circle:before {
  content: "󰲮";
}

.mdi-numeric-8-circle-outline:before {
  content: "󰲯";
}

.mdi-numeric-9:before {
  content: "󰭂";
}

.mdi-numeric-9-box:before {
  content: "󰎼";
}

.mdi-numeric-9-box-multiple:before {
  content: "󰼗";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "󰎽";
}

.mdi-numeric-9-box-outline:before {
  content: "󰎾";
}

.mdi-numeric-9-circle:before {
  content: "󰲰";
}

.mdi-numeric-9-circle-outline:before {
  content: "󰲱";
}

.mdi-numeric-9-plus:before {
  content: "󰿮";
}

.mdi-numeric-9-plus-box:before {
  content: "󰎿";
}

.mdi-numeric-9-plus-box-multiple:before {
  content: "󰼘";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "󰏀";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "󰏁";
}

.mdi-numeric-9-plus-circle:before {
  content: "󰲲";
}

.mdi-numeric-9-plus-circle-outline:before {
  content: "󰲳";
}

.mdi-numeric-negative-1:before {
  content: "󱁒";
}

.mdi-numeric-off:before {
  content: "󱧓";
}

.mdi-numeric-positive-1:before {
  content: "󱗋";
}

.mdi-nut:before {
  content: "󰛸";
}

.mdi-nutrition:before {
  content: "󰏂";
}

.mdi-nuxt:before {
  content: "󱄆";
}

.mdi-oar:before {
  content: "󰙼";
}

.mdi-ocarina:before {
  content: "󰷠";
}

.mdi-oci:before {
  content: "󱋩";
}

.mdi-ocr:before {
  content: "󱄺";
}

.mdi-octagon:before {
  content: "󰏃";
}

.mdi-octagon-outline:before {
  content: "󰏄";
}

.mdi-octagram:before {
  content: "󰛹";
}

.mdi-octagram-edit:before {
  content: "󱰴";
}

.mdi-octagram-edit-outline:before {
  content: "󱰵";
}

.mdi-octagram-minus:before {
  content: "󱰶";
}

.mdi-octagram-minus-outline:before {
  content: "󱰷";
}

.mdi-octagram-outline:before {
  content: "󰝵";
}

.mdi-octagram-plus:before {
  content: "󱰸";
}

.mdi-octagram-plus-outline:before {
  content: "󱰹";
}

.mdi-octahedron:before {
  content: "󱥐";
}

.mdi-octahedron-off:before {
  content: "󱥑";
}

.mdi-odnoklassniki:before {
  content: "󰏅";
}

.mdi-offer:before {
  content: "󱈛";
}

.mdi-office-building:before {
  content: "󰦑";
}

.mdi-office-building-cog:before {
  content: "󱥉";
}

.mdi-office-building-cog-outline:before {
  content: "󱥊";
}

.mdi-office-building-marker:before {
  content: "󱔠";
}

.mdi-office-building-marker-outline:before {
  content: "󱔡";
}

.mdi-office-building-minus:before {
  content: "󱮪";
}

.mdi-office-building-minus-outline:before {
  content: "󱮫";
}

.mdi-office-building-outline:before {
  content: "󱔟";
}

.mdi-office-building-plus:before {
  content: "󱮨";
}

.mdi-office-building-plus-outline:before {
  content: "󱮩";
}

.mdi-office-building-remove:before {
  content: "󱮬";
}

.mdi-office-building-remove-outline:before {
  content: "󱮭";
}

.mdi-oil:before {
  content: "󰏇";
}

.mdi-oil-lamp:before {
  content: "󰼙";
}

.mdi-oil-level:before {
  content: "󱁓";
}

.mdi-oil-temperature:before {
  content: "󰿸";
}

.mdi-om:before {
  content: "󰥳";
}

.mdi-omega:before {
  content: "󰏉";
}

.mdi-one-up:before {
  content: "󰮭";
}

.mdi-onepassword:before {
  content: "󰢁";
}

.mdi-opacity:before {
  content: "󰗌";
}

.mdi-open-in-app:before {
  content: "󰏋";
}

.mdi-open-in-new:before {
  content: "󰏌";
}

.mdi-open-source-initiative:before {
  content: "󰮮";
}

.mdi-openid:before {
  content: "󰏍";
}

.mdi-opera:before {
  content: "󰏎";
}

.mdi-orbit:before {
  content: "󰀘";
}

.mdi-orbit-variant:before {
  content: "󱗛";
}

.mdi-order-alphabetical-ascending:before {
  content: "󰈍";
}

.mdi-order-alphabetical-descending:before {
  content: "󰴇";
}

.mdi-order-bool-ascending:before {
  content: "󰊾";
}

.mdi-order-bool-ascending-variant:before {
  content: "󰦏";
}

.mdi-order-bool-descending:before {
  content: "󱎄";
}

.mdi-order-bool-descending-variant:before {
  content: "󰦐";
}

.mdi-order-numeric-ascending:before {
  content: "󰕅";
}

.mdi-order-numeric-descending:before {
  content: "󰕆";
}

.mdi-origin:before {
  content: "󰭃";
}

.mdi-ornament:before {
  content: "󰏏";
}

.mdi-ornament-variant:before {
  content: "󰏐";
}

.mdi-outdoor-lamp:before {
  content: "󱁔";
}

.mdi-overscan:before {
  content: "󱀅";
}

.mdi-owl:before {
  content: "󰏒";
}

.mdi-pac-man:before {
  content: "󰮯";
}

.mdi-package:before {
  content: "󰏓";
}

.mdi-package-check:before {
  content: "󱭑";
}

.mdi-package-down:before {
  content: "󰏔";
}

.mdi-package-up:before {
  content: "󰏕";
}

.mdi-package-variant:before {
  content: "󰏖";
}

.mdi-package-variant-closed:before {
  content: "󰏗";
}

.mdi-package-variant-closed-check:before {
  content: "󱭒";
}

.mdi-package-variant-closed-minus:before {
  content: "󱧔";
}

.mdi-package-variant-closed-plus:before {
  content: "󱧕";
}

.mdi-package-variant-closed-remove:before {
  content: "󱧖";
}

.mdi-package-variant-minus:before {
  content: "󱧗";
}

.mdi-package-variant-plus:before {
  content: "󱧘";
}

.mdi-package-variant-remove:before {
  content: "󱧙";
}

.mdi-page-first:before {
  content: "󰘀";
}

.mdi-page-last:before {
  content: "󰘁";
}

.mdi-page-layout-body:before {
  content: "󰛺";
}

.mdi-page-layout-footer:before {
  content: "󰛻";
}

.mdi-page-layout-header:before {
  content: "󰛼";
}

.mdi-page-layout-header-footer:before {
  content: "󰽿";
}

.mdi-page-layout-sidebar-left:before {
  content: "󰛽";
}

.mdi-page-layout-sidebar-right:before {
  content: "󰛾";
}

.mdi-page-next:before {
  content: "󰮰";
}

.mdi-page-next-outline:before {
  content: "󰮱";
}

.mdi-page-previous:before {
  content: "󰮲";
}

.mdi-page-previous-outline:before {
  content: "󰮳";
}

.mdi-pail:before {
  content: "󱐗";
}

.mdi-pail-minus:before {
  content: "󱐷";
}

.mdi-pail-minus-outline:before {
  content: "󱐼";
}

.mdi-pail-off:before {
  content: "󱐹";
}

.mdi-pail-off-outline:before {
  content: "󱐾";
}

.mdi-pail-outline:before {
  content: "󱐺";
}

.mdi-pail-plus:before {
  content: "󱐶";
}

.mdi-pail-plus-outline:before {
  content: "󱐻";
}

.mdi-pail-remove:before {
  content: "󱐸";
}

.mdi-pail-remove-outline:before {
  content: "󱐽";
}

.mdi-palette:before {
  content: "󰏘";
}

.mdi-palette-advanced:before {
  content: "󰏙";
}

.mdi-palette-outline:before {
  content: "󰸌";
}

.mdi-palette-swatch:before {
  content: "󰢵";
}

.mdi-palette-swatch-outline:before {
  content: "󱍜";
}

.mdi-palette-swatch-variant:before {
  content: "󱥚";
}

.mdi-palm-tree:before {
  content: "󱁕";
}

.mdi-pan:before {
  content: "󰮴";
}

.mdi-pan-bottom-left:before {
  content: "󰮵";
}

.mdi-pan-bottom-right:before {
  content: "󰮶";
}

.mdi-pan-down:before {
  content: "󰮷";
}

.mdi-pan-horizontal:before {
  content: "󰮸";
}

.mdi-pan-left:before {
  content: "󰮹";
}

.mdi-pan-right:before {
  content: "󰮺";
}

.mdi-pan-top-left:before {
  content: "󰮻";
}

.mdi-pan-top-right:before {
  content: "󰮼";
}

.mdi-pan-up:before {
  content: "󰮽";
}

.mdi-pan-vertical:before {
  content: "󰮾";
}

.mdi-panda:before {
  content: "󰏚";
}

.mdi-pandora:before {
  content: "󰏛";
}

.mdi-panorama:before {
  content: "󰏜";
}

.mdi-panorama-fisheye:before {
  content: "󰏝";
}

.mdi-panorama-horizontal:before {
  content: "󱤨";
}

.mdi-panorama-horizontal-outline:before {
  content: "󰏞";
}

.mdi-panorama-outline:before {
  content: "󱦌";
}

.mdi-panorama-sphere:before {
  content: "󱦍";
}

.mdi-panorama-sphere-outline:before {
  content: "󱦎";
}

.mdi-panorama-variant:before {
  content: "󱦏";
}

.mdi-panorama-variant-outline:before {
  content: "󱦐";
}

.mdi-panorama-vertical:before {
  content: "󱤩";
}

.mdi-panorama-vertical-outline:before {
  content: "󰏟";
}

.mdi-panorama-wide-angle:before {
  content: "󱥟";
}

.mdi-panorama-wide-angle-outline:before {
  content: "󰏠";
}

.mdi-paper-cut-vertical:before {
  content: "󰏡";
}

.mdi-paper-roll:before {
  content: "󱅗";
}

.mdi-paper-roll-outline:before {
  content: "󱅘";
}

.mdi-paperclip:before {
  content: "󰏢";
}

.mdi-paperclip-check:before {
  content: "󱫆";
}

.mdi-paperclip-lock:before {
  content: "󱧚";
}

.mdi-paperclip-minus:before {
  content: "󱫇";
}

.mdi-paperclip-off:before {
  content: "󱫈";
}

.mdi-paperclip-plus:before {
  content: "󱫉";
}

.mdi-paperclip-remove:before {
  content: "󱫊";
}

.mdi-parachute:before {
  content: "󰲴";
}

.mdi-parachute-outline:before {
  content: "󰲵";
}

.mdi-paragliding:before {
  content: "󱝅";
}

.mdi-parking:before {
  content: "󰏣";
}

.mdi-party-popper:before {
  content: "󱁖";
}

.mdi-passport:before {
  content: "󰟣";
}

.mdi-passport-alert:before {
  content: "󱲸";
}

.mdi-passport-biometric:before {
  content: "󰷡";
}

.mdi-passport-cancel:before {
  content: "󱲹";
}

.mdi-passport-check:before {
  content: "󱲺";
}

.mdi-passport-minus:before {
  content: "󱲻";
}

.mdi-passport-plus:before {
  content: "󱲼";
}

.mdi-passport-remove:before {
  content: "󱲽";
}

.mdi-pasta:before {
  content: "󱅠";
}

.mdi-patio-heater:before {
  content: "󰾀";
}

.mdi-patreon:before {
  content: "󰢂";
}

.mdi-pause:before {
  content: "󰏤";
}

.mdi-pause-box:before {
  content: "󰂼";
}

.mdi-pause-box-outline:before {
  content: "󱭺";
}

.mdi-pause-circle:before {
  content: "󰏥";
}

.mdi-pause-circle-outline:before {
  content: "󰏦";
}

.mdi-pause-octagon:before {
  content: "󰏧";
}

.mdi-pause-octagon-outline:before {
  content: "󰏨";
}

.mdi-paw:before {
  content: "󰏩";
}

.mdi-paw-off:before {
  content: "󰙗";
}

.mdi-paw-off-outline:before {
  content: "󱙶";
}

.mdi-paw-outline:before {
  content: "󱙵";
}

.mdi-peace:before {
  content: "󰢄";
}

.mdi-peanut:before {
  content: "󰿼";
}

.mdi-peanut-off:before {
  content: "󰿽";
}

.mdi-peanut-off-outline:before {
  content: "󰿿";
}

.mdi-peanut-outline:before {
  content: "󰿾";
}

.mdi-pen:before {
  content: "󰏪";
}

.mdi-pen-lock:before {
  content: "󰷢";
}

.mdi-pen-minus:before {
  content: "󰷣";
}

.mdi-pen-off:before {
  content: "󰷤";
}

.mdi-pen-plus:before {
  content: "󰷥";
}

.mdi-pen-remove:before {
  content: "󰷦";
}

.mdi-pencil:before {
  content: "󰏫";
}

.mdi-pencil-box:before {
  content: "󰏬";
}

.mdi-pencil-box-multiple:before {
  content: "󱅄";
}

.mdi-pencil-box-multiple-outline:before {
  content: "󱅅";
}

.mdi-pencil-box-outline:before {
  content: "󰏭";
}

.mdi-pencil-circle:before {
  content: "󰛿";
}

.mdi-pencil-circle-outline:before {
  content: "󰝶";
}

.mdi-pencil-lock:before {
  content: "󰏮";
}

.mdi-pencil-lock-outline:before {
  content: "󰷧";
}

.mdi-pencil-minus:before {
  content: "󰷨";
}

.mdi-pencil-minus-outline:before {
  content: "󰷩";
}

.mdi-pencil-off:before {
  content: "󰏯";
}

.mdi-pencil-off-outline:before {
  content: "󰷪";
}

.mdi-pencil-outline:before {
  content: "󰲶";
}

.mdi-pencil-plus:before {
  content: "󰷫";
}

.mdi-pencil-plus-outline:before {
  content: "󰷬";
}

.mdi-pencil-remove:before {
  content: "󰷭";
}

.mdi-pencil-remove-outline:before {
  content: "󰷮";
}

.mdi-pencil-ruler:before {
  content: "󱍓";
}

.mdi-pencil-ruler-outline:before {
  content: "󱰑";
}

.mdi-penguin:before {
  content: "󰻀";
}

.mdi-pentagon:before {
  content: "󰜁";
}

.mdi-pentagon-outline:before {
  content: "󰜀";
}

.mdi-pentagram:before {
  content: "󱙧";
}

.mdi-percent:before {
  content: "󰏰";
}

.mdi-percent-box:before {
  content: "󱨂";
}

.mdi-percent-box-outline:before {
  content: "󱨃";
}

.mdi-percent-circle:before {
  content: "󱨄";
}

.mdi-percent-circle-outline:before {
  content: "󱨅";
}

.mdi-percent-outline:before {
  content: "󱉸";
}

.mdi-periodic-table:before {
  content: "󰢶";
}

.mdi-perspective-less:before {
  content: "󰴣";
}

.mdi-perspective-more:before {
  content: "󰴤";
}

.mdi-ph:before {
  content: "󱟅";
}

.mdi-phone:before {
  content: "󰏲";
}

.mdi-phone-alert:before {
  content: "󰼚";
}

.mdi-phone-alert-outline:before {
  content: "󱆎";
}

.mdi-phone-bluetooth:before {
  content: "󰏳";
}

.mdi-phone-bluetooth-outline:before {
  content: "󱆏";
}

.mdi-phone-cancel:before {
  content: "󱂼";
}

.mdi-phone-cancel-outline:before {
  content: "󱆐";
}

.mdi-phone-check:before {
  content: "󱆩";
}

.mdi-phone-check-outline:before {
  content: "󱆪";
}

.mdi-phone-classic:before {
  content: "󰘂";
}

.mdi-phone-classic-off:before {
  content: "󱉹";
}

.mdi-phone-clock:before {
  content: "󱧛";
}

.mdi-phone-dial:before {
  content: "󱕙";
}

.mdi-phone-dial-outline:before {
  content: "󱕚";
}

.mdi-phone-forward:before {
  content: "󰏴";
}

.mdi-phone-forward-outline:before {
  content: "󱆑";
}

.mdi-phone-hangup:before {
  content: "󰏵";
}

.mdi-phone-hangup-outline:before {
  content: "󱆒";
}

.mdi-phone-in-talk:before {
  content: "󰏶";
}

.mdi-phone-in-talk-outline:before {
  content: "󱆂";
}

.mdi-phone-incoming:before {
  content: "󰏷";
}

.mdi-phone-incoming-outgoing:before {
  content: "󱬿";
}

.mdi-phone-incoming-outgoing-outline:before {
  content: "󱭀";
}

.mdi-phone-incoming-outline:before {
  content: "󱆓";
}

.mdi-phone-lock:before {
  content: "󰏸";
}

.mdi-phone-lock-outline:before {
  content: "󱆔";
}

.mdi-phone-log:before {
  content: "󰏹";
}

.mdi-phone-log-outline:before {
  content: "󱆕";
}

.mdi-phone-message:before {
  content: "󱆖";
}

.mdi-phone-message-outline:before {
  content: "󱆗";
}

.mdi-phone-minus:before {
  content: "󰙘";
}

.mdi-phone-minus-outline:before {
  content: "󱆘";
}

.mdi-phone-missed:before {
  content: "󰏺";
}

.mdi-phone-missed-outline:before {
  content: "󱆥";
}

.mdi-phone-off:before {
  content: "󰷯";
}

.mdi-phone-off-outline:before {
  content: "󱆦";
}

.mdi-phone-outgoing:before {
  content: "󰏻";
}

.mdi-phone-outgoing-outline:before {
  content: "󱆙";
}

.mdi-phone-outline:before {
  content: "󰷰";
}

.mdi-phone-paused:before {
  content: "󰏼";
}

.mdi-phone-paused-outline:before {
  content: "󱆚";
}

.mdi-phone-plus:before {
  content: "󰙙";
}

.mdi-phone-plus-outline:before {
  content: "󱆛";
}

.mdi-phone-refresh:before {
  content: "󱦓";
}

.mdi-phone-refresh-outline:before {
  content: "󱦔";
}

.mdi-phone-remove:before {
  content: "󱔯";
}

.mdi-phone-remove-outline:before {
  content: "󱔰";
}

.mdi-phone-return:before {
  content: "󰠯";
}

.mdi-phone-return-outline:before {
  content: "󱆜";
}

.mdi-phone-ring:before {
  content: "󱆫";
}

.mdi-phone-ring-outline:before {
  content: "󱆬";
}

.mdi-phone-rotate-landscape:before {
  content: "󰢅";
}

.mdi-phone-rotate-portrait:before {
  content: "󰢆";
}

.mdi-phone-settings:before {
  content: "󰏽";
}

.mdi-phone-settings-outline:before {
  content: "󱆝";
}

.mdi-phone-sync:before {
  content: "󱦕";
}

.mdi-phone-sync-outline:before {
  content: "󱦖";
}

.mdi-phone-voip:before {
  content: "󰏾";
}

.mdi-pi:before {
  content: "󰏿";
}

.mdi-pi-box:before {
  content: "󰐀";
}

.mdi-pi-hole:before {
  content: "󰷱";
}

.mdi-piano:before {
  content: "󰙽";
}

.mdi-piano-off:before {
  content: "󰚘";
}

.mdi-pickaxe:before {
  content: "󰢷";
}

.mdi-picture-in-picture-bottom-right:before {
  content: "󰹗";
}

.mdi-picture-in-picture-bottom-right-outline:before {
  content: "󰹘";
}

.mdi-picture-in-picture-top-right:before {
  content: "󰹙";
}

.mdi-picture-in-picture-top-right-outline:before {
  content: "󰹚";
}

.mdi-pier:before {
  content: "󰢇";
}

.mdi-pier-crane:before {
  content: "󰢈";
}

.mdi-pig:before {
  content: "󰐁";
}

.mdi-pig-variant:before {
  content: "󱀆";
}

.mdi-pig-variant-outline:before {
  content: "󱙸";
}

.mdi-piggy-bank:before {
  content: "󱀇";
}

.mdi-piggy-bank-outline:before {
  content: "󱙹";
}

.mdi-pill:before {
  content: "󰐂";
}

.mdi-pill-multiple:before {
  content: "󱭌";
}

.mdi-pill-off:before {
  content: "󱩜";
}

.mdi-pillar:before {
  content: "󰜂";
}

.mdi-pin:before {
  content: "󰐃";
}

.mdi-pin-off:before {
  content: "󰐄";
}

.mdi-pin-off-outline:before {
  content: "󰤰";
}

.mdi-pin-outline:before {
  content: "󰤱";
}

.mdi-pine-tree:before {
  content: "󰐅";
}

.mdi-pine-tree-box:before {
  content: "󰐆";
}

.mdi-pine-tree-fire:before {
  content: "󱐚";
}

.mdi-pine-tree-variant:before {
  content: "󱱳";
}

.mdi-pine-tree-variant-outline:before {
  content: "󱱴";
}

.mdi-pinterest:before {
  content: "󰐇";
}

.mdi-pinwheel:before {
  content: "󰫕";
}

.mdi-pinwheel-outline:before {
  content: "󰫖";
}

.mdi-pipe:before {
  content: "󰟥";
}

.mdi-pipe-disconnected:before {
  content: "󰟦";
}

.mdi-pipe-leak:before {
  content: "󰢉";
}

.mdi-pipe-valve:before {
  content: "󱡍";
}

.mdi-pipe-wrench:before {
  content: "󱍔";
}

.mdi-pirate:before {
  content: "󰨈";
}

.mdi-pistol:before {
  content: "󰜃";
}

.mdi-piston:before {
  content: "󰢊";
}

.mdi-pitchfork:before {
  content: "󱕓";
}

.mdi-pizza:before {
  content: "󰐉";
}

.mdi-plane-car:before {
  content: "󱫿";
}

.mdi-plane-train:before {
  content: "󱬀";
}

.mdi-play:before {
  content: "󰐊";
}

.mdi-play-box:before {
  content: "󱉺";
}

.mdi-play-box-edit-outline:before {
  content: "󱰺";
}

.mdi-play-box-lock:before {
  content: "󱨖";
}

.mdi-play-box-lock-open:before {
  content: "󱨗";
}

.mdi-play-box-lock-open-outline:before {
  content: "󱨘";
}

.mdi-play-box-lock-outline:before {
  content: "󱨙";
}

.mdi-play-box-multiple:before {
  content: "󰴙";
}

.mdi-play-box-multiple-outline:before {
  content: "󱏦";
}

.mdi-play-box-outline:before {
  content: "󰐋";
}

.mdi-play-circle:before {
  content: "󰐌";
}

.mdi-play-circle-outline:before {
  content: "󰐍";
}

.mdi-play-network:before {
  content: "󰢋";
}

.mdi-play-network-outline:before {
  content: "󰲷";
}

.mdi-play-outline:before {
  content: "󰼛";
}

.mdi-play-pause:before {
  content: "󰐎";
}

.mdi-play-protected-content:before {
  content: "󰐏";
}

.mdi-play-speed:before {
  content: "󰣿";
}

.mdi-playlist-check:before {
  content: "󰗇";
}

.mdi-playlist-edit:before {
  content: "󰤀";
}

.mdi-playlist-minus:before {
  content: "󰐐";
}

.mdi-playlist-music:before {
  content: "󰲸";
}

.mdi-playlist-music-outline:before {
  content: "󰲹";
}

.mdi-playlist-play:before {
  content: "󰐑";
}

.mdi-playlist-plus:before {
  content: "󰐒";
}

.mdi-playlist-remove:before {
  content: "󰐓";
}

.mdi-playlist-star:before {
  content: "󰷲";
}

.mdi-plex:before {
  content: "󰚺";
}

.mdi-pliers:before {
  content: "󱦤";
}

.mdi-plus:before {
  content: "󰐕";
}

.mdi-plus-box:before {
  content: "󰐖";
}

.mdi-plus-box-multiple:before {
  content: "󰌴";
}

.mdi-plus-box-multiple-outline:before {
  content: "󱅃";
}

.mdi-plus-box-outline:before {
  content: "󰜄";
}

.mdi-plus-circle:before {
  content: "󰐗";
}

.mdi-plus-circle-multiple:before {
  content: "󰍌";
}

.mdi-plus-circle-multiple-outline:before {
  content: "󰐘";
}

.mdi-plus-circle-outline:before {
  content: "󰐙";
}

.mdi-plus-lock:before {
  content: "󱩝";
}

.mdi-plus-lock-open:before {
  content: "󱩞";
}

.mdi-plus-minus:before {
  content: "󰦒";
}

.mdi-plus-minus-box:before {
  content: "󰦓";
}

.mdi-plus-minus-variant:before {
  content: "󱓉";
}

.mdi-plus-network:before {
  content: "󰐚";
}

.mdi-plus-network-outline:before {
  content: "󰲺";
}

.mdi-plus-outline:before {
  content: "󰜅";
}

.mdi-plus-thick:before {
  content: "󱇬";
}

.mdi-pocket:before {
  content: "󱲾";
}

.mdi-podcast:before {
  content: "󰦔";
}

.mdi-podium:before {
  content: "󰴥";
}

.mdi-podium-bronze:before {
  content: "󰴦";
}

.mdi-podium-gold:before {
  content: "󰴧";
}

.mdi-podium-silver:before {
  content: "󰴨";
}

.mdi-point-of-sale:before {
  content: "󰶒";
}

.mdi-pokeball:before {
  content: "󰐝";
}

.mdi-pokemon-go:before {
  content: "󰨉";
}

.mdi-poker-chip:before {
  content: "󰠰";
}

.mdi-polaroid:before {
  content: "󰐞";
}

.mdi-police-badge:before {
  content: "󱅧";
}

.mdi-police-badge-outline:before {
  content: "󱅨";
}

.mdi-police-station:before {
  content: "󱠹";
}

.mdi-poll:before {
  content: "󰐟";
}

.mdi-polo:before {
  content: "󱓃";
}

.mdi-polymer:before {
  content: "󰐡";
}

.mdi-pool:before {
  content: "󰘆";
}

.mdi-pool-thermometer:before {
  content: "󱩟";
}

.mdi-popcorn:before {
  content: "󰐢";
}

.mdi-post:before {
  content: "󱀈";
}

.mdi-post-lamp:before {
  content: "󱩠";
}

.mdi-post-outline:before {
  content: "󱀉";
}

.mdi-postage-stamp:before {
  content: "󰲻";
}

.mdi-pot:before {
  content: "󰋥";
}

.mdi-pot-mix:before {
  content: "󰙛";
}

.mdi-pot-mix-outline:before {
  content: "󰙷";
}

.mdi-pot-outline:before {
  content: "󰋿";
}

.mdi-pot-steam:before {
  content: "󰙚";
}

.mdi-pot-steam-outline:before {
  content: "󰌦";
}

.mdi-pound:before {
  content: "󰐣";
}

.mdi-pound-box:before {
  content: "󰐤";
}

.mdi-pound-box-outline:before {
  content: "󱅿";
}

.mdi-power:before {
  content: "󰐥";
}

.mdi-power-cycle:before {
  content: "󰤁";
}

.mdi-power-off:before {
  content: "󰤂";
}

.mdi-power-on:before {
  content: "󰤃";
}

.mdi-power-plug:before {
  content: "󰚥";
}

.mdi-power-plug-battery:before {
  content: "󱰻";
}

.mdi-power-plug-battery-outline:before {
  content: "󱰼";
}

.mdi-power-plug-off:before {
  content: "󰚦";
}

.mdi-power-plug-off-outline:before {
  content: "󱐤";
}

.mdi-power-plug-outline:before {
  content: "󱐥";
}

.mdi-power-settings:before {
  content: "󰐦";
}

.mdi-power-sleep:before {
  content: "󰤄";
}

.mdi-power-socket:before {
  content: "󰐧";
}

.mdi-power-socket-au:before {
  content: "󰤅";
}

.mdi-power-socket-ch:before {
  content: "󰾳";
}

.mdi-power-socket-de:before {
  content: "󱄇";
}

.mdi-power-socket-eu:before {
  content: "󰟧";
}

.mdi-power-socket-fr:before {
  content: "󱄈";
}

.mdi-power-socket-it:before {
  content: "󱓿";
}

.mdi-power-socket-jp:before {
  content: "󱄉";
}

.mdi-power-socket-uk:before {
  content: "󰟨";
}

.mdi-power-socket-us:before {
  content: "󰟩";
}

.mdi-power-standby:before {
  content: "󰤆";
}

.mdi-powershell:before {
  content: "󰨊";
}

.mdi-prescription:before {
  content: "󰜆";
}

.mdi-presentation:before {
  content: "󰐨";
}

.mdi-presentation-play:before {
  content: "󰐩";
}

.mdi-pretzel:before {
  content: "󱕢";
}

.mdi-printer:before {
  content: "󰐪";
}

.mdi-printer-3d:before {
  content: "󰐫";
}

.mdi-printer-3d-nozzle:before {
  content: "󰹛";
}

.mdi-printer-3d-nozzle-alert:before {
  content: "󱇀";
}

.mdi-printer-3d-nozzle-alert-outline:before {
  content: "󱇁";
}

.mdi-printer-3d-nozzle-heat:before {
  content: "󱢸";
}

.mdi-printer-3d-nozzle-heat-outline:before {
  content: "󱢹";
}

.mdi-printer-3d-nozzle-off:before {
  content: "󱬙";
}

.mdi-printer-3d-nozzle-off-outline:before {
  content: "󱬚";
}

.mdi-printer-3d-nozzle-outline:before {
  content: "󰹜";
}

.mdi-printer-3d-off:before {
  content: "󱬎";
}

.mdi-printer-alert:before {
  content: "󰐬";
}

.mdi-printer-check:before {
  content: "󱅆";
}

.mdi-printer-eye:before {
  content: "󱑘";
}

.mdi-printer-off:before {
  content: "󰹝";
}

.mdi-printer-off-outline:before {
  content: "󱞅";
}

.mdi-printer-outline:before {
  content: "󱞆";
}

.mdi-printer-pos:before {
  content: "󱁗";
}

.mdi-printer-pos-alert:before {
  content: "󱮼";
}

.mdi-printer-pos-alert-outline:before {
  content: "󱮽";
}

.mdi-printer-pos-cancel:before {
  content: "󱮾";
}

.mdi-printer-pos-cancel-outline:before {
  content: "󱮿";
}

.mdi-printer-pos-check:before {
  content: "󱯀";
}

.mdi-printer-pos-check-outline:before {
  content: "󱯁";
}

.mdi-printer-pos-cog:before {
  content: "󱯂";
}

.mdi-printer-pos-cog-outline:before {
  content: "󱯃";
}

.mdi-printer-pos-edit:before {
  content: "󱯄";
}

.mdi-printer-pos-edit-outline:before {
  content: "󱯅";
}

.mdi-printer-pos-minus:before {
  content: "󱯆";
}

.mdi-printer-pos-minus-outline:before {
  content: "󱯇";
}

.mdi-printer-pos-network:before {
  content: "󱯈";
}

.mdi-printer-pos-network-outline:before {
  content: "󱯉";
}

.mdi-printer-pos-off:before {
  content: "󱯊";
}

.mdi-printer-pos-off-outline:before {
  content: "󱯋";
}

.mdi-printer-pos-outline:before {
  content: "󱯌";
}

.mdi-printer-pos-pause:before {
  content: "󱯍";
}

.mdi-printer-pos-pause-outline:before {
  content: "󱯎";
}

.mdi-printer-pos-play:before {
  content: "󱯏";
}

.mdi-printer-pos-play-outline:before {
  content: "󱯐";
}

.mdi-printer-pos-plus:before {
  content: "󱯑";
}

.mdi-printer-pos-plus-outline:before {
  content: "󱯒";
}

.mdi-printer-pos-refresh:before {
  content: "󱯓";
}

.mdi-printer-pos-refresh-outline:before {
  content: "󱯔";
}

.mdi-printer-pos-remove:before {
  content: "󱯕";
}

.mdi-printer-pos-remove-outline:before {
  content: "󱯖";
}

.mdi-printer-pos-star:before {
  content: "󱯗";
}

.mdi-printer-pos-star-outline:before {
  content: "󱯘";
}

.mdi-printer-pos-stop:before {
  content: "󱯙";
}

.mdi-printer-pos-stop-outline:before {
  content: "󱯚";
}

.mdi-printer-pos-sync:before {
  content: "󱯛";
}

.mdi-printer-pos-sync-outline:before {
  content: "󱯜";
}

.mdi-printer-pos-wrench:before {
  content: "󱯝";
}

.mdi-printer-pos-wrench-outline:before {
  content: "󱯞";
}

.mdi-printer-search:before {
  content: "󱑗";
}

.mdi-printer-settings:before {
  content: "󰜇";
}

.mdi-printer-wireless:before {
  content: "󰨋";
}

.mdi-priority-high:before {
  content: "󰘃";
}

.mdi-priority-low:before {
  content: "󰘄";
}

.mdi-professional-hexagon:before {
  content: "󰐭";
}

.mdi-progress-alert:before {
  content: "󰲼";
}

.mdi-progress-check:before {
  content: "󰦕";
}

.mdi-progress-clock:before {
  content: "󰦖";
}

.mdi-progress-close:before {
  content: "󱄊";
}

.mdi-progress-download:before {
  content: "󰦗";
}

.mdi-progress-helper:before {
  content: "󱮢";
}

.mdi-progress-pencil:before {
  content: "󱞇";
}

.mdi-progress-question:before {
  content: "󱔢";
}

.mdi-progress-star:before {
  content: "󱞈";
}

.mdi-progress-star-four-points:before {
  content: "󱰽";
}

.mdi-progress-tag:before {
  content: "󱴍";
}

.mdi-progress-upload:before {
  content: "󰦘";
}

.mdi-progress-wrench:before {
  content: "󰲽";
}

.mdi-projector:before {
  content: "󰐮";
}

.mdi-projector-off:before {
  content: "󱨣";
}

.mdi-projector-screen:before {
  content: "󰐯";
}

.mdi-projector-screen-off:before {
  content: "󱠍";
}

.mdi-projector-screen-off-outline:before {
  content: "󱠎";
}

.mdi-projector-screen-outline:before {
  content: "󱜤";
}

.mdi-projector-screen-variant:before {
  content: "󱠏";
}

.mdi-projector-screen-variant-off:before {
  content: "󱠐";
}

.mdi-projector-screen-variant-off-outline:before {
  content: "󱠑";
}

.mdi-projector-screen-variant-outline:before {
  content: "󱠒";
}

.mdi-propane-tank:before {
  content: "󱍗";
}

.mdi-propane-tank-outline:before {
  content: "󱍘";
}

.mdi-protocol:before {
  content: "󰿘";
}

.mdi-publish:before {
  content: "󰚧";
}

.mdi-publish-off:before {
  content: "󱥅";
}

.mdi-pulse:before {
  content: "󰐰";
}

.mdi-pump:before {
  content: "󱐂";
}

.mdi-pump-off:before {
  content: "󱬢";
}

.mdi-pumpkin:before {
  content: "󰮿";
}

.mdi-purse:before {
  content: "󰼜";
}

.mdi-purse-outline:before {
  content: "󰼝";
}

.mdi-puzzle:before {
  content: "󰐱";
}

.mdi-puzzle-check:before {
  content: "󱐦";
}

.mdi-puzzle-check-outline:before {
  content: "󱐧";
}

.mdi-puzzle-edit:before {
  content: "󱓓";
}

.mdi-puzzle-edit-outline:before {
  content: "󱓙";
}

.mdi-puzzle-heart:before {
  content: "󱓔";
}

.mdi-puzzle-heart-outline:before {
  content: "󱓚";
}

.mdi-puzzle-minus:before {
  content: "󱓑";
}

.mdi-puzzle-minus-outline:before {
  content: "󱓗";
}

.mdi-puzzle-outline:before {
  content: "󰩦";
}

.mdi-puzzle-plus:before {
  content: "󱓐";
}

.mdi-puzzle-plus-outline:before {
  content: "󱓖";
}

.mdi-puzzle-remove:before {
  content: "󱓒";
}

.mdi-puzzle-remove-outline:before {
  content: "󱓘";
}

.mdi-puzzle-star:before {
  content: "󱓕";
}

.mdi-puzzle-star-outline:before {
  content: "󱓛";
}

.mdi-pyramid:before {
  content: "󱥒";
}

.mdi-pyramid-off:before {
  content: "󱥓";
}

.mdi-qi:before {
  content: "󰦙";
}

.mdi-qqchat:before {
  content: "󰘅";
}

.mdi-qrcode:before {
  content: "󰐲";
}

.mdi-qrcode-edit:before {
  content: "󰢸";
}

.mdi-qrcode-minus:before {
  content: "󱆌";
}

.mdi-qrcode-plus:before {
  content: "󱆋";
}

.mdi-qrcode-remove:before {
  content: "󱆍";
}

.mdi-qrcode-scan:before {
  content: "󰐳";
}

.mdi-quadcopter:before {
  content: "󰐴";
}

.mdi-quality-high:before {
  content: "󰐵";
}

.mdi-quality-low:before {
  content: "󰨌";
}

.mdi-quality-medium:before {
  content: "󰨍";
}

.mdi-queue-first-in-last-out:before {
  content: "󱲯";
}

.mdi-quora:before {
  content: "󰴩";
}

.mdi-rabbit:before {
  content: "󰤇";
}

.mdi-rabbit-variant:before {
  content: "󱩡";
}

.mdi-rabbit-variant-outline:before {
  content: "󱩢";
}

.mdi-racing-helmet:before {
  content: "󰶓";
}

.mdi-racquetball:before {
  content: "󰶔";
}

.mdi-radar:before {
  content: "󰐷";
}

.mdi-radiator:before {
  content: "󰐸";
}

.mdi-radiator-disabled:before {
  content: "󰫗";
}

.mdi-radiator-off:before {
  content: "󰫘";
}

.mdi-radio:before {
  content: "󰐹";
}

.mdi-radio-am:before {
  content: "󰲾";
}

.mdi-radio-fm:before {
  content: "󰲿";
}

.mdi-radio-handheld:before {
  content: "󰐺";
}

.mdi-radio-off:before {
  content: "󱈜";
}

.mdi-radio-tower:before {
  content: "󰐻";
}

.mdi-radioactive:before {
  content: "󰐼";
}

.mdi-radioactive-circle:before {
  content: "󱡝";
}

.mdi-radioactive-circle-outline:before {
  content: "󱡞";
}

.mdi-radioactive-off:before {
  content: "󰻁";
}

.mdi-radiobox-blank:before {
  content: "󰐽";
}

.mdi-radiobox-indeterminate-variant:before {
  content: "󱱞";
}

.mdi-radiobox-marked:before {
  content: "󰐾";
}

.mdi-radiology-box:before {
  content: "󱓅";
}

.mdi-radiology-box-outline:before {
  content: "󱓆";
}

.mdi-radius:before {
  content: "󰳀";
}

.mdi-radius-outline:before {
  content: "󰳁";
}

.mdi-railroad-light:before {
  content: "󰼞";
}

.mdi-rake:before {
  content: "󱕄";
}

.mdi-raspberry-pi:before {
  content: "󰐿";
}

.mdi-raw:before {
  content: "󱨏";
}

.mdi-raw-off:before {
  content: "󱨐";
}

.mdi-ray-end:before {
  content: "󰑀";
}

.mdi-ray-end-arrow:before {
  content: "󰑁";
}

.mdi-ray-start:before {
  content: "󰑂";
}

.mdi-ray-start-arrow:before {
  content: "󰑃";
}

.mdi-ray-start-end:before {
  content: "󰑄";
}

.mdi-ray-start-vertex-end:before {
  content: "󱗘";
}

.mdi-ray-vertex:before {
  content: "󰑅";
}

.mdi-razor-double-edge:before {
  content: "󱦗";
}

.mdi-razor-single-edge:before {
  content: "󱦘";
}

.mdi-react:before {
  content: "󰜈";
}

.mdi-read:before {
  content: "󰑇";
}

.mdi-receipt:before {
  content: "󰠤";
}

.mdi-receipt-clock:before {
  content: "󱰾";
}

.mdi-receipt-clock-outline:before {
  content: "󱰿";
}

.mdi-receipt-outline:before {
  content: "󰓷";
}

.mdi-receipt-send:before {
  content: "󱱀";
}

.mdi-receipt-send-outline:before {
  content: "󱱁";
}

.mdi-receipt-text:before {
  content: "󰑉";
}

.mdi-receipt-text-arrow-left:before {
  content: "󱱂";
}

.mdi-receipt-text-arrow-left-outline:before {
  content: "󱱃";
}

.mdi-receipt-text-arrow-right:before {
  content: "󱱄";
}

.mdi-receipt-text-arrow-right-outline:before {
  content: "󱱅";
}

.mdi-receipt-text-check:before {
  content: "󱩣";
}

.mdi-receipt-text-check-outline:before {
  content: "󱩤";
}

.mdi-receipt-text-clock:before {
  content: "󱱆";
}

.mdi-receipt-text-clock-outline:before {
  content: "󱱇";
}

.mdi-receipt-text-edit:before {
  content: "󱱈";
}

.mdi-receipt-text-edit-outline:before {
  content: "󱱉";
}

.mdi-receipt-text-minus:before {
  content: "󱩥";
}

.mdi-receipt-text-minus-outline:before {
  content: "󱩦";
}

.mdi-receipt-text-outline:before {
  content: "󱧜";
}

.mdi-receipt-text-plus:before {
  content: "󱩧";
}

.mdi-receipt-text-plus-outline:before {
  content: "󱩨";
}

.mdi-receipt-text-remove:before {
  content: "󱩩";
}

.mdi-receipt-text-remove-outline:before {
  content: "󱩪";
}

.mdi-receipt-text-send:before {
  content: "󱱊";
}

.mdi-receipt-text-send-outline:before {
  content: "󱱋";
}

.mdi-record:before {
  content: "󰑊";
}

.mdi-record-circle:before {
  content: "󰻂";
}

.mdi-record-circle-outline:before {
  content: "󰻃";
}

.mdi-record-player:before {
  content: "󰦚";
}

.mdi-record-rec:before {
  content: "󰑋";
}

.mdi-rectangle:before {
  content: "󰹞";
}

.mdi-rectangle-outline:before {
  content: "󰹟";
}

.mdi-recycle:before {
  content: "󰑌";
}

.mdi-recycle-variant:before {
  content: "󱎝";
}

.mdi-reddit:before {
  content: "󰑍";
}

.mdi-redhat:before {
  content: "󱄛";
}

.mdi-redo:before {
  content: "󰑎";
}

.mdi-redo-variant:before {
  content: "󰑏";
}

.mdi-reflect-horizontal:before {
  content: "󰨎";
}

.mdi-reflect-vertical:before {
  content: "󰨏";
}

.mdi-refresh:before {
  content: "󰑐";
}

.mdi-refresh-auto:before {
  content: "󱣲";
}

.mdi-refresh-circle:before {
  content: "󱍷";
}

.mdi-regex:before {
  content: "󰑑";
}

.mdi-registered-trademark:before {
  content: "󰩧";
}

.mdi-reiterate:before {
  content: "󱖈";
}

.mdi-relation-many-to-many:before {
  content: "󱒖";
}

.mdi-relation-many-to-one:before {
  content: "󱒗";
}

.mdi-relation-many-to-one-or-many:before {
  content: "󱒘";
}

.mdi-relation-many-to-only-one:before {
  content: "󱒙";
}

.mdi-relation-many-to-zero-or-many:before {
  content: "󱒚";
}

.mdi-relation-many-to-zero-or-one:before {
  content: "󱒛";
}

.mdi-relation-one-or-many-to-many:before {
  content: "󱒜";
}

.mdi-relation-one-or-many-to-one:before {
  content: "󱒝";
}

.mdi-relation-one-or-many-to-one-or-many:before {
  content: "󱒞";
}

.mdi-relation-one-or-many-to-only-one:before {
  content: "󱒟";
}

.mdi-relation-one-or-many-to-zero-or-many:before {
  content: "󱒠";
}

.mdi-relation-one-or-many-to-zero-or-one:before {
  content: "󱒡";
}

.mdi-relation-one-to-many:before {
  content: "󱒢";
}

.mdi-relation-one-to-one:before {
  content: "󱒣";
}

.mdi-relation-one-to-one-or-many:before {
  content: "󱒤";
}

.mdi-relation-one-to-only-one:before {
  content: "󱒥";
}

.mdi-relation-one-to-zero-or-many:before {
  content: "󱒦";
}

.mdi-relation-one-to-zero-or-one:before {
  content: "󱒧";
}

.mdi-relation-only-one-to-many:before {
  content: "󱒨";
}

.mdi-relation-only-one-to-one:before {
  content: "󱒩";
}

.mdi-relation-only-one-to-one-or-many:before {
  content: "󱒪";
}

.mdi-relation-only-one-to-only-one:before {
  content: "󱒫";
}

.mdi-relation-only-one-to-zero-or-many:before {
  content: "󱒬";
}

.mdi-relation-only-one-to-zero-or-one:before {
  content: "󱒭";
}

.mdi-relation-zero-or-many-to-many:before {
  content: "󱒮";
}

.mdi-relation-zero-or-many-to-one:before {
  content: "󱒯";
}

.mdi-relation-zero-or-many-to-one-or-many:before {
  content: "󱒰";
}

.mdi-relation-zero-or-many-to-only-one:before {
  content: "󱒱";
}

.mdi-relation-zero-or-many-to-zero-or-many:before {
  content: "󱒲";
}

.mdi-relation-zero-or-many-to-zero-or-one:before {
  content: "󱒳";
}

.mdi-relation-zero-or-one-to-many:before {
  content: "󱒴";
}

.mdi-relation-zero-or-one-to-one:before {
  content: "󱒵";
}

.mdi-relation-zero-or-one-to-one-or-many:before {
  content: "󱒶";
}

.mdi-relation-zero-or-one-to-only-one:before {
  content: "󱒷";
}

.mdi-relation-zero-or-one-to-zero-or-many:before {
  content: "󱒸";
}

.mdi-relation-zero-or-one-to-zero-or-one:before {
  content: "󱒹";
}

.mdi-relative-scale:before {
  content: "󰑒";
}

.mdi-reload:before {
  content: "󰑓";
}

.mdi-reload-alert:before {
  content: "󱄋";
}

.mdi-reminder:before {
  content: "󰢌";
}

.mdi-remote:before {
  content: "󰑔";
}

.mdi-remote-desktop:before {
  content: "󰢹";
}

.mdi-remote-off:before {
  content: "󰻄";
}

.mdi-remote-tv:before {
  content: "󰻅";
}

.mdi-remote-tv-off:before {
  content: "󰻆";
}

.mdi-rename:before {
  content: "󱰘";
}

.mdi-rename-box:before {
  content: "󰑕";
}

.mdi-rename-box-outline:before {
  content: "󱰙";
}

.mdi-rename-outline:before {
  content: "󱰚";
}

.mdi-reorder-horizontal:before {
  content: "󰚈";
}

.mdi-reorder-vertical:before {
  content: "󰚉";
}

.mdi-repeat:before {
  content: "󰑖";
}

.mdi-repeat-off:before {
  content: "󰑗";
}

.mdi-repeat-once:before {
  content: "󰑘";
}

.mdi-repeat-variant:before {
  content: "󰕇";
}

.mdi-replay:before {
  content: "󰑙";
}

.mdi-reply:before {
  content: "󰑚";
}

.mdi-reply-all:before {
  content: "󰑛";
}

.mdi-reply-all-outline:before {
  content: "󰼟";
}

.mdi-reply-circle:before {
  content: "󱆮";
}

.mdi-reply-outline:before {
  content: "󰼠";
}

.mdi-reproduction:before {
  content: "󰑜";
}

.mdi-resistor:before {
  content: "󰭄";
}

.mdi-resistor-nodes:before {
  content: "󰭅";
}

.mdi-resize:before {
  content: "󰩨";
}

.mdi-resize-bottom-right:before {
  content: "󰑝";
}

.mdi-responsive:before {
  content: "󰑞";
}

.mdi-restart:before {
  content: "󰜉";
}

.mdi-restart-alert:before {
  content: "󱄌";
}

.mdi-restart-off:before {
  content: "󰶕";
}

.mdi-restore:before {
  content: "󰦛";
}

.mdi-restore-alert:before {
  content: "󱄍";
}

.mdi-rewind:before {
  content: "󰑟";
}

.mdi-rewind-10:before {
  content: "󰴪";
}

.mdi-rewind-15:before {
  content: "󱥆";
}

.mdi-rewind-30:before {
  content: "󰶖";
}

.mdi-rewind-45:before {
  content: "󱬓";
}

.mdi-rewind-5:before {
  content: "󱇹";
}

.mdi-rewind-60:before {
  content: "󱘌";
}

.mdi-rewind-outline:before {
  content: "󰜊";
}

.mdi-rhombus:before {
  content: "󰜋";
}

.mdi-rhombus-medium:before {
  content: "󰨐";
}

.mdi-rhombus-medium-outline:before {
  content: "󱓜";
}

.mdi-rhombus-outline:before {
  content: "󰜌";
}

.mdi-rhombus-split:before {
  content: "󰨑";
}

.mdi-rhombus-split-outline:before {
  content: "󱓝";
}

.mdi-ribbon:before {
  content: "󰑠";
}

.mdi-rice:before {
  content: "󰟪";
}

.mdi-rickshaw:before {
  content: "󱖻";
}

.mdi-rickshaw-electric:before {
  content: "󱖼";
}

.mdi-ring:before {
  content: "󰟫";
}

.mdi-rivet:before {
  content: "󰹠";
}

.mdi-road:before {
  content: "󰑡";
}

.mdi-road-variant:before {
  content: "󰑢";
}

.mdi-robber:before {
  content: "󱁘";
}

.mdi-robot:before {
  content: "󰚩";
}

.mdi-robot-angry:before {
  content: "󱚝";
}

.mdi-robot-angry-outline:before {
  content: "󱚞";
}

.mdi-robot-confused:before {
  content: "󱚟";
}

.mdi-robot-confused-outline:before {
  content: "󱚠";
}

.mdi-robot-dead:before {
  content: "󱚡";
}

.mdi-robot-dead-outline:before {
  content: "󱚢";
}

.mdi-robot-excited:before {
  content: "󱚣";
}

.mdi-robot-excited-outline:before {
  content: "󱚤";
}

.mdi-robot-happy:before {
  content: "󱜙";
}

.mdi-robot-happy-outline:before {
  content: "󱜚";
}

.mdi-robot-industrial:before {
  content: "󰭆";
}

.mdi-robot-industrial-outline:before {
  content: "󱨚";
}

.mdi-robot-love:before {
  content: "󱚥";
}

.mdi-robot-love-outline:before {
  content: "󱚦";
}

.mdi-robot-mower:before {
  content: "󱇷";
}

.mdi-robot-mower-outline:before {
  content: "󱇳";
}

.mdi-robot-off:before {
  content: "󱚧";
}

.mdi-robot-off-outline:before {
  content: "󱙻";
}

.mdi-robot-outline:before {
  content: "󱙺";
}

.mdi-robot-vacuum:before {
  content: "󰜍";
}

.mdi-robot-vacuum-alert:before {
  content: "󱭝";
}

.mdi-robot-vacuum-off:before {
  content: "󱰁";
}

.mdi-robot-vacuum-variant:before {
  content: "󰤈";
}

.mdi-robot-vacuum-variant-alert:before {
  content: "󱭞";
}

.mdi-robot-vacuum-variant-off:before {
  content: "󱰂";
}

.mdi-rocket:before {
  content: "󰑣";
}

.mdi-rocket-launch:before {
  content: "󱓞";
}

.mdi-rocket-launch-outline:before {
  content: "󱓟";
}

.mdi-rocket-outline:before {
  content: "󱎯";
}

.mdi-rodent:before {
  content: "󱌧";
}

.mdi-roller-shade:before {
  content: "󱩫";
}

.mdi-roller-shade-closed:before {
  content: "󱩬";
}

.mdi-roller-skate:before {
  content: "󰴫";
}

.mdi-roller-skate-off:before {
  content: "󰅅";
}

.mdi-rollerblade:before {
  content: "󰴬";
}

.mdi-rollerblade-off:before {
  content: "󰀮";
}

.mdi-rollupjs:before {
  content: "󰯀";
}

.mdi-rolodex:before {
  content: "󱪹";
}

.mdi-rolodex-outline:before {
  content: "󱪺";
}

.mdi-roman-numeral-1:before {
  content: "󱂈";
}

.mdi-roman-numeral-10:before {
  content: "󱂑";
}

.mdi-roman-numeral-2:before {
  content: "󱂉";
}

.mdi-roman-numeral-3:before {
  content: "󱂊";
}

.mdi-roman-numeral-4:before {
  content: "󱂋";
}

.mdi-roman-numeral-5:before {
  content: "󱂌";
}

.mdi-roman-numeral-6:before {
  content: "󱂍";
}

.mdi-roman-numeral-7:before {
  content: "󱂎";
}

.mdi-roman-numeral-8:before {
  content: "󱂏";
}

.mdi-roman-numeral-9:before {
  content: "󱂐";
}

.mdi-room-service:before {
  content: "󰢍";
}

.mdi-room-service-outline:before {
  content: "󰶗";
}

.mdi-rotate-360:before {
  content: "󱦙";
}

.mdi-rotate-3d:before {
  content: "󰻇";
}

.mdi-rotate-3d-variant:before {
  content: "󰑤";
}

.mdi-rotate-left:before {
  content: "󰑥";
}

.mdi-rotate-left-variant:before {
  content: "󰑦";
}

.mdi-rotate-orbit:before {
  content: "󰶘";
}

.mdi-rotate-right:before {
  content: "󰑧";
}

.mdi-rotate-right-variant:before {
  content: "󰑨";
}

.mdi-rounded-corner:before {
  content: "󰘇";
}

.mdi-router:before {
  content: "󱇢";
}

.mdi-router-network:before {
  content: "󱂇";
}

.mdi-router-network-wireless:before {
  content: "󱲗";
}

.mdi-router-wireless:before {
  content: "󰑩";
}

.mdi-router-wireless-off:before {
  content: "󱖣";
}

.mdi-router-wireless-settings:before {
  content: "󰩩";
}

.mdi-routes:before {
  content: "󰑪";
}

.mdi-routes-clock:before {
  content: "󱁙";
}

.mdi-rowing:before {
  content: "󰘈";
}

.mdi-rss:before {
  content: "󰑫";
}

.mdi-rss-box:before {
  content: "󰑬";
}

.mdi-rss-off:before {
  content: "󰼡";
}

.mdi-rug:before {
  content: "󱑵";
}

.mdi-rugby:before {
  content: "󰶙";
}

.mdi-ruler:before {
  content: "󰑭";
}

.mdi-ruler-square:before {
  content: "󰳂";
}

.mdi-ruler-square-compass:before {
  content: "󰺾";
}

.mdi-run:before {
  content: "󰜎";
}

.mdi-run-fast:before {
  content: "󰑮";
}

.mdi-rv-truck:before {
  content: "󱇔";
}

.mdi-sack:before {
  content: "󰴮";
}

.mdi-sack-outline:before {
  content: "󱱌";
}

.mdi-sack-percent:before {
  content: "󰴯";
}

.mdi-safe:before {
  content: "󰩪";
}

.mdi-safe-square:before {
  content: "󱉼";
}

.mdi-safe-square-outline:before {
  content: "󱉽";
}

.mdi-safety-goggles:before {
  content: "󰴰";
}

.mdi-sail-boat:before {
  content: "󰻈";
}

.mdi-sail-boat-sink:before {
  content: "󱫯";
}

.mdi-sale:before {
  content: "󰑯";
}

.mdi-sale-outline:before {
  content: "󱨆";
}

.mdi-salesforce:before {
  content: "󰢎";
}

.mdi-sass:before {
  content: "󰟬";
}

.mdi-satellite:before {
  content: "󰑰";
}

.mdi-satellite-uplink:before {
  content: "󰤉";
}

.mdi-satellite-variant:before {
  content: "󰑱";
}

.mdi-sausage:before {
  content: "󰢺";
}

.mdi-sausage-off:before {
  content: "󱞉";
}

.mdi-saw-blade:before {
  content: "󰹡";
}

.mdi-sawtooth-wave:before {
  content: "󱑺";
}

.mdi-saxophone:before {
  content: "󰘉";
}

.mdi-scale:before {
  content: "󰑲";
}

.mdi-scale-balance:before {
  content: "󰗑";
}

.mdi-scale-bathroom:before {
  content: "󰑳";
}

.mdi-scale-off:before {
  content: "󱁚";
}

.mdi-scale-unbalanced:before {
  content: "󱦸";
}

.mdi-scan-helper:before {
  content: "󱏘";
}

.mdi-scanner:before {
  content: "󰚫";
}

.mdi-scanner-off:before {
  content: "󰤊";
}

.mdi-scatter-plot:before {
  content: "󰻉";
}

.mdi-scatter-plot-outline:before {
  content: "󰻊";
}

.mdi-scent:before {
  content: "󱥘";
}

.mdi-scent-off:before {
  content: "󱥙";
}

.mdi-school:before {
  content: "󰑴";
}

.mdi-school-outline:before {
  content: "󱆀";
}

.mdi-scissors-cutting:before {
  content: "󰩫";
}

.mdi-scooter:before {
  content: "󱖽";
}

.mdi-scooter-electric:before {
  content: "󱖾";
}

.mdi-scoreboard:before {
  content: "󱉾";
}

.mdi-scoreboard-outline:before {
  content: "󱉿";
}

.mdi-screen-rotation:before {
  content: "󰑵";
}

.mdi-screen-rotation-lock:before {
  content: "󰑸";
}

.mdi-screw-flat-top:before {
  content: "󰷳";
}

.mdi-screw-lag:before {
  content: "󰷴";
}

.mdi-screw-machine-flat-top:before {
  content: "󰷵";
}

.mdi-screw-machine-round-top:before {
  content: "󰷶";
}

.mdi-screw-round-top:before {
  content: "󰷷";
}

.mdi-screwdriver:before {
  content: "󰑶";
}

.mdi-script:before {
  content: "󰯁";
}

.mdi-script-outline:before {
  content: "󰑷";
}

.mdi-script-text:before {
  content: "󰯂";
}

.mdi-script-text-key:before {
  content: "󱜥";
}

.mdi-script-text-key-outline:before {
  content: "󱜦";
}

.mdi-script-text-outline:before {
  content: "󰯃";
}

.mdi-script-text-play:before {
  content: "󱜧";
}

.mdi-script-text-play-outline:before {
  content: "󱜨";
}

.mdi-sd:before {
  content: "󰑹";
}

.mdi-seal:before {
  content: "󰑺";
}

.mdi-seal-variant:before {
  content: "󰿙";
}

.mdi-search-web:before {
  content: "󰜏";
}

.mdi-seat:before {
  content: "󰳃";
}

.mdi-seat-flat:before {
  content: "󰑻";
}

.mdi-seat-flat-angled:before {
  content: "󰑼";
}

.mdi-seat-individual-suite:before {
  content: "󰑽";
}

.mdi-seat-legroom-extra:before {
  content: "󰑾";
}

.mdi-seat-legroom-normal:before {
  content: "󰑿";
}

.mdi-seat-legroom-reduced:before {
  content: "󰒀";
}

.mdi-seat-outline:before {
  content: "󰳄";
}

.mdi-seat-passenger:before {
  content: "󱉉";
}

.mdi-seat-recline-extra:before {
  content: "󰒁";
}

.mdi-seat-recline-normal:before {
  content: "󰒂";
}

.mdi-seatbelt:before {
  content: "󰳅";
}

.mdi-security:before {
  content: "󰒃";
}

.mdi-security-network:before {
  content: "󰒄";
}

.mdi-seed:before {
  content: "󰹢";
}

.mdi-seed-off:before {
  content: "󱏽";
}

.mdi-seed-off-outline:before {
  content: "󱏾";
}

.mdi-seed-outline:before {
  content: "󰹣";
}

.mdi-seed-plus:before {
  content: "󱩭";
}

.mdi-seed-plus-outline:before {
  content: "󱩮";
}

.mdi-seesaw:before {
  content: "󱖤";
}

.mdi-segment:before {
  content: "󰻋";
}

.mdi-select:before {
  content: "󰒅";
}

.mdi-select-all:before {
  content: "󰒆";
}

.mdi-select-arrow-down:before {
  content: "󱭙";
}

.mdi-select-arrow-up:before {
  content: "󱭘";
}

.mdi-select-color:before {
  content: "󰴱";
}

.mdi-select-compare:before {
  content: "󰫙";
}

.mdi-select-drag:before {
  content: "󰩬";
}

.mdi-select-group:before {
  content: "󰾂";
}

.mdi-select-inverse:before {
  content: "󰒇";
}

.mdi-select-marker:before {
  content: "󱊀";
}

.mdi-select-multiple:before {
  content: "󱊁";
}

.mdi-select-multiple-marker:before {
  content: "󱊂";
}

.mdi-select-off:before {
  content: "󰒈";
}

.mdi-select-place:before {
  content: "󰿚";
}

.mdi-select-remove:before {
  content: "󱟁";
}

.mdi-select-search:before {
  content: "󱈄";
}

.mdi-selection:before {
  content: "󰒉";
}

.mdi-selection-drag:before {
  content: "󰩭";
}

.mdi-selection-ellipse:before {
  content: "󰴲";
}

.mdi-selection-ellipse-arrow-inside:before {
  content: "󰼢";
}

.mdi-selection-ellipse-remove:before {
  content: "󱟂";
}

.mdi-selection-marker:before {
  content: "󱊃";
}

.mdi-selection-multiple:before {
  content: "󱊅";
}

.mdi-selection-multiple-marker:before {
  content: "󱊄";
}

.mdi-selection-off:before {
  content: "󰝷";
}

.mdi-selection-remove:before {
  content: "󱟃";
}

.mdi-selection-search:before {
  content: "󱈅";
}

.mdi-semantic-web:before {
  content: "󱌖";
}

.mdi-send:before {
  content: "󰒊";
}

.mdi-send-check:before {
  content: "󱅡";
}

.mdi-send-check-outline:before {
  content: "󱅢";
}

.mdi-send-circle:before {
  content: "󰷸";
}

.mdi-send-circle-outline:before {
  content: "󰷹";
}

.mdi-send-clock:before {
  content: "󱅣";
}

.mdi-send-clock-outline:before {
  content: "󱅤";
}

.mdi-send-lock:before {
  content: "󰟭";
}

.mdi-send-lock-outline:before {
  content: "󱅦";
}

.mdi-send-outline:before {
  content: "󱅥";
}

.mdi-send-variant:before {
  content: "󱱍";
}

.mdi-send-variant-clock:before {
  content: "󱱾";
}

.mdi-send-variant-clock-outline:before {
  content: "󱱿";
}

.mdi-send-variant-outline:before {
  content: "󱱎";
}

.mdi-serial-port:before {
  content: "󰙜";
}

.mdi-server:before {
  content: "󰒋";
}

.mdi-server-minus:before {
  content: "󰒌";
}

.mdi-server-minus-outline:before {
  content: "󱲘";
}

.mdi-server-network:before {
  content: "󰒍";
}

.mdi-server-network-off:before {
  content: "󰒎";
}

.mdi-server-network-outline:before {
  content: "󱲙";
}

.mdi-server-off:before {
  content: "󰒏";
}

.mdi-server-outline:before {
  content: "󱲚";
}

.mdi-server-plus:before {
  content: "󰒐";
}

.mdi-server-plus-outline:before {
  content: "󱲛";
}

.mdi-server-remove:before {
  content: "󰒑";
}

.mdi-server-security:before {
  content: "󰒒";
}

.mdi-set-all:before {
  content: "󰝸";
}

.mdi-set-center:before {
  content: "󰝹";
}

.mdi-set-center-right:before {
  content: "󰝺";
}

.mdi-set-left:before {
  content: "󰝻";
}

.mdi-set-left-center:before {
  content: "󰝼";
}

.mdi-set-left-right:before {
  content: "󰝽";
}

.mdi-set-merge:before {
  content: "󱓠";
}

.mdi-set-none:before {
  content: "󰝾";
}

.mdi-set-right:before {
  content: "󰝿";
}

.mdi-set-split:before {
  content: "󱓡";
}

.mdi-set-square:before {
  content: "󱑝";
}

.mdi-set-top-box:before {
  content: "󰦟";
}

.mdi-settings-helper:before {
  content: "󰩮";
}

.mdi-shaker:before {
  content: "󱄎";
}

.mdi-shaker-outline:before {
  content: "󱄏";
}

.mdi-shape:before {
  content: "󰠱";
}

.mdi-shape-circle-plus:before {
  content: "󰙝";
}

.mdi-shape-outline:before {
  content: "󰠲";
}

.mdi-shape-oval-plus:before {
  content: "󱇺";
}

.mdi-shape-plus:before {
  content: "󰒕";
}

.mdi-shape-plus-outline:before {
  content: "󱱏";
}

.mdi-shape-polygon-plus:before {
  content: "󰙞";
}

.mdi-shape-rectangle-plus:before {
  content: "󰙟";
}

.mdi-shape-square-plus:before {
  content: "󰙠";
}

.mdi-shape-square-rounded-plus:before {
  content: "󱓺";
}

.mdi-share:before {
  content: "󰒖";
}

.mdi-share-all:before {
  content: "󱇴";
}

.mdi-share-all-outline:before {
  content: "󱇵";
}

.mdi-share-circle:before {
  content: "󱆭";
}

.mdi-share-off:before {
  content: "󰼣";
}

.mdi-share-off-outline:before {
  content: "󰼤";
}

.mdi-share-outline:before {
  content: "󰤲";
}

.mdi-share-variant:before {
  content: "󰒗";
}

.mdi-share-variant-outline:before {
  content: "󱔔";
}

.mdi-shark:before {
  content: "󱢺";
}

.mdi-shark-fin:before {
  content: "󱙳";
}

.mdi-shark-fin-outline:before {
  content: "󱙴";
}

.mdi-shark-off:before {
  content: "󱢻";
}

.mdi-sheep:before {
  content: "󰳆";
}

.mdi-shield:before {
  content: "󰒘";
}

.mdi-shield-account:before {
  content: "󰢏";
}

.mdi-shield-account-outline:before {
  content: "󰨒";
}

.mdi-shield-account-variant:before {
  content: "󱖧";
}

.mdi-shield-account-variant-outline:before {
  content: "󱖨";
}

.mdi-shield-airplane:before {
  content: "󰚻";
}

.mdi-shield-airplane-outline:before {
  content: "󰳇";
}

.mdi-shield-alert:before {
  content: "󰻌";
}

.mdi-shield-alert-outline:before {
  content: "󰻍";
}

.mdi-shield-bug:before {
  content: "󱏚";
}

.mdi-shield-bug-outline:before {
  content: "󱏛";
}

.mdi-shield-car:before {
  content: "󰾃";
}

.mdi-shield-check:before {
  content: "󰕥";
}

.mdi-shield-check-outline:before {
  content: "󰳈";
}

.mdi-shield-cross:before {
  content: "󰳉";
}

.mdi-shield-cross-outline:before {
  content: "󰳊";
}

.mdi-shield-crown:before {
  content: "󱢼";
}

.mdi-shield-crown-outline:before {
  content: "󱢽";
}

.mdi-shield-edit:before {
  content: "󱆠";
}

.mdi-shield-edit-outline:before {
  content: "󱆡";
}

.mdi-shield-half:before {
  content: "󱍠";
}

.mdi-shield-half-full:before {
  content: "󰞀";
}

.mdi-shield-home:before {
  content: "󰚊";
}

.mdi-shield-home-outline:before {
  content: "󰳋";
}

.mdi-shield-key:before {
  content: "󰯄";
}

.mdi-shield-key-outline:before {
  content: "󰯅";
}

.mdi-shield-link-variant:before {
  content: "󰴳";
}

.mdi-shield-link-variant-outline:before {
  content: "󰴴";
}

.mdi-shield-lock:before {
  content: "󰦝";
}

.mdi-shield-lock-open:before {
  content: "󱦚";
}

.mdi-shield-lock-open-outline:before {
  content: "󱦛";
}

.mdi-shield-lock-outline:before {
  content: "󰳌";
}

.mdi-shield-moon:before {
  content: "󱠨";
}

.mdi-shield-moon-outline:before {
  content: "󱠩";
}

.mdi-shield-off:before {
  content: "󰦞";
}

.mdi-shield-off-outline:before {
  content: "󰦜";
}

.mdi-shield-outline:before {
  content: "󰒙";
}

.mdi-shield-plus:before {
  content: "󰫚";
}

.mdi-shield-plus-outline:before {
  content: "󰫛";
}

.mdi-shield-refresh:before {
  content: "󰂪";
}

.mdi-shield-refresh-outline:before {
  content: "󰇠";
}

.mdi-shield-remove:before {
  content: "󰫜";
}

.mdi-shield-remove-outline:before {
  content: "󰫝";
}

.mdi-shield-search:before {
  content: "󰶚";
}

.mdi-shield-star:before {
  content: "󱄻";
}

.mdi-shield-star-outline:before {
  content: "󱄼";
}

.mdi-shield-sun:before {
  content: "󱁝";
}

.mdi-shield-sun-outline:before {
  content: "󱁞";
}

.mdi-shield-sword:before {
  content: "󱢾";
}

.mdi-shield-sword-outline:before {
  content: "󱢿";
}

.mdi-shield-sync:before {
  content: "󱆢";
}

.mdi-shield-sync-outline:before {
  content: "󱆣";
}

.mdi-shimmer:before {
  content: "󱕅";
}

.mdi-ship-wheel:before {
  content: "󰠳";
}

.mdi-shipping-pallet:before {
  content: "󱡎";
}

.mdi-shoe-ballet:before {
  content: "󱗊";
}

.mdi-shoe-cleat:before {
  content: "󱗇";
}

.mdi-shoe-formal:before {
  content: "󰭇";
}

.mdi-shoe-heel:before {
  content: "󰭈";
}

.mdi-shoe-print:before {
  content: "󰷺";
}

.mdi-shoe-sneaker:before {
  content: "󱗈";
}

.mdi-shopping:before {
  content: "󰒚";
}

.mdi-shopping-music:before {
  content: "󰒛";
}

.mdi-shopping-outline:before {
  content: "󱇕";
}

.mdi-shopping-search:before {
  content: "󰾄";
}

.mdi-shopping-search-outline:before {
  content: "󱩯";
}

.mdi-shore:before {
  content: "󱓹";
}

.mdi-shovel:before {
  content: "󰜐";
}

.mdi-shovel-off:before {
  content: "󰜑";
}

.mdi-shower:before {
  content: "󰦠";
}

.mdi-shower-head:before {
  content: "󰦡";
}

.mdi-shredder:before {
  content: "󰒜";
}

.mdi-shuffle:before {
  content: "󰒝";
}

.mdi-shuffle-disabled:before {
  content: "󰒞";
}

.mdi-shuffle-variant:before {
  content: "󰒟";
}

.mdi-shuriken:before {
  content: "󱍿";
}

.mdi-sickle:before {
  content: "󱣀";
}

.mdi-sigma:before {
  content: "󰒠";
}

.mdi-sigma-lower:before {
  content: "󰘫";
}

.mdi-sign-caution:before {
  content: "󰒡";
}

.mdi-sign-direction:before {
  content: "󰞁";
}

.mdi-sign-direction-minus:before {
  content: "󱀀";
}

.mdi-sign-direction-plus:before {
  content: "󰿜";
}

.mdi-sign-direction-remove:before {
  content: "󰿝";
}

.mdi-sign-language:before {
  content: "󱭍";
}

.mdi-sign-language-outline:before {
  content: "󱭎";
}

.mdi-sign-pole:before {
  content: "󱓸";
}

.mdi-sign-real-estate:before {
  content: "󱄘";
}

.mdi-sign-text:before {
  content: "󰞂";
}

.mdi-sign-yield:before {
  content: "󱮯";
}

.mdi-signal:before {
  content: "󰒢";
}

.mdi-signal-2g:before {
  content: "󰜒";
}

.mdi-signal-3g:before {
  content: "󰜓";
}

.mdi-signal-4g:before {
  content: "󰜔";
}

.mdi-signal-5g:before {
  content: "󰩯";
}

.mdi-signal-cellular-1:before {
  content: "󰢼";
}

.mdi-signal-cellular-2:before {
  content: "󰢽";
}

.mdi-signal-cellular-3:before {
  content: "󰢾";
}

.mdi-signal-cellular-outline:before {
  content: "󰢿";
}

.mdi-signal-distance-variant:before {
  content: "󰹤";
}

.mdi-signal-hspa:before {
  content: "󰜕";
}

.mdi-signal-hspa-plus:before {
  content: "󰜖";
}

.mdi-signal-off:before {
  content: "󰞃";
}

.mdi-signal-variant:before {
  content: "󰘊";
}

.mdi-signature:before {
  content: "󰷻";
}

.mdi-signature-freehand:before {
  content: "󰷼";
}

.mdi-signature-image:before {
  content: "󰷽";
}

.mdi-signature-text:before {
  content: "󰷾";
}

.mdi-silo:before {
  content: "󱮟";
}

.mdi-silo-outline:before {
  content: "󰭉";
}

.mdi-silverware:before {
  content: "󰒣";
}

.mdi-silverware-clean:before {
  content: "󰿞";
}

.mdi-silverware-fork:before {
  content: "󰒤";
}

.mdi-silverware-fork-knife:before {
  content: "󰩰";
}

.mdi-silverware-spoon:before {
  content: "󰒥";
}

.mdi-silverware-variant:before {
  content: "󰒦";
}

.mdi-sim:before {
  content: "󰒧";
}

.mdi-sim-alert:before {
  content: "󰒨";
}

.mdi-sim-alert-outline:before {
  content: "󱗓";
}

.mdi-sim-off:before {
  content: "󰒩";
}

.mdi-sim-off-outline:before {
  content: "󱗔";
}

.mdi-sim-outline:before {
  content: "󱗕";
}

.mdi-simple-icons:before {
  content: "󱌝";
}

.mdi-sina-weibo:before {
  content: "󰫟";
}

.mdi-sine-wave:before {
  content: "󰥛";
}

.mdi-sitemap:before {
  content: "󰒪";
}

.mdi-sitemap-outline:before {
  content: "󱦜";
}

.mdi-size-l:before {
  content: "󱎦";
}

.mdi-size-m:before {
  content: "󱎥";
}

.mdi-size-s:before {
  content: "󱎤";
}

.mdi-size-xl:before {
  content: "󱎧";
}

.mdi-size-xs:before {
  content: "󱎣";
}

.mdi-size-xxl:before {
  content: "󱎨";
}

.mdi-size-xxs:before {
  content: "󱎢";
}

.mdi-size-xxxl:before {
  content: "󱎩";
}

.mdi-skate:before {
  content: "󰴵";
}

.mdi-skate-off:before {
  content: "󰚙";
}

.mdi-skateboard:before {
  content: "󱓂";
}

.mdi-skateboarding:before {
  content: "󰔁";
}

.mdi-skew-less:before {
  content: "󰴶";
}

.mdi-skew-more:before {
  content: "󰴷";
}

.mdi-ski:before {
  content: "󱌄";
}

.mdi-ski-cross-country:before {
  content: "󱌅";
}

.mdi-ski-water:before {
  content: "󱌆";
}

.mdi-skip-backward:before {
  content: "󰒫";
}

.mdi-skip-backward-outline:before {
  content: "󰼥";
}

.mdi-skip-forward:before {
  content: "󰒬";
}

.mdi-skip-forward-outline:before {
  content: "󰼦";
}

.mdi-skip-next:before {
  content: "󰒭";
}

.mdi-skip-next-circle:before {
  content: "󰙡";
}

.mdi-skip-next-circle-outline:before {
  content: "󰙢";
}

.mdi-skip-next-outline:before {
  content: "󰼧";
}

.mdi-skip-previous:before {
  content: "󰒮";
}

.mdi-skip-previous-circle:before {
  content: "󰙣";
}

.mdi-skip-previous-circle-outline:before {
  content: "󰙤";
}

.mdi-skip-previous-outline:before {
  content: "󰼨";
}

.mdi-skull:before {
  content: "󰚌";
}

.mdi-skull-crossbones:before {
  content: "󰯆";
}

.mdi-skull-crossbones-outline:before {
  content: "󰯇";
}

.mdi-skull-outline:before {
  content: "󰯈";
}

.mdi-skull-scan:before {
  content: "󱓇";
}

.mdi-skull-scan-outline:before {
  content: "󱓈";
}

.mdi-skype:before {
  content: "󰒯";
}

.mdi-skype-business:before {
  content: "󰒰";
}

.mdi-slack:before {
  content: "󰒱";
}

.mdi-slash-forward:before {
  content: "󰿟";
}

.mdi-slash-forward-box:before {
  content: "󰿠";
}

.mdi-sledding:before {
  content: "󰐛";
}

.mdi-sleep:before {
  content: "󰒲";
}

.mdi-sleep-off:before {
  content: "󰒳";
}

.mdi-slide:before {
  content: "󱖥";
}

.mdi-slope-downhill:before {
  content: "󰷿";
}

.mdi-slope-uphill:before {
  content: "󰸀";
}

.mdi-slot-machine:before {
  content: "󱄔";
}

.mdi-slot-machine-outline:before {
  content: "󱄕";
}

.mdi-smart-card:before {
  content: "󱂽";
}

.mdi-smart-card-off:before {
  content: "󱣷";
}

.mdi-smart-card-off-outline:before {
  content: "󱣸";
}

.mdi-smart-card-outline:before {
  content: "󱂾";
}

.mdi-smart-card-reader:before {
  content: "󱂿";
}

.mdi-smart-card-reader-outline:before {
  content: "󱃀";
}

.mdi-smog:before {
  content: "󰩱";
}

.mdi-smoke:before {
  content: "󱞙";
}

.mdi-smoke-detector:before {
  content: "󰎒";
}

.mdi-smoke-detector-alert:before {
  content: "󱤮";
}

.mdi-smoke-detector-alert-outline:before {
  content: "󱤯";
}

.mdi-smoke-detector-off:before {
  content: "󱠉";
}

.mdi-smoke-detector-off-outline:before {
  content: "󱠊";
}

.mdi-smoke-detector-outline:before {
  content: "󱠈";
}

.mdi-smoke-detector-variant:before {
  content: "󱠋";
}

.mdi-smoke-detector-variant-alert:before {
  content: "󱤰";
}

.mdi-smoke-detector-variant-off:before {
  content: "󱠌";
}

.mdi-smoking:before {
  content: "󰒴";
}

.mdi-smoking-off:before {
  content: "󰒵";
}

.mdi-smoking-pipe:before {
  content: "󱐍";
}

.mdi-smoking-pipe-off:before {
  content: "󱐨";
}

.mdi-snail:before {
  content: "󱙷";
}

.mdi-snake:before {
  content: "󱔎";
}

.mdi-snapchat:before {
  content: "󰒶";
}

.mdi-snowboard:before {
  content: "󱌇";
}

.mdi-snowflake:before {
  content: "󰜗";
}

.mdi-snowflake-alert:before {
  content: "󰼩";
}

.mdi-snowflake-check:before {
  content: "󱩰";
}

.mdi-snowflake-melt:before {
  content: "󱋋";
}

.mdi-snowflake-off:before {
  content: "󱓣";
}

.mdi-snowflake-thermometer:before {
  content: "󱩱";
}

.mdi-snowflake-variant:before {
  content: "󰼪";
}

.mdi-snowman:before {
  content: "󰒷";
}

.mdi-snowmobile:before {
  content: "󰛝";
}

.mdi-snowshoeing:before {
  content: "󱩲";
}

.mdi-soccer:before {
  content: "󰒸";
}

.mdi-soccer-field:before {
  content: "󰠴";
}

.mdi-social-distance-2-meters:before {
  content: "󱕹";
}

.mdi-social-distance-6-feet:before {
  content: "󱕺";
}

.mdi-sofa:before {
  content: "󰒹";
}

.mdi-sofa-outline:before {
  content: "󱕭";
}

.mdi-sofa-single:before {
  content: "󱕮";
}

.mdi-sofa-single-outline:before {
  content: "󱕯";
}

.mdi-solar-panel:before {
  content: "󰶛";
}

.mdi-solar-panel-large:before {
  content: "󰶜";
}

.mdi-solar-power:before {
  content: "󰩲";
}

.mdi-solar-power-variant:before {
  content: "󱩳";
}

.mdi-solar-power-variant-outline:before {
  content: "󱩴";
}

.mdi-soldering-iron:before {
  content: "󱂒";
}

.mdi-solid:before {
  content: "󰚍";
}

.mdi-sony-playstation:before {
  content: "󰐔";
}

.mdi-sort:before {
  content: "󰒺";
}

.mdi-sort-alphabetical-ascending:before {
  content: "󰖽";
}

.mdi-sort-alphabetical-ascending-variant:before {
  content: "󱅈";
}

.mdi-sort-alphabetical-descending:before {
  content: "󰖿";
}

.mdi-sort-alphabetical-descending-variant:before {
  content: "󱅉";
}

.mdi-sort-alphabetical-variant:before {
  content: "󰒻";
}

.mdi-sort-ascending:before {
  content: "󰒼";
}

.mdi-sort-bool-ascending:before {
  content: "󱎅";
}

.mdi-sort-bool-ascending-variant:before {
  content: "󱎆";
}

.mdi-sort-bool-descending:before {
  content: "󱎇";
}

.mdi-sort-bool-descending-variant:before {
  content: "󱎈";
}

.mdi-sort-calendar-ascending:before {
  content: "󱕇";
}

.mdi-sort-calendar-descending:before {
  content: "󱕈";
}

.mdi-sort-clock-ascending:before {
  content: "󱕉";
}

.mdi-sort-clock-ascending-outline:before {
  content: "󱕊";
}

.mdi-sort-clock-descending:before {
  content: "󱕋";
}

.mdi-sort-clock-descending-outline:before {
  content: "󱕌";
}

.mdi-sort-descending:before {
  content: "󰒽";
}

.mdi-sort-numeric-ascending:before {
  content: "󱎉";
}

.mdi-sort-numeric-ascending-variant:before {
  content: "󰤍";
}

.mdi-sort-numeric-descending:before {
  content: "󱎊";
}

.mdi-sort-numeric-descending-variant:before {
  content: "󰫒";
}

.mdi-sort-numeric-variant:before {
  content: "󰒾";
}

.mdi-sort-reverse-variant:before {
  content: "󰌼";
}

.mdi-sort-variant:before {
  content: "󰒿";
}

.mdi-sort-variant-lock:before {
  content: "󰳍";
}

.mdi-sort-variant-lock-open:before {
  content: "󰳎";
}

.mdi-sort-variant-off:before {
  content: "󱪻";
}

.mdi-sort-variant-remove:before {
  content: "󱅇";
}

.mdi-soundbar:before {
  content: "󱟛";
}

.mdi-soundcloud:before {
  content: "󰓀";
}

.mdi-source-branch:before {
  content: "󰘬";
}

.mdi-source-branch-check:before {
  content: "󱓏";
}

.mdi-source-branch-minus:before {
  content: "󱓋";
}

.mdi-source-branch-plus:before {
  content: "󱓊";
}

.mdi-source-branch-refresh:before {
  content: "󱓍";
}

.mdi-source-branch-remove:before {
  content: "󱓌";
}

.mdi-source-branch-sync:before {
  content: "󱓎";
}

.mdi-source-commit:before {
  content: "󰜘";
}

.mdi-source-commit-end:before {
  content: "󰜙";
}

.mdi-source-commit-end-local:before {
  content: "󰜚";
}

.mdi-source-commit-local:before {
  content: "󰜛";
}

.mdi-source-commit-next-local:before {
  content: "󰜜";
}

.mdi-source-commit-start:before {
  content: "󰜝";
}

.mdi-source-commit-start-next-local:before {
  content: "󰜞";
}

.mdi-source-fork:before {
  content: "󰓁";
}

.mdi-source-merge:before {
  content: "󰘭";
}

.mdi-source-pull:before {
  content: "󰓂";
}

.mdi-source-repository:before {
  content: "󰳏";
}

.mdi-source-repository-multiple:before {
  content: "󰳐";
}

.mdi-soy-sauce:before {
  content: "󰟮";
}

.mdi-soy-sauce-off:before {
  content: "󱏼";
}

.mdi-spa:before {
  content: "󰳑";
}

.mdi-spa-outline:before {
  content: "󰳒";
}

.mdi-space-invaders:before {
  content: "󰯉";
}

.mdi-space-station:before {
  content: "󱎃";
}

.mdi-spade:before {
  content: "󰹥";
}

.mdi-speaker:before {
  content: "󰓃";
}

.mdi-speaker-bluetooth:before {
  content: "󰦢";
}

.mdi-speaker-message:before {
  content: "󱬑";
}

.mdi-speaker-multiple:before {
  content: "󰴸";
}

.mdi-speaker-off:before {
  content: "󰓄";
}

.mdi-speaker-pause:before {
  content: "󱭳";
}

.mdi-speaker-play:before {
  content: "󱭲";
}

.mdi-speaker-stop:before {
  content: "󱭴";
}

.mdi-speaker-wireless:before {
  content: "󰜟";
}

.mdi-spear:before {
  content: "󱡅";
}

.mdi-speedometer:before {
  content: "󰓅";
}

.mdi-speedometer-medium:before {
  content: "󰾅";
}

.mdi-speedometer-slow:before {
  content: "󰾆";
}

.mdi-spellcheck:before {
  content: "󰓆";
}

.mdi-sphere:before {
  content: "󱥔";
}

.mdi-sphere-off:before {
  content: "󱥕";
}

.mdi-spider:before {
  content: "󱇪";
}

.mdi-spider-outline:before {
  content: "󱱵";
}

.mdi-spider-thread:before {
  content: "󱇫";
}

.mdi-spider-web:before {
  content: "󰯊";
}

.mdi-spirit-level:before {
  content: "󱓱";
}

.mdi-spoon-sugar:before {
  content: "󱐩";
}

.mdi-spotify:before {
  content: "󰓇";
}

.mdi-spotlight:before {
  content: "󰓈";
}

.mdi-spotlight-beam:before {
  content: "󰓉";
}

.mdi-spray:before {
  content: "󰙥";
}

.mdi-spray-bottle:before {
  content: "󰫠";
}

.mdi-sprinkler:before {
  content: "󱁟";
}

.mdi-sprinkler-fire:before {
  content: "󱦝";
}

.mdi-sprinkler-variant:before {
  content: "󱁠";
}

.mdi-sprout:before {
  content: "󰹦";
}

.mdi-sprout-outline:before {
  content: "󰹧";
}

.mdi-square:before {
  content: "󰝤";
}

.mdi-square-circle:before {
  content: "󱔀";
}

.mdi-square-circle-outline:before {
  content: "󱱐";
}

.mdi-square-edit-outline:before {
  content: "󰤌";
}

.mdi-square-medium:before {
  content: "󰨓";
}

.mdi-square-medium-outline:before {
  content: "󰨔";
}

.mdi-square-off:before {
  content: "󱋮";
}

.mdi-square-off-outline:before {
  content: "󱋯";
}

.mdi-square-opacity:before {
  content: "󱡔";
}

.mdi-square-outline:before {
  content: "󰝣";
}

.mdi-square-root:before {
  content: "󰞄";
}

.mdi-square-root-box:before {
  content: "󰦣";
}

.mdi-square-rounded:before {
  content: "󱓻";
}

.mdi-square-rounded-badge:before {
  content: "󱨇";
}

.mdi-square-rounded-badge-outline:before {
  content: "󱨈";
}

.mdi-square-rounded-outline:before {
  content: "󱓼";
}

.mdi-square-small:before {
  content: "󰨕";
}

.mdi-square-wave:before {
  content: "󱑻";
}

.mdi-squeegee:before {
  content: "󰫡";
}

.mdi-ssh:before {
  content: "󰣀";
}

.mdi-stack-exchange:before {
  content: "󰘋";
}

.mdi-stack-overflow:before {
  content: "󰓌";
}

.mdi-stackpath:before {
  content: "󰍙";
}

.mdi-stadium:before {
  content: "󰿹";
}

.mdi-stadium-outline:before {
  content: "󱬃";
}

.mdi-stadium-variant:before {
  content: "󰜠";
}

.mdi-stairs:before {
  content: "󰓍";
}

.mdi-stairs-box:before {
  content: "󱎞";
}

.mdi-stairs-down:before {
  content: "󱊾";
}

.mdi-stairs-up:before {
  content: "󱊽";
}

.mdi-stamper:before {
  content: "󰴹";
}

.mdi-standard-definition:before {
  content: "󰟯";
}

.mdi-star:before {
  content: "󰓎";
}

.mdi-star-box:before {
  content: "󰩳";
}

.mdi-star-box-multiple:before {
  content: "󱊆";
}

.mdi-star-box-multiple-outline:before {
  content: "󱊇";
}

.mdi-star-box-outline:before {
  content: "󰩴";
}

.mdi-star-check:before {
  content: "󱕦";
}

.mdi-star-check-outline:before {
  content: "󱕪";
}

.mdi-star-circle:before {
  content: "󰓏";
}

.mdi-star-circle-outline:before {
  content: "󰦤";
}

.mdi-star-cog:before {
  content: "󱙨";
}

.mdi-star-cog-outline:before {
  content: "󱙩";
}

.mdi-star-crescent:before {
  content: "󰥹";
}

.mdi-star-david:before {
  content: "󰥺";
}

.mdi-star-face:before {
  content: "󰦥";
}

.mdi-star-four-points:before {
  content: "󰫢";
}

.mdi-star-four-points-box:before {
  content: "󱱑";
}

.mdi-star-four-points-box-outline:before {
  content: "󱱒";
}

.mdi-star-four-points-circle:before {
  content: "󱱓";
}

.mdi-star-four-points-circle-outline:before {
  content: "󱱔";
}

.mdi-star-four-points-outline:before {
  content: "󰫣";
}

.mdi-star-four-points-small:before {
  content: "󱱕";
}

.mdi-star-half:before {
  content: "󰉆";
}

.mdi-star-half-full:before {
  content: "󰓐";
}

.mdi-star-minus:before {
  content: "󱕤";
}

.mdi-star-minus-outline:before {
  content: "󱕨";
}

.mdi-star-off:before {
  content: "󰓑";
}

.mdi-star-off-outline:before {
  content: "󱕛";
}

.mdi-star-outline:before {
  content: "󰓒";
}

.mdi-star-plus:before {
  content: "󱕣";
}

.mdi-star-plus-outline:before {
  content: "󱕧";
}

.mdi-star-remove:before {
  content: "󱕥";
}

.mdi-star-remove-outline:before {
  content: "󱕩";
}

.mdi-star-settings:before {
  content: "󱙪";
}

.mdi-star-settings-outline:before {
  content: "󱙫";
}

.mdi-star-shooting:before {
  content: "󱝁";
}

.mdi-star-shooting-outline:before {
  content: "󱝂";
}

.mdi-star-three-points:before {
  content: "󰫤";
}

.mdi-star-three-points-outline:before {
  content: "󰫥";
}

.mdi-state-machine:before {
  content: "󱇯";
}

.mdi-steam:before {
  content: "󰓓";
}

.mdi-steering:before {
  content: "󰓔";
}

.mdi-steering-off:before {
  content: "󰤎";
}

.mdi-step-backward:before {
  content: "󰓕";
}

.mdi-step-backward-2:before {
  content: "󰓖";
}

.mdi-step-forward:before {
  content: "󰓗";
}

.mdi-step-forward-2:before {
  content: "󰓘";
}

.mdi-stethoscope:before {
  content: "󰓙";
}

.mdi-sticker:before {
  content: "󱍤";
}

.mdi-sticker-alert:before {
  content: "󱍥";
}

.mdi-sticker-alert-outline:before {
  content: "󱍦";
}

.mdi-sticker-check:before {
  content: "󱍧";
}

.mdi-sticker-check-outline:before {
  content: "󱍨";
}

.mdi-sticker-circle-outline:before {
  content: "󰗐";
}

.mdi-sticker-emoji:before {
  content: "󰞅";
}

.mdi-sticker-minus:before {
  content: "󱍩";
}

.mdi-sticker-minus-outline:before {
  content: "󱍪";
}

.mdi-sticker-outline:before {
  content: "󱍫";
}

.mdi-sticker-plus:before {
  content: "󱍬";
}

.mdi-sticker-plus-outline:before {
  content: "󱍭";
}

.mdi-sticker-remove:before {
  content: "󱍮";
}

.mdi-sticker-remove-outline:before {
  content: "󱍯";
}

.mdi-sticker-text:before {
  content: "󱞎";
}

.mdi-sticker-text-outline:before {
  content: "󱞏";
}

.mdi-stocking:before {
  content: "󰓚";
}

.mdi-stomach:before {
  content: "󱂓";
}

.mdi-stool:before {
  content: "󱥝";
}

.mdi-stool-outline:before {
  content: "󱥞";
}

.mdi-stop:before {
  content: "󰓛";
}

.mdi-stop-circle:before {
  content: "󰙦";
}

.mdi-stop-circle-outline:before {
  content: "󰙧";
}

.mdi-storage-tank:before {
  content: "󱩵";
}

.mdi-storage-tank-outline:before {
  content: "󱩶";
}

.mdi-store:before {
  content: "󰓜";
}

.mdi-store-24-hour:before {
  content: "󰓝";
}

.mdi-store-alert:before {
  content: "󱣁";
}

.mdi-store-alert-outline:before {
  content: "󱣂";
}

.mdi-store-check:before {
  content: "󱣃";
}

.mdi-store-check-outline:before {
  content: "󱣄";
}

.mdi-store-clock:before {
  content: "󱣅";
}

.mdi-store-clock-outline:before {
  content: "󱣆";
}

.mdi-store-cog:before {
  content: "󱣇";
}

.mdi-store-cog-outline:before {
  content: "󱣈";
}

.mdi-store-edit:before {
  content: "󱣉";
}

.mdi-store-edit-outline:before {
  content: "󱣊";
}

.mdi-store-marker:before {
  content: "󱣋";
}

.mdi-store-marker-outline:before {
  content: "󱣌";
}

.mdi-store-minus:before {
  content: "󱙞";
}

.mdi-store-minus-outline:before {
  content: "󱣍";
}

.mdi-store-off:before {
  content: "󱣎";
}

.mdi-store-off-outline:before {
  content: "󱣏";
}

.mdi-store-outline:before {
  content: "󱍡";
}

.mdi-store-plus:before {
  content: "󱙟";
}

.mdi-store-plus-outline:before {
  content: "󱣐";
}

.mdi-store-remove:before {
  content: "󱙠";
}

.mdi-store-remove-outline:before {
  content: "󱣑";
}

.mdi-store-search:before {
  content: "󱣒";
}

.mdi-store-search-outline:before {
  content: "󱣓";
}

.mdi-store-settings:before {
  content: "󱣔";
}

.mdi-store-settings-outline:before {
  content: "󱣕";
}

.mdi-storefront:before {
  content: "󰟇";
}

.mdi-storefront-check:before {
  content: "󱭽";
}

.mdi-storefront-check-outline:before {
  content: "󱭾";
}

.mdi-storefront-edit:before {
  content: "󱭿";
}

.mdi-storefront-edit-outline:before {
  content: "󱮀";
}

.mdi-storefront-minus:before {
  content: "󱮃";
}

.mdi-storefront-minus-outline:before {
  content: "󱮄";
}

.mdi-storefront-outline:before {
  content: "󱃁";
}

.mdi-storefront-plus:before {
  content: "󱮁";
}

.mdi-storefront-plus-outline:before {
  content: "󱮂";
}

.mdi-storefront-remove:before {
  content: "󱮅";
}

.mdi-storefront-remove-outline:before {
  content: "󱮆";
}

.mdi-stove:before {
  content: "󰓞";
}

.mdi-strategy:before {
  content: "󱇖";
}

.mdi-stretch-to-page:before {
  content: "󰼫";
}

.mdi-stretch-to-page-outline:before {
  content: "󰼬";
}

.mdi-string-lights:before {
  content: "󱊺";
}

.mdi-string-lights-off:before {
  content: "󱊻";
}

.mdi-subdirectory-arrow-left:before {
  content: "󰘌";
}

.mdi-subdirectory-arrow-right:before {
  content: "󰘍";
}

.mdi-submarine:before {
  content: "󱕬";
}

.mdi-subtitles:before {
  content: "󰨖";
}

.mdi-subtitles-outline:before {
  content: "󰨗";
}

.mdi-subway:before {
  content: "󰚬";
}

.mdi-subway-alert-variant:before {
  content: "󰶝";
}

.mdi-subway-variant:before {
  content: "󰓟";
}

.mdi-summit:before {
  content: "󰞆";
}

.mdi-sun-angle:before {
  content: "󱬧";
}

.mdi-sun-angle-outline:before {
  content: "󱬨";
}

.mdi-sun-clock:before {
  content: "󱩷";
}

.mdi-sun-clock-outline:before {
  content: "󱩸";
}

.mdi-sun-compass:before {
  content: "󱦥";
}

.mdi-sun-snowflake:before {
  content: "󱞖";
}

.mdi-sun-snowflake-variant:before {
  content: "󱩹";
}

.mdi-sun-thermometer:before {
  content: "󱣖";
}

.mdi-sun-thermometer-outline:before {
  content: "󱣗";
}

.mdi-sun-wireless:before {
  content: "󱟾";
}

.mdi-sun-wireless-outline:before {
  content: "󱟿";
}

.mdi-sunglasses:before {
  content: "󰓠";
}

.mdi-surfing:before {
  content: "󱝆";
}

.mdi-surround-sound:before {
  content: "󰗅";
}

.mdi-surround-sound-2-0:before {
  content: "󰟰";
}

.mdi-surround-sound-2-1:before {
  content: "󱜩";
}

.mdi-surround-sound-3-1:before {
  content: "󰟱";
}

.mdi-surround-sound-5-1:before {
  content: "󰟲";
}

.mdi-surround-sound-5-1-2:before {
  content: "󱜪";
}

.mdi-surround-sound-7-1:before {
  content: "󰟳";
}

.mdi-svg:before {
  content: "󰜡";
}

.mdi-swap-horizontal:before {
  content: "󰓡";
}

.mdi-swap-horizontal-bold:before {
  content: "󰯍";
}

.mdi-swap-horizontal-circle:before {
  content: "󰿡";
}

.mdi-swap-horizontal-circle-outline:before {
  content: "󰿢";
}

.mdi-swap-horizontal-hidden:before {
  content: "󱴎";
}

.mdi-swap-horizontal-variant:before {
  content: "󰣁";
}

.mdi-swap-vertical:before {
  content: "󰓢";
}

.mdi-swap-vertical-bold:before {
  content: "󰯎";
}

.mdi-swap-vertical-circle:before {
  content: "󰿣";
}

.mdi-swap-vertical-circle-outline:before {
  content: "󰿤";
}

.mdi-swap-vertical-variant:before {
  content: "󰣂";
}

.mdi-swim:before {
  content: "󰓣";
}

.mdi-switch:before {
  content: "󰓤";
}

.mdi-sword:before {
  content: "󰓥";
}

.mdi-sword-cross:before {
  content: "󰞇";
}

.mdi-syllabary-hangul:before {
  content: "󱌳";
}

.mdi-syllabary-hiragana:before {
  content: "󱌴";
}

.mdi-syllabary-katakana:before {
  content: "󱌵";
}

.mdi-syllabary-katakana-halfwidth:before {
  content: "󱌶";
}

.mdi-symbol:before {
  content: "󱔁";
}

.mdi-symfony:before {
  content: "󰫦";
}

.mdi-synagogue:before {
  content: "󱬄";
}

.mdi-synagogue-outline:before {
  content: "󱬅";
}

.mdi-sync:before {
  content: "󰓦";
}

.mdi-sync-alert:before {
  content: "󰓧";
}

.mdi-sync-circle:before {
  content: "󱍸";
}

.mdi-sync-off:before {
  content: "󰓨";
}

.mdi-tab:before {
  content: "󰓩";
}

.mdi-tab-minus:before {
  content: "󰭋";
}

.mdi-tab-plus:before {
  content: "󰝜";
}

.mdi-tab-remove:before {
  content: "󰭌";
}

.mdi-tab-search:before {
  content: "󱦞";
}

.mdi-tab-unselected:before {
  content: "󰓪";
}

.mdi-table:before {
  content: "󰓫";
}

.mdi-table-account:before {
  content: "󱎹";
}

.mdi-table-alert:before {
  content: "󱎺";
}

.mdi-table-arrow-down:before {
  content: "󱎻";
}

.mdi-table-arrow-left:before {
  content: "󱎼";
}

.mdi-table-arrow-right:before {
  content: "󱎽";
}

.mdi-table-arrow-up:before {
  content: "󱎾";
}

.mdi-table-border:before {
  content: "󰨘";
}

.mdi-table-cancel:before {
  content: "󱎿";
}

.mdi-table-chair:before {
  content: "󱁡";
}

.mdi-table-check:before {
  content: "󱏀";
}

.mdi-table-clock:before {
  content: "󱏁";
}

.mdi-table-cog:before {
  content: "󱏂";
}

.mdi-table-column:before {
  content: "󰠵";
}

.mdi-table-column-plus-after:before {
  content: "󰓬";
}

.mdi-table-column-plus-before:before {
  content: "󰓭";
}

.mdi-table-column-remove:before {
  content: "󰓮";
}

.mdi-table-column-width:before {
  content: "󰓯";
}

.mdi-table-edit:before {
  content: "󰓰";
}

.mdi-table-eye:before {
  content: "󱂔";
}

.mdi-table-eye-off:before {
  content: "󱏃";
}

.mdi-table-filter:before {
  content: "󱮌";
}

.mdi-table-furniture:before {
  content: "󰖼";
}

.mdi-table-headers-eye:before {
  content: "󱈝";
}

.mdi-table-headers-eye-off:before {
  content: "󱈞";
}

.mdi-table-heart:before {
  content: "󱏄";
}

.mdi-table-key:before {
  content: "󱏅";
}

.mdi-table-large:before {
  content: "󰓱";
}

.mdi-table-large-plus:before {
  content: "󰾇";
}

.mdi-table-large-remove:before {
  content: "󰾈";
}

.mdi-table-lock:before {
  content: "󱏆";
}

.mdi-table-merge-cells:before {
  content: "󰦦";
}

.mdi-table-minus:before {
  content: "󱏇";
}

.mdi-table-multiple:before {
  content: "󱏈";
}

.mdi-table-network:before {
  content: "󱏉";
}

.mdi-table-of-contents:before {
  content: "󰠶";
}

.mdi-table-off:before {
  content: "󱏊";
}

.mdi-table-picnic:before {
  content: "󱝃";
}

.mdi-table-pivot:before {
  content: "󱠼";
}

.mdi-table-plus:before {
  content: "󰩵";
}

.mdi-table-question:before {
  content: "󱬡";
}

.mdi-table-refresh:before {
  content: "󱎠";
}

.mdi-table-remove:before {
  content: "󰩶";
}

.mdi-table-row:before {
  content: "󰠷";
}

.mdi-table-row-height:before {
  content: "󰓲";
}

.mdi-table-row-plus-after:before {
  content: "󰓳";
}

.mdi-table-row-plus-before:before {
  content: "󰓴";
}

.mdi-table-row-remove:before {
  content: "󰓵";
}

.mdi-table-search:before {
  content: "󰤏";
}

.mdi-table-settings:before {
  content: "󰠸";
}

.mdi-table-split-cell:before {
  content: "󱐪";
}

.mdi-table-star:before {
  content: "󱏋";
}

.mdi-table-sync:before {
  content: "󱎡";
}

.mdi-table-tennis:before {
  content: "󰹨";
}

.mdi-tablet:before {
  content: "󰓶";
}

.mdi-tablet-cellphone:before {
  content: "󰦧";
}

.mdi-tablet-dashboard:before {
  content: "󰻎";
}

.mdi-taco:before {
  content: "󰝢";
}

.mdi-tag:before {
  content: "󰓹";
}

.mdi-tag-arrow-down:before {
  content: "󱜫";
}

.mdi-tag-arrow-down-outline:before {
  content: "󱜬";
}

.mdi-tag-arrow-left:before {
  content: "󱜭";
}

.mdi-tag-arrow-left-outline:before {
  content: "󱜮";
}

.mdi-tag-arrow-right:before {
  content: "󱜯";
}

.mdi-tag-arrow-right-outline:before {
  content: "󱜰";
}

.mdi-tag-arrow-up:before {
  content: "󱜱";
}

.mdi-tag-arrow-up-outline:before {
  content: "󱜲";
}

.mdi-tag-check:before {
  content: "󱩺";
}

.mdi-tag-check-outline:before {
  content: "󱩻";
}

.mdi-tag-edit:before {
  content: "󱲜";
}

.mdi-tag-edit-outline:before {
  content: "󱲝";
}

.mdi-tag-faces:before {
  content: "󰓺";
}

.mdi-tag-heart:before {
  content: "󰚋";
}

.mdi-tag-heart-outline:before {
  content: "󰯏";
}

.mdi-tag-hidden:before {
  content: "󱱶";
}

.mdi-tag-minus:before {
  content: "󰤐";
}

.mdi-tag-minus-outline:before {
  content: "󱈟";
}

.mdi-tag-multiple:before {
  content: "󰓻";
}

.mdi-tag-multiple-outline:before {
  content: "󱋷";
}

.mdi-tag-off:before {
  content: "󱈠";
}

.mdi-tag-off-outline:before {
  content: "󱈡";
}

.mdi-tag-outline:before {
  content: "󰓼";
}

.mdi-tag-plus:before {
  content: "󰜢";
}

.mdi-tag-plus-outline:before {
  content: "󱈢";
}

.mdi-tag-remove:before {
  content: "󰜣";
}

.mdi-tag-remove-outline:before {
  content: "󱈣";
}

.mdi-tag-search:before {
  content: "󱤇";
}

.mdi-tag-search-outline:before {
  content: "󱤈";
}

.mdi-tag-text:before {
  content: "󱈤";
}

.mdi-tag-text-outline:before {
  content: "󰓽";
}

.mdi-tailwind:before {
  content: "󱏿";
}

.mdi-tally-mark-1:before {
  content: "󱪼";
}

.mdi-tally-mark-2:before {
  content: "󱪽";
}

.mdi-tally-mark-3:before {
  content: "󱪾";
}

.mdi-tally-mark-4:before {
  content: "󱪿";
}

.mdi-tally-mark-5:before {
  content: "󱫀";
}

.mdi-tangram:before {
  content: "󰓸";
}

.mdi-tank:before {
  content: "󰴺";
}

.mdi-tanker-truck:before {
  content: "󰿥";
}

.mdi-tape-drive:before {
  content: "󱛟";
}

.mdi-tape-measure:before {
  content: "󰭍";
}

.mdi-target:before {
  content: "󰓾";
}

.mdi-target-account:before {
  content: "󰯐";
}

.mdi-target-variant:before {
  content: "󰩷";
}

.mdi-taxi:before {
  content: "󰓿";
}

.mdi-tea:before {
  content: "󰶞";
}

.mdi-tea-outline:before {
  content: "󰶟";
}

.mdi-teamviewer:before {
  content: "󰔀";
}

.mdi-teddy-bear:before {
  content: "󱣻";
}

.mdi-telescope:before {
  content: "󰭎";
}

.mdi-television:before {
  content: "󰔂";
}

.mdi-television-ambient-light:before {
  content: "󱍖";
}

.mdi-television-box:before {
  content: "󰠹";
}

.mdi-television-classic:before {
  content: "󰟴";
}

.mdi-television-classic-off:before {
  content: "󰠺";
}

.mdi-television-guide:before {
  content: "󰔃";
}

.mdi-television-off:before {
  content: "󰠻";
}

.mdi-television-pause:before {
  content: "󰾉";
}

.mdi-television-play:before {
  content: "󰻏";
}

.mdi-television-shimmer:before {
  content: "󱄐";
}

.mdi-television-speaker:before {
  content: "󱬛";
}

.mdi-television-speaker-off:before {
  content: "󱬜";
}

.mdi-television-stop:before {
  content: "󰾊";
}

.mdi-temperature-celsius:before {
  content: "󰔄";
}

.mdi-temperature-fahrenheit:before {
  content: "󰔅";
}

.mdi-temperature-kelvin:before {
  content: "󰔆";
}

.mdi-temple-buddhist:before {
  content: "󱬆";
}

.mdi-temple-buddhist-outline:before {
  content: "󱬇";
}

.mdi-temple-hindu:before {
  content: "󱬈";
}

.mdi-temple-hindu-outline:before {
  content: "󱬉";
}

.mdi-tennis:before {
  content: "󰶠";
}

.mdi-tennis-ball:before {
  content: "󰔇";
}

.mdi-tennis-ball-outline:before {
  content: "󱱟";
}

.mdi-tent:before {
  content: "󰔈";
}

.mdi-terraform:before {
  content: "󱁢";
}

.mdi-terrain:before {
  content: "󰔉";
}

.mdi-test-tube:before {
  content: "󰙨";
}

.mdi-test-tube-empty:before {
  content: "󰤑";
}

.mdi-test-tube-off:before {
  content: "󰤒";
}

.mdi-text:before {
  content: "󰦨";
}

.mdi-text-account:before {
  content: "󱕰";
}

.mdi-text-box:before {
  content: "󰈚";
}

.mdi-text-box-check:before {
  content: "󰺦";
}

.mdi-text-box-check-outline:before {
  content: "󰺧";
}

.mdi-text-box-edit:before {
  content: "󱩼";
}

.mdi-text-box-edit-outline:before {
  content: "󱩽";
}

.mdi-text-box-minus:before {
  content: "󰺨";
}

.mdi-text-box-minus-outline:before {
  content: "󰺩";
}

.mdi-text-box-multiple:before {
  content: "󰪷";
}

.mdi-text-box-multiple-outline:before {
  content: "󰪸";
}

.mdi-text-box-outline:before {
  content: "󰧭";
}

.mdi-text-box-plus:before {
  content: "󰺪";
}

.mdi-text-box-plus-outline:before {
  content: "󰺫";
}

.mdi-text-box-remove:before {
  content: "󰺬";
}

.mdi-text-box-remove-outline:before {
  content: "󰺭";
}

.mdi-text-box-search:before {
  content: "󰺮";
}

.mdi-text-box-search-outline:before {
  content: "󰺯";
}

.mdi-text-long:before {
  content: "󰦪";
}

.mdi-text-recognition:before {
  content: "󱄽";
}

.mdi-text-search:before {
  content: "󱎸";
}

.mdi-text-search-variant:before {
  content: "󱩾";
}

.mdi-text-shadow:before {
  content: "󰙩";
}

.mdi-text-short:before {
  content: "󰦩";
}

.mdi-texture:before {
  content: "󰔌";
}

.mdi-texture-box:before {
  content: "󰿦";
}

.mdi-theater:before {
  content: "󰔍";
}

.mdi-theme-light-dark:before {
  content: "󰔎";
}

.mdi-thermometer:before {
  content: "󰔏";
}

.mdi-thermometer-alert:before {
  content: "󰸁";
}

.mdi-thermometer-auto:before {
  content: "󱬏";
}

.mdi-thermometer-bluetooth:before {
  content: "󱢕";
}

.mdi-thermometer-check:before {
  content: "󱩿";
}

.mdi-thermometer-chevron-down:before {
  content: "󰸂";
}

.mdi-thermometer-chevron-up:before {
  content: "󰸃";
}

.mdi-thermometer-high:before {
  content: "󱃂";
}

.mdi-thermometer-lines:before {
  content: "󰔐";
}

.mdi-thermometer-low:before {
  content: "󱃃";
}

.mdi-thermometer-minus:before {
  content: "󰸄";
}

.mdi-thermometer-off:before {
  content: "󱔱";
}

.mdi-thermometer-plus:before {
  content: "󰸅";
}

.mdi-thermometer-probe:before {
  content: "󱬫";
}

.mdi-thermometer-probe-off:before {
  content: "󱬬";
}

.mdi-thermometer-water:before {
  content: "󱪀";
}

.mdi-thermostat:before {
  content: "󰎓";
}

.mdi-thermostat-auto:before {
  content: "󱬗";
}

.mdi-thermostat-box:before {
  content: "󰢑";
}

.mdi-thermostat-box-auto:before {
  content: "󱬘";
}

.mdi-thermostat-cog:before {
  content: "󱲀";
}

.mdi-thought-bubble:before {
  content: "󰟶";
}

.mdi-thought-bubble-outline:before {
  content: "󰟷";
}

.mdi-thumb-down:before {
  content: "󰔑";
}

.mdi-thumb-down-outline:before {
  content: "󰔒";
}

.mdi-thumb-up:before {
  content: "󰔓";
}

.mdi-thumb-up-outline:before {
  content: "󰔔";
}

.mdi-thumbs-up-down:before {
  content: "󰔕";
}

.mdi-thumbs-up-down-outline:before {
  content: "󱤔";
}

.mdi-ticket:before {
  content: "󰔖";
}

.mdi-ticket-account:before {
  content: "󰔗";
}

.mdi-ticket-confirmation:before {
  content: "󰔘";
}

.mdi-ticket-confirmation-outline:before {
  content: "󱎪";
}

.mdi-ticket-outline:before {
  content: "󰤓";
}

.mdi-ticket-percent:before {
  content: "󰜤";
}

.mdi-ticket-percent-outline:before {
  content: "󱐫";
}

.mdi-tie:before {
  content: "󰔙";
}

.mdi-tilde:before {
  content: "󰜥";
}

.mdi-tilde-off:before {
  content: "󱣳";
}

.mdi-timelapse:before {
  content: "󰔚";
}

.mdi-timeline:before {
  content: "󰯑";
}

.mdi-timeline-alert:before {
  content: "󰾕";
}

.mdi-timeline-alert-outline:before {
  content: "󰾘";
}

.mdi-timeline-check:before {
  content: "󱔲";
}

.mdi-timeline-check-outline:before {
  content: "󱔳";
}

.mdi-timeline-clock:before {
  content: "󱇻";
}

.mdi-timeline-clock-outline:before {
  content: "󱇼";
}

.mdi-timeline-minus:before {
  content: "󱔴";
}

.mdi-timeline-minus-outline:before {
  content: "󱔵";
}

.mdi-timeline-outline:before {
  content: "󰯒";
}

.mdi-timeline-plus:before {
  content: "󰾖";
}

.mdi-timeline-plus-outline:before {
  content: "󰾗";
}

.mdi-timeline-question:before {
  content: "󰾙";
}

.mdi-timeline-question-outline:before {
  content: "󰾚";
}

.mdi-timeline-remove:before {
  content: "󱔶";
}

.mdi-timeline-remove-outline:before {
  content: "󱔷";
}

.mdi-timeline-text:before {
  content: "󰯓";
}

.mdi-timeline-text-outline:before {
  content: "󰯔";
}

.mdi-timer:before {
  content: "󱎫";
}

.mdi-timer-10:before {
  content: "󰔜";
}

.mdi-timer-3:before {
  content: "󰔝";
}

.mdi-timer-alert:before {
  content: "󱫌";
}

.mdi-timer-alert-outline:before {
  content: "󱫍";
}

.mdi-timer-cancel:before {
  content: "󱫎";
}

.mdi-timer-cancel-outline:before {
  content: "󱫏";
}

.mdi-timer-check:before {
  content: "󱫐";
}

.mdi-timer-check-outline:before {
  content: "󱫑";
}

.mdi-timer-cog:before {
  content: "󱤥";
}

.mdi-timer-cog-outline:before {
  content: "󱤦";
}

.mdi-timer-edit:before {
  content: "󱫒";
}

.mdi-timer-edit-outline:before {
  content: "󱫓";
}

.mdi-timer-lock:before {
  content: "󱫔";
}

.mdi-timer-lock-open:before {
  content: "󱫕";
}

.mdi-timer-lock-open-outline:before {
  content: "󱫖";
}

.mdi-timer-lock-outline:before {
  content: "󱫗";
}

.mdi-timer-marker:before {
  content: "󱫘";
}

.mdi-timer-marker-outline:before {
  content: "󱫙";
}

.mdi-timer-minus:before {
  content: "󱫚";
}

.mdi-timer-minus-outline:before {
  content: "󱫛";
}

.mdi-timer-music:before {
  content: "󱫜";
}

.mdi-timer-music-outline:before {
  content: "󱫝";
}

.mdi-timer-off:before {
  content: "󱎬";
}

.mdi-timer-off-outline:before {
  content: "󰔞";
}

.mdi-timer-outline:before {
  content: "󰔛";
}

.mdi-timer-pause:before {
  content: "󱫞";
}

.mdi-timer-pause-outline:before {
  content: "󱫟";
}

.mdi-timer-play:before {
  content: "󱫠";
}

.mdi-timer-play-outline:before {
  content: "󱫡";
}

.mdi-timer-plus:before {
  content: "󱫢";
}

.mdi-timer-plus-outline:before {
  content: "󱫣";
}

.mdi-timer-refresh:before {
  content: "󱫤";
}

.mdi-timer-refresh-outline:before {
  content: "󱫥";
}

.mdi-timer-remove:before {
  content: "󱫦";
}

.mdi-timer-remove-outline:before {
  content: "󱫧";
}

.mdi-timer-sand:before {
  content: "󰔟";
}

.mdi-timer-sand-complete:before {
  content: "󱦟";
}

.mdi-timer-sand-empty:before {
  content: "󰚭";
}

.mdi-timer-sand-full:before {
  content: "󰞌";
}

.mdi-timer-sand-paused:before {
  content: "󱦠";
}

.mdi-timer-settings:before {
  content: "󱤣";
}

.mdi-timer-settings-outline:before {
  content: "󱤤";
}

.mdi-timer-star:before {
  content: "󱫨";
}

.mdi-timer-star-outline:before {
  content: "󱫩";
}

.mdi-timer-stop:before {
  content: "󱫪";
}

.mdi-timer-stop-outline:before {
  content: "󱫫";
}

.mdi-timer-sync:before {
  content: "󱫬";
}

.mdi-timer-sync-outline:before {
  content: "󱫭";
}

.mdi-timetable:before {
  content: "󰔠";
}

.mdi-tire:before {
  content: "󱢖";
}

.mdi-toaster:before {
  content: "󱁣";
}

.mdi-toaster-off:before {
  content: "󱆷";
}

.mdi-toaster-oven:before {
  content: "󰳓";
}

.mdi-toggle-switch:before {
  content: "󰔡";
}

.mdi-toggle-switch-off:before {
  content: "󰔢";
}

.mdi-toggle-switch-off-outline:before {
  content: "󰨙";
}

.mdi-toggle-switch-outline:before {
  content: "󰨚";
}

.mdi-toggle-switch-variant:before {
  content: "󱨥";
}

.mdi-toggle-switch-variant-off:before {
  content: "󱨦";
}

.mdi-toilet:before {
  content: "󰦫";
}

.mdi-toolbox:before {
  content: "󰦬";
}

.mdi-toolbox-outline:before {
  content: "󰦭";
}

.mdi-tools:before {
  content: "󱁤";
}

.mdi-tooltip:before {
  content: "󰔣";
}

.mdi-tooltip-account:before {
  content: "󰀌";
}

.mdi-tooltip-cellphone:before {
  content: "󱠻";
}

.mdi-tooltip-check:before {
  content: "󱕜";
}

.mdi-tooltip-check-outline:before {
  content: "󱕝";
}

.mdi-tooltip-edit:before {
  content: "󰔤";
}

.mdi-tooltip-edit-outline:before {
  content: "󱋅";
}

.mdi-tooltip-image:before {
  content: "󰔥";
}

.mdi-tooltip-image-outline:before {
  content: "󰯕";
}

.mdi-tooltip-minus:before {
  content: "󱕞";
}

.mdi-tooltip-minus-outline:before {
  content: "󱕟";
}

.mdi-tooltip-outline:before {
  content: "󰔦";
}

.mdi-tooltip-plus:before {
  content: "󰯖";
}

.mdi-tooltip-plus-outline:before {
  content: "󰔧";
}

.mdi-tooltip-question:before {
  content: "󱮺";
}

.mdi-tooltip-question-outline:before {
  content: "󱮻";
}

.mdi-tooltip-remove:before {
  content: "󱕠";
}

.mdi-tooltip-remove-outline:before {
  content: "󱕡";
}

.mdi-tooltip-text:before {
  content: "󰔨";
}

.mdi-tooltip-text-outline:before {
  content: "󰯗";
}

.mdi-tooth:before {
  content: "󰣃";
}

.mdi-tooth-outline:before {
  content: "󰔩";
}

.mdi-toothbrush:before {
  content: "󱄩";
}

.mdi-toothbrush-electric:before {
  content: "󱄬";
}

.mdi-toothbrush-paste:before {
  content: "󱄪";
}

.mdi-torch:before {
  content: "󱘆";
}

.mdi-tortoise:before {
  content: "󰴻";
}

.mdi-toslink:before {
  content: "󱊸";
}

.mdi-touch-text-outline:before {
  content: "󱱠";
}

.mdi-tournament:before {
  content: "󰦮";
}

.mdi-tow-truck:before {
  content: "󰠼";
}

.mdi-tower-beach:before {
  content: "󰚁";
}

.mdi-tower-fire:before {
  content: "󰚂";
}

.mdi-town-hall:before {
  content: "󱡵";
}

.mdi-toy-brick:before {
  content: "󱊈";
}

.mdi-toy-brick-marker:before {
  content: "󱊉";
}

.mdi-toy-brick-marker-outline:before {
  content: "󱊊";
}

.mdi-toy-brick-minus:before {
  content: "󱊋";
}

.mdi-toy-brick-minus-outline:before {
  content: "󱊌";
}

.mdi-toy-brick-outline:before {
  content: "󱊍";
}

.mdi-toy-brick-plus:before {
  content: "󱊎";
}

.mdi-toy-brick-plus-outline:before {
  content: "󱊏";
}

.mdi-toy-brick-remove:before {
  content: "󱊐";
}

.mdi-toy-brick-remove-outline:before {
  content: "󱊑";
}

.mdi-toy-brick-search:before {
  content: "󱊒";
}

.mdi-toy-brick-search-outline:before {
  content: "󱊓";
}

.mdi-track-light:before {
  content: "󰤔";
}

.mdi-track-light-off:before {
  content: "󱬁";
}

.mdi-trackpad:before {
  content: "󰟸";
}

.mdi-trackpad-lock:before {
  content: "󰤳";
}

.mdi-tractor:before {
  content: "󰢒";
}

.mdi-tractor-variant:before {
  content: "󱓄";
}

.mdi-trademark:before {
  content: "󰩸";
}

.mdi-traffic-cone:before {
  content: "󱍼";
}

.mdi-traffic-light:before {
  content: "󰔫";
}

.mdi-traffic-light-outline:before {
  content: "󱠪";
}

.mdi-train:before {
  content: "󰔬";
}

.mdi-train-bus:before {
  content: "󱳇";
}

.mdi-train-car:before {
  content: "󰯘";
}

.mdi-train-car-autorack:before {
  content: "󱬭";
}

.mdi-train-car-box:before {
  content: "󱬮";
}

.mdi-train-car-box-full:before {
  content: "󱬯";
}

.mdi-train-car-box-open:before {
  content: "󱬰";
}

.mdi-train-car-caboose:before {
  content: "󱬱";
}

.mdi-train-car-centerbeam:before {
  content: "󱬲";
}

.mdi-train-car-centerbeam-full:before {
  content: "󱬳";
}

.mdi-train-car-container:before {
  content: "󱬴";
}

.mdi-train-car-flatbed:before {
  content: "󱬵";
}

.mdi-train-car-flatbed-car:before {
  content: "󱬶";
}

.mdi-train-car-flatbed-tank:before {
  content: "󱬷";
}

.mdi-train-car-gondola:before {
  content: "󱬸";
}

.mdi-train-car-gondola-full:before {
  content: "󱬹";
}

.mdi-train-car-hopper:before {
  content: "󱬺";
}

.mdi-train-car-hopper-covered:before {
  content: "󱬻";
}

.mdi-train-car-hopper-full:before {
  content: "󱬼";
}

.mdi-train-car-intermodal:before {
  content: "󱬽";
}

.mdi-train-car-passenger:before {
  content: "󱜳";
}

.mdi-train-car-passenger-door:before {
  content: "󱜴";
}

.mdi-train-car-passenger-door-open:before {
  content: "󱜵";
}

.mdi-train-car-passenger-variant:before {
  content: "󱜶";
}

.mdi-train-car-tank:before {
  content: "󱬾";
}

.mdi-train-variant:before {
  content: "󰣄";
}

.mdi-tram:before {
  content: "󰔭";
}

.mdi-tram-side:before {
  content: "󰿧";
}

.mdi-transcribe:before {
  content: "󰔮";
}

.mdi-transcribe-close:before {
  content: "󰔯";
}

.mdi-transfer:before {
  content: "󱁥";
}

.mdi-transfer-down:before {
  content: "󰶡";
}

.mdi-transfer-left:before {
  content: "󰶢";
}

.mdi-transfer-right:before {
  content: "󰔰";
}

.mdi-transfer-up:before {
  content: "󰶣";
}

.mdi-transit-connection:before {
  content: "󰴼";
}

.mdi-transit-connection-horizontal:before {
  content: "󱕆";
}

.mdi-transit-connection-variant:before {
  content: "󰴽";
}

.mdi-transit-detour:before {
  content: "󰾋";
}

.mdi-transit-skip:before {
  content: "󱔕";
}

.mdi-transit-transfer:before {
  content: "󰚮";
}

.mdi-transition:before {
  content: "󰤕";
}

.mdi-transition-masked:before {
  content: "󰤖";
}

.mdi-translate:before {
  content: "󰗊";
}

.mdi-translate-off:before {
  content: "󰸆";
}

.mdi-translate-variant:before {
  content: "󱮙";
}

.mdi-transmission-tower:before {
  content: "󰴾";
}

.mdi-transmission-tower-export:before {
  content: "󱤬";
}

.mdi-transmission-tower-import:before {
  content: "󱤭";
}

.mdi-transmission-tower-off:before {
  content: "󱧝";
}

.mdi-trash-can:before {
  content: "󰩹";
}

.mdi-trash-can-outline:before {
  content: "󰩺";
}

.mdi-tray:before {
  content: "󱊔";
}

.mdi-tray-alert:before {
  content: "󱊕";
}

.mdi-tray-arrow-down:before {
  content: "󰄠";
}

.mdi-tray-arrow-up:before {
  content: "󰄝";
}

.mdi-tray-full:before {
  content: "󱊖";
}

.mdi-tray-minus:before {
  content: "󱊗";
}

.mdi-tray-plus:before {
  content: "󱊘";
}

.mdi-tray-remove:before {
  content: "󱊙";
}

.mdi-treasure-chest:before {
  content: "󰜦";
}

.mdi-treasure-chest-outline:before {
  content: "󱱷";
}

.mdi-tree:before {
  content: "󰔱";
}

.mdi-tree-outline:before {
  content: "󰹩";
}

.mdi-trello:before {
  content: "󰔲";
}

.mdi-trending-down:before {
  content: "󰔳";
}

.mdi-trending-neutral:before {
  content: "󰔴";
}

.mdi-trending-up:before {
  content: "󰔵";
}

.mdi-triangle:before {
  content: "󰔶";
}

.mdi-triangle-down:before {
  content: "󱱖";
}

.mdi-triangle-down-outline:before {
  content: "󱱗";
}

.mdi-triangle-outline:before {
  content: "󰔷";
}

.mdi-triangle-small-down:before {
  content: "󱨉";
}

.mdi-triangle-small-up:before {
  content: "󱨊";
}

.mdi-triangle-wave:before {
  content: "󱑼";
}

.mdi-triforce:before {
  content: "󰯙";
}

.mdi-trophy:before {
  content: "󰔸";
}

.mdi-trophy-award:before {
  content: "󰔹";
}

.mdi-trophy-broken:before {
  content: "󰶤";
}

.mdi-trophy-outline:before {
  content: "󰔺";
}

.mdi-trophy-variant:before {
  content: "󰔻";
}

.mdi-trophy-variant-outline:before {
  content: "󰔼";
}

.mdi-truck:before {
  content: "󰔽";
}

.mdi-truck-alert:before {
  content: "󱧞";
}

.mdi-truck-alert-outline:before {
  content: "󱧟";
}

.mdi-truck-cargo-container:before {
  content: "󱣘";
}

.mdi-truck-check:before {
  content: "󰳔";
}

.mdi-truck-check-outline:before {
  content: "󱊚";
}

.mdi-truck-delivery:before {
  content: "󰔾";
}

.mdi-truck-delivery-outline:before {
  content: "󱊛";
}

.mdi-truck-fast:before {
  content: "󰞈";
}

.mdi-truck-fast-outline:before {
  content: "󱊜";
}

.mdi-truck-flatbed:before {
  content: "󱢑";
}

.mdi-truck-minus:before {
  content: "󱦮";
}

.mdi-truck-minus-outline:before {
  content: "󱦽";
}

.mdi-truck-off-road:before {
  content: "󱲞";
}

.mdi-truck-off-road-off:before {
  content: "󱲟";
}

.mdi-truck-outline:before {
  content: "󱊝";
}

.mdi-truck-plus:before {
  content: "󱦭";
}

.mdi-truck-plus-outline:before {
  content: "󱦼";
}

.mdi-truck-remove:before {
  content: "󱦯";
}

.mdi-truck-remove-outline:before {
  content: "󱦾";
}

.mdi-truck-snowflake:before {
  content: "󱦦";
}

.mdi-truck-trailer:before {
  content: "󰜧";
}

.mdi-trumpet:before {
  content: "󱂖";
}

.mdi-tshirt-crew:before {
  content: "󰩻";
}

.mdi-tshirt-crew-outline:before {
  content: "󰔿";
}

.mdi-tshirt-v:before {
  content: "󰩼";
}

.mdi-tshirt-v-outline:before {
  content: "󰕀";
}

.mdi-tsunami:before {
  content: "󱪁";
}

.mdi-tumble-dryer:before {
  content: "󰤗";
}

.mdi-tumble-dryer-alert:before {
  content: "󱆺";
}

.mdi-tumble-dryer-off:before {
  content: "󱆻";
}

.mdi-tune:before {
  content: "󰘮";
}

.mdi-tune-variant:before {
  content: "󱕂";
}

.mdi-tune-vertical:before {
  content: "󰙪";
}

.mdi-tune-vertical-variant:before {
  content: "󱕃";
}

.mdi-tunnel:before {
  content: "󱠽";
}

.mdi-tunnel-outline:before {
  content: "󱠾";
}

.mdi-turbine:before {
  content: "󱪂";
}

.mdi-turkey:before {
  content: "󱜛";
}

.mdi-turnstile:before {
  content: "󰳕";
}

.mdi-turnstile-outline:before {
  content: "󰳖";
}

.mdi-turtle:before {
  content: "󰳗";
}

.mdi-twitch:before {
  content: "󰕃";
}

.mdi-twitter:before {
  content: "󰕄";
}

.mdi-two-factor-authentication:before {
  content: "󰦯";
}

.mdi-typewriter:before {
  content: "󰼭";
}

.mdi-ubisoft:before {
  content: "󰯚";
}

.mdi-ubuntu:before {
  content: "󰕈";
}

.mdi-ufo:before {
  content: "󱃄";
}

.mdi-ufo-outline:before {
  content: "󱃅";
}

.mdi-ultra-high-definition:before {
  content: "󰟹";
}

.mdi-umbraco:before {
  content: "󰕉";
}

.mdi-umbrella:before {
  content: "󰕊";
}

.mdi-umbrella-beach:before {
  content: "󱢊";
}

.mdi-umbrella-beach-outline:before {
  content: "󱢋";
}

.mdi-umbrella-closed:before {
  content: "󰦰";
}

.mdi-umbrella-closed-outline:before {
  content: "󱏢";
}

.mdi-umbrella-closed-variant:before {
  content: "󱏡";
}

.mdi-umbrella-outline:before {
  content: "󰕋";
}

.mdi-underwear-outline:before {
  content: "󱴏";
}

.mdi-undo:before {
  content: "󰕌";
}

.mdi-undo-variant:before {
  content: "󰕍";
}

.mdi-unfold-less-horizontal:before {
  content: "󰕎";
}

.mdi-unfold-less-vertical:before {
  content: "󰝠";
}

.mdi-unfold-more-horizontal:before {
  content: "󰕏";
}

.mdi-unfold-more-vertical:before {
  content: "󰝡";
}

.mdi-ungroup:before {
  content: "󰕐";
}

.mdi-unicode:before {
  content: "󰻐";
}

.mdi-unicorn:before {
  content: "󱗂";
}

.mdi-unicorn-variant:before {
  content: "󱗃";
}

.mdi-unicycle:before {
  content: "󱗥";
}

.mdi-unity:before {
  content: "󰚯";
}

.mdi-unreal:before {
  content: "󰦱";
}

.mdi-update:before {
  content: "󰚰";
}

.mdi-upload:before {
  content: "󰕒";
}

.mdi-upload-box:before {
  content: "󱴐";
}

.mdi-upload-box-outline:before {
  content: "󱴑";
}

.mdi-upload-circle:before {
  content: "󱴒";
}

.mdi-upload-circle-outline:before {
  content: "󱴓";
}

.mdi-upload-lock:before {
  content: "󱍳";
}

.mdi-upload-lock-outline:before {
  content: "󱍴";
}

.mdi-upload-multiple:before {
  content: "󰠽";
}

.mdi-upload-multiple-outline:before {
  content: "󱴔";
}

.mdi-upload-network:before {
  content: "󰛶";
}

.mdi-upload-network-outline:before {
  content: "󰳘";
}

.mdi-upload-off:before {
  content: "󱃆";
}

.mdi-upload-off-outline:before {
  content: "󱃇";
}

.mdi-upload-outline:before {
  content: "󰸇";
}

.mdi-usb:before {
  content: "󰕓";
}

.mdi-usb-c-port:before {
  content: "󱲿";
}

.mdi-usb-flash-drive:before {
  content: "󱊞";
}

.mdi-usb-flash-drive-outline:before {
  content: "󱊟";
}

.mdi-usb-port:before {
  content: "󱇰";
}

.mdi-vacuum:before {
  content: "󱦡";
}

.mdi-vacuum-outline:before {
  content: "󱦢";
}

.mdi-valve:before {
  content: "󱁦";
}

.mdi-valve-closed:before {
  content: "󱁧";
}

.mdi-valve-open:before {
  content: "󱁨";
}

.mdi-van-passenger:before {
  content: "󰟺";
}

.mdi-van-utility:before {
  content: "󰟻";
}

.mdi-vanish:before {
  content: "󰟼";
}

.mdi-vanish-quarter:before {
  content: "󱕔";
}

.mdi-vanity-light:before {
  content: "󱇡";
}

.mdi-variable:before {
  content: "󰫧";
}

.mdi-variable-box:before {
  content: "󱄑";
}

.mdi-vector-arrange-above:before {
  content: "󰕔";
}

.mdi-vector-arrange-below:before {
  content: "󰕕";
}

.mdi-vector-bezier:before {
  content: "󰫨";
}

.mdi-vector-circle:before {
  content: "󰕖";
}

.mdi-vector-circle-variant:before {
  content: "󰕗";
}

.mdi-vector-combine:before {
  content: "󰕘";
}

.mdi-vector-curve:before {
  content: "󰕙";
}

.mdi-vector-difference:before {
  content: "󰕚";
}

.mdi-vector-difference-ab:before {
  content: "󰕛";
}

.mdi-vector-difference-ba:before {
  content: "󰕜";
}

.mdi-vector-ellipse:before {
  content: "󰢓";
}

.mdi-vector-intersection:before {
  content: "󰕝";
}

.mdi-vector-line:before {
  content: "󰕞";
}

.mdi-vector-link:before {
  content: "󰿨";
}

.mdi-vector-point:before {
  content: "󰇄";
}

.mdi-vector-point-edit:before {
  content: "󰧨";
}

.mdi-vector-point-minus:before {
  content: "󱭸";
}

.mdi-vector-point-plus:before {
  content: "󱭹";
}

.mdi-vector-point-select:before {
  content: "󰕟";
}

.mdi-vector-polygon:before {
  content: "󰕠";
}

.mdi-vector-polygon-variant:before {
  content: "󱡖";
}

.mdi-vector-polyline:before {
  content: "󰕡";
}

.mdi-vector-polyline-edit:before {
  content: "󱈥";
}

.mdi-vector-polyline-minus:before {
  content: "󱈦";
}

.mdi-vector-polyline-plus:before {
  content: "󱈧";
}

.mdi-vector-polyline-remove:before {
  content: "󱈨";
}

.mdi-vector-radius:before {
  content: "󰝊";
}

.mdi-vector-rectangle:before {
  content: "󰗆";
}

.mdi-vector-selection:before {
  content: "󰕢";
}

.mdi-vector-square:before {
  content: "󰀁";
}

.mdi-vector-square-close:before {
  content: "󱡗";
}

.mdi-vector-square-edit:before {
  content: "󱣙";
}

.mdi-vector-square-minus:before {
  content: "󱣚";
}

.mdi-vector-square-open:before {
  content: "󱡘";
}

.mdi-vector-square-plus:before {
  content: "󱣛";
}

.mdi-vector-square-remove:before {
  content: "󱣜";
}

.mdi-vector-triangle:before {
  content: "󰕣";
}

.mdi-vector-union:before {
  content: "󰕤";
}

.mdi-vhs:before {
  content: "󰨛";
}

.mdi-vibrate:before {
  content: "󰕦";
}

.mdi-vibrate-off:before {
  content: "󰳙";
}

.mdi-video:before {
  content: "󰕧";
}

.mdi-video-2d:before {
  content: "󱨜";
}

.mdi-video-3d:before {
  content: "󰟽";
}

.mdi-video-3d-off:before {
  content: "󱏙";
}

.mdi-video-3d-variant:before {
  content: "󰻑";
}

.mdi-video-4k-box:before {
  content: "󰠾";
}

.mdi-video-account:before {
  content: "󰤙";
}

.mdi-video-box:before {
  content: "󰃽";
}

.mdi-video-box-off:before {
  content: "󰃾";
}

.mdi-video-check:before {
  content: "󱁩";
}

.mdi-video-check-outline:before {
  content: "󱁪";
}

.mdi-video-high-definition:before {
  content: "󱔮";
}

.mdi-video-image:before {
  content: "󰤚";
}

.mdi-video-input-antenna:before {
  content: "󰠿";
}

.mdi-video-input-component:before {
  content: "󰡀";
}

.mdi-video-input-hdmi:before {
  content: "󰡁";
}

.mdi-video-input-scart:before {
  content: "󰾌";
}

.mdi-video-input-svideo:before {
  content: "󰡂";
}

.mdi-video-marker:before {
  content: "󱦩";
}

.mdi-video-marker-outline:before {
  content: "󱦪";
}

.mdi-video-minus:before {
  content: "󰦲";
}

.mdi-video-minus-outline:before {
  content: "󰊺";
}

.mdi-video-off:before {
  content: "󰕨";
}

.mdi-video-off-outline:before {
  content: "󰯛";
}

.mdi-video-outline:before {
  content: "󰯜";
}

.mdi-video-plus:before {
  content: "󰦳";
}

.mdi-video-plus-outline:before {
  content: "󰇓";
}

.mdi-video-stabilization:before {
  content: "󰤛";
}

.mdi-video-standard-definition:before {
  content: "󱲠";
}

.mdi-video-switch:before {
  content: "󰕩";
}

.mdi-video-switch-outline:before {
  content: "󰞐";
}

.mdi-video-vintage:before {
  content: "󰨜";
}

.mdi-video-wireless:before {
  content: "󰻒";
}

.mdi-video-wireless-outline:before {
  content: "󰻓";
}

.mdi-view-agenda:before {
  content: "󰕪";
}

.mdi-view-agenda-outline:before {
  content: "󱇘";
}

.mdi-view-array:before {
  content: "󰕫";
}

.mdi-view-array-outline:before {
  content: "󱒅";
}

.mdi-view-carousel:before {
  content: "󰕬";
}

.mdi-view-carousel-outline:before {
  content: "󱒆";
}

.mdi-view-column:before {
  content: "󰕭";
}

.mdi-view-column-outline:before {
  content: "󱒇";
}

.mdi-view-comfy:before {
  content: "󰹪";
}

.mdi-view-comfy-outline:before {
  content: "󱒈";
}

.mdi-view-compact:before {
  content: "󰹫";
}

.mdi-view-compact-outline:before {
  content: "󰹬";
}

.mdi-view-dashboard:before {
  content: "󰕮";
}

.mdi-view-dashboard-edit:before {
  content: "󱥇";
}

.mdi-view-dashboard-edit-outline:before {
  content: "󱥈";
}

.mdi-view-dashboard-outline:before {
  content: "󰨝";
}

.mdi-view-dashboard-variant:before {
  content: "󰡃";
}

.mdi-view-dashboard-variant-outline:before {
  content: "󱒉";
}

.mdi-view-day:before {
  content: "󰕯";
}

.mdi-view-day-outline:before {
  content: "󱒊";
}

.mdi-view-gallery:before {
  content: "󱢈";
}

.mdi-view-gallery-outline:before {
  content: "󱢉";
}

.mdi-view-grid:before {
  content: "󰕰";
}

.mdi-view-grid-compact:before {
  content: "󱱡";
}

.mdi-view-grid-outline:before {
  content: "󱇙";
}

.mdi-view-grid-plus:before {
  content: "󰾍";
}

.mdi-view-grid-plus-outline:before {
  content: "󱇚";
}

.mdi-view-headline:before {
  content: "󰕱";
}

.mdi-view-list:before {
  content: "󰕲";
}

.mdi-view-list-outline:before {
  content: "󱒋";
}

.mdi-view-module:before {
  content: "󰕳";
}

.mdi-view-module-outline:before {
  content: "󱒌";
}

.mdi-view-parallel:before {
  content: "󰜨";
}

.mdi-view-parallel-outline:before {
  content: "󱒍";
}

.mdi-view-quilt:before {
  content: "󰕴";
}

.mdi-view-quilt-outline:before {
  content: "󱒎";
}

.mdi-view-sequential:before {
  content: "󰜩";
}

.mdi-view-sequential-outline:before {
  content: "󱒏";
}

.mdi-view-split-horizontal:before {
  content: "󰯋";
}

.mdi-view-split-vertical:before {
  content: "󰯌";
}

.mdi-view-stream:before {
  content: "󰕵";
}

.mdi-view-stream-outline:before {
  content: "󱒐";
}

.mdi-view-week:before {
  content: "󰕶";
}

.mdi-view-week-outline:before {
  content: "󱒑";
}

.mdi-vimeo:before {
  content: "󰕷";
}

.mdi-violin:before {
  content: "󰘏";
}

.mdi-virtual-reality:before {
  content: "󰢔";
}

.mdi-virus:before {
  content: "󱎶";
}

.mdi-virus-off:before {
  content: "󱣡";
}

.mdi-virus-off-outline:before {
  content: "󱣢";
}

.mdi-virus-outline:before {
  content: "󱎷";
}

.mdi-vlc:before {
  content: "󰕼";
}

.mdi-voicemail:before {
  content: "󰕽";
}

.mdi-volcano:before {
  content: "󱪃";
}

.mdi-volcano-outline:before {
  content: "󱪄";
}

.mdi-volleyball:before {
  content: "󰦴";
}

.mdi-volume-equal:before {
  content: "󱬐";
}

.mdi-volume-high:before {
  content: "󰕾";
}

.mdi-volume-low:before {
  content: "󰕿";
}

.mdi-volume-medium:before {
  content: "󰖀";
}

.mdi-volume-minus:before {
  content: "󰝞";
}

.mdi-volume-mute:before {
  content: "󰝟";
}

.mdi-volume-off:before {
  content: "󰖁";
}

.mdi-volume-plus:before {
  content: "󰝝";
}

.mdi-volume-source:before {
  content: "󱄠";
}

.mdi-volume-variant-off:before {
  content: "󰸈";
}

.mdi-volume-vibrate:before {
  content: "󱄡";
}

.mdi-vote:before {
  content: "󰨟";
}

.mdi-vote-outline:before {
  content: "󰨠";
}

.mdi-vpn:before {
  content: "󰖂";
}

.mdi-vuejs:before {
  content: "󰡄";
}

.mdi-vuetify:before {
  content: "󰹭";
}

.mdi-walk:before {
  content: "󰖃";
}

.mdi-wall:before {
  content: "󰟾";
}

.mdi-wall-fire:before {
  content: "󱨑";
}

.mdi-wall-sconce:before {
  content: "󰤜";
}

.mdi-wall-sconce-flat:before {
  content: "󰤝";
}

.mdi-wall-sconce-flat-outline:before {
  content: "󱟉";
}

.mdi-wall-sconce-flat-variant:before {
  content: "󰐜";
}

.mdi-wall-sconce-flat-variant-outline:before {
  content: "󱟊";
}

.mdi-wall-sconce-outline:before {
  content: "󱟋";
}

.mdi-wall-sconce-round:before {
  content: "󰝈";
}

.mdi-wall-sconce-round-outline:before {
  content: "󱟌";
}

.mdi-wall-sconce-round-variant:before {
  content: "󰤞";
}

.mdi-wall-sconce-round-variant-outline:before {
  content: "󱟍";
}

.mdi-wallet:before {
  content: "󰖄";
}

.mdi-wallet-bifold:before {
  content: "󱱘";
}

.mdi-wallet-bifold-outline:before {
  content: "󱱙";
}

.mdi-wallet-giftcard:before {
  content: "󰖅";
}

.mdi-wallet-membership:before {
  content: "󰖆";
}

.mdi-wallet-outline:before {
  content: "󰯝";
}

.mdi-wallet-plus:before {
  content: "󰾎";
}

.mdi-wallet-plus-outline:before {
  content: "󰾏";
}

.mdi-wallet-travel:before {
  content: "󰖇";
}

.mdi-wallpaper:before {
  content: "󰸉";
}

.mdi-wan:before {
  content: "󰖈";
}

.mdi-wardrobe:before {
  content: "󰾐";
}

.mdi-wardrobe-outline:before {
  content: "󰾑";
}

.mdi-warehouse:before {
  content: "󰾁";
}

.mdi-washing-machine:before {
  content: "󰜪";
}

.mdi-washing-machine-alert:before {
  content: "󱆼";
}

.mdi-washing-machine-off:before {
  content: "󱆽";
}

.mdi-watch:before {
  content: "󰖉";
}

.mdi-watch-export:before {
  content: "󰖊";
}

.mdi-watch-export-variant:before {
  content: "󰢕";
}

.mdi-watch-import:before {
  content: "󰖋";
}

.mdi-watch-import-variant:before {
  content: "󰢖";
}

.mdi-watch-variant:before {
  content: "󰢗";
}

.mdi-watch-vibrate:before {
  content: "󰚱";
}

.mdi-watch-vibrate-off:before {
  content: "󰳚";
}

.mdi-water:before {
  content: "󰖌";
}

.mdi-water-alert:before {
  content: "󱔂";
}

.mdi-water-alert-outline:before {
  content: "󱔃";
}

.mdi-water-boiler:before {
  content: "󰾒";
}

.mdi-water-boiler-alert:before {
  content: "󱆳";
}

.mdi-water-boiler-auto:before {
  content: "󱮘";
}

.mdi-water-boiler-off:before {
  content: "󱆴";
}

.mdi-water-check:before {
  content: "󱔄";
}

.mdi-water-check-outline:before {
  content: "󱔅";
}

.mdi-water-circle:before {
  content: "󱠆";
}

.mdi-water-minus:before {
  content: "󱔆";
}

.mdi-water-minus-outline:before {
  content: "󱔇";
}

.mdi-water-off:before {
  content: "󰖍";
}

.mdi-water-off-outline:before {
  content: "󱔈";
}

.mdi-water-opacity:before {
  content: "󱡕";
}

.mdi-water-outline:before {
  content: "󰸊";
}

.mdi-water-percent:before {
  content: "󰖎";
}

.mdi-water-percent-alert:before {
  content: "󱔉";
}

.mdi-water-plus:before {
  content: "󱔊";
}

.mdi-water-plus-outline:before {
  content: "󱔋";
}

.mdi-water-polo:before {
  content: "󱊠";
}

.mdi-water-pump:before {
  content: "󰖏";
}

.mdi-water-pump-off:before {
  content: "󰾓";
}

.mdi-water-remove:before {
  content: "󱔌";
}

.mdi-water-remove-outline:before {
  content: "󱔍";
}

.mdi-water-sync:before {
  content: "󱟆";
}

.mdi-water-thermometer:before {
  content: "󱪅";
}

.mdi-water-thermometer-outline:before {
  content: "󱪆";
}

.mdi-water-well:before {
  content: "󱁫";
}

.mdi-water-well-outline:before {
  content: "󱁬";
}

.mdi-waterfall:before {
  content: "󱡉";
}

.mdi-watering-can:before {
  content: "󱒁";
}

.mdi-watering-can-outline:before {
  content: "󱒂";
}

.mdi-watermark:before {
  content: "󰘒";
}

.mdi-wave:before {
  content: "󰼮";
}

.mdi-wave-arrow-down:before {
  content: "󱲰";
}

.mdi-wave-arrow-up:before {
  content: "󱲱";
}

.mdi-wave-undercurrent:before {
  content: "󱳀";
}

.mdi-waveform:before {
  content: "󱑽";
}

.mdi-waves:before {
  content: "󰞍";
}

.mdi-waves-arrow-left:before {
  content: "󱡙";
}

.mdi-waves-arrow-right:before {
  content: "󱡚";
}

.mdi-waves-arrow-up:before {
  content: "󱡛";
}

.mdi-waze:before {
  content: "󰯞";
}

.mdi-weather-cloudy:before {
  content: "󰖐";
}

.mdi-weather-cloudy-alert:before {
  content: "󰼯";
}

.mdi-weather-cloudy-arrow-right:before {
  content: "󰹮";
}

.mdi-weather-cloudy-clock:before {
  content: "󱣶";
}

.mdi-weather-dust:before {
  content: "󱭚";
}

.mdi-weather-fog:before {
  content: "󰖑";
}

.mdi-weather-hail:before {
  content: "󰖒";
}

.mdi-weather-hazy:before {
  content: "󰼰";
}

.mdi-weather-hurricane:before {
  content: "󰢘";
}

.mdi-weather-hurricane-outline:before {
  content: "󱱸";
}

.mdi-weather-lightning:before {
  content: "󰖓";
}

.mdi-weather-lightning-rainy:before {
  content: "󰙾";
}

.mdi-weather-moonset:before {
  content: "󱴕";
}

.mdi-weather-moonset-down:before {
  content: "󱴖";
}

.mdi-weather-moonset-up:before {
  content: "󱴗";
}

.mdi-weather-night:before {
  content: "󰖔";
}

.mdi-weather-night-partly-cloudy:before {
  content: "󰼱";
}

.mdi-weather-partly-cloudy:before {
  content: "󰖕";
}

.mdi-weather-partly-lightning:before {
  content: "󰼲";
}

.mdi-weather-partly-rainy:before {
  content: "󰼳";
}

.mdi-weather-partly-snowy:before {
  content: "󰼴";
}

.mdi-weather-partly-snowy-rainy:before {
  content: "󰼵";
}

.mdi-weather-pouring:before {
  content: "󰖖";
}

.mdi-weather-rainy:before {
  content: "󰖗";
}

.mdi-weather-snowy:before {
  content: "󰖘";
}

.mdi-weather-snowy-heavy:before {
  content: "󰼶";
}

.mdi-weather-snowy-rainy:before {
  content: "󰙿";
}

.mdi-weather-sunny:before {
  content: "󰖙";
}

.mdi-weather-sunny-alert:before {
  content: "󰼷";
}

.mdi-weather-sunny-off:before {
  content: "󱓤";
}

.mdi-weather-sunset:before {
  content: "󰖚";
}

.mdi-weather-sunset-down:before {
  content: "󰖛";
}

.mdi-weather-sunset-up:before {
  content: "󰖜";
}

.mdi-weather-tornado:before {
  content: "󰼸";
}

.mdi-weather-windy:before {
  content: "󰖝";
}

.mdi-weather-windy-variant:before {
  content: "󰖞";
}

.mdi-web:before {
  content: "󰖟";
}

.mdi-web-box:before {
  content: "󰾔";
}

.mdi-web-cancel:before {
  content: "󱞐";
}

.mdi-web-check:before {
  content: "󰞉";
}

.mdi-web-clock:before {
  content: "󱉊";
}

.mdi-web-minus:before {
  content: "󱂠";
}

.mdi-web-off:before {
  content: "󰪎";
}

.mdi-web-plus:before {
  content: "󰀳";
}

.mdi-web-refresh:before {
  content: "󱞑";
}

.mdi-web-remove:before {
  content: "󰕑";
}

.mdi-web-sync:before {
  content: "󱞒";
}

.mdi-webcam:before {
  content: "󰖠";
}

.mdi-webcam-off:before {
  content: "󱜷";
}

.mdi-webhook:before {
  content: "󰘯";
}

.mdi-webpack:before {
  content: "󰜫";
}

.mdi-webrtc:before {
  content: "󱉈";
}

.mdi-wechat:before {
  content: "󰘑";
}

.mdi-weight:before {
  content: "󰖡";
}

.mdi-weight-gram:before {
  content: "󰴿";
}

.mdi-weight-kilogram:before {
  content: "󰖢";
}

.mdi-weight-lifter:before {
  content: "󱅝";
}

.mdi-weight-pound:before {
  content: "󰦵";
}

.mdi-whatsapp:before {
  content: "󰖣";
}

.mdi-wheel-barrow:before {
  content: "󱓲";
}

.mdi-wheelchair:before {
  content: "󱪇";
}

.mdi-wheelchair-accessibility:before {
  content: "󰖤";
}

.mdi-whistle:before {
  content: "󰦶";
}

.mdi-whistle-outline:before {
  content: "󱊼";
}

.mdi-white-balance-auto:before {
  content: "󰖥";
}

.mdi-white-balance-incandescent:before {
  content: "󰖦";
}

.mdi-white-balance-iridescent:before {
  content: "󰖧";
}

.mdi-white-balance-sunny:before {
  content: "󰖨";
}

.mdi-widgets:before {
  content: "󰜬";
}

.mdi-widgets-outline:before {
  content: "󱍕";
}

.mdi-wifi:before {
  content: "󰖩";
}

.mdi-wifi-alert:before {
  content: "󱚵";
}

.mdi-wifi-arrow-down:before {
  content: "󱚶";
}

.mdi-wifi-arrow-left:before {
  content: "󱚷";
}

.mdi-wifi-arrow-left-right:before {
  content: "󱚸";
}

.mdi-wifi-arrow-right:before {
  content: "󱚹";
}

.mdi-wifi-arrow-up:before {
  content: "󱚺";
}

.mdi-wifi-arrow-up-down:before {
  content: "󱚻";
}

.mdi-wifi-cancel:before {
  content: "󱚼";
}

.mdi-wifi-check:before {
  content: "󱚽";
}

.mdi-wifi-cog:before {
  content: "󱚾";
}

.mdi-wifi-lock:before {
  content: "󱚿";
}

.mdi-wifi-lock-open:before {
  content: "󱛀";
}

.mdi-wifi-marker:before {
  content: "󱛁";
}

.mdi-wifi-minus:before {
  content: "󱛂";
}

.mdi-wifi-off:before {
  content: "󰖪";
}

.mdi-wifi-plus:before {
  content: "󱛃";
}

.mdi-wifi-refresh:before {
  content: "󱛄";
}

.mdi-wifi-remove:before {
  content: "󱛅";
}

.mdi-wifi-settings:before {
  content: "󱛆";
}

.mdi-wifi-star:before {
  content: "󰸋";
}

.mdi-wifi-strength-1:before {
  content: "󰤟";
}

.mdi-wifi-strength-1-alert:before {
  content: "󰤠";
}

.mdi-wifi-strength-1-lock:before {
  content: "󰤡";
}

.mdi-wifi-strength-1-lock-open:before {
  content: "󱛋";
}

.mdi-wifi-strength-2:before {
  content: "󰤢";
}

.mdi-wifi-strength-2-alert:before {
  content: "󰤣";
}

.mdi-wifi-strength-2-lock:before {
  content: "󰤤";
}

.mdi-wifi-strength-2-lock-open:before {
  content: "󱛌";
}

.mdi-wifi-strength-3:before {
  content: "󰤥";
}

.mdi-wifi-strength-3-alert:before {
  content: "󰤦";
}

.mdi-wifi-strength-3-lock:before {
  content: "󰤧";
}

.mdi-wifi-strength-3-lock-open:before {
  content: "󱛍";
}

.mdi-wifi-strength-4:before {
  content: "󰤨";
}

.mdi-wifi-strength-4-alert:before {
  content: "󰤩";
}

.mdi-wifi-strength-4-lock:before {
  content: "󰤪";
}

.mdi-wifi-strength-4-lock-open:before {
  content: "󱛎";
}

.mdi-wifi-strength-alert-outline:before {
  content: "󰤫";
}

.mdi-wifi-strength-lock-open-outline:before {
  content: "󱛏";
}

.mdi-wifi-strength-lock-outline:before {
  content: "󰤬";
}

.mdi-wifi-strength-off:before {
  content: "󰤭";
}

.mdi-wifi-strength-off-outline:before {
  content: "󰤮";
}

.mdi-wifi-strength-outline:before {
  content: "󰤯";
}

.mdi-wifi-sync:before {
  content: "󱛇";
}

.mdi-wikipedia:before {
  content: "󰖬";
}

.mdi-wind-power:before {
  content: "󱪈";
}

.mdi-wind-power-outline:before {
  content: "󱪉";
}

.mdi-wind-turbine:before {
  content: "󰶥";
}

.mdi-wind-turbine-alert:before {
  content: "󱦫";
}

.mdi-wind-turbine-check:before {
  content: "󱦬";
}

.mdi-window-close:before {
  content: "󰖭";
}

.mdi-window-closed:before {
  content: "󰖮";
}

.mdi-window-closed-variant:before {
  content: "󱇛";
}

.mdi-window-maximize:before {
  content: "󰖯";
}

.mdi-window-minimize:before {
  content: "󰖰";
}

.mdi-window-open:before {
  content: "󰖱";
}

.mdi-window-open-variant:before {
  content: "󱇜";
}

.mdi-window-restore:before {
  content: "󰖲";
}

.mdi-window-shutter:before {
  content: "󱄜";
}

.mdi-window-shutter-alert:before {
  content: "󱄝";
}

.mdi-window-shutter-auto:before {
  content: "󱮣";
}

.mdi-window-shutter-cog:before {
  content: "󱪊";
}

.mdi-window-shutter-open:before {
  content: "󱄞";
}

.mdi-window-shutter-settings:before {
  content: "󱪋";
}

.mdi-windsock:before {
  content: "󱗺";
}

.mdi-wiper:before {
  content: "󰫩";
}

.mdi-wiper-wash:before {
  content: "󰶦";
}

.mdi-wiper-wash-alert:before {
  content: "󱣟";
}

.mdi-wizard-hat:before {
  content: "󱑷";
}

.mdi-wordpress:before {
  content: "󰖴";
}

.mdi-wrap:before {
  content: "󰖶";
}

.mdi-wrap-disabled:before {
  content: "󰯟";
}

.mdi-wrench:before {
  content: "󰖷";
}

.mdi-wrench-check:before {
  content: "󱮏";
}

.mdi-wrench-check-outline:before {
  content: "󱮐";
}

.mdi-wrench-clock:before {
  content: "󱦣";
}

.mdi-wrench-clock-outline:before {
  content: "󱮓";
}

.mdi-wrench-cog:before {
  content: "󱮑";
}

.mdi-wrench-cog-outline:before {
  content: "󱮒";
}

.mdi-wrench-outline:before {
  content: "󰯠";
}

.mdi-xamarin:before {
  content: "󰡅";
}

.mdi-xml:before {
  content: "󰗀";
}

.mdi-xmpp:before {
  content: "󰟿";
}

.mdi-yahoo:before {
  content: "󰭏";
}

.mdi-yeast:before {
  content: "󰗁";
}

.mdi-yin-yang:before {
  content: "󰚀";
}

.mdi-yoga:before {
  content: "󱅼";
}

.mdi-youtube:before {
  content: "󰗃";
}

.mdi-youtube-gaming:before {
  content: "󰡈";
}

.mdi-youtube-studio:before {
  content: "󰡇";
}

.mdi-youtube-subscription:before {
  content: "󰵀";
}

.mdi-youtube-tv:before {
  content: "󰑈";
}

.mdi-yurt:before {
  content: "󱔖";
}

.mdi-z-wave:before {
  content: "󰫪";
}

.mdi-zend:before {
  content: "󰫫";
}

.mdi-zigbee:before {
  content: "󰵁";
}

.mdi-zip-box:before {
  content: "󰗄";
}

.mdi-zip-box-outline:before {
  content: "󰿺";
}

.mdi-zip-disk:before {
  content: "󰨣";
}

.mdi-zodiac-aquarius:before {
  content: "󰩽";
}

.mdi-zodiac-aries:before {
  content: "󰩾";
}

.mdi-zodiac-cancer:before {
  content: "󰩿";
}

.mdi-zodiac-capricorn:before {
  content: "󰪀";
}

.mdi-zodiac-gemini:before {
  content: "󰪁";
}

.mdi-zodiac-leo:before {
  content: "󰪂";
}

.mdi-zodiac-libra:before {
  content: "󰪃";
}

.mdi-zodiac-pisces:before {
  content: "󰪄";
}

.mdi-zodiac-sagittarius:before {
  content: "󰪅";
}

.mdi-zodiac-scorpio:before {
  content: "󰪆";
}

.mdi-zodiac-taurus:before {
  content: "󰪇";
}

.mdi-zodiac-virgo:before {
  content: "󰪈";
}

.mdi-blank:before {
  content: "";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: #0000008a;
}

.mdi-dark.mdi-inactive:before {
  color: #00000042;
}

.mdi-light:before {
  color: #fff;
}

.mdi-light.mdi-inactive:before {
  color: #ffffff4d;
}

.mdi-rotate-45:before {
  transform: rotate(45deg);
}

.mdi-rotate-90:before {
  transform: rotate(90deg);
}

.mdi-rotate-135:before {
  transform: rotate(135deg);
}

.mdi-rotate-180:before {
  transform: rotate(180deg);
}

.mdi-rotate-225:before {
  transform: rotate(225deg);
}

.mdi-rotate-270:before {
  transform: rotate(270deg);
}

.mdi-rotate-315:before {
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  filter: FlipH;
  -ms-filter: "FlipH";
  transform: scaleX(-1);
}

.mdi-flip-v:before {
  filter: FlipV;
  -ms-filter: "FlipV";
  transform: scaleY(-1);
}

.mdi-spin:before {
  animation: 2s linear infinite mdi-spin;
}

@keyframes mdi-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .taginput .taginput-container.is-focusable, .textarea, .input, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  height: 2.5em;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus, .pagination-ellipsis:focus, .file-cta:focus, .file-name:focus, .select select:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .input:focus, .button:focus, .is-focused.pagination-previous, .is-focused.pagination-next, .is-focused.pagination-link, .is-focused.pagination-ellipsis, .is-focused.file-cta, .is-focused.file-name, .select select.is-focused, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .is-focused.input, .is-focused.button, .pagination-previous:active, .pagination-next:active, .pagination-link:active, .pagination-ellipsis:active, .file-cta:active, .file-name:active, .select select:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .input:active, .button:active, .is-active.pagination-previous, .is-active.pagination-next, .is-active.pagination-link, .is-active.pagination-ellipsis, .is-active.file-cta, .is-active.file-name, .select select.is-active, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .is-active.input, .is-active.button {
  outline: none;
}

[disabled].pagination-previous, [disabled].pagination-next, [disabled].pagination-link, [disabled].pagination-ellipsis, [disabled].file-cta, [disabled].file-name, .select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, [disabled].button, fieldset[disabled] .pagination-previous, fieldset[disabled] .pagination-next, fieldset[disabled] .pagination-link, fieldset[disabled] .pagination-ellipsis, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .button {
  cursor: not-allowed;
}

.switch, .b-radio.radio, .b-checkbox.checkbox, .carousel-list, .carousel, .is-unselectable, .tabs, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .breadcrumb, .file, .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  content: " ";
  pointer-events: none;
  transform-origin: center;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  width: .625em;
  height: .625em;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.tabs:not(:last-child), .pagination:not(:last-child), .message:not(:last-child), .level:not(:last-child), .breadcrumb:not(:last-child), .block:not(:last-child), .title:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .progress:not(:last-child), .progress-wrapper.is-not-native:not(:last-child), .notification:not(:last-child), .content:not(:last-child), .box:not(:last-child) {
  margin-bottom: 1.5rem;
}

.modal-close, .delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  vertical-align: top;
  background-color: #0a0a0a33;
  border: none;
  border-radius: 9999px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.modal-close:before, .delete:before, .modal-close:after, .delete:after {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.modal-close:before, .delete:before {
  width: 50%;
  height: 2px;
}

.modal-close:after, .delete:after {
  width: 2px;
  height: 50%;
}

.modal-close:hover, .delete:hover, .modal-close:focus, .delete:focus {
  background-color: #0a0a0a4d;
}

.modal-close:active, .delete:active {
  background-color: #0a0a0a66;
}

.is-small.modal-close, .is-small.delete {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.is-medium.modal-close, .is-medium.delete {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.is-large.modal-close, .is-large.delete {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.control.is-loading:after, .select.is-loading:after, .loader, .button.is-loading:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.b-image-wrapper > img.has-ratio, .b-image-wrapper > img.placeholder, .hero-video, .is-overlay, .modal-background, .modal, .image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  position: absolute;
  inset: 0;
}

.navbar-burger {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  min-width: 300px;
  font-size: 16px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #7957d5;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.box {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.25rem;
  display: block;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

a.box:hover, a.box:focus {
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #7957d5;
}

a.box:active {
  box-shadow: inset 0 1px 2px #0a0a0a33, 0 0 0 1px #7957d5;
}

.button {
  color: #363636;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-width: 1px;
  border-color: #dbdbdb;
  justify-content: center;
  padding: calc(.5em - 1px) 1em;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  width: 1.5em;
  height: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.5em - 1px);
  margin-right: .25em;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .25em;
  margin-right: calc(-.5em - 1px);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.5em - 1px);
  margin-right: calc(-.5em - 1px);
}

.button:hover, .button.is-hovered {
  color: #363636;
  border-color: #b5b5b5;
}

.button:focus, .button.is-focused {
  color: #363636;
  border-color: #7957d5;
}

.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #7957d540;
}

.button:active, .button.is-active {
  color: #363636;
  border-color: #4a4a4a;
}

.button.is-text {
  color: #4a4a4a;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-text:active, .button.is-text.is-active {
  color: #363636;
  background-color: #e8e8e8;
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-ghost {
  color: #7957d5;
  background: none;
  border-color: #0000;
  text-decoration: none;
}

.button.is-ghost:hover, .button.is-ghost.is-hovered {
  color: #7957d5;
  text-decoration: underline;
}

.button.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white:hover, .button.is-white.is-hovered {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: #0000;
}

.button.is-white:focus, .button.is-white.is-focused {
  color: #0a0a0a;
  border-color: #0000;
}

.button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.button.is-white:active, .button.is-white.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  box-shadow: none;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-inverted {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
  background-color: #000;
}

.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-white.is-loading:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-outlined.is-loading:hover:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .button.is-white.is-outlined.is-loading:focus:after, .button.is-white.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
  color: #fff;
  background-color: #0a0a0a;
}

.button.is-white.is-inverted.is-outlined.is-loading:hover:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.button.is-black:hover, .button.is-black.is-hovered {
  color: #fff;
  background-color: #040404;
  border-color: #0000;
}

.button.is-black:focus, .button.is-black.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.button.is-black:active, .button.is-black.is-active {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  box-shadow: none;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.button.is-black.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined {
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-outlined.is-loading:hover:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .button.is-black.is-outlined.is-loading:focus:after, .button.is-black.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  box-shadow: none;
  color: #0a0a0a;
  background-color: #0000;
  border-color: #0a0a0a;
}

.button.is-black.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
  color: #0a0a0a;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading:hover:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #0a0a0a #0a0a0a !important;
}

.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.button.is-light:hover, .button.is-light.is-hovered {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.button.is-light:focus, .button.is-light.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.button.is-light:active, .button.is-light.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  box-shadow: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-light.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined {
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-outlined.is-loading:hover:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .button.is-light.is-outlined.is-loading:focus:after, .button.is-light.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined.is-loading:hover:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: #0000;
}

.button.is-dark:hover, .button.is-dark.is-hovered {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #0000;
}

.button.is-dark:focus, .button.is-dark.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #36363640;
}

.button.is-dark:active, .button.is-dark.is-active {
  color: #fff;
  background-color: #292929;
  border-color: #0000;
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  box-shadow: none;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-inverted {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  box-shadow: none;
  color: #363636;
  background-color: #fff;
  border-color: #0000;
}

.button.is-dark.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined {
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-outlined.is-loading:hover:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-outlined.is-loading:focus:after, .button.is-dark.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  box-shadow: none;
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted.is-outlined.is-loading:hover:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary {
  color: #fff;
  background-color: #7957d5;
  border-color: #0000;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
  color: #fff;
  background-color: #714dd2;
  border-color: #0000;
}

.button.is-primary:focus, .button.is-primary.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #7957d540;
}

.button.is-primary:active, .button.is-primary.is-active {
  color: #fff;
  background-color: #6943d0;
  border-color: #0000;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  box-shadow: none;
  background-color: #7957d5;
  border-color: #7957d5;
}

.button.is-primary.is-inverted {
  color: #7957d5;
  background-color: #fff;
}

.button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  box-shadow: none;
  color: #7957d5;
  background-color: #fff;
  border-color: #0000;
}

.button.is-primary.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined {
  color: #7957d5;
  background-color: #0000;
  border-color: #7957d5;
}

.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
  color: #fff;
  background-color: #7957d5;
  border-color: #7957d5;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #7957d5 #7957d5 !important;
}

.button.is-primary.is-outlined.is-loading:hover:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-outlined.is-loading:focus:after, .button.is-primary.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  box-shadow: none;
  color: #7957d5;
  background-color: #0000;
  border-color: #7957d5;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
  color: #7957d5;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading:hover:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #7957d5 #7957d5 !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-light {
  color: #552fbc;
  background-color: #f2effb;
}

.button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
  color: #552fbc;
  background-color: #eae4f8;
  border-color: #0000;
}

.button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
  color: #552fbc;
  background-color: #e2daf6;
  border-color: #0000;
}

.button.is-link {
  color: #fff;
  background-color: #7957d5;
  border-color: #0000;
}

.button.is-link:hover, .button.is-link.is-hovered {
  color: #fff;
  background-color: #714dd2;
  border-color: #0000;
}

.button.is-link:focus, .button.is-link.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #7957d540;
}

.button.is-link:active, .button.is-link.is-active {
  color: #fff;
  background-color: #6943d0;
  border-color: #0000;
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  box-shadow: none;
  background-color: #7957d5;
  border-color: #7957d5;
}

.button.is-link.is-inverted {
  color: #7957d5;
  background-color: #fff;
}

.button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], fieldset[disabled] .button.is-link.is-inverted {
  box-shadow: none;
  color: #7957d5;
  background-color: #fff;
  border-color: #0000;
}

.button.is-link.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined {
  color: #7957d5;
  background-color: #0000;
  border-color: #7957d5;
}

.button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
  color: #fff;
  background-color: #7957d5;
  border-color: #7957d5;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: #0000 #0000 #7957d5 #7957d5 !important;
}

.button.is-link.is-outlined.is-loading:hover:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .button.is-link.is-outlined.is-loading:focus:after, .button.is-link.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined[disabled], fieldset[disabled] .button.is-link.is-outlined {
  box-shadow: none;
  color: #7957d5;
  background-color: #0000;
  border-color: #7957d5;
}

.button.is-link.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
  color: #7957d5;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading:hover:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #7957d5 #7957d5 !important;
}

.button.is-link.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-link.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-light {
  color: #552fbc;
  background-color: #f2effb;
}

.button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
  color: #552fbc;
  background-color: #eae4f8;
  border-color: #0000;
}

.button.is-link.is-light:active, .button.is-link.is-light.is-active {
  color: #552fbc;
  background-color: #e2daf6;
  border-color: #0000;
}

.button.is-info {
  color: #fff;
  background-color: #167df0;
  border-color: #0000;
}

.button.is-info:hover, .button.is-info.is-hovered {
  color: #fff;
  background-color: #0f77ea;
  border-color: #0000;
}

.button.is-info:focus, .button.is-info.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #167df040;
}

.button.is-info:active, .button.is-info.is-active {
  color: #fff;
  background-color: #0e71de;
  border-color: #0000;
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  box-shadow: none;
  background-color: #167df0;
  border-color: #167df0;
}

.button.is-info.is-inverted {
  color: #167df0;
  background-color: #fff;
}

.button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  box-shadow: none;
  color: #167df0;
  background-color: #fff;
  border-color: #0000;
}

.button.is-info.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined {
  color: #167df0;
  background-color: #0000;
  border-color: #167df0;
}

.button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
  color: #fff;
  background-color: #167df0;
  border-color: #167df0;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: #0000 #0000 #167df0 #167df0 !important;
}

.button.is-info.is-outlined.is-loading:hover:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .button.is-info.is-outlined.is-loading:focus:after, .button.is-info.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  box-shadow: none;
  color: #167df0;
  background-color: #0000;
  border-color: #167df0;
}

.button.is-info.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
  color: #167df0;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading:hover:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #167df0 #167df0 !important;
}

.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-light {
  color: #0d68ce;
  background-color: #ecf4fe;
}

.button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
  color: #0d68ce;
  background-color: #e0eefd;
  border-color: #0000;
}

.button.is-info.is-light:active, .button.is-info.is-light.is-active {
  color: #0d68ce;
  background-color: #d4e7fc;
  border-color: #0000;
}

.button.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: #0000;
}

.button.is-success:hover, .button.is-success.is-hovered {
  color: #fff;
  background-color: #3ec487;
  border-color: #0000;
}

.button.is-success:focus, .button.is-success.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.button.is-success:active, .button.is-success.is-active {
  color: #fff;
  background-color: #3abb81;
  border-color: #0000;
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  box-shadow: none;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-inverted {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  box-shadow: none;
  color: #48c78e;
  background-color: #fff;
  border-color: #0000;
}

.button.is-success.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined {
  color: #48c78e;
  background-color: #0000;
  border-color: #48c78e;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: #0000 #0000 #48c78e #48c78e !important;
}

.button.is-success.is-outlined.is-loading:hover:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .button.is-success.is-outlined.is-loading:focus:after, .button.is-success.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  box-shadow: none;
  color: #48c78e;
  background-color: #0000;
  border-color: #48c78e;
}

.button.is-success.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-loading:hover:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #48c78e #48c78e !important;
}

.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
  color: #257953;
  background-color: #e6f7ef;
  border-color: #0000;
}

.button.is-success.is-light:active, .button.is-success.is-light.is-active {
  color: #257953;
  background-color: #dcf4e9;
  border-color: #0000;
}

.button.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #0000;
}

.button.is-warning:hover, .button.is-warning.is-hovered {
  color: #000000b3;
  background-color: #ffdc7d;
  border-color: #0000;
}

.button.is-warning:focus, .button.is-warning.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.button.is-warning:active, .button.is-warning.is-active {
  color: #000000b3;
  background-color: #ffd970;
  border-color: #0000;
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  box-shadow: none;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-inverted {
  color: #ffe08a;
  background-color: #000000b3;
}

.button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  box-shadow: none;
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-warning.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined {
  color: #ffe08a;
  background-color: #0000;
  border-color: #ffe08a;
}

.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: #0000 #0000 #ffe08a #ffe08a !important;
}

.button.is-warning.is-outlined.is-loading:hover:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-outlined.is-loading:focus:after, .button.is-warning.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  box-shadow: none;
  color: #ffe08a;
  background-color: #0000;
  border-color: #ffe08a;
}

.button.is-warning.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
  color: #ffe08a;
  background-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined.is-loading:hover:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #ffe08a #ffe08a !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
  color: #946c00;
  background-color: #fff6de;
  border-color: #0000;
}

.button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
  color: #946c00;
  background-color: #fff3d1;
  border-color: #0000;
}

.button.is-danger {
  color: #fff;
  background-color: #f14668;
  border-color: #0000;
}

.button.is-danger:hover, .button.is-danger.is-hovered {
  color: #fff;
  background-color: #f03a5f;
  border-color: #0000;
}

.button.is-danger:focus, .button.is-danger.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #f1466840;
}

.button.is-danger:active, .button.is-danger.is-active {
  color: #fff;
  background-color: #ef2e55;
  border-color: #0000;
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  box-shadow: none;
  background-color: #f14668;
  border-color: #f14668;
}

.button.is-danger.is-inverted {
  color: #f14668;
  background-color: #fff;
}

.button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  box-shadow: none;
  color: #f14668;
  background-color: #fff;
  border-color: #0000;
}

.button.is-danger.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined {
  color: #f14668;
  background-color: #0000;
  border-color: #f14668;
}

.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
  color: #fff;
  background-color: #f14668;
  border-color: #f14668;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f14668 #f14668 !important;
}

.button.is-danger.is-outlined.is-loading:hover:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-outlined.is-loading:focus:after, .button.is-danger.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  box-shadow: none;
  color: #f14668;
  background-color: #0000;
  border-color: #f14668;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
  color: #f14668;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading:hover:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #f14668 #f14668 !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
  color: #cc0f35;
  background-color: #fde0e6;
  border-color: #0000;
}

.button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
  color: #cc0f35;
  background-color: #fcd4dc;
  border-color: #0000;
}

.button.is-small {
  font-size: .75rem;
}

.button.is-small:not(.is-rounded) {
  border-radius: 2px;
}

.button.is-normal {
  font-size: 1rem;
}

.button.is-medium {
  font-size: 1.25rem;
}

.button.is-large {
  font-size: 1.5rem;
}

.button[disabled], fieldset[disabled] .button {
  box-shadow: none;
  opacity: .5;
  background-color: #fff;
  border-color: #dbdbdb;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #7a7a7a;
  box-shadow: none;
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.button.is-rounded {
  border-radius: 9999px;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  font-size: .75rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
  border-radius: 2px;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 1.25rem;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 1.5rem;
}

.buttons.has-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child {
  margin-right: 0;
}

.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

@media screen and (width <= 768px) {
  .button.is-responsive.is-small {
    font-size: .5625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .65625rem;
  }

  .button.is-responsive.is-medium {
    font-size: .75rem;
  }

  .button.is-responsive.is-large {
    font-size: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .button.is-responsive.is-small {
    font-size: .65625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .75rem;
  }

  .button.is-responsive.is-medium {
    font-size: 1rem;
  }

  .button.is-responsive.is-large {
    font-size: 1.25rem;
  }
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (width <= 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width <= 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (width >= 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width >= 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #f5f5f5;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: #363636;
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: .75rem;
}

.content.is-normal {
  font-size: 1rem;
}

.content.is-medium {
  font-size: 1.25rem;
}

.content.is-large {
  font-size: 1.5rem;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
}

.icon.is-small {
  width: 1rem;
  height: 1rem;
}

.icon.is-medium {
  width: 2rem;
  height: 2rem;
}

.icon.is-large {
  width: 3rem;
  height: 3rem;
}

.icon-text {
  color: inherit;
  vertical-align: top;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.5rem;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-text .icon:not(:last-child) {
  margin-right: .25em;
}

.icon-text .icon:not(:first-child) {
  margin-left: .25em;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image img.is-rounded {
  border-radius: 9999px;
}

.image.is-fullwidth {
  width: 100%;
}

.image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  width: 100%;
  height: 100%;
}

.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}

.image.is-5by4 {
  padding-top: 80%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-5by3 {
  padding-top: 60%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.is-3by1 {
  padding-top: 33.3333%;
}

.image.is-4by5 {
  padding-top: 125%;
}

.image.is-3by4 {
  padding-top: 133.333%;
}

.image.is-2by3 {
  padding-top: 150%;
}

.image.is-3by5 {
  padding-top: 166.667%;
}

.image.is-9by16 {
  padding-top: 177.778%;
}

.image.is-1by2 {
  padding-top: 200%;
}

.image.is-1by3 {
  padding-top: 300%;
}

.image.is-16x16 {
  width: 16px;
  height: 16px;
}

.image.is-24x24 {
  width: 24px;
  height: 24px;
}

.image.is-32x32 {
  width: 32px;
  height: 32px;
}

.image.is-48x48 {
  width: 48px;
  height: 48px;
}

.image.is-64x64 {
  width: 64px;
  height: 64px;
}

.image.is-96x96 {
  width: 96px;
  height: 96px;
}

.image.is-128x128 {
  width: 128px;
  height: 128px;
}

.notification {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.notification.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.notification.is-dark {
  color: #fff;
  background-color: #363636;
}

.notification.is-primary {
  color: #fff;
  background-color: #7957d5;
}

.notification.is-primary.is-light {
  color: #552fbc;
  background-color: #f2effb;
}

.notification.is-link {
  color: #fff;
  background-color: #7957d5;
}

.notification.is-link.is-light {
  color: #552fbc;
  background-color: #f2effb;
}

.notification.is-info {
  color: #fff;
  background-color: #167df0;
}

.notification.is-info.is-light {
  color: #0d68ce;
  background-color: #ecf4fe;
}

.notification.is-success {
  color: #fff;
  background-color: #48c78e;
}

.notification.is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.notification.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.notification.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.notification.is-danger {
  color: #fff;
  background-color: #f14668;
}

.notification.is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.progress, .progress-wrapper.is-not-native {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 9999px;
  width: 100%;
  height: 1rem;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: #ededed;
}

.progress-wrapper.is-not-native::-webkit-progress-bar {
  background-color: #ededed;
}

.progress::-webkit-progress-value {
  background-color: #4a4a4a;
}

.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #4a4a4a;
}

.progress::-moz-progress-bar {
  background-color: #4a4a4a;
}

.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #4a4a4a;
}

.progress::-ms-fill {
  background-color: #4a4a4a;
  border: none;
}

.progress-wrapper.is-not-native::-ms-fill {
  background-color: #4a4a4a;
  border: none;
}

.progress.is-white::-webkit-progress-value {
  background-color: #fff;
}

.is-white.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #fff;
}

.progress.is-white::-moz-progress-bar {
  background-color: #fff;
}

.is-white.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #fff;
}

.progress.is-white::-ms-fill {
  background-color: #fff;
}

.is-white.progress-wrapper.is-not-native::-ms-fill {
  background-color: #fff;
}

.progress.is-white:indeterminate, .is-white.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #fff 30%, #ededed 30%);
}

.progress.is-black::-webkit-progress-value {
  background-color: #0a0a0a;
}

.is-black.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #0a0a0a;
}

.progress.is-black::-moz-progress-bar {
  background-color: #0a0a0a;
}

.is-black.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #0a0a0a;
}

.progress.is-black::-ms-fill {
  background-color: #0a0a0a;
}

.is-black.progress-wrapper.is-not-native::-ms-fill {
  background-color: #0a0a0a;
}

.progress.is-black:indeterminate, .is-black.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #0a0a0a 30%, #ededed 30%);
}

.progress.is-light::-webkit-progress-value {
  background-color: #f5f5f5;
}

.is-light.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #f5f5f5;
}

.progress.is-light::-moz-progress-bar {
  background-color: #f5f5f5;
}

.is-light.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #f5f5f5;
}

.progress.is-light::-ms-fill {
  background-color: #f5f5f5;
}

.is-light.progress-wrapper.is-not-native::-ms-fill {
  background-color: #f5f5f5;
}

.progress.is-light:indeterminate, .is-light.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #f5f5f5 30%, #ededed 30%);
}

.progress.is-dark::-webkit-progress-value {
  background-color: #363636;
}

.is-dark.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #363636;
}

.progress.is-dark::-moz-progress-bar {
  background-color: #363636;
}

.is-dark.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #363636;
}

.progress.is-dark::-ms-fill {
  background-color: #363636;
}

.is-dark.progress-wrapper.is-not-native::-ms-fill {
  background-color: #363636;
}

.progress.is-dark:indeterminate, .is-dark.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #363636 30%, #ededed 30%);
}

.progress.is-primary::-webkit-progress-value {
  background-color: #7957d5;
}

.is-primary.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #7957d5;
}

.progress.is-primary::-moz-progress-bar {
  background-color: #7957d5;
}

.is-primary.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #7957d5;
}

.progress.is-primary::-ms-fill {
  background-color: #7957d5;
}

.is-primary.progress-wrapper.is-not-native::-ms-fill {
  background-color: #7957d5;
}

.progress.is-primary:indeterminate, .is-primary.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #7957d5 30%, #ededed 30%);
}

.progress.is-link::-webkit-progress-value {
  background-color: #7957d5;
}

.is-link.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #7957d5;
}

.progress.is-link::-moz-progress-bar {
  background-color: #7957d5;
}

.is-link.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #7957d5;
}

.progress.is-link::-ms-fill {
  background-color: #7957d5;
}

.is-link.progress-wrapper.is-not-native::-ms-fill {
  background-color: #7957d5;
}

.progress.is-link:indeterminate, .is-link.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #7957d5 30%, #ededed 30%);
}

.progress.is-info::-webkit-progress-value {
  background-color: #167df0;
}

.is-info.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #167df0;
}

.progress.is-info::-moz-progress-bar {
  background-color: #167df0;
}

.is-info.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #167df0;
}

.progress.is-info::-ms-fill {
  background-color: #167df0;
}

.is-info.progress-wrapper.is-not-native::-ms-fill {
  background-color: #167df0;
}

.progress.is-info:indeterminate, .is-info.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #167df0 30%, #ededed 30%);
}

.progress.is-success::-webkit-progress-value {
  background-color: #48c78e;
}

.is-success.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #48c78e;
}

.progress.is-success::-moz-progress-bar {
  background-color: #48c78e;
}

.is-success.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #48c78e;
}

.progress.is-success::-ms-fill {
  background-color: #48c78e;
}

.is-success.progress-wrapper.is-not-native::-ms-fill {
  background-color: #48c78e;
}

.progress.is-success:indeterminate, .is-success.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #48c78e 30%, #ededed 30%);
}

.progress.is-warning::-webkit-progress-value {
  background-color: #ffe08a;
}

.is-warning.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #ffe08a;
}

.progress.is-warning::-moz-progress-bar {
  background-color: #ffe08a;
}

.is-warning.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #ffe08a;
}

.progress.is-warning::-ms-fill {
  background-color: #ffe08a;
}

.is-warning.progress-wrapper.is-not-native::-ms-fill {
  background-color: #ffe08a;
}

.progress.is-warning:indeterminate, .is-warning.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #ffe08a 30%, #ededed 30%);
}

.progress.is-danger::-webkit-progress-value {
  background-color: #f14668;
}

.is-danger.progress-wrapper.is-not-native::-webkit-progress-value {
  background-color: #f14668;
}

.progress.is-danger::-moz-progress-bar {
  background-color: #f14668;
}

.is-danger.progress-wrapper.is-not-native::-moz-progress-bar {
  background-color: #f14668;
}

.progress.is-danger::-ms-fill {
  background-color: #f14668;
}

.is-danger.progress-wrapper.is-not-native::-ms-fill {
  background-color: #f14668;
}

.progress.is-danger:indeterminate, .is-danger.progress-wrapper.is-not-native:indeterminate {
  background-image: linear-gradient(to right, #f14668 30%, #ededed 30%);
}

.progress:indeterminate, .progress-wrapper.is-not-native:indeterminate {
  background-color: #ededed;
  background-image: linear-gradient(to right, #4a4a4a 30%, #ededed 30%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  animation-name: moveIndeterminate;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.progress:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress-wrapper.is-not-native:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

.progress-wrapper.is-not-native:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-ms-fill {
  animation-name: none;
}

.progress-wrapper.is-not-native:indeterminate::-ms-fill {
  animation-name: none;
}

.progress.is-small, .is-small.progress-wrapper.is-not-native {
  height: .75rem;
}

.progress.is-medium, .is-medium.progress-wrapper.is-not-native {
  height: 1.25rem;
}

.progress.is-large, .is-large.progress-wrapper.is-not-native {
  height: 1.5rem;
}

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.table {
  color: #363636;
  background-color: #fff;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.table td.is-light, .table th.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.table td.is-dark, .table th.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.table td.is-primary, .table th.is-primary, .table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #7957d5;
  border-color: #7957d5;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #167df0;
  border-color: #167df0;
}

.table td.is-success, .table th.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.table td.is-warning, .table th.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #f14668;
  border-color: #f14668;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  color: #fff;
  background-color: #7957d5;
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #363636;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  color: #fff;
  background-color: #7957d5;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.table thead {
  background-color: #0000;
}

.table thead td, .table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.table tfoot {
  background-color: #0000;
}

.table tfoot td, .table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.table tbody {
  background-color: #0000;
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #f5f5f5;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto hidden;
}

.tags {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tags .tag {
  margin-bottom: .5rem;
}

.tags .tag:not(:last-child) {
  margin-right: .5rem;
}

.tags:last-child {
  margin-bottom: -.5rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: 1rem;
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 1.25rem;
}

.tags.is-centered {
  justify-content: center;
}

.tags.is-centered .tag {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.is-right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tags.is-right .tag:not(:last-child), .tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #4a4a4a;
  white-space: nowrap;
  background-color: #f5f5f5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 2em;
  padding-left: .75em;
  padding-right: .75em;
  font-size: .75rem;
  line-height: 1.5;
  display: inline-flex;
}

.tag:not(body) .delete {
  margin-left: .25rem;
  margin-right: -.375rem;
}

.tag:not(body).is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.tag:not(body).is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.tag:not(body).is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.tag:not(body).is-dark {
  color: #fff;
  background-color: #363636;
}

.tag:not(body).is-primary {
  color: #fff;
  background-color: #7957d5;
}

.tag:not(body).is-primary.is-light {
  color: #552fbc;
  background-color: #f2effb;
}

.tag:not(body).is-link {
  color: #fff;
  background-color: #7957d5;
}

.tag:not(body).is-link.is-light {
  color: #552fbc;
  background-color: #f2effb;
}

.tag:not(body).is-info {
  color: #fff;
  background-color: #167df0;
}

.tag:not(body).is-info.is-light {
  color: #0d68ce;
  background-color: #ecf4fe;
}

.tag:not(body).is-success {
  color: #fff;
  background-color: #48c78e;
}

.tag:not(body).is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.tag:not(body).is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.tag:not(body).is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.tag:not(body).is-danger {
  color: #fff;
  background-color: #f14668;
}

.tag:not(body).is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.tag:not(body).is-normal {
  font-size: .75rem;
}

.tag:not(body).is-medium {
  font-size: 1rem;
}

.tag:not(body).is-large {
  font-size: 1.25rem;
}

.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -.375em;
  margin-right: .1875em;
}

.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: -.375em;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: -.375em;
  margin-right: -.375em;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:before, .tag:not(body).is-delete:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  width: 50%;
  height: 1px;
}

.tag:not(body).is-delete:after {
  width: 1px;
  height: 50%;
}

.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #e8e8e8;
}

.tag:not(body).is-delete:active {
  background-color: #dbdbdb;
}

.tag:not(body).is-rounded {
  border-radius: 9999px;
}

a.tag:hover {
  text-decoration: underline;
}

.title, .subtitle {
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub, .title sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.number {
  text-align: center;
  vertical-align: top;
  background-color: #f5f5f5;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  min-width: 2.5em;
  height: 2em;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  display: inline-flex;
}

.select select, .taginput .taginput-container.is-focusable, .textarea, .input {
  color: #363636;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.select select::-moz-placeholder {
  color: #3636364d;
}

.taginput .taginput-container.is-focusable::-moz-placeholder {
  color: #3636364d;
}

.textarea::-moz-placeholder {
  color: #3636364d;
}

.input::-moz-placeholder {
  color: #3636364d;
}

.select select::-webkit-input-placeholder {
  color: #3636364d;
}

.taginput .taginput-container.is-focusable::-webkit-input-placeholder {
  color: #3636364d;
}

.textarea::-webkit-input-placeholder {
  color: #3636364d;
}

.input::-webkit-input-placeholder {
  color: #3636364d;
}

.select select:-moz-placeholder {
  color: #3636364d;
}

.taginput .taginput-container.is-focusable:-moz-placeholder {
  color: #3636364d;
}

.textarea:-moz-placeholder {
  color: #3636364d;
}

.input:-moz-placeholder {
  color: #3636364d;
}

.select select:-ms-input-placeholder {
  color: #3636364d;
}

.taginput .taginput-container.is-focusable:-ms-input-placeholder {
  color: #3636364d;
}

.textarea:-ms-input-placeholder {
  color: #3636364d;
}

.input:-ms-input-placeholder {
  color: #3636364d;
}

.select select:hover, .taginput .taginput-container.is-focusable:hover, .textarea:hover, .input:hover, .select select.is-hovered, .taginput .is-hovered.taginput-container.is-focusable, .is-hovered.textarea, .is-hovered.input {
  border-color: #b5b5b5;
}

.select select:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .input:focus, .select select.is-focused, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .is-focused.input, .select select:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .input:active, .select select.is-active, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .is-active.input {
  border-color: #7957d5;
  box-shadow: 0 0 0 .125em #7957d540;
}

.select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #7a7a7a;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.select select[disabled]::-moz-placeholder {
  color: #7a7a7a4d;
}

.taginput [disabled].taginput-container.is-focusable::-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea::-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].input::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select::-moz-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .taginput .taginput-container.is-focusable::-moz-placeholder {
  color: #7a7a7a4d;
}

.taginput fieldset[disabled] .taginput-container.is-focusable::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input::-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.taginput [disabled].taginput-container.is-focusable::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .taginput .taginput-container.is-focusable::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.taginput fieldset[disabled] .taginput-container.is-focusable::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-moz-placeholder {
  color: #7a7a7a4d;
}

.taginput [disabled].taginput-container.is-focusable:-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].input:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select:-moz-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .taginput .taginput-container.is-focusable:-moz-placeholder {
  color: #7a7a7a4d;
}

.taginput fieldset[disabled] .taginput-container.is-focusable:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input:-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.taginput [disabled].taginput-container.is-focusable:-ms-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-ms-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .taginput .taginput-container.is-focusable:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.taginput fieldset[disabled] .taginput-container.is-focusable:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.taginput .taginput-container.is-focusable, .textarea, .input {
  width: 100%;
  max-width: 100%;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

.taginput [readonly].taginput-container.is-focusable, [readonly].textarea, [readonly].input {
  box-shadow: none;
}

.taginput .is-white.taginput-container.is-focusable, .is-white.textarea, .is-white.input {
  border-color: #fff;
}

.taginput .is-white.taginput-container.is-focusable:focus, .is-white.textarea:focus, .is-white.input:focus, .taginput .is-white.is-focused.taginput-container.is-focusable, .is-white.is-focused.textarea, .is-white.is-focused.input, .taginput .is-white.taginput-container.is-focusable:active, .is-white.textarea:active, .is-white.input:active, .taginput .is-white.is-active.taginput-container.is-focusable, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.taginput .is-black.taginput-container.is-focusable, .is-black.textarea, .is-black.input {
  border-color: #0a0a0a;
}

.taginput .is-black.taginput-container.is-focusable:focus, .is-black.textarea:focus, .is-black.input:focus, .taginput .is-black.is-focused.taginput-container.is-focusable, .is-black.is-focused.textarea, .is-black.is-focused.input, .taginput .is-black.taginput-container.is-focusable:active, .is-black.textarea:active, .is-black.input:active, .taginput .is-black.is-active.taginput-container.is-focusable, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.taginput .is-light.taginput-container.is-focusable, .is-light.textarea, .is-light.input {
  border-color: #f5f5f5;
}

.taginput .is-light.taginput-container.is-focusable:focus, .is-light.textarea:focus, .is-light.input:focus, .taginput .is-light.is-focused.taginput-container.is-focusable, .is-light.is-focused.textarea, .is-light.is-focused.input, .taginput .is-light.taginput-container.is-focusable:active, .is-light.textarea:active, .is-light.input:active, .taginput .is-light.is-active.taginput-container.is-focusable, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.taginput .is-dark.taginput-container.is-focusable, .is-dark.textarea, .is-dark.input {
  border-color: #363636;
}

.taginput .is-dark.taginput-container.is-focusable:focus, .is-dark.textarea:focus, .is-dark.input:focus, .taginput .is-dark.is-focused.taginput-container.is-focusable, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .taginput .is-dark.taginput-container.is-focusable:active, .is-dark.textarea:active, .is-dark.input:active, .taginput .is-dark.is-active.taginput-container.is-focusable, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 .125em #36363640;
}

.taginput .is-primary.taginput-container.is-focusable, .is-primary.textarea, .is-primary.input {
  border-color: #7957d5;
}

.taginput .is-primary.taginput-container.is-focusable:focus, .is-primary.textarea:focus, .is-primary.input:focus, .taginput .is-primary.is-focused.taginput-container.is-focusable, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .taginput .is-primary.taginput-container.is-focusable:active, .is-primary.textarea:active, .is-primary.input:active, .taginput .is-primary.is-active.taginput-container.is-focusable, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 0 0 .125em #7957d540;
}

.taginput .is-link.taginput-container.is-focusable, .is-link.textarea, .is-link.input {
  border-color: #7957d5;
}

.taginput .is-link.taginput-container.is-focusable:focus, .is-link.textarea:focus, .is-link.input:focus, .taginput .is-link.is-focused.taginput-container.is-focusable, .is-link.is-focused.textarea, .is-link.is-focused.input, .taginput .is-link.taginput-container.is-focusable:active, .is-link.textarea:active, .is-link.input:active, .taginput .is-link.is-active.taginput-container.is-focusable, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 0 0 .125em #7957d540;
}

.taginput .is-info.taginput-container.is-focusable, .is-info.textarea, .is-info.input {
  border-color: #167df0;
}

.taginput .is-info.taginput-container.is-focusable:focus, .is-info.textarea:focus, .is-info.input:focus, .taginput .is-info.is-focused.taginput-container.is-focusable, .is-info.is-focused.textarea, .is-info.is-focused.input, .taginput .is-info.taginput-container.is-focusable:active, .is-info.textarea:active, .is-info.input:active, .taginput .is-info.is-active.taginput-container.is-focusable, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 .125em #167df040;
}

.taginput .is-success.taginput-container.is-focusable, .is-success.textarea, .is-success.input {
  border-color: #48c78e;
}

.taginput .is-success.taginput-container.is-focusable:focus, .is-success.textarea:focus, .is-success.input:focus, .taginput .is-success.is-focused.taginput-container.is-focusable, .is-success.is-focused.textarea, .is-success.is-focused.input, .taginput .is-success.taginput-container.is-focusable:active, .is-success.textarea:active, .is-success.input:active, .taginput .is-success.is-active.taginput-container.is-focusable, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.taginput .is-warning.taginput-container.is-focusable, .is-warning.textarea, .is-warning.input {
  border-color: #ffe08a;
}

.taginput .is-warning.taginput-container.is-focusable:focus, .is-warning.textarea:focus, .is-warning.input:focus, .taginput .is-warning.is-focused.taginput-container.is-focusable, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .taginput .is-warning.taginput-container.is-focusable:active, .is-warning.textarea:active, .is-warning.input:active, .taginput .is-warning.is-active.taginput-container.is-focusable, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.taginput .is-danger.taginput-container.is-focusable, .is-danger.textarea, .is-danger.input {
  border-color: #f14668;
}

.taginput .is-danger.taginput-container.is-focusable:focus, .is-danger.textarea:focus, .is-danger.input:focus, .taginput .is-danger.is-focused.taginput-container.is-focusable, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .taginput .is-danger.taginput-container.is-focusable:active, .is-danger.textarea:active, .is-danger.input:active, .taginput .is-danger.is-active.taginput-container.is-focusable, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 .125em #f1466840;
}

.taginput .is-small.taginput-container.is-focusable, .is-small.textarea, .is-small.input {
  border-radius: 2px;
  font-size: .75rem;
}

.taginput .is-medium.taginput-container.is-focusable, .is-medium.textarea, .is-medium.input {
  font-size: 1.25rem;
}

.taginput .is-large.taginput-container.is-focusable, .is-large.textarea, .is-large.input {
  font-size: 1.5rem;
}

.taginput .is-fullwidth.taginput-container.is-focusable, .is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.taginput .is-inline.taginput-container.is-focusable, .is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  max-width: 100%;
  padding: calc(.75em - 1px);
  display: block;
}

.textarea:not([rows]) {
  min-height: 8em;
  max-height: 40em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

.radio:hover, .checkbox:hover {
  color: #363636;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox, .radio input[disabled], .checkbox input[disabled] {
  color: #7a7a7a;
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: .5em;
}

.select {
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 2.5em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #7957d5;
  right: 1.125em;
}

.select.is-rounded select {
  border-radius: 9999px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  outline: none;
  max-width: 100%;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #f5f5f5;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #363636;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.select.is-black:not(:hover):after, .select.is-black select {
  border-color: #0a0a0a;
}

.select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: #000;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #f5f5f5;
}

.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #e8e8e8;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.select.is-dark:not(:hover):after, .select.is-dark select {
  border-color: #363636;
}

.select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #292929;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 .125em #36363640;
}

.select.is-primary:not(:hover):after, .select.is-primary select {
  border-color: #7957d5;
}

.select.is-primary select:hover, .select.is-primary select.is-hovered {
  border-color: #6943d0;
}

.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 0 0 .125em #7957d540;
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #7957d5;
}

.select.is-link select:hover, .select.is-link select.is-hovered {
  border-color: #6943d0;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 0 0 .125em #7957d540;
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #167df0;
}

.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #0e71de;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 .125em #167df040;
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #48c78e;
}

.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #3abb81;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #ffe08a;
}

.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #ffd970;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #f14668;
}

.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #ef2e55;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 .125em #f1466840;
}

.select.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.select.is-medium {
  font-size: 1.25rem;
}

.select.is-large {
  font-size: 1.5rem;
}

.select.is-disabled:after {
  opacity: .5;
  border-color: #7a7a7a !important;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: .75rem;
}

.select.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.select.is-loading.is-large:after {
  font-size: 1.5rem;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0000;
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #0a0a0a;
  background-color: #f9f9f9;
  border-color: #0000;
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #0a0a0a;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #0a0a0a;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-black .file-cta {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0000;
}

.file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fff;
  background-color: #040404;
  border-color: #0000;
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #0a0a0a40;
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #f5f5f540;
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.file.is-dark .file-cta {
  color: #fff;
  background-color: #363636;
  border-color: #0000;
}

.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #0000;
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #36363640;
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #fff;
  background-color: #292929;
  border-color: #0000;
}

.file.is-primary .file-cta {
  color: #fff;
  background-color: #7957d5;
  border-color: #0000;
}

.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  color: #fff;
  background-color: #714dd2;
  border-color: #0000;
}

.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #7957d540;
}

.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  color: #fff;
  background-color: #6943d0;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #7957d5;
  border-color: #0000;
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fff;
  background-color: #714dd2;
  border-color: #0000;
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #7957d540;
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fff;
  background-color: #6943d0;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #167df0;
  border-color: #0000;
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fff;
  background-color: #0f77ea;
  border-color: #0000;
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #167df040;
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fff;
  background-color: #0e71de;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #fff;
  background-color: #48c78e;
  border-color: #0000;
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #fff;
  background-color: #3ec487;
  border-color: #0000;
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #48c78e40;
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #fff;
  background-color: #3abb81;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #0000;
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: #000000b3;
  background-color: #ffdc7d;
  border-color: #0000;
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffe08a40;
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: #000000b3;
  background-color: #ffd970;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #f14668;
  border-color: #0000;
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fff;
  background-color: #f03a5f;
  border-color: #0000;
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #f1466840;
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fff;
  background-color: #ef2e55;
  border-color: #0000;
}

.file.is-small {
  font-size: .75rem;
}

.file.is-normal {
  font-size: 1rem;
}

.file.is-medium {
  font-size: 1.25rem;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 1.5rem;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: 4px;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #363636;
  background-color: #eee;
}

.file-label:hover .file-name {
  border-color: #d5d5d5;
}

.file-label:active .file-cta {
  color: #363636;
  background-color: #e8e8e8;
}

.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  opacity: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  white-space: nowrap;
  border-color: #dbdbdb;
  border-radius: 4px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #4a4a4a;
  background-color: #f5f5f5;
}

.file-name {
  text-align: inherit;
  text-overflow: ellipsis;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  max-width: 16em;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #363636;
  font-size: 1rem;
  font-weight: 600;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1.25rem;
}

.label.is-large {
  font-size: 1.5rem;
}

.help {
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #0a0a0a;
}

.help.is-light {
  color: #f5f5f5;
}

.help.is-dark {
  color: #363636;
}

.help.is-primary, .help.is-link {
  color: #7957d5;
}

.help.is-info {
  color: #167df0;
}

.help.is-success {
  color: #48c78e;
}

.help.is-warning {
  color: #ffe08a;
}

.help.is-danger {
  color: #f14668;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (width <= 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1.25rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.5rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: inherit;
  font-size: 1rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #4a4a4a;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: .75rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1.25rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.5rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  pointer-events: none;
  z-index: 4;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.5em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.5em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: .75rem;
}

.control.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.control.is-loading.is-large:after {
  font-size: 1.5rem;
}

.breadcrumb {
  white-space: nowrap;
  font-size: 1rem;
}

.breadcrumb a {
  color: #7957d5;
  justify-content: center;
  align-items: center;
  padding: 0 .75em;
  display: flex;
}

.breadcrumb a:hover {
  color: #363636;
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-left: 0;
}

.breadcrumb li.is-active a {
  color: #363636;
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: #b5b5b5;
  content: "/";
}

.breadcrumb ul, .breadcrumb ol {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-right: .5em;
}

.breadcrumb .icon:last-child {
  margin-left: .5em;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul {
  justify-content: flex-end;
}

.breadcrumb.is-small {
  font-size: .75rem;
}

.breadcrumb.is-medium {
  font-size: 1.25rem;
}

.breadcrumb.is-large {
  font-size: 1.5rem;
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: .25rem;
  max-width: 100%;
  position: relative;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-header {
  background-color: #0000;
  align-items: stretch;
  display: flex;
  box-shadow: 0 .125em .25em #0a0a0a1a;
}

.card-header-title {
  color: #363636;
  flex-grow: 1;
  align-items: center;
  padding: .75rem 1rem;
  font-weight: 700;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .75rem 1rem;
  font-family: inherit;
  font-size: 1em;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-image:first-child img {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-image:last-child img {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-content {
  background-color: #0000;
  padding: 1.5rem;
}

.card-footer {
  background-color: #0000;
  border-top: 1px solid #ededed;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #ededed;
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.dropdown {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
  bottom: 100%;
}

.dropdown-menu {
  z-index: 20;
  min-width: 12rem;
  padding-top: 4px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.dropdown-item, .dropdown .dropdown-menu .has-link a {
  color: #4a4a4a;
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

a.dropdown-item, .dropdown .dropdown-menu .has-link a, button.dropdown-item {
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  padding-right: 3rem;
}

a.dropdown-item:hover, .dropdown .dropdown-menu .has-link a:hover, button.dropdown-item:hover {
  color: #0a0a0a;
  background-color: #f5f5f5;
}

a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
  color: #fff;
  background-color: #7957d5;
}

.dropdown-divider {
  background-color: #ededed;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level code {
  border-radius: 4px;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (width <= 768px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (width <= 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (width >= 769px), print {
  .level-right {
    display: flex;
  }
}

.media {
  text-align: inherit;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid #dbdbdb80;
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid #dbdbdb80;
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: inherit;
  flex: auto;
}

@media screen and (width <= 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.menu {
  font-size: 1rem;
}

.menu.is-small {
  font-size: .75rem;
}

.menu.is-medium {
  font-size: 1.25rem;
}

.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #4a4a4a;
  border-radius: 2px;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #363636;
  background-color: #f5f5f5;
}

.menu-list a.is-active {
  color: #fff;
  background-color: #7957d5;
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #7a7a7a;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 1rem;
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: .75rem;
}

.message.is-medium {
  font-size: 1.25rem;
}

.message.is-large {
  font-size: 1.5rem;
}

.message.is-white {
  background-color: #fff;
}

.message.is-white .message-header {
  color: #0a0a0a;
  background-color: #fff;
}

.message.is-white .message-body {
  border-color: #fff;
}

.message.is-black {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fff;
  background-color: #0a0a0a;
}

.message.is-black .message-body {
  border-color: #0a0a0a;
}

.message.is-light {
  background-color: #fafafa;
}

.message.is-light .message-header {
  color: #000000b3;
  background-color: #f5f5f5;
}

.message.is-light .message-body {
  border-color: #f5f5f5;
}

.message.is-dark {
  background-color: #fafafa;
}

.message.is-dark .message-header {
  color: #fff;
  background-color: #363636;
}

.message.is-dark .message-body {
  border-color: #363636;
}

.message.is-primary {
  background-color: #f2effb;
}

.message.is-primary .message-header {
  color: #fff;
  background-color: #7957d5;
}

.message.is-primary .message-body {
  color: #552fbc;
  border-color: #7957d5;
}

.message.is-link {
  background-color: #f2effb;
}

.message.is-link .message-header {
  color: #fff;
  background-color: #7957d5;
}

.message.is-link .message-body {
  color: #552fbc;
  border-color: #7957d5;
}

.message.is-info {
  background-color: #ecf4fe;
}

.message.is-info .message-header {
  color: #fff;
  background-color: #167df0;
}

.message.is-info .message-body {
  color: #0d68ce;
  border-color: #167df0;
}

.message.is-success {
  background-color: #effaf5;
}

.message.is-success .message-header {
  color: #fff;
  background-color: #48c78e;
}

.message.is-success .message-body {
  color: #257953;
  border-color: #48c78e;
}

.message.is-warning {
  background-color: #fffaeb;
}

.message.is-warning .message-header {
  color: #000000b3;
  background-color: #ffe08a;
}

.message.is-warning .message-body {
  color: #946c00;
  border-color: #ffe08a;
}

.message.is-danger {
  background-color: #feecf0;
}

.message.is-danger .message-header {
  color: #fff;
  background-color: #f14668;
}

.message.is-danger .message-body {
  color: #cc0f35;
  border-color: #f14668;
}

.message-header {
  color: #fff;
  background-color: #4a4a4a;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #4a4a4a;
  border: 0 solid #dbdbdb;
  border-left-width: 4px;
  border-radius: 4px;
  padding: 1.25em 1.5em;
}

.message-body code, .message-body pre {
  background-color: #fff;
}

.message-body pre code {
  background-color: #0000;
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #0a0a0adb;
}

.modal-content, .modal-card {
  width: 100%;
  max-height: calc(100vh - 160px);
  margin: 0 20px;
  position: relative;
  overflow: auto;
}

@media screen and (width >= 769px) {
  .modal-content, .modal-card {
    width: 640px;
    max-height: calc(100vh - 40px);
    margin: 0 auto;
  }
}

.modal-close {
  background: none;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal-card {
  -ms-overflow-y: visible;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #f5f5f5;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.navbar {
  z-index: 30;
  background-color: #fff;
  min-height: 3.25rem;
  position: relative;
}

.navbar.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #0a0a0a;
}

.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #0a0a0a;
}

.navbar.is-white .navbar-burger {
  color: #0a0a0a;
}

@media screen and (width >= 1024px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #0a0a0a;
  }

  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #0a0a0a;
  }

  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #0a0a0a;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #0a0a0a;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-black .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #0a0a0a;
  }
}

.navbar.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-light .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #f5f5f5;
  }
}

.navbar.is-dark {
  color: #fff;
  background-color: #363636;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-dark .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #363636;
  }
}

.navbar.is-primary {
  color: #fff;
  background-color: #7957d5;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #6943d0;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-primary .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #6943d0;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #6943d0;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #7957d5;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #7957d5;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #6943d0;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-link .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #6943d0;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #6943d0;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #7957d5;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #167df0;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #0e71de;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-info .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #0e71de;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #0e71de;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #167df0;
  }
}

.navbar.is-success {
  color: #fff;
  background-color: #48c78e;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-success .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #48c78e;
  }
}

.navbar.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-warning .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #ffe08a;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #f14668;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-danger .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #ef2e55;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #ef2e55;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #f14668;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  min-height: 3.25rem;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 2px #f5f5f5;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px #f5f5f5;
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 3.25rem;
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: 3.25rem;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: stretch;
  min-height: 3.25rem;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: #0000;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  color: #4a4a4a;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  width: 3.25rem;
  height: 3.25rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  transform-origin: center;
  background-color: currentColor;
  width: 16px;
  height: 1px;
  transition-property: background-color, opacity, transform;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:first-child {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:first-child {
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #4a4a4a;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  color: #7957d5;
  background-color: #fafafa;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 1.75rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  border-bottom: 1px solid #0000;
  min-height: 3.25rem;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #7957d5;
}

.navbar-item.is-tab.is-active {
  color: #7957d5;
  background-color: #0000;
  border-bottom: 3px solid #7957d5;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #7957d5;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: .5rem 0;
  display: none;
}

@media screen and (width <= 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 8px 16px #0a0a0a1a;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 3.25rem);
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: 3.25rem;
  }
}

@media screen and (width >= 1024px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 3.25rem;
  }

  .navbar.is-spaced {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-start, .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.is-spaced .navbar-link {
    border-radius: 4px;
  }

  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
    background-color: #0000 !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f5f5f5;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #7957d5;
    background-color: #f5f5f5;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: 2px solid #dbdbdb;
    border-radius: 6px 6px 0 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px #0a0a0a1a;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    z-index: 20;
    background-color: #fff;
    border-top: 2px solid #dbdbdb;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    min-width: 100%;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px #0a0a0a1a;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover {
    color: #0a0a0a;
    background-color: #f5f5f5;
  }

  .navbar-dropdown a.navbar-item.is-active {
    color: #7957d5;
    background-color: #f5f5f5;
  }

  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 6px;
    transition-property: opacity, transform;
    transition-duration: 86ms;
    display: block;
    top: calc(100% - 4px);
    transform: translateY(-5px);
    box-shadow: 0 8px 8px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px #0a0a0a1a;
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem;
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem;
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem;
  }

  a.navbar-item.is-active, .navbar-link.is-active {
    color: #0a0a0a;
  }

  a.navbar-item.is-active:not(:focus):not(:hover), .navbar-link.is-active:not(:focus):not(:hover) {
    background-color: #0000;
  }

  .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #fafafa;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.pagination {
  margin: -.25rem;
  font-size: 1rem;
}

.pagination.is-small {
  font-size: .75rem;
}

.pagination.is-medium {
  font-size: 1.25rem;
}

.pagination.is-large {
  font-size: 1.5rem;
}

.pagination.is-rounded .pagination-previous, .pagination.is-rounded .pagination-next {
  border-radius: 9999px;
  padding-left: 1em;
  padding-right: 1em;
}

.pagination.is-rounded .pagination-link {
  border-radius: 9999px;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  text-align: center;
  justify-content: center;
  margin: .25rem;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1em;
}

.pagination-previous, .pagination-next, .pagination-link {
  color: #363636;
  border-color: #dbdbdb;
  min-width: 2.5em;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover {
  color: #363636;
  border-color: #b5b5b5;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  border-color: #7957d5;
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: inset 0 1px 2px #0a0a0a33;
}

.pagination-previous[disabled], .pagination-previous.is-disabled, .pagination-next[disabled], .pagination-next.is-disabled, .pagination-link[disabled], .pagination-link.is-disabled {
  box-shadow: none;
  color: #7a7a7a;
  opacity: .5;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.pagination-previous, .pagination-next {
  white-space: nowrap;
  padding-left: .75em;
  padding-right: .75em;
}

.pagination-link.is-current {
  color: #fff;
  background-color: #7957d5;
  border-color: #7957d5;
}

.pagination-ellipsis {
  color: #b5b5b5;
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

.pagination-list li {
  list-style: none;
}

@media screen and (width <= 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-previous, .pagination-next, .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (width >= 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  border-radius: 6px;
  font-size: 1rem;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel.is-white .panel-heading {
  color: #0a0a0a;
  background-color: #fff;
}

.panel.is-white .panel-tabs a.is-active {
  border-bottom-color: #fff;
}

.panel.is-white .panel-block.is-active .panel-icon {
  color: #fff;
}

.panel.is-black .panel-heading {
  color: #fff;
  background-color: #0a0a0a;
}

.panel.is-black .panel-tabs a.is-active {
  border-bottom-color: #0a0a0a;
}

.panel.is-black .panel-block.is-active .panel-icon {
  color: #0a0a0a;
}

.panel.is-light .panel-heading {
  color: #000000b3;
  background-color: #f5f5f5;
}

.panel.is-light .panel-tabs a.is-active {
  border-bottom-color: #f5f5f5;
}

.panel.is-light .panel-block.is-active .panel-icon {
  color: #f5f5f5;
}

.panel.is-dark .panel-heading {
  color: #fff;
  background-color: #363636;
}

.panel.is-dark .panel-tabs a.is-active {
  border-bottom-color: #363636;
}

.panel.is-dark .panel-block.is-active .panel-icon {
  color: #363636;
}

.panel.is-primary .panel-heading {
  color: #fff;
  background-color: #7957d5;
}

.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: #7957d5;
}

.panel.is-primary .panel-block.is-active .panel-icon {
  color: #7957d5;
}

.panel.is-link .panel-heading {
  color: #fff;
  background-color: #7957d5;
}

.panel.is-link .panel-tabs a.is-active {
  border-bottom-color: #7957d5;
}

.panel.is-link .panel-block.is-active .panel-icon {
  color: #7957d5;
}

.panel.is-info .panel-heading {
  color: #fff;
  background-color: #167df0;
}

.panel.is-info .panel-tabs a.is-active {
  border-bottom-color: #167df0;
}

.panel.is-info .panel-block.is-active .panel-icon {
  color: #167df0;
}

.panel.is-success .panel-heading {
  color: #fff;
  background-color: #48c78e;
}

.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: #48c78e;
}

.panel.is-success .panel-block.is-active .panel-icon {
  color: #48c78e;
}

.panel.is-warning .panel-heading {
  color: #000000b3;
  background-color: #ffe08a;
}

.panel.is-warning .panel-tabs a.is-active {
  border-bottom-color: #ffe08a;
}

.panel.is-warning .panel-block.is-active .panel-icon {
  color: #ffe08a;
}

.panel.is-danger .panel-heading {
  color: #fff;
  background-color: #f14668;
}

.panel.is-danger .panel-tabs a.is-active {
  border-bottom-color: #f14668;
}

.panel.is-danger .panel-block.is-active .panel-icon {
  color: #f14668;
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.panel-heading {
  color: #363636;
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  padding: .75em 1em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #363636;
  border-bottom-color: #4a4a4a;
}

.panel-list a {
  color: #4a4a4a;
}

.panel-list a:hover {
  color: #7957d5;
}

.panel-block {
  color: #363636;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-right: .75em;
}

.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #363636;
  border-left-color: #7957d5;
}

.panel-block.is-active .panel-icon {
  color: #7957d5;
}

.panel-block:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #f5f5f5;
}

.panel-icon {
  text-align: center;
  vertical-align: top;
  color: #7a7a7a;
  width: 1em;
  height: 1em;
  margin-right: .75em;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow: auto hidden;
}

.tabs a {
  color: #4a4a4a;
  vertical-align: top;
  border-bottom: 1px solid #dbdbdb;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  padding: .5em 1em;
  display: flex;
}

.tabs a:hover {
  color: #363636;
  border-bottom-color: #363636;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #7957d5;
  border-bottom-color: #7957d5;
}

.tabs ul {
  border-bottom: 1px solid #dbdbdb;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
}

.tabs.is-boxed a:hover {
  background-color: #f5f5f5;
  border-bottom-color: #dbdbdb;
}

.tabs.is-boxed li.is-active a {
  background-color: #fff;
  border-color: #dbdbdb;
  border-bottom-color: #0000 !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border: 1px solid #dbdbdb;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #f5f5f5;
  border-color: #b5b5b5;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tabs.is-toggle li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tabs.is-toggle li.is-active a {
  color: #fff;
  z-index: 1;
  background-color: #7957d5;
  border-color: #7957d5;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  padding-left: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  padding-right: 1.25em;
}

.tabs.is-small {
  font-size: .75rem;
}

.tabs.is-medium {
  font-size: 1.25rem;
}

.tabs.is-large {
  font-size: 1.5rem;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  flex: 1 1 0;
  align-items: stretch;
  min-height: min-content;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (width >= 769px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    flex: none;
    width: 8.33333%;
  }

  .tile.is-2 {
    flex: none;
    width: 16.6667%;
  }

  .tile.is-3 {
    flex: none;
    width: 25%;
  }

  .tile.is-4 {
    flex: none;
    width: 33.3333%;
  }

  .tile.is-5 {
    flex: none;
    width: 41.6667%;
  }

  .tile.is-6 {
    flex: none;
    width: 50%;
  }

  .tile.is-7 {
    flex: none;
    width: 58.3333%;
  }

  .tile.is-8 {
    flex: none;
    width: 66.6667%;
  }

  .tile.is-9 {
    flex: none;
    width: 75%;
  }

  .tile.is-10 {
    flex: none;
    width: 83.3333%;
  }

  .tile.is-11 {
    flex: none;
    width: 91.6667%;
  }

  .tile.is-12 {
    flex: none;
    width: 100%;
  }
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black {
  color: #0a0a0a !important;
}

a.has-text-black:hover, a.has-text-black:focus {
  color: #000 !important;
}

.has-background-black {
  background-color: #0a0a0a !important;
}

.has-text-light {
  color: #f5f5f5 !important;
}

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important;
}

.has-background-light {
  background-color: #f5f5f5 !important;
}

.has-text-dark {
  color: #363636 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #1c1c1c !important;
}

.has-background-dark {
  background-color: #363636 !important;
}

.has-text-primary {
  color: #7957d5 !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #5a32c7 !important;
}

.has-background-primary {
  background-color: #7957d5 !important;
}

.has-text-primary-light {
  color: #f2effb !important;
}

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #d1c6f1 !important;
}

.has-background-primary-light {
  background-color: #f2effb !important;
}

.has-text-primary-dark {
  color: #552fbc !important;
}

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #704bd2 !important;
}

.has-background-primary-dark {
  background-color: #552fbc !important;
}

.has-text-link {
  color: #7957d5 !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #5a32c7 !important;
}

.has-background-link {
  background-color: #7957d5 !important;
}

.has-text-link-light {
  color: #f2effb !important;
}

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #d1c6f1 !important;
}

.has-background-link-light {
  background-color: #f2effb !important;
}

.has-text-link-dark {
  color: #552fbc !important;
}

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #704bd2 !important;
}

.has-background-link-dark {
  background-color: #552fbc !important;
}

.has-text-info {
  color: #167df0 !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #0d64c6 !important;
}

.has-background-info {
  background-color: #167df0 !important;
}

.has-text-info-light {
  color: #ecf4fe !important;
}

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #bcdafb !important;
}

.has-background-info-light {
  background-color: #ecf4fe !important;
}

.has-text-info-dark {
  color: #0d68ce !important;
}

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #1e81f1 !important;
}

.has-background-info-dark {
  background-color: #0d68ce !important;
}

.has-text-success {
  color: #48c78e !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #34a873 !important;
}

.has-background-success {
  background-color: #48c78e !important;
}

.has-text-success-light {
  color: #effaf5 !important;
}

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #c8eedd !important;
}

.has-background-success-light {
  background-color: #effaf5 !important;
}

.has-text-success-dark {
  color: #257953 !important;
}

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #31a06e !important;
}

.has-background-success-dark {
  background-color: #257953 !important;
}

.has-text-warning {
  color: #ffe08a !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffd257 !important;
}

.has-background-warning {
  background-color: #ffe08a !important;
}

.has-text-warning-light {
  color: #fffaeb !important;
}

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffecb8 !important;
}

.has-background-warning-light {
  background-color: #fffaeb !important;
}

.has-text-warning-dark {
  color: #946c00 !important;
}

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c79200 !important;
}

.has-background-warning-dark {
  background-color: #946c00 !important;
}

.has-text-danger {
  color: #f14668 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #ee1742 !important;
}

.has-background-danger {
  background-color: #f14668 !important;
}

.has-text-danger-light {
  color: #feecf0 !important;
}

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #fabdc9 !important;
}

.has-background-danger-light {
  background-color: #feecf0 !important;
}

.has-text-danger-dark {
  color: #cc0f35 !important;
}

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #ee2049 !important;
}

.has-background-danger-dark {
  background-color: #cc0f35 !important;
}

.has-text-black-bis {
  color: #121212 !important;
}

.has-background-black-bis {
  background-color: #121212 !important;
}

.has-text-black-ter {
  color: #242424 !important;
}

.has-background-black-ter {
  background-color: #242424 !important;
}

.has-text-grey-darker {
  color: #363636 !important;
}

.has-background-grey-darker {
  background-color: #363636 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-background-grey-dark {
  background-color: #4a4a4a !important;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.has-background-grey {
  background-color: #7a7a7a !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-background-grey-light {
  background-color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-background-grey-lighter {
  background-color: #dbdbdb !important;
}

.has-text-white-ter {
  color: #f5f5f5 !important;
}

.has-background-white-ter {
  background-color: #f5f5f5 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-background-white-bis {
  background-color: #fafafa !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-relative {
  position: relative !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mr-5 {
  margin-right: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pr-5 {
  padding-right: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5 {
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (width <= 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (width <= 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (width <= 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (width <= 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (width <= 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (width <= 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (width >= 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (width <= 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (width <= 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #0a0a0a;
}

.hero.is-white .subtitle {
  color: #0a0a0ae6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #0a0a0a;
}

@media screen and (width <= 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #0a0a0ab3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #0a0a0a;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #0a0a0a;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (width <= 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #0a0a0a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #0a0a0a !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
}

@media screen and (width <= 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
  }
}

.hero.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title {
  color: #000000b3;
}

.hero.is-light .subtitle {
  color: #000000e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #000000b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f5 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #363636;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: #ffffffe6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #363636;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #ffffffb3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #363636 !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #363636;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
}

@media screen and (width <= 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #7957d5;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #7957d5;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fff;
  background-color: #6943d0;
}

.hero.is-primary .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a {
  opacity: 1;
  color: #7957d5 !important;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #7957d5;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #3725d4 0%, #7957d5 71%, #9b67df 100%);
}

@media screen and (width <= 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #3725d4 0%, #7957d5 71%, #9b67df 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #7957d5;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title {
  color: #fff;
}

.hero.is-link .subtitle {
  color: #ffffffe6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #7957d5;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #ffffffb3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fff;
  background-color: #6943d0;
}

.hero.is-link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #7957d5 !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #7957d5;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #3725d4 0%, #7957d5 71%, #9b67df 100%);
}

@media screen and (width <= 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #3725d4 0%, #7957d5 71%, #9b67df 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #167df0;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #167df0;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #0e71de;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #167df0 !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #167df0;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #0286d1 0%, #167df0 71%, #2868f7 100%);
}

@media screen and (width <= 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #0286d1 0%, #167df0 71%, #2868f7 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #48c78e;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title {
  color: #fff;
}

.hero.is-success .subtitle {
  color: #ffffffe6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #48c78e;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #ffffffb3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #48c78e !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #48c78e;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
}

@media screen and (width <= 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
  }
}

.hero.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffe08a;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #ffe08a !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
}

@media screen and (width <= 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #f14668;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #f14668;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #f14668 !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #f14668;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
}

@media screen and (width <= 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (width <= 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (width <= 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (width >= 1024px) {
  .section {
    padding: 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 6rem;
}

.is-noscroll {
  width: 100%;
  position: fixed;
  bottom: 0;
  overflow-y: hidden;
}

.fadeOut {
  animation-name: fadeOut;
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeInDown {
  animation-name: fadeInDown;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .15s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.zoom-in-enter-active, .zoom-in-leave-active {
  transition: opacity .15s ease-out;
}

.zoom-in-enter-active .animation-content, .zoom-in-enter-active .animation-content, .zoom-in-leave-active .animation-content, .zoom-in-leave-active .animation-content {
  transition: transform .15s ease-out;
}

.zoom-in-enter, .zoom-in-leave-active {
  opacity: 0;
}

.zoom-in-enter .animation-content, .zoom-in-enter .animation-content, .zoom-in-leave-active .animation-content, .zoom-in-leave-active .animation-content {
  transform: scale(.95);
}

.zoom-out-enter-active, .zoom-out-leave-active {
  transition: opacity .15s ease-out;
}

.zoom-out-enter-active .animation-content, .zoom-out-enter-active .animation-content, .zoom-out-leave-active .animation-content, .zoom-out-leave-active .animation-content {
  transition: transform .15s ease-out;
}

.zoom-out-enter, .zoom-out-leave-active {
  opacity: 0;
}

.zoom-out-enter .animation-content, .zoom-out-enter .animation-content, .zoom-out-leave-active .animation-content, .zoom-out-leave-active .animation-content {
  transform: scale(1.05);
}

.slide-next-enter-active, .slide-next-leave-active, .slide-prev-enter-active, .slide-prev-leave-active {
  transition: transform .25s cubic-bezier(.785, .135, .15, .86);
}

.slide-prev-leave-to, .slide-next-enter {
  width: 100%;
  position: absolute;
  transform: translate3d(-100%, 0, 0);
}

.slide-prev-enter, .slide-next-leave-to {
  width: 100%;
  position: absolute;
  transform: translate3d(100%, 0, 0);
}

.slide-down-enter-active, .slide-down-leave-active, .slide-up-enter-active, .slide-up-leave-active {
  transition: transform .25s cubic-bezier(.785, .135, .15, .86);
}

.slide-up-leave-to, .slide-down-enter {
  height: 100%;
  position: absolute;
  transform: translate3d(0, -100%, 0);
}

.slide-up-enter, .slide-down-leave-to {
  height: 100%;
  position: absolute;
  transform: translate3d(0, 100%, 0);
}

.slide-enter-active {
  transition: all .15s ease-out;
}

.slide-leave-active {
  transition: all .15s cubic-bezier(0, 1, .5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  overflow: hidden;
}

.autocomplete {
  position: relative;
}

.autocomplete .dropdown-menu {
  width: 100%;
  display: block;
}

.autocomplete .dropdown-menu.is-opened-top {
  top: auto;
  bottom: 100%;
}

.autocomplete .dropdown-content {
  max-height: 200px;
  overflow: auto;
}

.autocomplete .dropdown-item, .autocomplete .dropdown .dropdown-menu .has-link a, .dropdown .dropdown-menu .has-link .autocomplete a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.autocomplete .dropdown-item.is-hovered, .autocomplete .dropdown .dropdown-menu .has-link a.is-hovered, .dropdown .dropdown-menu .has-link .autocomplete a.is-hovered {
  color: #0a0a0a;
  background: #f5f5f5;
}

.autocomplete .dropdown-item.is-disabled, .autocomplete .dropdown .dropdown-menu .has-link a.is-disabled, .dropdown .dropdown-menu .has-link .autocomplete a.is-disabled {
  opacity: .5;
  cursor: not-allowed;
}

.autocomplete.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.autocomplete.is-medium {
  font-size: 1.25rem;
}

.autocomplete.is-large {
  font-size: 1.5rem;
}

.carousel {
  min-height: 120px;
  position: relative;
}

.carousel.is-overlay {
  z-index: 40;
  background-color: #0a0a0adb;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  display: flex;
  position: fixed;
}

.carousel.is-overlay .carousel-item img {
  cursor: default;
}

.carousel.is-overlay .carousel-indicator.has-background {
  background: none;
}

.carousel .progress, .carousel .progress-wrapper.is-not-native {
  border-radius: 2px;
  height: .25rem;
  margin-bottom: 0;
}

.carousel .carousel-items {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 769px), print {
  .carousel .carousel-items:hover .carousel-arrow.is-hovered {
    opacity: 1;
  }
}

.carousel .carousel-items .carousel-item {
  flex-shrink: 0;
  width: 100%;
}

.carousel .carousel-pause {
  pointer-events: none;
  z-index: 1;
  position: absolute;
  top: 0;
  right: .15rem;
}

.carousel .carousel-indicator {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5rem;
  display: flex;
}

.carousel .carousel-indicator.has-background {
  background: #0a0a0a80;
}

.carousel .carousel-indicator.has-custom {
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: auto hidden;
}

.carousel .carousel-indicator.has-custom.is-small .indicator-item {
  flex: 1 0 10%;
}

.carousel .carousel-indicator.has-custom.is-medium .indicator-item {
  flex: 1 0 16.6667%;
}

.carousel .carousel-indicator.is-inside {
  position: absolute;
}

.carousel .carousel-indicator.is-inside.is-bottom {
  bottom: 0;
}

.carousel .carousel-indicator.is-inside.is-top {
  top: 0;
}

.carousel .carousel-indicator .indicator-item:not(:last-child) {
  margin-right: .5rem;
}

.carousel .carousel-indicator .indicator-item.is-active .indicator-style, .carousel .carousel-indicator .indicator-item .indicator-style:hover {
  background: #7957d5;
  border: 1px solid #fff;
}

.carousel .carousel-indicator .indicator-item .indicator-style {
  background: #fff;
  border: 1px solid #7957d5;
  outline: none;
  transition: all .15s ease-out;
  display: block;
}

.carousel .carousel-indicator .indicator-item .indicator-style.is-boxes {
  width: 10px;
  height: 10px;
}

.carousel .carousel-indicator .indicator-item .indicator-style.is-dots {
  border-radius: 9999px;
  width: 10px;
  height: 10px;
}

.carousel .carousel-indicator .indicator-item .indicator-style.is-lines {
  width: 25px;
  height: 5px;
}

.carousel-list {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-list.has-shadow {
  box-shadow: 0 0 10px #00000040;
}

@media screen and (width >= 769px), print {
  .carousel-list:hover .carousel-arrow.is-hovered {
    opacity: 1;
  }
}

.carousel-list .carousel-slides {
  width: 100%;
  display: flex;
  position: relative;
}

.carousel-list .carousel-slides:not(.is-dragging) {
  transition: all .25s ease-out;
}

.carousel-list .carousel-slides.has-grayscale .carousel-slide img {
  filter: grayscale();
}

.carousel-list .carousel-slides.has-grayscale .carousel-slide.is-active img {
  filter: grayscale(0%);
}

.carousel-list .carousel-slides.has-opacity .carousel-slide img {
  opacity: .25;
}

.carousel-list .carousel-slides.has-opacity .carousel-slide.is-active img {
  opacity: 1;
}

.carousel-list .carousel-slides .carousel-slide {
  border: 2px solid #0000;
  flex-shrink: 0;
}

.carousel-arrow {
  transition: all .15s ease-out;
}

.carousel-arrow.is-hovered {
  opacity: 0;
}

.carousel-arrow .icon {
  color: #7957d5;
  cursor: pointer;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 9999px;
  outline: 0;
}

.carousel-arrow .icon:hover {
  opacity: 1;
  border: 1px solid #7957d5;
}

.carousel-arrow .icon.has-icons-left, .carousel-arrow .icon.has-icons-right {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-arrow .icon.has-icons-left {
  left: 1.5rem;
}

.carousel-arrow .icon.has-icons-right {
  right: 1.5rem;
}

.b-checkbox.checkbox {
  outline: none;
  align-items: center;
  display: inline-flex;
}

.b-checkbox.checkbox:not(.button) {
  margin-right: .5em;
}

.b-checkbox.checkbox:not(.button) + .checkbox:last-child {
  margin-right: 0;
}

.b-checkbox.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: -1;
  outline: none;
  position: absolute;
  left: 0;
}

.b-checkbox.checkbox input[type="checkbox"] + .check {
  background: none;
  border: 2px solid #7a7a7a;
  border-radius: 4px;
  flex-shrink: 0;
  width: 1.25em;
  height: 1.25em;
  transition: background .15s ease-out;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check {
  background: #7957d5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #7957d5;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-white {
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:hsl(0, 0%25, 4%25)' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #fff;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-black {
  background: #0a0a0a url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:hsl(0, 0%25, 100%25)' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #0a0a0a;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-light {
  background: #f5f5f5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:rgba(0, 0, 0, 0.7)' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #f5f5f5;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-dark {
  background: #363636 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #363636;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-primary, .b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-link {
  background: #7957d5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #7957d5;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-info {
  background: #167df0 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #167df0;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-success {
  background: #48c78e url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #48c78e;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-warning {
  background: #ffe08a url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:rgba(0, 0, 0, 0.7)' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #ffe08a;
}

.b-checkbox.checkbox input[type="checkbox"]:checked + .check.is-danger {
  background: #f14668 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  border-color: #f14668;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check {
  background: #7957d5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:%23fff' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #7957d5;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-white {
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:hsl(0, 0%25, 4%25)' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #fff;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-black {
  background: #0a0a0a url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:hsl(0, 0%25, 100%25)' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #0a0a0a;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-light {
  background: #f5f5f5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:rgba(0, 0, 0, 0.7)' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #f5f5f5;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-dark {
  background: #363636 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:%23fff' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #363636;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-primary, .b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-link {
  background: #7957d5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:%23fff' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #7957d5;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-info {
  background: #167df0 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:%23fff' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #167df0;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-success {
  background: #48c78e url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:%23fff' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #48c78e;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-warning {
  background: #ffe08a url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:rgba(0, 0, 0, 0.7)' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #ffe08a;
}

.b-checkbox.checkbox input[type="checkbox"]:indeterminate + .check.is-danger {
  background: #f14668 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect style='fill:%23fff' width='0.7' height='0.2' x='.15' y='.4'%3E%3C/rect%3E%3C/svg%3E") center no-repeat;
  border-color: #f14668;
}

.b-checkbox.checkbox input[type="checkbox"]:focus + .check {
  box-shadow: 0 0 .5em #7a7a7acc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check {
  box-shadow: 0 0 .5em #7957d5cc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-white {
  box-shadow: 0 0 .5em #fffc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-black {
  box-shadow: 0 0 .5em #0a0a0acc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-light {
  box-shadow: 0 0 .5em #f5f5f5cc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-dark {
  box-shadow: 0 0 .5em #363636cc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-primary, .b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-link {
  box-shadow: 0 0 .5em #7957d5cc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-info {
  box-shadow: 0 0 .5em #167df0cc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-success {
  box-shadow: 0 0 .5em #48c78ecc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-warning {
  box-shadow: 0 0 .5em #ffe08acc;
}

.b-checkbox.checkbox input[type="checkbox"]:focus:checked + .check.is-danger {
  box-shadow: 0 0 .5em #f14668cc;
}

.b-checkbox.checkbox .control-label {
  padding-left: calc(.75em - 1px);
}

.b-checkbox.checkbox.button {
  display: flex;
}

.b-checkbox.checkbox[disabled] {
  opacity: .5;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check {
  border-color: #7957d5;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-white {
  border-color: #fff;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-black {
  border-color: #0a0a0a;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-light {
  border-color: #f5f5f5;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-dark {
  border-color: #363636;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-primary, .b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-link {
  border-color: #7957d5;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-info {
  border-color: #167df0;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-success {
  border-color: #48c78e;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-warning {
  border-color: #ffe08a;
}

.b-checkbox.checkbox:hover input[type="checkbox"]:not(:disabled) + .check.is-danger {
  border-color: #f14668;
}

.b-checkbox.checkbox.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.b-checkbox.checkbox.is-medium {
  font-size: 1.25rem;
}

.b-checkbox.checkbox.is-large {
  font-size: 1.5rem;
}

.b-clockpicker .card-header {
  color: #fff;
  background-color: #7957d5;
}

.b-clockpicker .b-clockpicker-face:after {
  background-color: #7957d5;
}

.b-clockpicker .b-clockpicker-face-hand {
  background-color: #7957d5;
  border-color: #7957d5;
}

.b-clockpicker .b-clockpicker-face-number.active {
  color: #fff;
  background-color: #7957d5;
}

.b-clockpicker.is-white .card-header {
  color: #0a0a0a;
  background-color: #fff;
}

.b-clockpicker.is-white .b-clockpicker-face:after {
  background-color: #fff;
}

.b-clockpicker.is-white .b-clockpicker-face-hand {
  background-color: #fff;
  border-color: #fff;
}

.b-clockpicker.is-white .b-clockpicker-face-number.active {
  color: #0a0a0a;
  background-color: #fff;
}

.b-clockpicker.is-black .card-header {
  color: #fff;
  background-color: #0a0a0a;
}

.b-clockpicker.is-black .b-clockpicker-face:after {
  background-color: #0a0a0a;
}

.b-clockpicker.is-black .b-clockpicker-face-hand {
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.b-clockpicker.is-black .b-clockpicker-face-number.active {
  color: #fff;
  background-color: #0a0a0a;
}

.b-clockpicker.is-light .card-header {
  color: #000000b3;
  background-color: #f5f5f5;
}

.b-clockpicker.is-light .b-clockpicker-face:after {
  background-color: #f5f5f5;
}

.b-clockpicker.is-light .b-clockpicker-face-hand {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.b-clockpicker.is-light .b-clockpicker-face-number.active {
  color: #000000b3;
  background-color: #f5f5f5;
}

.b-clockpicker.is-dark .card-header {
  color: #fff;
  background-color: #363636;
}

.b-clockpicker.is-dark .b-clockpicker-face:after {
  background-color: #363636;
}

.b-clockpicker.is-dark .b-clockpicker-face-hand {
  background-color: #363636;
  border-color: #363636;
}

.b-clockpicker.is-dark .b-clockpicker-face-number.active {
  color: #fff;
  background-color: #363636;
}

.b-clockpicker.is-primary .card-header {
  color: #fff;
  background-color: #7957d5;
}

.b-clockpicker.is-primary .b-clockpicker-face:after {
  background-color: #7957d5;
}

.b-clockpicker.is-primary .b-clockpicker-face-hand {
  background-color: #7957d5;
  border-color: #7957d5;
}

.b-clockpicker.is-primary .b-clockpicker-face-number.active, .b-clockpicker.is-link .card-header {
  color: #fff;
  background-color: #7957d5;
}

.b-clockpicker.is-link .b-clockpicker-face:after {
  background-color: #7957d5;
}

.b-clockpicker.is-link .b-clockpicker-face-hand {
  background-color: #7957d5;
  border-color: #7957d5;
}

.b-clockpicker.is-link .b-clockpicker-face-number.active {
  color: #fff;
  background-color: #7957d5;
}

.b-clockpicker.is-info .card-header {
  color: #fff;
  background-color: #167df0;
}

.b-clockpicker.is-info .b-clockpicker-face:after {
  background-color: #167df0;
}

.b-clockpicker.is-info .b-clockpicker-face-hand {
  background-color: #167df0;
  border-color: #167df0;
}

.b-clockpicker.is-info .b-clockpicker-face-number.active {
  color: #fff;
  background-color: #167df0;
}

.b-clockpicker.is-success .card-header {
  color: #fff;
  background-color: #48c78e;
}

.b-clockpicker.is-success .b-clockpicker-face:after {
  background-color: #48c78e;
}

.b-clockpicker.is-success .b-clockpicker-face-hand {
  background-color: #48c78e;
  border-color: #48c78e;
}

.b-clockpicker.is-success .b-clockpicker-face-number.active {
  color: #fff;
  background-color: #48c78e;
}

.b-clockpicker.is-warning .card-header {
  color: #000000b3;
  background-color: #ffe08a;
}

.b-clockpicker.is-warning .b-clockpicker-face:after {
  background-color: #ffe08a;
}

.b-clockpicker.is-warning .b-clockpicker-face-hand {
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.b-clockpicker.is-warning .b-clockpicker-face-number.active {
  color: #000000b3;
  background-color: #ffe08a;
}

.b-clockpicker.is-danger .card-header {
  color: #fff;
  background-color: #f14668;
}

.b-clockpicker.is-danger .b-clockpicker-face:after {
  background-color: #f14668;
}

.b-clockpicker.is-danger .b-clockpicker-face-hand {
  background-color: #f14668;
  border-color: #f14668;
}

.b-clockpicker.is-danger .b-clockpicker-face-number.active {
  color: #fff;
  background-color: #f14668;
}

.b-clockpicker .dropdown-menu {
  min-width: 0;
}

.b-clockpicker .dropdown, .b-clockpicker .dropdown-trigger {
  width: 100%;
}

.b-clockpicker .dropdown .input[readonly], .b-clockpicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

.b-clockpicker .dropdown .input[readonly]:focus, .b-clockpicker .dropdown .input[readonly].is-focused, .b-clockpicker .dropdown .input[readonly]:active, .b-clockpicker .dropdown .input[readonly].is-active, .b-clockpicker .dropdown-trigger .input[readonly]:focus, .b-clockpicker .dropdown-trigger .input[readonly].is-focused, .b-clockpicker .dropdown-trigger .input[readonly]:active, .b-clockpicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: 0 0 0 .125em #7957d540;
}

.b-clockpicker .dropdown-item, .b-clockpicker .dropdown .dropdown-menu .has-link a, .dropdown .dropdown-menu .has-link .b-clockpicker a {
  font-size: inherit;
  padding: 0;
}

.b-clockpicker .dropdown-content {
  padding-top: 0;
  padding-bottom: 0;
}

.b-clockpicker .card {
  border-radius: .25rem;
}

.b-clockpicker .card-header {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.b-clockpicker .card-content {
  padding: 12px;
}

.b-clockpicker-btn {
  cursor: pointer;
  opacity: .6;
}

.b-clockpicker-btn:hover, .b-clockpicker-btn.active {
  opacity: 1;
}

.b-clockpicker-period .b-clockpicker-btn {
  font-size: 16px;
}

.b-clockpicker-time span {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.b-clockpicker-header {
  color: inherit;
  justify-content: flex-end;
  line-height: 1;
  display: flex;
}

.b-clockpicker-header .b-clockpicker-time {
  white-space: nowrap;
}

.b-clockpicker-header .b-clockpicker-time span {
  height: 60px;
  font-size: 60px;
}

.b-clockpicker-header .b-clockpicker-period {
  flex-direction: column;
  align-self: flex-end;
  margin: 8px 0 6px 8px;
  display: flex;
}

.b-clockpicker-body {
  transition: all .9s cubic-bezier(.25, .8, .5, 1);
}

.b-clockpicker-body .b-clockpicker-btn {
  border-radius: 9999px;
  margin-bottom: 2px;
  padding: 0 8px;
}

.b-clockpicker-body .b-clockpicker-btn:hover, .b-clockpicker-body .b-clockpicker-btn.active {
  color: #fff;
  background-color: #7957d5;
}

.b-clockpicker-body .b-clockpicker-period {
  position: absolute;
  top: 5px;
  right: 5px;
}

.b-clockpicker-body .b-clockpicker-time {
  font-size: 16px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.b-clockpicker-body .b-clockpicker-face {
  background-color: #dbdbdb;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.b-clockpicker-body .b-clockpicker-face:after {
  content: "";
  z-index: 10;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.b-clockpicker-body .b-clockpicker-face-outer-ring {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50%;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  position: relative;
}

.b-clockpicker-body .b-clockpicker-face-number {
  cursor: default;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: flex;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.b-clockpicker-body .b-clockpicker-face-number > span {
  z-index: 1;
}

.b-clockpicker-body .b-clockpicker-face-number:before, .b-clockpicker-body .b-clockpicker-face-number:after {
  content: "";
  border-radius: 100%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.b-clockpicker-body .b-clockpicker-face-number.active {
  cursor: default;
  z-index: 2;
}

.b-clockpicker-body .b-clockpicker-face-number.disabled {
  pointer-events: none;
  opacity: .25;
}

.b-clockpicker-body .b-clockpicker-face-hand {
  transform-origin: bottom;
  will-change: transform;
  z-index: 1;
  width: 2px;
  height: calc(50% - 6px);
  position: absolute;
  bottom: 50%;
  left: calc(50% - 1px);
}

.b-clockpicker-body .b-clockpicker-face-hand:before {
  border-style: solid;
  border-width: 2px;
  border-color: inherit;
  content: "";
  background: none;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.b-clockpicker-footer {
  padding: 12px;
  display: block;
}

.b-clockpicker.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.b-clockpicker.is-medium {
  font-size: 1.25rem;
}

.b-clockpicker.is-large {
  font-size: 1.5rem;
}

.collapse .collapse-trigger {
  cursor: pointer;
  display: inline;
}

.collapse .collapse-content {
  display: inherit;
}

.colorpicker .color-name {
  text-transform: uppercase;
  font-family: monospace;
}

.colorpicker .colorpicker-footer {
  border: 0 solid #dbdbdb;
  border-top-width: 1px;
  margin-top: .875rem;
  padding-top: .875rem;
}

.colorpicker .colorpicker-footer .colorpicker-fields {
  margin-bottom: .875rem;
}

.colorpicker .colorpicker-footer .colorpicker-fields .field {
  flex-grow: 1;
}

.colorpicker .colorpicker-footer .colorpicker-fields .field .field-label {
  margin-right: .5rem;
}

.colorpicker .colorpicker-footer .colorpicker-fields .field .control {
  display: flex;
}

.colorpicker .colorpicker-footer .colorpicker-fields .field .control .input {
  -webkit-appearance: textfield;
  appearance: textfield;
  text-align: right;
  flex-grow: 1;
  width: 0;
  min-width: 42px;
}

.colorpicker .colorpicker-footer .colorpicker-fields .field .control .input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.colorpicker .colorpicker-footer .colorpicker-fields .field .control .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.colorpicker .dropdown.is-expanded .dropdown-menu, .colorpicker .dropdown.is-expanded.is-mobile-modal .dropdown-menu {
  width: unset;
}

.colorpicker .dropdown-horizontal-colorpicker {
  flex-direction: row;
  display: flex;
}

.colorpicker .dropdown-horizontal-colorpicker .colorpicker-footer {
  border-width: 0 0 0 1px;
  margin-top: 0;
  margin-left: .875rem;
  padding-top: 0;
  padding-left: .875rem;
}

svg.b-colorpicker-triangle {
  -webkit-user-select: none;
  user-select: none;
  width: 200px;
  height: 200px;
}

@media (width <= 769px) {
  svg.b-colorpicker-triangle {
    width: 100%;
    height: auto;
  }
}

svg.b-colorpicker-triangle .colorpicker-triangle-hue {
  background: conic-gradient(red, #ff0, #0f0, #0ff, #00f, #f0f, red);
  width: 100%;
  height: 100%;
}

svg.b-colorpicker-triangle .colorpicker-triangle-slider-hue, svg.b-colorpicker-triangle .colorpicker-triangle-slider-hue foreignObject, svg.b-colorpicker-triangle .colorpicker-triangle-slider-hue g, svg.b-colorpicker-triangle .colorpicker-triangle-slider-sl, svg.b-colorpicker-triangle .colorpicker-triangle-slider-sl foreignObject, svg.b-colorpicker-triangle .colorpicker-triangle-slider-sl g {
  transform-origin: 50%;
}

svg.b-colorpicker-triangle .hue-range-thumb, svg.b-colorpicker-triangle .sl-range-thumb {
  cursor: grab;
  background: none;
  border-radius: 4px;
  width: calc(100% - .25em);
  height: calc(100% - .25em);
  margin: .125em;
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #dbdbdb;
}

svg.b-colorpicker-triangle .hue-range-thumb:hover, svg.b-colorpicker-triangle .sl-range-thumb:hover {
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #b5b5b5;
}

svg.b-colorpicker-triangle .hue-range-thumb:focus, svg.b-colorpicker-triangle .sl-range-thumb:focus {
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #7957d5, 0 0 0 .125em #7957d540;
}

svg.b-colorpicker-triangle .hue-range-thumb:active, svg.b-colorpicker-triangle .sl-range-thumb:active {
  cursor: grabbing;
}

div.b-colorpicker-square {
  aspect-ratio: 1;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

div.b-colorpicker-square .colorpicker-square-slider-hue {
  background: conic-gradient(red, #ff0, #0f0, #0ff, #00f, #f0f, red);
  border-radius: 4px;
  position: absolute;
  inset: 0;
}

div.b-colorpicker-square .colorpicker-square-slider-hue .hue-range-thumb {
  aspect-ratio: 1;
  cursor: grab;
  border-radius: 2.28571px;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #dbdbdb;
}

div.b-colorpicker-square .colorpicker-square-slider-hue .hue-range-thumb:hover {
  box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 2px #b5b5b5, 0 0 0 1px #b5b5b5;
}

div.b-colorpicker-square .colorpicker-square-slider-hue .hue-range-thumb:focus {
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #7957d5, 0 0 0 .125em #7957d540;
}

div.b-colorpicker-square .colorpicker-square-slider-hue .hue-range-thumb:active {
  cursor: grabbing;
}

div.b-colorpicker-square .colorpicker-square-slider-sl {
  background: #fff;
  border-radius: 2px;
  position: absolute;
  inset: 0;
}

div.b-colorpicker-square .colorpicker-square-slider-sl:before {
  content: "";
  mix-blend-mode: hard-light;
  background: linear-gradient(0deg, #000, #fff);
  position: absolute;
  inset: 0;
}

div.b-colorpicker-square .colorpicker-square-slider-sl .sl-range-thumb {
  cursor: grab;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #dbdbdb;
}

div.b-colorpicker-square .colorpicker-square-slider-sl .sl-range-thumb:hover {
  box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 2px #b5b5b5, 0 0 0 1px #b5b5b5;
}

div.b-colorpicker-square .colorpicker-square-slider-sl .sl-range-thumb:focus {
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #7957d5, 0 0 0 .125em #7957d540;
}

div.b-colorpicker-square .colorpicker-square-slider-sl .sl-range-thumb:active {
  cursor: grabbing;
}

.b-colorpicker-alpha-slider {
  background-image: linear-gradient(45deg, #c7c7c7 25%, #0000 25% 75%, #c7c7c7 75%, #c7c7c7), linear-gradient(45deg, #c7c7c7 25%, #0000 25% 75%, #c7c7c7 75%, #c7c7c7);
  background-position: .5em .5em, 0 0;
  background-size: 1em 1em;
  border-radius: 4px;
  height: 1em;
  margin-top: .125em;
  margin-bottom: .875rem;
  position: relative;
}

.b-colorpicker-alpha-slider .alpha-range-thumb {
  cursor: grab;
  border-radius: 4px;
  width: .4em;
  height: 1.25em;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 2px #dbdbdb, 0 0 0 1px #dbdbdb;
}

.b-colorpicker-alpha-slider .alpha-range-thumb:hover {
  box-shadow: inset 0 0 0 1px #fff, inset 0 0 0 2px #b5b5b5, 0 0 0 1px #b5b5b5;
}

.b-colorpicker-alpha-slider .alpha-range-thumb:focus {
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #7957d5, 0 0 0 .125em #7957d540;
}

.b-colorpicker-alpha-slider .alpha-range-thumb:active {
  cursor: grabbing;
}

.b-colorpicker-alpha-slider .alpha-range-thumb > .b-tooltip, .b-colorpicker-alpha-slider .alpha-range-thumb > .b-tooltip > .tooltip-trigger {
  display: block;
  position: absolute;
  inset: 0;
}

.datepicker {
  font-size: .875rem;
}

.datepicker .dropdown, .datepicker .dropdown-trigger {
  width: 100%;
}

.datepicker .dropdown .input[readonly], .datepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

.datepicker .dropdown .input[readonly]:focus, .datepicker .dropdown .input[readonly].is-focused, .datepicker .dropdown .input[readonly]:active, .datepicker .dropdown .input[readonly].is-active, .datepicker .dropdown-trigger .input[readonly]:focus, .datepicker .dropdown-trigger .input[readonly].is-focused, .datepicker .dropdown-trigger .input[readonly]:active, .datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: 0 0 0 .125em #7957d540;
}

.datepicker .dropdown.is-disabled {
  opacity: 1;
}

.datepicker .dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 .5em 1em -.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
}

.datepicker .dropdown-item, .datepicker .dropdown .dropdown-menu .has-link a, .dropdown .dropdown-menu .has-link .datepicker a {
  font-size: inherit;
}

.datepicker .datepicker-header {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: .875rem;
  padding-bottom: .875rem;
}

.datepicker .datepicker-footer {
  border-top: 1px solid #dbdbdb;
  margin-top: .875rem;
  padding-top: .875rem;
}

.datepicker .datepicker-table {
  margin: 0 auto;
  display: table;
}

.datepicker .datepicker-table .datepicker-cell {
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  padding: .5rem .75rem;
  display: table-cell;
}

.datepicker .datepicker-table .datepicker-header {
  display: table-header-group;
}

.datepicker .datepicker-table .datepicker-header .datepicker-cell {
  color: #7a7a7a;
  font-weight: 600;
}

.datepicker .datepicker-table .datepicker-body {
  display: table-row-group;
}

.datepicker .datepicker-table .datepicker-body .datepicker-row {
  display: table-row;
}

.datepicker .datepicker-table .datepicker-body .datepicker-months {
  flex-flow: wrap;
  width: 17rem;
  display: inline-flex;
}

.datepicker .datepicker-table .datepicker-body .datepicker-months .datepicker-cell {
  justify-content: center;
  align-items: center;
  width: 33.33%;
  height: 2.5rem;
  display: flex;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-unselectable {
  color: #b5b5b5;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: 1px solid #7957d580;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable {
  color: #4a4a4a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:hover:not(.is-selected), .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:focus:not(.is-selected) {
  color: #0a0a0a;
  cursor: pointer;
  background-color: #f5f5f5;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-first-hovered {
  color: #dbdbdb;
  background-color: #7a7a7a;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-within-hovered {
  color: #0a0a0a;
  background-color: #f5f5f5;
  border-radius: 0;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable.is-within-hovered-range.is-last-hovered {
  color: #dbdbdb;
  background-color: #7a7a7a;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  color: #fff;
  background-color: #7957d5;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-first-selected {
  color: #fff;
  background-color: #7957d5;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-within-selected {
  background-color: #7957d580;
  border-radius: 0;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-last-selected {
  color: #fff;
  background-color: #7957d5;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-nearby:not(.is-selected) {
  color: #b5b5b5;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-week-number {
  cursor: default;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell {
  padding: .3rem .75rem .75rem;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event {
  position: relative;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events {
  justify-content: center;
  width: 100%;
  padding: 0 .35rem;
  display: flex;
  position: absolute;
  bottom: .425rem;
  left: 0;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-white {
  background-color: #fff;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-black {
  background-color: #0a0a0a;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-light {
  background-color: #f5f5f5;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-dark {
  background-color: #363636;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-primary, .datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-link {
  background-color: #7957d5;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-info {
  background-color: #167df0;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-success {
  background-color: #48c78e;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-warning {
  background-color: #ffe08a;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-danger {
  background-color: #f14668;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event.dots .event {
  border-radius: 50%;
  width: .35em;
  height: .35em;
  margin: 0 .1em;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.has-event.bars .event {
  width: 100%;
  height: .25em;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.is-selected {
  overflow: hidden;
}

.datepicker .datepicker-table .datepicker-body.has-events .datepicker-cell.is-selected .events .event.is-primary {
  background-color: #aa94e4;
}

.datepicker.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.datepicker.is-medium {
  font-size: 1.25rem;
}

.datepicker.is-large {
  font-size: 1.5rem;
}

@media screen and (width >= 1024px) {
  .datepicker .footer-horizontal-timepicker {
    border: none;
    margin-left: 5px;
    padding-left: 10px;
    display: flex;
  }

  .datepicker .dropdown-horizontal-timepicker {
    display: flex;
  }

  .datepicker .content-horizontal-timepicker {
    border-right: 1px solid #dbdbdb;
  }
}

.dialog .modal-card {
  width: auto;
  max-width: 460px;
}

.dialog .modal-card .modal-card-head {
  font-size: 1.25rem;
  font-weight: 600;
}

.dialog .modal-card .modal-card-body .field {
  margin-top: 16px;
}

.dialog .modal-card .modal-card-body.is-titleless {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.dialog .modal-card .modal-card-foot {
  justify-content: flex-end;
}

.dialog .modal-card .modal-card-foot .button {
  min-width: 5em;
  font-weight: 600;
  display: inline;
}

@media screen and (width >= 769px), print {
  .dialog .modal-card {
    min-width: 320px;
  }
}

.dialog.is-small .modal-card, .dialog.is-small .input, .dialog.is-small .button {
  border-radius: 2px;
  font-size: .75rem;
}

.dialog.is-medium .modal-card, .dialog.is-medium .input, .dialog.is-medium .button {
  font-size: 1.25rem;
}

.dialog.is-large .modal-card, .dialog.is-large .input, .dialog.is-large .button {
  font-size: 1.5rem;
}

.dialog.has-custom-container {
  position: absolute;
}

.dropdown + .dropdown {
  margin-left: .5em;
}

.dropdown .background {
  z-index: 40;
  cursor: pointer;
  background-color: #0a0a0adb;
  position: fixed;
  inset: 0;
}

@media screen and (width >= 1024px) {
  .dropdown .background {
    display: none;
  }
}

.dropdown.dropdown-menu-animation .dropdown-menu {
  display: block;
}

.dropdown .dropdown-menu .dropdown-item.is-disabled, .dropdown .dropdown-menu .has-link a.is-disabled {
  cursor: not-allowed;
}

.dropdown .dropdown-menu .dropdown-item.is-disabled:hover, .dropdown .dropdown-menu .has-link a.is-disabled:hover {
  background: inherit;
  color: inherit;
}

.dropdown .dropdown-menu .has-link a {
  white-space: nowrap;
  padding-right: 3rem;
}

.dropdown.is-hoverable:not(.is-active) .dropdown-menu {
  display: none;
}

.dropdown.is-hoverable:not(.is-touch-enabled):hover .dropdown-menu {
  display: inherit;
}

.dropdown.is-expanded, .dropdown.is-expanded .dropdown-trigger, .dropdown.is-expanded .dropdown-menu {
  width: 100%;
}

.dropdown.is-expanded.is-mobile-modal .dropdown-menu {
  max-width: 100%;
}

.dropdown:not(.is-disabled) .dropdown-menu .dropdown-item.is-disabled, .dropdown:not(.is-disabled) .dropdown-menu .has-link a.is-disabled {
  opacity: .5;
}

.dropdown .navbar-item {
  height: 100%;
}

.dropdown.is-disabled {
  opacity: .5;
  cursor: not-allowed;
}

.dropdown.is-disabled .dropdown-trigger {
  pointer-events: none;
}

.dropdown.is-inline .dropdown-menu {
  padding: 0;
  display: inline-block;
  position: static;
}

.dropdown.is-top-right .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.dropdown.is-top-left .dropdown-menu {
  inset: auto 0 100% auto;
}

.dropdown.is-bottom-left .dropdown-menu {
  left: auto;
  right: 0;
}

@media screen and (width <= 1023px) {
  .dropdown.is-mobile-modal:not(.is-hoverable) > .dropdown-menu, .dropdown.is-mobile-modal.is-touch-enabled > .dropdown-menu {
    white-space: normal;
    width: calc(100vw - 40px);
    max-width: 460px;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    transform: translate3d(-50%, -25%, 0);
    z-index: 50 !important;
    position: fixed !important;
    inset: 25% auto auto 50% !important;
  }

  .dropdown.is-mobile-modal:not(.is-hoverable) > .dropdown-menu > .dropdown-content > .dropdown-item, .dropdown .dropdown-menu .has-link .dropdown.is-mobile-modal:not(.is-hoverable) > .dropdown-menu > .dropdown-content > a, .dropdown.is-mobile-modal:not(.is-hoverable) > .dropdown-menu > .dropdown-content > .has-link a, .dropdown.is-mobile-modal.is-touch-enabled > .dropdown-menu > .dropdown-content > .dropdown-item, .dropdown .dropdown-menu .has-link .dropdown.is-mobile-modal.is-touch-enabled > .dropdown-menu > .dropdown-content > a, .dropdown.is-mobile-modal.is-touch-enabled > .dropdown-menu > .dropdown-content > .has-link a {
    padding: 1rem 1.5rem;
  }

  html.is-clipped-touch {
    overflow: hidden !important;
  }
}

.field.is-grouped .field {
  flex-shrink: 0;
}

.field.is-grouped .field:not(:last-child) {
  margin-right: .75rem;
}

.field.is-grouped .field.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons .control:first-child .control .button, .field.has-addons .control:first-child .control .input, .field.has-addons .control:first-child .control .select select {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.field.has-addons .control:last-child .control .button, .field.has-addons .control:last-child .control .input, .field.has-addons .control:last-child .control .select select {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.field.has-addons .control .control .button, .field.has-addons .control .control .input, .field.has-addons .control .control .select select {
  border-radius: 0;
}

.field.has-addons .b-numberinput:not(:first-child) .control:first-child .button, .field.has-addons .b-numberinput:not(:first-child) .control:first-child .input, .field.has-addons .b-numberinput:not(:first-child) .control:first-child .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .b-numberinput:not(:last-child) .control:last-child .button, .field.has-addons .b-numberinput:not(:last-child) .control:last-child .input, .field.has-addons .b-numberinput:not(:last-child) .control:last-child .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons.b-numberinput .control {
  margin-right: unset;
}

.field.is-floating-label, .field.is-floating-in-label {
  position: relative;
}

.field.is-floating-label .label, .field.is-floating-in-label .label {
  z-index: 5;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #0000;
  max-width: calc(100% - 2em);
  font-size: .75rem;
  position: absolute;
  left: 1em;
  overflow: hidden;
}

.field.is-floating-label .label.is-small, .field.is-floating-in-label .label.is-small {
  font-size: .5625rem;
}

.field.is-floating-label .label.is-medium, .field.is-floating-in-label .label.is-medium {
  font-size: .9375rem;
}

.field.is-floating-label .label.is-large, .field.is-floating-in-label .label.is-large {
  font-size: 1.125rem;
}

.field.is-floating-label .taginput .counter, .field.is-floating-in-label .taginput .counter {
  float: none;
  text-align: right;
}

.field.is-floating-label.has-addons > .label + .control .button, .field.is-floating-label.has-addons > .label + .control .input, .field.is-floating-label.has-addons > .label + .control .select select, .field.is-floating-in-label.has-addons > .label + .control .button, .field.is-floating-in-label.has-addons > .label + .control .input, .field.is-floating-in-label.has-addons > .label + .control .select select {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.field.is-floating-label .label {
  padding-left: .125em;
  padding-right: .125em;
  top: -.775em;
}

.field.is-floating-label .label:before {
  content: "";
  z-index: -1;
  background-color: #fff;
  height: .375em;
  display: block;
  position: absolute;
  top: .775em;
  left: 0;
  right: 0;
}

.field.is-floating-label .input:focus, .field.is-floating-label .textarea:focus, .field.is-floating-label .select select:focus {
  box-shadow: none;
}

.field.is-floating-label .taginput .taginput-container {
  padding-top: .475em;
}

.field.is-floating-label .taginput .taginput-container.is-focused {
  box-shadow: none;
}

.field.is-floating-in-label > .label {
  top: .25em;
}

.field.is-floating-in-label > .label + .control.datepicker .input, .field.is-floating-in-label > .label + .control.timepicker .input, .field.is-floating-in-label > .label + .control:not(.datepicker):not(.timepicker):not(.taginput) .input, .field.is-floating-in-label > .label + .control:not(.datepicker):not(.timepicker):not(.taginput) .textarea, .field.is-floating-in-label > .label + .control:not(.datepicker):not(.timepicker):not(.taginput) select {
  height: 3.25em;
  padding-top: calc(1.625em - .5625rem);
  padding-bottom: 1px;
}

.field.is-floating-in-label > .label + .control:not(.datepicker):not(.timepicker):not(.taginput) .select:not(multiple) {
  height: 3.25em;
}

.field.is-floating-in-label > .label + .control:not(.datepicker):not(.timepicker):not(.taginput) .select:not(multiple).is-loading:after {
  margin-top: calc(1.625em - .5625rem);
}

.field.is-floating-in-label > .label + .control:not(.datepicker):not(.timepicker):not(.taginput) .select:not(multiple):after {
  margin-top: 1px;
}

.field.is-floating-in-label > .label + .control.taginput .taginput-container {
  padding-top: calc(1.9em - .5625rem - 1px);
}

.field.is-floating-in-label > .label + .control:not(.taginput) .is-left.icon, .field.is-floating-in-label > .label + .control:not(.taginput) .is-right.icon {
  height: 3.25em;
}

.field.is-floating-in-label > .label + .control:not(.taginput) .is-left.icon {
  padding-top: calc(1.625em - .5625rem);
}

.field.is-floating-in-label > .label + .control.is-loading:after {
  margin-top: calc(1.625em - .5625rem);
}

.field.is-floating-in-label > .label + .field-body > .is-grouped .control .input, .field.is-floating-in-label > .label + .field-body > .is-grouped .control .textarea, .field.is-floating-in-label > .label + .field-body > .is-grouped .control select, .field.is-floating-in-label > .label + .field-body > .has-addons .control .input, .field.is-floating-in-label > .label + .field-body > .has-addons .control .textarea, .field.is-floating-in-label > .label + .field-body > .has-addons .control select {
  padding-top: calc(1.625em - .5625rem);
  padding-bottom: 1px;
}

.field.is-floating-in-label > .label + .field-body > .is-grouped .control .input, .field.is-floating-in-label > .label + .field-body > .is-grouped .control .textarea, .field.is-floating-in-label > .label + .field-body > .is-grouped .control select, .field.is-floating-in-label > .label + .field-body > .is-grouped .control .button, .field.is-floating-in-label > .label + .field-body > .has-addons .control .input, .field.is-floating-in-label > .label + .field-body > .has-addons .control .textarea, .field.is-floating-in-label > .label + .field-body > .has-addons .control select, .field.is-floating-in-label > .label + .field-body > .has-addons .control .button, .field.is-floating-in-label.has-numberinput .b-numberinput .control .input, .field.is-floating-in-label.has-numberinput .b-numberinput .control .button {
  height: 3.25em;
}

.field.is-floating-label.has-numberinput .label, .field.is-floating-in-label.has-numberinput .label {
  margin-left: 3rem;
}

.field.is-floating-label.has-numberinput.has-numberinput-is-small .label, .field.is-floating-in-label.has-numberinput.has-numberinput-is-small .label {
  margin-left: 2.25rem;
}

.field.is-floating-label.has-numberinput.has-numberinput-is-medium .label, .field.is-floating-in-label.has-numberinput.has-numberinput-is-medium .label {
  margin-left: 3.75rem;
}

.field.is-floating-label.has-numberinput.has-numberinput-is-large .label, .field.is-floating-in-label.has-numberinput.has-numberinput-is-large .label {
  margin-left: 4.5rem;
}

.field.is-floating-label.has-numberinput-compact .label, .field.is-floating-in-label.has-numberinput-compact .label {
  margin-left: 2.25rem;
}

.field.is-floating-label.has-numberinput-compact.has-numberinput-is-small .label, .field.is-floating-in-label.has-numberinput-compact.has-numberinput-is-small .label {
  margin-left: 1.6875rem;
}

.field.is-floating-label.has-numberinput-compact.has-numberinput-is-medium .label, .field.is-floating-in-label.has-numberinput-compact.has-numberinput-is-medium .label {
  margin-left: 2.8125rem;
}

.field.is-floating-label.has-numberinput-compact.has-numberinput-is-large .label, .field.is-floating-in-label.has-numberinput-compact.has-numberinput-is-large .label {
  margin-left: 3.375rem;
}

.field.is-grouped-right.is-floating-in-label .label, .field.has-addons-right.is-floating-in-label .label, .field.is-grouped-right.is-floating-label .label, .field.has-addons-right.is-floating-label .label {
  position: relative;
  left: 5.25em;
}

.control .help.counter {
  float: right;
  margin-left: .5em;
}

.control .icon.is-clickable {
  pointer-events: auto;
  cursor: pointer;
}

.control.is-loading:after {
  top: calc(50% - .5em);
  right: .75em;
}

.icon {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: inherit;
}

.icon svg {
  fill: currentColor;
  stroke-width: 0;
  stroke: currentColor;
  pointer-events: none;
  background-color: #0000;
  width: 100%;
  height: 100%;
}

.b-image-wrapper > img {
  object-fit: cover;
}

.b-image-wrapper > img.has-ratio, .b-image-wrapper > img.placeholder {
  width: 100%;
  height: 100%;
}

.b-image-wrapper > img.placeholder {
  filter: blur(10px);
}

.loading-overlay {
  z-index: 29;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.loading-overlay.is-active {
  display: flex;
}

.loading-overlay.is-full-page {
  z-index: 999;
  position: fixed;
}

.loading-overlay.is-full-page .loading-icon:after {
  width: 5em;
  height: 5em;
  top: calc(50% - 2.5em);
  left: calc(50% - 2.5em);
}

.loading-overlay .loading-background {
  background: #ffffff80;
  position: absolute;
  inset: 0;
}

.loading-overlay .loading-icon {
  position: relative;
}

.loading-overlay .loading-icon:after {
  content: "";
  border: .25em solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  width: 3em;
  height: 3em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: absolute;
  top: calc(50% - 1.5em);
  left: calc(50% - 1.5em);
}

.menu .menu-list li > a.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5;
}

.message .media, .notification .media {
  border: 0;
  padding-top: 0;
}

.message .auto-close-progress progress, .notification .auto-close-progress progress {
  border-radius: 0 !important;
  height: 10px !important;
}

.modal.is-full-screen > .animation-content, .modal.is-full-screen > .animation-content > .modal-card {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  margin: 0;
}

.modal .animation-content {
  margin: 0 20px;
}

.modal .animation-content .modal-card {
  margin: 0;
}

@media screen and (width <= 768px) {
  .modal .animation-content {
    width: 100%;
  }
}

.modal .modal-content {
  width: 100%;
}

@media screen and (width <= 768px) {
  .modal .modal-close {
    background-color: #0a0a0a16;
  }

  .modal .modal-close:hover, .modal .modal-close :focus {
    background-color: #0a0a0a2c;
  }

  .modal .modal-close:active {
    background-color: #0a0a0a42;
  }
}

.navbar.has-navbar-centered .navbar-start {
  justify-content: center;
  margin-left: auto;
}

.navbar.has-navbar-centered .navbar-end {
  margin-left: 0;
}

.navbar .navbar-dropdown.is-boxed {
  visibility: hidden;
  transition-property: opacity, visibility, transform;
}

.navbar .navbar-item.has-dropdown.is-active .is-boxed, .navbar .navbar-item.has-dropdown.is-hoverable:hover .is-boxed {
  visibility: visible;
}

.notices {
  z-index: 1000;
  pointer-events: none;
  padding: 2em;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.notices .toast {
  text-align: center;
  pointer-events: auto;
  opacity: .92;
  border-radius: 2em;
  margin: .5em 0;
  padding: .75em 1.5em;
  animation-duration: .15s;
  display: inline-flex;
  box-shadow: 0 1px 4px #0000001f, 0 0 6px #0000000a;
}

.notices .toast.is-white {
  color: #0a0a0a;
  background: #fff;
}

.notices .toast.is-black {
  color: #fff;
  background: #0a0a0a;
}

.notices .toast.is-light {
  color: #000000b3;
  background: #f5f5f5;
}

.notices .toast.is-dark {
  color: #fff;
  background: #363636;
}

.notices .toast.is-primary, .notices .toast.is-link {
  color: #fff;
  background: #7957d5;
}

.notices .toast.is-info {
  color: #fff;
  background: #167df0;
}

.notices .toast.is-success {
  color: #fff;
  background: #48c78e;
}

.notices .toast.is-warning {
  color: #000000b3;
  background: #ffe08a;
}

.notices .toast.is-danger {
  color: #fff;
  background: #f14668;
}

.notices .snackbar {
  pointer-events: auto;
  color: #fff;
  background: #363636;
  border-radius: 4px;
  justify-content: space-around;
  align-items: center;
  min-height: 3em;
  margin: .5em 0;
  animation-duration: .15s;
  display: inline-flex;
  box-shadow: 0 1px 4px #0000001f, 0 0 6px #0000000a;
}

.notices .snackbar .text {
  padding: .5em 1em;
}

.notices .snackbar .action {
  margin-left: auto;
  padding: .5em .5em .5em 0;
}

.notices .snackbar .action .button {
  text-transform: uppercase;
  background: #363636;
  border: #0000;
  font-weight: 600;
}

.notices .snackbar .action .button:hover, .notices .snackbar .action .button:active {
  background: #292929;
}

.notices .snackbar .action.is-white .button {
  color: #fff;
}

.notices .snackbar .action.is-black .button {
  color: #0a0a0a;
}

.notices .snackbar .action.is-light .button {
  color: #f5f5f5;
}

.notices .snackbar .action.is-dark .button {
  color: #363636;
}

.notices .snackbar .action.is-primary .button, .notices .snackbar .action.is-link .button {
  color: #7957d5;
}

.notices .snackbar .action.is-info .button {
  color: #167df0;
}

.notices .snackbar .action.is-success .button {
  color: #48c78e;
}

.notices .snackbar .action.is-warning .button {
  color: #ffe08a;
}

.notices .snackbar .action.is-danger .button {
  color: #f14668;
}

.notices .snackbar .action.is-cancel {
  padding-right: 0;
}

@media screen and (width <= 768px) {
  .notices .snackbar {
    border-radius: 0;
    width: 100%;
    margin: 0;
  }
}

@media screen and (width >= 769px), print {
  .notices .snackbar {
    min-width: 350px;
    max-width: 600px;
    overflow: hidden;
  }
}

.notices .notification {
  pointer-events: auto;
  max-width: 600px;
}

.notices .toast.is-top, .notices .toast.is-bottom, .notices .snackbar.is-top, .notices .snackbar.is-bottom, .notices .notification.is-top, .notices .notification.is-bottom {
  align-self: center;
}

.notices .toast.is-top-right, .notices .toast.is-bottom-right, .notices .snackbar.is-top-right, .notices .snackbar.is-bottom-right, .notices .notification.is-top-right, .notices .notification.is-bottom-right {
  align-self: flex-end;
}

.notices .toast.is-top-left, .notices .toast.is-bottom-left, .notices .snackbar.is-top-left, .notices .snackbar.is-bottom-left, .notices .notification.is-top-left, .notices .notification.is-bottom-left {
  align-self: flex-start;
}

.notices .toast.is-toast, .notices .snackbar.is-toast, .notices .notification.is-toast {
  opacity: .92;
}

.notices.is-top {
  flex-direction: column;
}

.notices.is-bottom {
  flex-direction: column-reverse;
}

.notices.is-bottom .notification {
  margin-bottom: 0;
}

.notices.is-bottom .notification:not(:first-child) {
  margin-bottom: 1.5rem;
}

.notices.has-custom-container {
  position: absolute;
}

@media screen and (width <= 768px) {
  .notices {
    padding: 0;
    position: fixed !important;
  }
}

.notification .auto-close-progress.progress-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.b-numberinput.field {
  margin-bottom: 0;
}

.b-numberinput.field.is-grouped div.control, .b-numberinput.field.has-addons.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.b-numberinput input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.b-numberinput input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.b-numberinput input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}

.b-numberinput .button.is-rounded {
  padding-left: 1em;
  padding-right: 1em;
}

.pagination .pagination-next, .pagination .pagination-previous {
  padding-left: .75em;
  padding-right: .75em;
}

.pagination .pagination-next.is-disabled, .pagination .pagination-previous.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5;
}

.pagination.is-simple {
  justify-content: normal;
}

.pagination.is-simple.is-centered {
  justify-content: center;
}

.pagination.is-simple.is-right {
  justify-content: flex-end;
}

.pagination .is-current {
  pointer-events: none;
  cursor: not-allowed;
}

@media screen and (width >= 769px), print {
  .pagination.has-input .pagination-list {
    order: 1;
  }

  .pagination.has-input .pagination-input {
    order: 2;
  }

  .pagination.has-input .pagination-previous {
    order: 3;
  }

  .pagination.has-input .pagination-next {
    order: 4;
  }

  .pagination.has-input.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.has-input.is-centered .pagination-list {
    order: 2;
  }

  .pagination.has-input.is-centered .pagination-input {
    order: 3;
  }

  .pagination.has-input.is-centered .pagination-next {
    order: 4;
  }

  .pagination.has-input.is-centered.is-input-right .pagination-previous {
    order: 1;
  }

  .pagination.has-input.is-centered.is-input-right .pagination-list {
    order: 2;
  }

  .pagination.has-input.is-centered.is-input-right .pagination-next {
    order: 3;
  }

  .pagination.has-input.is-centered.is-input-right .pagination-input {
    order: 4;
  }

  .pagination.has-input.is-centered.is-input-left .pagination-input {
    order: 1;
  }

  .pagination.has-input.is-centered.is-input-left .pagination-previous {
    order: 2;
  }

  .pagination.has-input.is-centered.is-input-left .pagination-list {
    order: 3;
  }

  .pagination.has-input.is-centered.is-input-left .pagination-next {
    order: 4;
  }

  .pagination.has-input.is-right .pagination-previous {
    order: 1;
  }

  .pagination.has-input.is-right .pagination-next {
    order: 2;
  }

  .pagination.has-input.is-right .pagination-input {
    order: 3;
  }

  .pagination.has-input.is-right .pagination-list {
    order: 4;
  }

  .pagination.has-input.is-right.is-input-right .pagination-previous {
    order: 1;
  }

  .pagination.has-input.is-right.is-input-right .pagination-next {
    order: 2;
  }

  .pagination.has-input.is-right.is-input-right .pagination-list {
    order: 3;
  }

  .pagination.has-input.is-right.is-input-right .pagination-input {
    order: 4;
  }

  .pagination.has-input.is-right.is-input-left .pagination-input {
    order: 1;
  }

  .pagination.has-input.is-right.is-input-left .pagination-previous {
    order: 2;
  }

  .pagination.has-input.is-right.is-input-left .pagination-next {
    order: 3;
  }

  .pagination.has-input.is-right.is-input-left .pagination-list {
    order: 4;
  }

  .pagination.has-input.is-input-right .pagination-list {
    order: 1;
  }

  .pagination.has-input.is-input-right .pagination-previous {
    order: 2;
  }

  .pagination.has-input.is-input-right .pagination-next {
    order: 3;
  }

  .pagination.has-input.is-input-right .pagination-input {
    order: 4;
  }

  .pagination.has-input.is-input-left .pagination-input {
    order: 1;
  }

  .pagination.has-input.is-input-left .pagination-list {
    order: 2;
  }

  .pagination.has-input.is-input-left .pagination-previous {
    order: 3;
  }

  .pagination.has-input.is-input-left .pagination-next {
    order: 4;
  }
}

.progress-wrapper {
  position: relative;
  overflow: hidden;
}

.progress-wrapper:not(:last-child) {
  margin-bottom: 1.5rem;
}

.progress-wrapper .progress-value {
  color: #000000b3;
  white-space: nowrap;
  font-size: .666667rem;
  font-weight: 700;
  line-height: 1rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.progress-wrapper .progress, .progress-wrapper .progress-wrapper.is-not-native, .progress-wrapper .progress-bar {
  margin-bottom: 0;
}

.progress-wrapper .progress.is-small + .progress-value, .progress-wrapper .is-small.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress.is-small .progress-value, .progress-wrapper .is-small.progress-wrapper.is-not-native .progress-value, .progress-wrapper .progress-bar.is-small + .progress-value, .progress-wrapper .progress-bar.is-small .progress-value {
  font-size: .5rem;
  line-height: .75rem;
}

.progress-wrapper .progress.is-medium + .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress.is-medium .progress-value, .progress-wrapper .is-medium.progress-wrapper.is-not-native .progress-value, .progress-wrapper .progress-bar.is-medium + .progress-value, .progress-wrapper .progress-bar.is-medium .progress-value {
  font-size: .833333rem;
  line-height: 1.25rem;
}

.progress-wrapper .progress.is-large + .progress-value, .progress-wrapper .is-large.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress.is-large .progress-value, .progress-wrapper .is-large.progress-wrapper.is-not-native .progress-value, .progress-wrapper .progress-bar.is-large + .progress-value, .progress-wrapper .progress-bar.is-large .progress-value {
  font-size: 1rem;
  line-height: 1.5rem;
}

.progress-wrapper .progress::-webkit-progress-value {
  transition: width .5s;
}

.progress-wrapper .progress-wrapper.is-not-native::-webkit-progress-value {
  transition: width .5s;
}

.progress-wrapper .progress-bar::-webkit-progress-value {
  transition: width .5s;
}

.progress-wrapper .progress.is-more-than-half + .progress-value, .progress-wrapper .is-more-than-half.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half + .progress-value {
  color: #fff;
}

.progress-wrapper .progress.is-more-than-half.is-white + .progress-value, .progress-wrapper .is-more-than-half.is-white.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-white + .progress-value {
  color: #0a0a0a;
}

.progress-wrapper .progress.is-more-than-half.is-black + .progress-value, .progress-wrapper .is-more-than-half.is-black.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-black + .progress-value {
  color: #fff;
}

.progress-wrapper .progress.is-more-than-half.is-light + .progress-value, .progress-wrapper .is-more-than-half.is-light.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-light + .progress-value {
  color: #000000b3;
}

.progress-wrapper .progress.is-more-than-half.is-dark + .progress-value, .progress-wrapper .is-more-than-half.is-dark.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-dark + .progress-value, .progress-wrapper .progress.is-more-than-half.is-primary + .progress-value, .progress-wrapper .is-more-than-half.is-primary.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-primary + .progress-value, .progress-wrapper .progress.is-more-than-half.is-link + .progress-value, .progress-wrapper .is-more-than-half.is-link.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-link + .progress-value, .progress-wrapper .progress.is-more-than-half.is-info + .progress-value, .progress-wrapper .is-more-than-half.is-info.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-info + .progress-value, .progress-wrapper .progress.is-more-than-half.is-success + .progress-value, .progress-wrapper .is-more-than-half.is-success.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-success + .progress-value {
  color: #fff;
}

.progress-wrapper .progress.is-more-than-half.is-warning + .progress-value, .progress-wrapper .is-more-than-half.is-warning.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-warning + .progress-value {
  color: #000000b3;
}

.progress-wrapper .progress.is-more-than-half.is-danger + .progress-value, .progress-wrapper .is-more-than-half.is-danger.progress-wrapper.is-not-native + .progress-value, .progress-wrapper .progress-bar.is-more-than-half.is-danger + .progress-value {
  color: #fff;
}

.progress-wrapper .progress.is-squared, .progress-wrapper .is-squared.progress-wrapper.is-not-native, .progress-wrapper .progress-bar.is-squared {
  border-radius: 0;
}

.progress-wrapper.is-not-native {
  white-space: nowrap;
  background-color: #ededed;
  border-radius: 9999px;
}

.progress-wrapper.is-not-native .progress-bar {
  vertical-align: top;
  background-color: #4a4a4a;
  height: 100%;
  display: inline-block;
  position: relative;
}

.progress-wrapper.is-not-native .progress-bar .progress-value {
  color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-white {
  background-color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-white .progress-value {
  color: #0a0a0a;
}

.progress-wrapper.is-not-native .progress-bar.is-black {
  background-color: #0a0a0a;
}

.progress-wrapper.is-not-native .progress-bar.is-black .progress-value {
  color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-light {
  background-color: #f5f5f5;
}

.progress-wrapper.is-not-native .progress-bar.is-light .progress-value {
  color: #000000b3;
}

.progress-wrapper.is-not-native .progress-bar.is-dark {
  background-color: #363636;
}

.progress-wrapper.is-not-native .progress-bar.is-dark .progress-value {
  color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-primary {
  background-color: #7957d5;
}

.progress-wrapper.is-not-native .progress-bar.is-primary .progress-value {
  color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-link {
  background-color: #7957d5;
}

.progress-wrapper.is-not-native .progress-bar.is-link .progress-value {
  color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-info {
  background-color: #167df0;
}

.progress-wrapper.is-not-native .progress-bar.is-info .progress-value {
  color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-success {
  background-color: #48c78e;
}

.progress-wrapper.is-not-native .progress-bar.is-success .progress-value {
  color: #fff;
}

.progress-wrapper.is-not-native .progress-bar.is-warning {
  background-color: #ffe08a;
}

.progress-wrapper.is-not-native .progress-bar.is-warning .progress-value {
  color: #000000b3;
}

.progress-wrapper.is-not-native .progress-bar.is-danger {
  background-color: #f14668;
}

.progress-wrapper.is-not-native .progress-bar.is-danger .progress-value {
  color: #fff;
}

.progress-wrapper.is-squared {
  border-radius: 0;
}

.b-radio.radio {
  outline: none;
  align-items: center;
  display: inline-flex;
}

.b-radio.radio:not(.button) {
  margin-right: .5em;
}

.b-radio.radio:not(.button) + .radio:last-child {
  margin-right: 0;
}

.b-radio.radio + .radio {
  margin-left: 0;
}

.b-radio.radio input[type="radio"] {
  opacity: 0;
  z-index: -1;
  outline: none;
  position: absolute;
  left: 0;
}

.b-radio.radio input[type="radio"] + .check {
  cursor: pointer;
  border: 2px solid #7a7a7a;
  border-radius: 50%;
  flex-shrink: 0;
  width: 1.25em;
  height: 1.25em;
  transition: background .15s ease-out;
  display: flex;
  position: relative;
}

.b-radio.radio input[type="radio"] + .check:before {
  content: "";
  background-color: #7957d5;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  margin-bottom: -.625em;
  margin-left: -.625em;
  transition: transform .15s ease-out;
  display: flex;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: scale(0);
}

.b-radio.radio input[type="radio"] + .check.is-white:before {
  background: #fff;
}

.b-radio.radio input[type="radio"] + .check.is-black:before {
  background: #0a0a0a;
}

.b-radio.radio input[type="radio"] + .check.is-light:before {
  background: #f5f5f5;
}

.b-radio.radio input[type="radio"] + .check.is-dark:before {
  background: #363636;
}

.b-radio.radio input[type="radio"] + .check.is-primary:before, .b-radio.radio input[type="radio"] + .check.is-link:before {
  background: #7957d5;
}

.b-radio.radio input[type="radio"] + .check.is-info:before {
  background: #167df0;
}

.b-radio.radio input[type="radio"] + .check.is-success:before {
  background: #48c78e;
}

.b-radio.radio input[type="radio"] + .check.is-warning:before {
  background: #ffe08a;
}

.b-radio.radio input[type="radio"] + .check.is-danger:before {
  background: #f14668;
}

.b-radio.radio input[type="radio"]:checked + .check {
  border-color: #7957d5;
}

.b-radio.radio input[type="radio"]:checked + .check.is-white {
  border-color: #fff;
}

.b-radio.radio input[type="radio"]:checked + .check.is-black {
  border-color: #0a0a0a;
}

.b-radio.radio input[type="radio"]:checked + .check.is-light {
  border-color: #f5f5f5;
}

.b-radio.radio input[type="radio"]:checked + .check.is-dark {
  border-color: #363636;
}

.b-radio.radio input[type="radio"]:checked + .check.is-primary, .b-radio.radio input[type="radio"]:checked + .check.is-link {
  border-color: #7957d5;
}

.b-radio.radio input[type="radio"]:checked + .check.is-info {
  border-color: #167df0;
}

.b-radio.radio input[type="radio"]:checked + .check.is-success {
  border-color: #48c78e;
}

.b-radio.radio input[type="radio"]:checked + .check.is-warning {
  border-color: #ffe08a;
}

.b-radio.radio input[type="radio"]:checked + .check.is-danger {
  border-color: #f14668;
}

.b-radio.radio input[type="radio"]:checked + .check:before {
  transform: scale(.5);
}

.b-radio.radio input[type="radio"]:focus + .check {
  box-shadow: 0 0 .5em #7a7a7acc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check {
  box-shadow: 0 0 .5em #7957d5cc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-white {
  box-shadow: 0 0 .5em #fffc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-black {
  box-shadow: 0 0 .5em #0a0a0acc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-light {
  box-shadow: 0 0 .5em #f5f5f5cc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-dark {
  box-shadow: 0 0 .5em #363636cc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-primary, .b-radio.radio input[type="radio"]:focus:checked + .check.is-link {
  box-shadow: 0 0 .5em #7957d5cc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-info {
  box-shadow: 0 0 .5em #167df0cc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-success {
  box-shadow: 0 0 .5em #48c78ecc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-warning {
  box-shadow: 0 0 .5em #ffe08acc;
}

.b-radio.radio input[type="radio"]:focus:checked + .check.is-danger {
  box-shadow: 0 0 .5em #f14668cc;
}

.b-radio.radio input[type="radio"][disabled] + .check {
  cursor: not-allowed;
}

.b-radio.radio .control-label {
  padding-left: calc(.75em - 1px);
}

.b-radio.radio.button {
  display: flex;
}

.b-radio.radio.button.is-selected {
  z-index: 1;
}

.b-radio.radio[disabled] {
  opacity: .5;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check {
  border-color: #7957d5;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-white {
  border-color: #fff;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-black {
  border-color: #0a0a0a;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-light {
  border-color: #f5f5f5;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-dark {
  border-color: #363636;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-primary, .b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-link {
  border-color: #7957d5;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-info {
  border-color: #167df0;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-success {
  border-color: #48c78e;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-warning {
  border-color: #ffe08a;
}

.b-radio.radio:hover input[type="radio"]:not(:disabled) + .check.is-danger {
  border-color: #f14668;
}

.b-radio.radio.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.b-radio.radio.is-medium {
  font-size: 1.25rem;
}

.b-radio.radio.is-large {
  font-size: 1.5rem;
}

.rate {
  align-items: center;
  display: flex;
}

.rate:not(:last-child) {
  margin-bottom: .75rem;
}

.rate.is-spaced .rate-item:not(:last-child) {
  margin-right: .25rem;
}

.rate.is-disabled .rate-item {
  cursor: initial;
}

.rate.is-disabled .rate-item:hover {
  transform: none;
}

.rate.is-rtl .rate-item {
  order: 1;
}

.rate.is-rtl .rate-text {
  margin-left: 0;
  margin-right: .35rem;
}

.rate .rate-item {
  cursor: pointer;
  transition: all .3s;
  display: inline-flex;
  position: relative;
}

.rate .rate-item:hover {
  transform: scale(1.1);
}

.rate .rate-item.set-on .icon, .rate .rate-item.set-half .is-half {
  color: #ffd970;
}

.rate .rate-item.set-half .is-half {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.rate .icon {
  color: #dbdbdb;
  pointer-events: none;
  width: inherit;
  line-height: 1;
}

.rate .is-half > i {
  position: absolute;
  left: 0;
}

.rate .rate-text {
  margin-left: .35rem;
  font-size: .8rem;
}

.rate .rate-text.is-small {
  font-size: .6rem;
}

.rate .rate-text.is-medium {
  font-size: 1rem;
}

.rate .rate-text.is-large {
  font-size: 1.2rem;
}

.select select {
  padding-right: 2.5em;
  text-rendering: auto !important;
}

.select select option {
  color: #4a4a4a;
  padding: calc(.5em - 1px) calc(.75em - 1px);
}

.select select option:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.select select optgroup {
  color: #b5b5b5;
  padding: .25em 0;
  font-style: normal;
  font-weight: 400;
}

.select select[disabled] {
  opacity: 1;
}

.select.is-empty select {
  color: #7a7a7ab3;
}

.select.is-loading:after {
  top: calc(50% - .5em);
  right: .75em;
}

.b-skeleton {
  vertical-align: middle;
  flex-direction: column;
  width: 100%;
  display: inline-flex;
}

.b-skeleton > .b-skeleton-item {
  background: linear-gradient(90deg, #dbdbdb 25%, #dbdbdb80 50%, #dbdbdb 75%) 0 0 / 400% 100%;
  width: 100%;
}

.b-skeleton > .b-skeleton-item.is-rounded {
  border-radius: 4px;
}

.b-skeleton > .b-skeleton-item:after {
  content: " ";
}

.b-skeleton > .b-skeleton-item + .b-skeleton-item {
  margin-top: .5rem;
}

.b-skeleton.is-animated > .b-skeleton-item {
  animation: 1.5s infinite skeleton-loading;
}

.b-skeleton.is-centered {
  align-items: center;
}

.b-skeleton.is-right {
  align-items: flex-end;
}

.b-skeleton + .b-skeleton {
  margin-top: .5rem;
}

.b-skeleton > .b-skeleton-item {
  line-height: 1rem;
}

.b-skeleton.is-small > .b-skeleton-item {
  line-height: .75rem;
}

.b-skeleton.is-medium > .b-skeleton-item {
  line-height: 1.25rem;
}

.b-skeleton.is-large > .b-skeleton-item {
  line-height: 1.5rem;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

.b-sidebar .sidebar-content {
  z-index: 39;
  background-color: #f5f5f5;
  width: 260px;
  box-shadow: 5px 0 13px 3px #0a0a0a1a;
}

.b-sidebar .sidebar-content.is-white {
  background-color: #fff;
}

.b-sidebar .sidebar-content.is-black {
  background-color: #0a0a0a;
}

.b-sidebar .sidebar-content.is-light {
  background-color: #f5f5f5;
}

.b-sidebar .sidebar-content.is-dark {
  background-color: #363636;
}

.b-sidebar .sidebar-content.is-primary, .b-sidebar .sidebar-content.is-link {
  background-color: #7957d5;
}

.b-sidebar .sidebar-content.is-info {
  background-color: #167df0;
}

.b-sidebar .sidebar-content.is-success {
  background-color: #48c78e;
}

.b-sidebar .sidebar-content.is-warning {
  background-color: #ffe08a;
}

.b-sidebar .sidebar-content.is-danger {
  background-color: #f14668;
}

.b-sidebar .sidebar-content.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.b-sidebar .sidebar-content.is-fixed.is-right {
  left: auto;
  right: 0;
}

.b-sidebar .sidebar-content.is-absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.b-sidebar .sidebar-content.is-absolute.is-right {
  left: auto;
  right: 0;
}

.b-sidebar .sidebar-content.is-mini {
  width: 80px;
}

.b-sidebar .sidebar-content.is-mini.is-mini-expand:hover:not(.is-mini-delayed) {
  transition: width .15s ease-out;
}

.b-sidebar .sidebar-content.is-mini.is-mini-expand:hover:not(.is-mini-delayed):not(.is-fullwidth) {
  width: 260px;
}

.b-sidebar .sidebar-content.is-mini.is-mini-expand:hover:not(.is-mini-delayed):not(.is-fullwidth).is-mini-expand-fixed {
  position: fixed;
}

.b-sidebar .sidebar-content.is-static {
  position: static;
}

.b-sidebar .sidebar-content.is-absolute, .b-sidebar .sidebar-content.is-static {
  transition: width .15s ease-out;
}

.b-sidebar .sidebar-content.is-fullwidth {
  width: 100%;
  max-width: 100%;
}

.b-sidebar .sidebar-content.is-fullheight {
  flex-direction: column;
  align-content: stretch;
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden auto;
}

@media screen and (width <= 768px) {
  .b-sidebar .sidebar-content.is-mini-mobile {
    width: 80px;
  }

  .b-sidebar .sidebar-content.is-mini-mobile.is-mini-expand:hover:not(.is-fullwidth-mobile) {
    width: 260px;
  }

  .b-sidebar .sidebar-content.is-mini-mobile.is-mini-expand:hover:not(.is-fullwidth-mobile).is-mini-expand-fixed {
    position: fixed;
  }

  .b-sidebar .sidebar-content.is-hidden-mobile {
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .b-sidebar .sidebar-content.is-fullwidth-mobile {
    width: 100%;
    max-width: 100%;
  }
}

.b-sidebar .sidebar-content .sidebar-close {
  background: #0a0a0a4d;
}

.b-sidebar .sidebar-content .sidebar-close:hover {
  background: #0a0a0adb;
}

.b-sidebar .sidebar-background {
  z-index: 38;
  background: #0a0a0adb;
  position: fixed;
  inset: 0;
}

.b-slider {
  background: none;
  width: 100%;
  margin: 1em 0;
}

.b-slider .b-slider-track {
  cursor: pointer;
  background: #dbdbdb;
  border-radius: 4px;
  align-items: center;
  display: flex;
  position: relative;
}

.b-slider .b-slider-fill {
  background: #dbdbdb;
  border: 0 solid #7a7a7a;
  border-radius: 4px;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 #7a7a7a;
}

.b-slider .b-slider-thumb-wrapper {
  cursor: grab;
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.b-slider .b-slider-thumb-wrapper .b-slider-thumb {
  box-shadow: none;
  background: #fff;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
}

.b-slider .b-slider-thumb-wrapper .b-slider-thumb:focus {
  transform: scale(1.25);
}

.b-slider .b-slider-thumb-wrapper.is-dragging {
  cursor: grabbing;
}

.b-slider .b-slider-thumb-wrapper.is-dragging .b-slider-thumb {
  transform: scale(1.25);
}

.b-slider .b-slider-thumb-wrapper.has-indicator .b-slider-thumb {
  align-items: center;
  width: auto;
  padding: 16px 10px;
  display: flex;
}

.b-slider.slider-focus {
  cursor: pointer;
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.b-slider.is-rounded .b-slider-thumb {
  border-radius: 9999px;
}

.b-slider.is-disabled .b-slider-track {
  cursor: not-allowed;
  opacity: .5;
}

.b-slider.is-disabled .b-slider-thumb-wrapper {
  cursor: not-allowed;
}

.b-slider.is-disabled .b-slider-thumb-wrapper .b-slider-thumb {
  transform: scale(1);
}

.b-slider .b-slider-track {
  height: .5rem;
}

.b-slider .b-slider-thumb {
  width: 1rem;
  height: 1rem;
}

.b-slider .b-slider-tick {
  height: .25rem;
}

.b-slider .b-slider-tick-label {
  font-size: .75rem;
  position: absolute;
  top: calc(.25rem + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.b-slider.is-small .b-slider-track {
  height: .375rem;
}

.b-slider.is-small .b-slider-thumb {
  width: .75rem;
  height: .75rem;
}

.b-slider.is-small .b-slider-tick {
  height: .1875rem;
}

.b-slider.is-small .b-slider-tick-label {
  font-size: .75rem;
  position: absolute;
  top: calc(.1875rem + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.b-slider.is-medium .b-slider-track {
  height: .625rem;
}

.b-slider.is-medium .b-slider-thumb {
  width: 1.25rem;
  height: 1.25rem;
}

.b-slider.is-medium .b-slider-tick {
  height: .3125rem;
}

.b-slider.is-medium .b-slider-tick-label {
  font-size: .75rem;
  position: absolute;
  top: calc(.3125rem + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.b-slider.is-large .b-slider-track {
  height: .75rem;
}

.b-slider.is-large .b-slider-thumb {
  width: 1.5rem;
  height: 1.5rem;
}

.b-slider.is-large .b-slider-tick {
  height: .375rem;
}

.b-slider.is-large .b-slider-tick-label {
  font-size: .75rem;
  position: absolute;
  top: calc(.375rem + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.b-slider.is-white .b-slider-fill {
  background: #fff !important;
}

.b-slider.is-black .b-slider-fill {
  background: #0a0a0a !important;
}

.b-slider.is-light .b-slider-fill {
  background: #f5f5f5 !important;
}

.b-slider.is-dark .b-slider-fill {
  background: #363636 !important;
}

.b-slider.is-primary .b-slider-fill, .b-slider.is-link .b-slider-fill {
  background: #7957d5 !important;
}

.b-slider.is-info .b-slider-fill {
  background: #167df0 !important;
}

.b-slider.is-success .b-slider-fill {
  background: #48c78e !important;
}

.b-slider.is-warning .b-slider-fill {
  background: #ffe08a !important;
}

.b-slider.is-danger .b-slider-fill {
  background: #f14668 !important;
}

.b-slider .b-slider-tick {
  background: #b5b5b5;
  border-radius: 4px;
  width: 3px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.b-slider .b-slider-tick.is-tick-hidden {
  background: none;
}

.b-steps .steps .step-items {
  flex-wrap: wrap;
  display: flex;
}

.b-steps .steps .step-items .step-item {
  flex-grow: 1;
  flex-basis: 1em;
  margin-top: 0;
  position: relative;
}

.b-steps .steps .step-items .step-item .step-link {
  color: #4a4a4a;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.b-steps .steps .step-items .step-item .step-link:not(.is-clickable) {
  cursor: not-allowed;
}

.b-steps .steps .step-items .step-item .step-marker {
  color: #fff;
  z-index: 1;
  background: #b5b5b5;
  border: .2em solid #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  display: flex;
  overflow: hidden;
}

.b-steps .steps .step-items .step-item.is-white:before, .b-steps .steps .step-items .step-item.is-white:after {
  background: linear-gradient(to left, #dbdbdb 50%, #fff 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-white.is-active .step-marker {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.b-steps .steps .step-items .step-item.is-white.is-active:before, .b-steps .steps .step-items .step-item.is-white.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-white.is-previous .step-marker {
  color: #0a0a0a;
  background-color: #fff;
}

.b-steps .steps .step-items .step-item.is-white.is-previous:before, .b-steps .steps .step-items .step-item.is-white.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-black:before, .b-steps .steps .step-items .step-item.is-black:after {
  background: linear-gradient(to left, #dbdbdb 50%, #0a0a0a 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-black.is-active .step-marker {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #0a0a0a;
}

.b-steps .steps .step-items .step-item.is-black.is-active:before, .b-steps .steps .step-items .step-item.is-black.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-black.is-previous .step-marker {
  color: #fff;
  background-color: #0a0a0a;
}

.b-steps .steps .step-items .step-item.is-black.is-previous:before, .b-steps .steps .step-items .step-item.is-black.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-light:before, .b-steps .steps .step-items .step-item.is-light:after {
  background: linear-gradient(to left, #dbdbdb 50%, #f5f5f5 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-light.is-active .step-marker {
  color: #f5f5f5;
  background-color: #fff;
  border-color: #f5f5f5;
}

.b-steps .steps .step-items .step-item.is-light.is-active:before, .b-steps .steps .step-items .step-item.is-light.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-light.is-previous .step-marker {
  color: #000000b3;
  background-color: #f5f5f5;
}

.b-steps .steps .step-items .step-item.is-light.is-previous:before, .b-steps .steps .step-items .step-item.is-light.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-dark:before, .b-steps .steps .step-items .step-item.is-dark:after {
  background: linear-gradient(to left, #dbdbdb 50%, #363636 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-dark.is-active .step-marker {
  color: #363636;
  background-color: #fff;
  border-color: #363636;
}

.b-steps .steps .step-items .step-item.is-dark.is-active:before, .b-steps .steps .step-items .step-item.is-dark.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-dark.is-previous .step-marker {
  color: #fff;
  background-color: #363636;
}

.b-steps .steps .step-items .step-item.is-dark.is-previous:before, .b-steps .steps .step-items .step-item.is-dark.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-primary:before, .b-steps .steps .step-items .step-item.is-primary:after {
  background: linear-gradient(to left, #dbdbdb 50%, #7957d5 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-primary.is-active .step-marker {
  color: #7957d5;
  background-color: #fff;
  border-color: #7957d5;
}

.b-steps .steps .step-items .step-item.is-primary.is-active:before, .b-steps .steps .step-items .step-item.is-primary.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-primary.is-previous .step-marker {
  color: #fff;
  background-color: #7957d5;
}

.b-steps .steps .step-items .step-item.is-primary.is-previous:before, .b-steps .steps .step-items .step-item.is-primary.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-link:before, .b-steps .steps .step-items .step-item.is-link:after {
  background: linear-gradient(to left, #dbdbdb 50%, #7957d5 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-link.is-active .step-marker {
  color: #7957d5;
  background-color: #fff;
  border-color: #7957d5;
}

.b-steps .steps .step-items .step-item.is-link.is-active:before, .b-steps .steps .step-items .step-item.is-link.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-link.is-previous .step-marker {
  color: #fff;
  background-color: #7957d5;
}

.b-steps .steps .step-items .step-item.is-link.is-previous:before, .b-steps .steps .step-items .step-item.is-link.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-info:before, .b-steps .steps .step-items .step-item.is-info:after {
  background: linear-gradient(to left, #dbdbdb 50%, #167df0 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-info.is-active .step-marker {
  color: #167df0;
  background-color: #fff;
  border-color: #167df0;
}

.b-steps .steps .step-items .step-item.is-info.is-active:before, .b-steps .steps .step-items .step-item.is-info.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-info.is-previous .step-marker {
  color: #fff;
  background-color: #167df0;
}

.b-steps .steps .step-items .step-item.is-info.is-previous:before, .b-steps .steps .step-items .step-item.is-info.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-success:before, .b-steps .steps .step-items .step-item.is-success:after {
  background: linear-gradient(to left, #dbdbdb 50%, #48c78e 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-success.is-active .step-marker {
  color: #48c78e;
  background-color: #fff;
  border-color: #48c78e;
}

.b-steps .steps .step-items .step-item.is-success.is-active:before, .b-steps .steps .step-items .step-item.is-success.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-success.is-previous .step-marker {
  color: #fff;
  background-color: #48c78e;
}

.b-steps .steps .step-items .step-item.is-success.is-previous:before, .b-steps .steps .step-items .step-item.is-success.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-warning:before, .b-steps .steps .step-items .step-item.is-warning:after {
  background: linear-gradient(to left, #dbdbdb 50%, #ffe08a 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-warning.is-active .step-marker {
  color: #ffe08a;
  background-color: #fff;
  border-color: #ffe08a;
}

.b-steps .steps .step-items .step-item.is-warning.is-active:before, .b-steps .steps .step-items .step-item.is-warning.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-warning.is-previous .step-marker {
  color: #000000b3;
  background-color: #ffe08a;
}

.b-steps .steps .step-items .step-item.is-warning.is-previous:before, .b-steps .steps .step-items .step-item.is-warning.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-danger:before, .b-steps .steps .step-items .step-item.is-danger:after {
  background: linear-gradient(to left, #dbdbdb 50%, #f14668 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-danger.is-active .step-marker {
  color: #f14668;
  background-color: #fff;
  border-color: #f14668;
}

.b-steps .steps .step-items .step-item.is-danger.is-active:before, .b-steps .steps .step-items .step-item.is-danger.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-danger.is-previous .step-marker {
  color: #fff;
  background-color: #f14668;
}

.b-steps .steps .step-items .step-item.is-danger.is-previous:before, .b-steps .steps .step-items .step-item.is-danger.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item .step-marker {
  color: #fff;
}

.b-steps .steps .step-items .step-item .step-details {
  text-align: center;
  z-index: 1;
}

.b-steps .steps .step-items .step-item:not(:first-child), .b-steps .steps .step-items .step-item:only-child {
  flex-shrink: 1;
}

.b-steps .steps .step-items .step-item:not(:first-child):before, .b-steps .steps .step-items .step-item:only-child:before {
  content: " ";
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: -50%;
}

.b-steps .steps .step-items .step-item:only-child:after {
  content: " ";
  height: .2em;
  display: block;
  position: absolute;
  bottom: 0;
}

.b-steps .steps .step-items .step-item:only-child:before, .b-steps .steps .step-items .step-item:only-child:after {
  width: 25%;
  left: 50%;
}

.b-steps .steps .step-items .step-item:only-child:before {
  left: auto;
  right: 50%;
}

.b-steps .steps .step-items .step-item:before, .b-steps .steps .step-items .step-item:after {
  background: linear-gradient(to left, #dbdbdb 50%, #7957d5 50%) 100% 100% / 200% 100%;
}

.b-steps .steps .step-items .step-item.is-active .step-link {
  cursor: default;
}

.b-steps .steps .step-items .step-item.is-active .step-marker {
  color: #7957d5;
  background-color: #fff;
  border-color: #7957d5;
}

.b-steps .steps .step-items .step-item.is-active:before, .b-steps .steps .step-items .step-item.is-active:after {
  background-position: 0 100%;
}

.b-steps .steps .step-items .step-item.is-previous .step-marker {
  color: #fff;
  background-color: #7957d5;
}

.b-steps .steps .step-items .step-item.is-previous:before, .b-steps .steps .step-items .step-item.is-previous:after {
  background-position: 0 100%;
}

.b-steps .steps + .step-content {
  flex-direction: column;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: visible;
}

.b-steps .steps + .step-content .step-item {
  flex-shrink: 0;
  flex-basis: auto;
}

.b-steps .steps + .step-content .step-item:focus {
  outline: none;
}

.b-steps .steps + .step-content.is-transitioning {
  overflow: hidden;
}

.b-steps .steps.is-rounded .step-item .step-marker {
  border-radius: 9999px;
}

.b-steps .steps.is-animated .step-item:not(:first-child):before, .b-steps .steps.is-animated .step-item:only-child:before {
  transition: background .15s ease-out;
}

.b-steps .steps.has-label-right .step-items .step-item .step-link, .b-steps .steps.has-label-left .step-items .step-item .step-link {
  flex-direction: row;
}

.b-steps .steps.has-label-right .step-items .step-item .step-link > .step-details, .b-steps .steps.has-label-left .step-items .step-item .step-link > .step-details {
  background-color: #fff;
  padding: .2em;
}

.b-steps .steps.has-label-left .step-items .step-item .step-link {
  flex-direction: row-reverse;
}

.b-steps .steps {
  min-height: 2rem;
  font-size: 1rem;
}

.b-steps .steps .step-items .step-item .step-marker {
  width: 2rem;
  height: 2rem;
}

.b-steps .steps .step-items .step-item .step-marker .icon *, .b-steps .steps .step-items .step-item .step-marker .icon :before {
  font-size: 1rem;
}

.b-steps .steps .step-items .step-item .step-details .step-title {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1rem;
}

.b-steps .steps .step-items .step-item:not(:first-child):before, .b-steps .steps .step-items .step-item:only-child:before {
  height: .2em;
  top: 1rem;
}

.b-steps .steps .step-items .step-item:only-child:after {
  top: 1rem;
}

@media screen and (width <= 768px) {
  .b-steps .steps .step-items .step-item:before, .b-steps .steps .step-items .step-item:after, .b-steps .steps .step-items .step-item:not(:first-child):before {
    top: 1rem;
  }
}

.b-steps.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-vertical > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-vertical > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(1rem - .1em);
}

.b-steps.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-vertical > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-vertical > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(1rem - .1em);
}

.b-steps.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(1rem - .1em);
}

.b-steps.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(1rem - .1em);
}

.b-steps.is-small .steps {
  min-height: 1.5rem;
  font-size: .75rem;
}

.b-steps.is-small .steps .step-items .step-item .step-marker {
  width: 1.5rem;
  height: 1.5rem;
}

.b-steps.is-small .steps .step-items .step-item .step-marker .icon *, .b-steps.is-small .steps .step-items .step-item .step-marker .icon :before {
  font-size: .75rem;
}

.b-steps.is-small .steps .step-items .step-item .step-details .step-title {
  font-size: .9rem;
  font-weight: 600;
  line-height: .75rem;
}

.b-steps.is-small .steps .step-items .step-item:not(:first-child):before, .b-steps.is-small .steps .step-items .step-item:only-child:before {
  height: .2em;
  top: .75rem;
}

.b-steps.is-small .steps .step-items .step-item:only-child:after {
  top: .75rem;
}

@media screen and (width <= 768px) {
  .b-steps.is-small .steps .step-items .step-item:before, .b-steps.is-small .steps .step-items .step-item:after, .b-steps.is-small .steps .step-items .step-item:not(:first-child):before {
    top: .75rem;
  }
}

.b-steps.is-small.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-small.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-small.is-vertical > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-small.is-vertical > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(.75rem - .1em);
}

.b-steps.is-small.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-small.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-small.is-vertical > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-small.is-vertical > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(.75rem - .1em);
}

.b-steps.is-small.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-small.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-small.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-small.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(.75rem - .1em);
}

.b-steps.is-small.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-small.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-small.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-small.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(.75rem - .1em);
}

.b-steps.is-medium .steps {
  min-height: 2.5rem;
  font-size: 1.25rem;
}

.b-steps.is-medium .steps .step-items .step-item .step-marker {
  width: 2.5rem;
  height: 2.5rem;
}

.b-steps.is-medium .steps .step-items .step-item .step-marker .icon *, .b-steps.is-medium .steps .step-items .step-item .step-marker .icon :before {
  font-size: 1.25rem;
}

.b-steps.is-medium .steps .step-items .step-item .step-details .step-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.b-steps.is-medium .steps .step-items .step-item:not(:first-child):before, .b-steps.is-medium .steps .step-items .step-item:only-child:before {
  height: .2em;
  top: 1.25rem;
}

.b-steps.is-medium .steps .step-items .step-item:only-child:after {
  top: 1.25rem;
}

@media screen and (width <= 768px) {
  .b-steps.is-medium .steps .step-items .step-item:before, .b-steps.is-medium .steps .step-items .step-item:after, .b-steps.is-medium .steps .step-items .step-item:not(:first-child):before {
    top: 1.25rem;
  }
}

.b-steps.is-medium.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-medium.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-medium.is-vertical > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-medium.is-vertical > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(1.25rem - .1em);
}

.b-steps.is-medium.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-medium.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-medium.is-vertical > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-medium.is-vertical > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(1.25rem - .1em);
}

.b-steps.is-medium.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-medium.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-medium.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-medium.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(1.25rem - .1em);
}

.b-steps.is-medium.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-medium.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-medium.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-medium.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(1.25rem - .1em);
}

.b-steps.is-large .steps {
  min-height: 3rem;
  font-size: 1.5rem;
}

.b-steps.is-large .steps .step-items .step-item .step-marker {
  width: 3rem;
  height: 3rem;
}

.b-steps.is-large .steps .step-items .step-item .step-marker .icon *, .b-steps.is-large .steps .step-items .step-item .step-marker .icon :before {
  font-size: 1.5rem;
}

.b-steps.is-large .steps .step-items .step-item .step-details .step-title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.b-steps.is-large .steps .step-items .step-item:not(:first-child):before, .b-steps.is-large .steps .step-items .step-item:only-child:before {
  height: .2em;
  top: 1.5rem;
}

.b-steps.is-large .steps .step-items .step-item:only-child:after {
  top: 1.5rem;
}

@media screen and (width <= 768px) {
  .b-steps.is-large .steps .step-items .step-item:before, .b-steps.is-large .steps .step-items .step-item:after, .b-steps.is-large .steps .step-items .step-item:not(:first-child):before {
    top: 1.5rem;
  }
}

.b-steps.is-large.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-large.is-vertical > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-large.is-vertical > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-large.is-vertical > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(1.5rem - .1em);
}

.b-steps.is-large.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-large.is-vertical > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-large.is-vertical > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-large.is-vertical > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(1.5rem - .1em);
}

.b-steps.is-large.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):before, .b-steps.is-large.is-vertical.is-right > .steps.has-label-right .step-items .step-item:not(:first-child):after, .b-steps.is-large.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:before, .b-steps.is-large.is-vertical.is-right > .steps.has-label-right .step-items .step-item:only-child:after {
  left: calc(1.5rem - .1em);
}

.b-steps.is-large.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):before, .b-steps.is-large.is-vertical.is-right > .steps.has-label-left .step-items .step-item:not(:first-child):after, .b-steps.is-large.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:before, .b-steps.is-large.is-vertical.is-right > .steps.has-label-left .step-items .step-item:only-child:after {
  left: auto;
  right: calc(1.5rem - .1em);
}

.b-steps.is-vertical {
  flex-flow: wrap;
  display: flex;
}

.b-steps.is-vertical > .steps .step-items {
  border-bottom-color: #0000;
  flex-direction: column;
  height: 100%;
}

.b-steps.is-vertical > .steps .step-items .step-item {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em 0;
  display: flex;
}

.b-steps.is-vertical > .steps .step-items .step-item:before, .b-steps.is-vertical > .steps .step-items .step-item:after {
  background: linear-gradient(to top, #dbdbdb 50%, #7957d5 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-white:before, .b-steps.is-vertical > .steps .step-items .step-item.is-white:after {
  background: linear-gradient(to top, #dbdbdb 50%, #fff 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-black:before, .b-steps.is-vertical > .steps .step-items .step-item.is-black:after {
  background: linear-gradient(to top, #dbdbdb 50%, #0a0a0a 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-light:before, .b-steps.is-vertical > .steps .step-items .step-item.is-light:after {
  background: linear-gradient(to top, #dbdbdb 50%, #f5f5f5 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-dark:before, .b-steps.is-vertical > .steps .step-items .step-item.is-dark:after {
  background: linear-gradient(to top, #dbdbdb 50%, #363636 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-primary:before, .b-steps.is-vertical > .steps .step-items .step-item.is-primary:after, .b-steps.is-vertical > .steps .step-items .step-item.is-link:before, .b-steps.is-vertical > .steps .step-items .step-item.is-link:after {
  background: linear-gradient(to top, #dbdbdb 50%, #7957d5 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-info:before, .b-steps.is-vertical > .steps .step-items .step-item.is-info:after {
  background: linear-gradient(to top, #dbdbdb 50%, #167df0 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-success:before, .b-steps.is-vertical > .steps .step-items .step-item.is-success:after {
  background: linear-gradient(to top, #dbdbdb 50%, #48c78e 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-warning:before, .b-steps.is-vertical > .steps .step-items .step-item.is-warning:after {
  background: linear-gradient(to top, #dbdbdb 50%, #ffe08a 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item.is-danger:before, .b-steps.is-vertical > .steps .step-items .step-item.is-danger:after {
  background: linear-gradient(to top, #dbdbdb 50%, #f14668 50%) 0 100% / 100% 200%;
}

.b-steps.is-vertical > .steps .step-items .step-item:not(:first-child):before, .b-steps.is-vertical > .steps .step-items .step-item:only-child:before {
  width: .2em;
  height: 100%;
  top: -50%;
  left: calc(50% - .1em);
}

.b-steps.is-vertical > .steps .step-items .step-item.is-active:before, .b-steps.is-vertical > .steps .step-items .step-item.is-active:after, .b-steps.is-vertical > .steps .step-items .step-item.is-previous:before, .b-steps.is-vertical > .steps .step-items .step-item.is-previous:after {
  background-position: 100% 0;
}

.b-steps.is-vertical > .steps .step-items .step-item:only-child:before {
  top: 50%;
}

.b-steps.is-vertical > .steps .step-items .step-item:only-child:after {
  width: .2em;
  top: auto;
  bottom: 50%;
}

.b-steps.is-vertical > .steps .step-items .step-item:only-child:before, .b-steps.is-vertical > .steps .step-items .step-item:only-child:after {
  height: 25%;
}

.b-steps.is-vertical > .steps.has-label-right .step-items .step-item {
  justify-content: flex-start;
}

.b-steps.is-vertical > .steps.has-label-left .step-items .step-item {
  justify-content: flex-end;
}

.b-steps.is-vertical > .steps:not(.has-label-right):not(.has-label-left) .step-items .step-item .step-link > .step-details {
  background-color: #fff;
}

.b-steps.is-vertical > .step-content {
  flex-grow: 1;
}

.b-steps.is-vertical > .step-navigation {
  flex-basis: 100%;
}

.b-steps.is-vertical.is-right {
  flex-direction: row-reverse;
}

@media screen and (width <= 768px) {
  .b-steps:not(.is-vertical) .steps.mobile-minimalist .step-items .step-item:not(.is-active) {
    display: none;
  }

  .b-steps:not(.is-vertical) .steps.mobile-minimalist .step-items .step-item:before, .b-steps:not(.is-vertical) .steps.mobile-minimalist .step-items .step-item:after, .b-steps:not(.is-vertical) .steps.mobile-minimalist .step-items .step-item:not(:first-child):before {
    content: " ";
    width: 25%;
    height: .2em;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
  }

  .b-steps:not(.is-vertical) .steps.mobile-minimalist .step-items .step-item:before, .b-steps:not(.is-vertical) .steps.mobile-minimalist .step-items .step-item:not(:first-child):before {
    left: auto;
    right: 50%;
  }

  .b-steps:not(.is-vertical) .steps.mobile-compact .step-items .step-item:not(.is-active) .step-details {
    display: none;
  }
}

.switch {
  cursor: pointer;
  align-items: center;
  margin-right: .5em;
  display: inline-flex;
  position: relative;
}

.switch + .switch:last-child {
  margin-right: 0;
}

.switch input[type="checkbox"] {
  opacity: 0;
  z-index: -1;
  outline: none;
  position: absolute;
  left: 0;
}

.switch input[type="checkbox"] + .check {
  background: #b5b5b5;
  border-radius: 4px;
  flex-shrink: 0;
  align-items: center;
  width: 2.75em;
  height: 1.575em;
  padding: .2em;
  transition: background .15s ease-out, box-shadow .15s ease-out;
  display: flex;
}

.switch input[type="checkbox"] + .check.is-white-passive, .switch input[type="checkbox"] + .check:hover {
  background: #fff;
}

.switch input[type="checkbox"] + .check.is-black-passive, .switch input[type="checkbox"] + .check:hover {
  background: #0a0a0a;
}

.switch input[type="checkbox"] + .check.is-light-passive, .switch input[type="checkbox"] + .check:hover {
  background: #f5f5f5;
}

.switch input[type="checkbox"] + .check.is-dark-passive, .switch input[type="checkbox"] + .check:hover {
  background: #363636;
}

.switch input[type="checkbox"] + .check.is-primary-passive, .switch input[type="checkbox"] + .check:hover, .switch input[type="checkbox"] + .check.is-link-passive, .switch input[type="checkbox"] + .check:hover {
  background: #7957d5;
}

.switch input[type="checkbox"] + .check.is-info-passive, .switch input[type="checkbox"] + .check:hover {
  background: #167df0;
}

.switch input[type="checkbox"] + .check.is-success-passive, .switch input[type="checkbox"] + .check:hover {
  background: #48c78e;
}

.switch input[type="checkbox"] + .check.is-warning-passive, .switch input[type="checkbox"] + .check:hover {
  background: #ffe08a;
}

.switch input[type="checkbox"] + .check.is-danger-passive, .switch input[type="checkbox"] + .check:hover {
  background: #f14668;
}

.switch input[type="checkbox"] + .check.input[type="checkbox"] + .switch input[type="checkbox"] + .check.check {
  background: "pink";
}

.switch input[type="checkbox"] + .check:before {
  content: "";
  will-change: transform;
  transform-origin: 0;
  background: #f5f5f5;
  border-radius: 4px;
  width: 1.175em;
  height: 1.175em;
  transition: transform .15s ease-out;
  display: block;
  box-shadow: 0 3px 1px #0000000d, 0 2px 2px #0000001a, 0 3px 3px #0000000d;
}

.switch input[type="checkbox"] + .check.is-elastic:before {
  border-radius: 4px;
  transform: scaleX(1.5);
}

.switch input[type="checkbox"]:checked + .check {
  background: #7957d5;
}

.switch input[type="checkbox"]:checked + .check.is-white {
  background: #fff;
}

.switch input[type="checkbox"]:checked + .check.is-black {
  background: #0a0a0a;
}

.switch input[type="checkbox"]:checked + .check.is-light {
  background: #f5f5f5;
}

.switch input[type="checkbox"]:checked + .check.is-dark {
  background: #363636;
}

.switch input[type="checkbox"]:checked + .check.is-primary, .switch input[type="checkbox"]:checked + .check.is-link {
  background: #7957d5;
}

.switch input[type="checkbox"]:checked + .check.is-info {
  background: #167df0;
}

.switch input[type="checkbox"]:checked + .check.is-success {
  background: #48c78e;
}

.switch input[type="checkbox"]:checked + .check.is-warning {
  background: #ffe08a;
}

.switch input[type="checkbox"]:checked + .check.is-danger {
  background: #f14668;
}

.switch input[type="checkbox"]:checked + .check:before {
  transform: translate3d(100%, 0, 0);
}

.switch input[type="checkbox"]:checked + .check.is-elastic:before {
  transform: translate3d(50%, 0, 0)scaleX(1.5);
}

.switch input[type="checkbox"]:focus, .switch input[type="checkbox"]:active {
  outline: none;
}

.switch input[type="checkbox"]:focus + .check, .switch input[type="checkbox"]:active + .check {
  box-shadow: 0 0 .5em #7a7a7a99;
}

.switch input[type="checkbox"]:focus + .check.is-white-passive, .switch input[type="checkbox"]:active + .check.is-white-passive {
  box-shadow: 0 0 .5em #fffc;
}

.switch input[type="checkbox"]:focus + .check.is-black-passive, .switch input[type="checkbox"]:active + .check.is-black-passive {
  box-shadow: 0 0 .5em #0a0a0acc;
}

.switch input[type="checkbox"]:focus + .check.is-light-passive, .switch input[type="checkbox"]:active + .check.is-light-passive {
  box-shadow: 0 0 .5em #f5f5f5cc;
}

.switch input[type="checkbox"]:focus + .check.is-dark-passive, .switch input[type="checkbox"]:active + .check.is-dark-passive {
  box-shadow: 0 0 .5em #363636cc;
}

.switch input[type="checkbox"]:focus + .check.is-primary-passive, .switch input[type="checkbox"]:active + .check.is-primary-passive, .switch input[type="checkbox"]:focus + .check.is-link-passive, .switch input[type="checkbox"]:active + .check.is-link-passive {
  box-shadow: 0 0 .5em #7957d5cc;
}

.switch input[type="checkbox"]:focus + .check.is-info-passive, .switch input[type="checkbox"]:active + .check.is-info-passive {
  box-shadow: 0 0 .5em #167df0cc;
}

.switch input[type="checkbox"]:focus + .check.is-success-passive, .switch input[type="checkbox"]:active + .check.is-success-passive {
  box-shadow: 0 0 .5em #48c78ecc;
}

.switch input[type="checkbox"]:focus + .check.is-warning-passive, .switch input[type="checkbox"]:active + .check.is-warning-passive {
  box-shadow: 0 0 .5em #ffe08acc;
}

.switch input[type="checkbox"]:focus + .check.is-danger-passive, .switch input[type="checkbox"]:active + .check.is-danger-passive {
  box-shadow: 0 0 .5em #f14668cc;
}

.switch input[type="checkbox"]:focus:checked + .check, .switch input[type="checkbox"]:active:checked + .check {
  box-shadow: 0 0 .5em #7957d5cc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-white, .switch input[type="checkbox"]:active:checked + .check.is-white {
  box-shadow: 0 0 .5em #fffc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-black, .switch input[type="checkbox"]:active:checked + .check.is-black {
  box-shadow: 0 0 .5em #0a0a0acc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-light, .switch input[type="checkbox"]:active:checked + .check.is-light {
  box-shadow: 0 0 .5em #f5f5f5cc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-dark, .switch input[type="checkbox"]:active:checked + .check.is-dark {
  box-shadow: 0 0 .5em #363636cc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-primary, .switch input[type="checkbox"]:active:checked + .check.is-primary, .switch input[type="checkbox"]:focus:checked + .check.is-link, .switch input[type="checkbox"]:active:checked + .check.is-link {
  box-shadow: 0 0 .5em #7957d5cc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-info, .switch input[type="checkbox"]:active:checked + .check.is-info {
  box-shadow: 0 0 .5em #167df0cc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-success, .switch input[type="checkbox"]:active:checked + .check.is-success {
  box-shadow: 0 0 .5em #48c78ecc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-warning, .switch input[type="checkbox"]:active:checked + .check.is-warning {
  box-shadow: 0 0 .5em #ffe08acc;
}

.switch input[type="checkbox"]:focus:checked + .check.is-danger, .switch input[type="checkbox"]:active:checked + .check.is-danger {
  box-shadow: 0 0 .5em #f14668cc;
}

.switch.has-left-label {
  flex-direction: row-reverse;
}

.switch.has-left-label .control-label {
  padding-right: calc(.75em - 1px);
}

.switch:not(.has-left-label) .control-label {
  padding-left: calc(.75em - 1px);
}

.switch:hover input[type="checkbox"] + .check {
  background: #b5b5b5e6;
}

.switch:hover input[type="checkbox"] + .check.is-white-passive {
  background: #ffffffe6;
}

.switch:hover input[type="checkbox"] + .check.is-black-passive {
  background: #0a0a0ae6;
}

.switch:hover input[type="checkbox"] + .check.is-light-passive {
  background: #f5f5f5e6;
}

.switch:hover input[type="checkbox"] + .check.is-dark-passive {
  background: #363636e6;
}

.switch:hover input[type="checkbox"] + .check.is-primary-passive, .switch:hover input[type="checkbox"] + .check.is-link-passive {
  background: #7957d5e6;
}

.switch:hover input[type="checkbox"] + .check.is-info-passive {
  background: #167df0e6;
}

.switch:hover input[type="checkbox"] + .check.is-success-passive {
  background: #48c78ee6;
}

.switch:hover input[type="checkbox"] + .check.is-warning-passive {
  background: #ffe08ae6;
}

.switch:hover input[type="checkbox"] + .check.is-danger-passive {
  background: #f14668e6;
}

.switch:hover input[type="checkbox"]:checked + .check {
  background: #7957d5e6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-white {
  background: #ffffffe6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-black {
  background: #0a0a0ae6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-light {
  background: #f5f5f5e6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-dark {
  background: #363636e6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-primary, .switch:hover input[type="checkbox"]:checked + .check.is-link {
  background: #7957d5e6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-info {
  background: #167df0e6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-success {
  background: #48c78ee6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-warning {
  background: #ffe08ae6;
}

.switch:hover input[type="checkbox"]:checked + .check.is-danger {
  background: #f14668e6;
}

.switch.is-rounded input[type="checkbox"] + .check, .switch.is-rounded input[type="checkbox"] + .check:before {
  border-radius: 9999px;
}

.switch.is-rounded input[type="checkbox"].is-elastic:before {
  border-radius: 9999px;
  transform: scaleX(1.5);
}

.switch.is-outlined input[type="checkbox"] + .check {
  background: none;
  border: .1rem solid #b5b5b5;
}

.switch.is-outlined input[type="checkbox"] + .check.is-white-passive {
  border: .1rem solid #ffffffe6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-white-passive:before {
  background: #fff;
}

.switch.is-outlined input[type="checkbox"] + .check.is-white-passive:hover {
  border-color: #ffffffe6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-black-passive {
  border: .1rem solid #0a0a0ae6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-black-passive:before {
  background: #0a0a0a;
}

.switch.is-outlined input[type="checkbox"] + .check.is-black-passive:hover {
  border-color: #0a0a0ae6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-light-passive {
  border: .1rem solid #f5f5f5e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-light-passive:before {
  background: #f5f5f5;
}

.switch.is-outlined input[type="checkbox"] + .check.is-light-passive:hover {
  border-color: #f5f5f5e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-dark-passive {
  border: .1rem solid #363636e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-dark-passive:before {
  background: #363636;
}

.switch.is-outlined input[type="checkbox"] + .check.is-dark-passive:hover {
  border-color: #363636e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-primary-passive {
  border: .1rem solid #7957d5e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-primary-passive:before {
  background: #7957d5;
}

.switch.is-outlined input[type="checkbox"] + .check.is-primary-passive:hover {
  border-color: #7957d5e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-link-passive {
  border: .1rem solid #7957d5e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-link-passive:before {
  background: #7957d5;
}

.switch.is-outlined input[type="checkbox"] + .check.is-link-passive:hover {
  border-color: #7957d5e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-info-passive {
  border: .1rem solid #167df0e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-info-passive:before {
  background: #167df0;
}

.switch.is-outlined input[type="checkbox"] + .check.is-info-passive:hover {
  border-color: #167df0e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-success-passive {
  border: .1rem solid #48c78ee6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-success-passive:before {
  background: #48c78e;
}

.switch.is-outlined input[type="checkbox"] + .check.is-success-passive:hover {
  border-color: #48c78ee6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-warning-passive {
  border: .1rem solid #ffe08ae6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-warning-passive:before {
  background: #ffe08a;
}

.switch.is-outlined input[type="checkbox"] + .check.is-warning-passive:hover {
  border-color: #ffe08ae6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-danger-passive {
  border: .1rem solid #f14668e6;
}

.switch.is-outlined input[type="checkbox"] + .check.is-danger-passive:before {
  background: #f14668;
}

.switch.is-outlined input[type="checkbox"] + .check.is-danger-passive:hover {
  border-color: #f14668e6;
}

.switch.is-outlined input[type="checkbox"] + .check:before {
  background: #b5b5b5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check {
  border-color: #7957d5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-white {
  background: none;
  border-color: #fff;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-white:before {
  background: #fff;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-black {
  background: none;
  border-color: #0a0a0a;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-black:before {
  background: #0a0a0a;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-light {
  background: none;
  border-color: #f5f5f5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-light:before {
  background: #f5f5f5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-dark {
  background: none;
  border-color: #363636;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-dark:before {
  background: #363636;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-primary {
  background: none;
  border-color: #7957d5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-primary:before {
  background: #7957d5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-link {
  background: none;
  border-color: #7957d5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-link:before {
  background: #7957d5;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-info {
  background: none;
  border-color: #167df0;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-info:before {
  background: #167df0;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-success {
  background: none;
  border-color: #48c78e;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-success:before {
  background: #48c78e;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-warning {
  background: none;
  border-color: #ffe08a;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-warning:before {
  background: #ffe08a;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-danger {
  background: none;
  border-color: #f14668;
}

.switch.is-outlined input[type="checkbox"]:checked + .check.is-danger:before {
  background: #f14668;
}

.switch.is-outlined input[type="checkbox"]:checked + .check:before {
  background: #7957d5;
}

.switch.is-outlined:hover input[type="checkbox"] + .check {
  background: none;
  border-color: #b5b5b5e6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check {
  background: none;
  border-color: #7957d5e6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-white {
  border-color: #ffffffe6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-black {
  border-color: #0a0a0ae6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-light {
  border-color: #f5f5f5e6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-dark {
  border-color: #363636e6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-primary, .switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-link {
  border-color: #7957d5e6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-info {
  border-color: #167df0e6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-success {
  border-color: #48c78ee6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-warning {
  border-color: #ffe08ae6;
}

.switch.is-outlined:hover input[type="checkbox"]:checked + .check.is-danger {
  border-color: #f14668e6;
}

.switch.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.switch.is-medium {
  font-size: 1.25rem;
}

.switch.is-large {
  font-size: 1.5rem;
}

.switch[disabled] {
  opacity: .5;
  cursor: not-allowed;
  color: #7a7a7a;
}

.table-wrapper .table {
  margin-bottom: 0;
}

.table-wrapper:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media screen and (width <= 1023px) {
  .table-wrapper {
    overflow-x: auto;
  }
}

.b-table {
  transition: opacity 86ms ease-out;
}

@media screen and (width >= 769px), print {
  .b-table .table-mobile-sort {
    display: none;
  }
}

.b-table .icon {
  transition: transform .15s ease-out, opacity 86ms ease-out;
}

.b-table .icon.is-desc {
  transform: rotate(180deg);
}

.b-table .icon.is-expanded {
  transform: rotate(90deg);
}

.b-table .sort-icon.icon.is-desc {
  transform: rotate(180deg)translateY(-50%) !important;
}

.b-table .table {
  border-collapse: separate;
  border: 1px solid #0000;
  border-radius: 4px;
  width: 100%;
}

.b-table .table th {
  font-weight: 600;
}

.b-table .table th .th-wrap {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.b-table .table th .th-wrap .icon {
  margin-left: .5rem;
  margin-right: 0;
  font-size: 1rem;
}

.b-table .table th .th-wrap.is-numeric {
  text-align: right;
  flex-direction: row-reverse;
  width: 95%;
}

.b-table .table th .th-wrap.is-numeric .sort-icon {
  margin-left: 0;
  margin-right: .5rem;
  left: 0;
  right: auto;
}

.b-table .table th .th-wrap.is-centered {
  text-align: center;
  justify-content: center;
}

.b-table .table th.is-current-sort {
  border-color: #7a7a7a;
  font-weight: 700;
}

.b-table .table th.is-sortable:hover {
  border-color: #7a7a7a;
}

.b-table .table th.is-sortable, .b-table .table th.is-sortable .th-wrap {
  cursor: pointer;
}

.b-table .table th.is-sortable .is-relative, .b-table .table th.is-sortable .th-wrap .is-relative {
  position: absolute;
}

.b-table .table th .sort-icon {
  position: absolute;
  bottom: 50%;
  right: 0;
  transform: translateY(50%);
}

.b-table .table th .multi-sort-icons {
  align-items: center;
  display: flex;
}

.b-table .table th .multi-sort-icons .multi-sort-cancel-icon {
  margin-left: 10px;
}

.b-table .table th.is-sticky {
  background: none;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3 !important;
}

.b-table .table tr.is-selected .checkbox input:checked + .check {
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%237957d5' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
}

.b-table .table tr.is-selected .checkbox input + .check {
  border-color: #fff;
}

.b-table .table tr.is-empty:hover {
  background-color: #0000;
}

.b-table .table .chevron-cell {
  vertical-align: middle;
}

.b-table .table .chevron-cell > a {
  color: #7957d5 !important;
}

.b-table .table .checkbox-cell {
  width: 40px;
}

.b-table .table .checkbox-cell .checkbox {
  vertical-align: middle;
}

.b-table .table .checkbox-cell .checkbox .check {
  transition: none;
}

.b-table .table tr.detail {
  background: #fafafa;
  box-shadow: inset 0 1px 3px #dbdbdb;
}

.b-table .table tr.detail .detail-container {
  padding: 1rem;
}

.b-table .table:focus {
  border-color: #7957d5;
  box-shadow: 0 0 0 .125em #7957d540;
}

.b-table .table.is-bordered th.is-current-sort, .b-table .table.is-bordered th.is-sortable:hover {
  background: #f5f5f5;
  border-color: #dbdbdb;
}

.b-table .table td.is-sticky {
  z-index: 1;
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.b-table .table.is-striped tbody tr:not(.is-selected):nth-child(2n) td.is-sticky {
  background: #fafafa;
}

.b-table .level:not(.top) {
  padding-bottom: 1.5rem;
}

.b-table .table-wrapper {
  position: relative;
}

.b-table .table-wrapper.has-sticky-header {
  height: 300px;
  overflow-y: auto;
}

@media screen and (width <= 768px) {
  .b-table .table-wrapper.has-sticky-header.has-mobile-cards {
    height: initial !important;
    overflow-y: initial !important;
  }
}

.b-table .table-wrapper.has-sticky-header tr:first-child th {
  z-index: 2;
  background: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media screen and (width <= 768px) {
  .b-table .table-wrapper.has-mobile-cards .table {
    background-color: #0000;
  }

  .b-table .table-wrapper.has-mobile-cards thead tr {
    box-shadow: none;
    border-width: 0;
  }

  .b-table .table-wrapper.has-mobile-cards thead tr th {
    display: none;
  }

  .b-table .table-wrapper.has-mobile-cards thead tr .checkbox-cell {
    text-align: right;
    border: 0;
    width: 100%;
    margin-bottom: 1rem;
    display: block;
  }

  .b-table .table-wrapper.has-mobile-cards tfoot th {
    display: inherit;
    border: 0;
  }

  .b-table .table-wrapper.has-mobile-cards tr {
    max-width: 100%;
    display: block;
    position: relative;
    box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
  }

  .b-table .table-wrapper.has-mobile-cards tr td {
    display: inherit;
    border: 0;
  }

  .b-table .table-wrapper.has-mobile-cards tr td:last-child {
    border-bottom: 0;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(:last-child) {
    margin-bottom: 1rem;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]) {
    background: #fff;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]):hover {
    background-color: #fff;
  }

  .b-table .table-wrapper.has-mobile-cards tr.detail {
    margin-top: -1rem;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td {
    text-align: right;
    border-bottom: 1px solid #f5f5f5;
    justify-content: space-between;
    width: auto;
    display: flex;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
    content: attr(data-label);
    text-align: left;
    padding-right: .5em;
    font-weight: 600;
  }
}

.b-table .table-wrapper.is-card-list .table {
  background-color: #0000;
}

.b-table .table-wrapper.is-card-list thead tr {
  box-shadow: none;
  border-width: 0;
}

.b-table .table-wrapper.is-card-list thead tr th {
  display: none;
}

.b-table .table-wrapper.is-card-list thead tr .checkbox-cell {
  text-align: right;
  border: 0;
  width: 100%;
  margin-bottom: 1rem;
  display: block;
}

.b-table .table-wrapper.is-card-list tfoot th {
  display: inherit;
  border: 0;
}

.b-table .table-wrapper.is-card-list tr {
  max-width: 100%;
  display: block;
  position: relative;
  box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
}

.b-table .table-wrapper.is-card-list tr td {
  display: inherit;
  border: 0;
}

.b-table .table-wrapper.is-card-list tr td:last-child {
  border-bottom: 0;
}

.b-table .table-wrapper.is-card-list tr:not(:last-child) {
  margin-bottom: 1rem;
}

.b-table .table-wrapper.is-card-list tr:not([class*="is-"]) {
  background: #fff;
}

.b-table .table-wrapper.is-card-list tr:not([class*="is-"]):hover {
  background-color: #fff;
}

.b-table .table-wrapper.is-card-list tr.detail {
  margin-top: -1rem;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td {
  text-align: right;
  border-bottom: 1px solid #f5f5f5;
  justify-content: space-between;
  width: auto;
  display: flex;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
  content: attr(data-label);
  text-align: left;
  padding-right: .5em;
  font-weight: 600;
}

.touch-dragged-cell {
  position: absolute;
}

.touch-dragged-cell, .touch-dragged-cell .table {
  pointer-events: none;
  background-color: #ffffff1a;
}

.touch-dragged-cell .table {
  width: 100%;
}

.touch-dragged-cell .table tr, .touch-dragged-cell .table td {
  background-color: #0000;
}

@media screen and (width <= 768px) {
  .touch-dragged-cell.has-mobile-cards .table tr {
    display: block;
  }

  .touch-dragged-cell.has-mobile-cards .table tr td {
    text-align: right;
    justify-content: space-between;
    display: flex;
  }

  .touch-dragged-cell.has-mobile-cards .table tr td:before {
    content: attr(data-label);
    text-align: left;
    padding-right: .5em;
    font-weight: 600;
  }
}

.b-tabs .tabs {
  flex-shrink: 0;
  margin-bottom: 0;
}

.b-tabs .tabs li a:focus {
  border-bottom-color: #7957d5;
  outline: none;
}

.b-tabs .tabs li:not(.is-active) a:focus {
  border-bottom-color: #363636;
}

.b-tabs .tabs li.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5;
}

.b-tabs .tabs.is-boxed li a:focus {
  background-color: #fff;
  border-bottom-color: #0000;
}

.b-tabs .tabs.is-boxed li:not(.is-active) a:focus {
  background-color: #f5f5f5;
  border-bottom-color: #dbdbdb;
}

.b-tabs .tabs.is-toggle li a:focus {
  background-color: #7957d5;
  border-color: #7957d5;
}

.b-tabs .tabs.is-toggle li:not(.is-active) a:focus {
  background-color: #f5f5f5;
  border-color: #b5b5b5;
}

.b-tabs .tab-content {
  flex-direction: column;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: visible;
}

.b-tabs .tab-content .tab-item {
  flex-shrink: 0;
  flex-basis: auto;
}

.b-tabs .tab-content .tab-item:focus {
  outline: none;
}

.b-tabs .tab-content.is-transitioning {
  overflow: hidden;
}

.b-tabs:not(:last-child) {
  margin-bottom: 1.5rem;
}

.b-tabs.is-fullwidth {
  width: 100%;
}

.b-tabs.is-vertical {
  flex-flow: wrap;
  display: flex;
}

.b-tabs.is-vertical > .tabs ul {
  border-bottom-color: #0000;
  flex-direction: column;
}

.b-tabs.is-vertical > .tabs ul li {
  width: 100%;
}

.b-tabs.is-vertical > .tabs ul li a {
  justify-content: left;
}

.b-tabs.is-vertical > .tabs.is-boxed li a {
  border-radius: 4px 0 0 4px;
  border-bottom-color: #0000 !important;
  border-right-color: #dbdbdb !important;
}

.b-tabs.is-vertical > .tabs.is-boxed li.is-active a {
  border-bottom-color: #dbdbdb !important;
  border-right-color: #0000 !important;
}

.b-tabs.is-vertical > .tabs.is-toggle li + li {
  margin-left: 0;
}

.b-tabs.is-vertical > .tabs.is-toggle li:first-child a {
  border-radius: 4px 4px 0 0;
}

.b-tabs.is-vertical > .tabs.is-toggle li:last-child a {
  border-radius: 0 0 4px 4px;
}

.b-tabs.is-vertical > .tabs.is-fullwidth li a {
  height: 100%;
}

.b-tabs.is-vertical > .tab-content {
  flex-grow: 1;
}

.b-tabs.is-vertical.is-right, .b-tabs.is-vertical.is-right > .tabs ul a {
  flex-direction: row-reverse;
}

.b-tabs.is-vertical.is-right > .tabs ul a .icon:first-child {
  margin-left: .5em;
  margin-right: 0;
}

.b-tabs.is-vertical.is-right > .tabs.is-boxed li a {
  border-radius: 0 4px 4px 0;
  border-bottom-color: #0000 !important;
  border-left-color: #dbdbdb !important;
  border-right-color: #0000 !important;
}

.b-tabs.is-vertical.is-right > .tabs.is-boxed li.is-active a {
  border-bottom-color: #dbdbdb !important;
  border-left-color: #0000 !important;
  border-right-color: #dbdbdb !important;
}

.b-tabs.is-multiline > .tabs ul {
  flex-wrap: wrap;
  flex-shrink: 1;
}

.tag .has-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 10em;
  overflow: hidden;
}

.tag .delete.is-white, .tag.is-delete.is-white, .tag.has-delete-icon.is-white {
  background: #fff;
}

.tag .delete.is-white:hover, .tag.is-delete.is-white:hover, .tag.has-delete-icon.is-white:hover {
  background-color: #e6e6e6;
  text-decoration: none;
}

.tag .delete.is-black, .tag.is-delete.is-black, .tag.has-delete-icon.is-black {
  background: #0a0a0a;
}

.tag .delete.is-black:hover, .tag.is-delete.is-black:hover, .tag.has-delete-icon.is-black:hover {
  background-color: #000;
  text-decoration: none;
}

.tag .delete.is-light, .tag.is-delete.is-light, .tag.has-delete-icon.is-light {
  background: #f5f5f5;
}

.tag .delete.is-light:hover, .tag.is-delete.is-light:hover, .tag.has-delete-icon.is-light:hover {
  background-color: #dbdbdb;
  text-decoration: none;
}

.tag .delete.is-dark, .tag.is-delete.is-dark, .tag.has-delete-icon.is-dark {
  background: #363636;
}

.tag .delete.is-dark:hover, .tag.is-delete.is-dark:hover, .tag.has-delete-icon.is-dark:hover {
  background-color: #1c1c1c;
  text-decoration: none;
}

.tag .delete.is-primary, .tag.is-delete.is-primary, .tag.has-delete-icon.is-primary {
  background: #7957d5;
}

.tag .delete.is-primary:hover, .tag.is-delete.is-primary:hover, .tag.has-delete-icon.is-primary:hover {
  background-color: #5a32c7;
  text-decoration: none;
}

.tag .delete.is-link, .tag.is-delete.is-link, .tag.has-delete-icon.is-link {
  background: #7957d5;
}

.tag .delete.is-link:hover, .tag.is-delete.is-link:hover, .tag.has-delete-icon.is-link:hover {
  background-color: #5a32c7;
  text-decoration: none;
}

.tag .delete.is-info, .tag.is-delete.is-info, .tag.has-delete-icon.is-info {
  background: #167df0;
}

.tag .delete.is-info:hover, .tag.is-delete.is-info:hover, .tag.has-delete-icon.is-info:hover {
  background-color: #0d64c6;
  text-decoration: none;
}

.tag .delete.is-success, .tag.is-delete.is-success, .tag.has-delete-icon.is-success {
  background: #48c78e;
}

.tag .delete.is-success:hover, .tag.is-delete.is-success:hover, .tag.has-delete-icon.is-success:hover {
  background-color: #34a873;
  text-decoration: none;
}

.tag .delete.is-warning, .tag.is-delete.is-warning, .tag.has-delete-icon.is-warning {
  background: #ffe08a;
}

.tag .delete.is-warning:hover, .tag.is-delete.is-warning:hover, .tag.has-delete-icon.is-warning:hover {
  background-color: #ffd257;
  text-decoration: none;
}

.tag .delete.is-danger, .tag.is-delete.is-danger, .tag.has-delete-icon.is-danger {
  background: #f14668;
}

.tag .delete.is-danger:hover, .tag.is-delete.is-danger:hover, .tag.has-delete-icon.is-danger:hover {
  background-color: #ee1742;
  text-decoration: none;
}

.tag.has-delete-icon {
  padding: 0;
}

.tag.has-delete-icon .icon:first-child:not(:last-child) {
  margin-left: 0;
  margin-right: 0;
}

.tags.inline-tags {
  margin-bottom: 0;
}

.tags.inline-tags:not(:last-child) {
  margin-right: .5rem;
}

.taginput .taginput-container {
  display: flex;
}

.taginput .taginput-container.is-focusable {
  cursor: text;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding: calc(.275em - 1px) 0 0;
}

.taginput .taginput-container:not(.is-focusable) {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: auto;
}

.taginput .taginput-container:not(.is-focusable).is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.taginput .taginput-container:not(.is-focusable).is-medium {
  font-size: 1.25rem;
}

.taginput .taginput-container:not(.is-focusable).is-large {
  font-size: 1.5rem;
}

.taginput .taginput-container > .tag, .taginput .taginput-container > .tags {
  height: calc(2em - 1px);
  margin-bottom: calc(.275em - 1px);
  margin-left: .275rem;
  font-size: .9em;
}

.taginput .taginput-container > .tag .tag, .taginput .taginput-container > .tags .tag {
  height: calc(2em - 1px);
  margin-bottom: 0;
  font-size: .9em;
}

.taginput .taginput-container > .tag .tag.is-delete, .taginput .taginput-container > .tags .tag.is-delete {
  width: calc(2em - 1px);
}

.taginput .taginput-container .autocomplete {
  flex: 1;
  position: static;
}

.taginput .taginput-container .autocomplete input {
  box-shadow: none;
  border: none;
  min-width: 8em;
  height: calc(2em - 1px);
  margin-bottom: calc(.275em - 1px);
  padding-top: 0;
  padding-bottom: 0;
}

.taginput .taginput-container .autocomplete input:focus {
  box-shadow: none !important;
}

.taginput .taginput-container .autocomplete .icon {
  height: calc(2em - 1px);
}

.taginput .taginput-container .autocomplete > .control.is-loading:after {
  top: .375em;
}

.timepicker .dropdown-menu {
  min-width: 0;
}

.timepicker .dropdown, .timepicker .dropdown-trigger {
  width: 100%;
}

.timepicker .dropdown .input[readonly], .timepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

.timepicker .dropdown .input[readonly]:focus, .timepicker .dropdown .input[readonly].is-focused, .timepicker .dropdown .input[readonly]:active, .timepicker .dropdown .input[readonly].is-active, .timepicker .dropdown-trigger .input[readonly]:focus, .timepicker .dropdown-trigger .input[readonly].is-focused, .timepicker .dropdown-trigger .input[readonly]:active, .timepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: 0 0 0 .125em #7957d540;
}

.timepicker .dropdown.is-disabled {
  opacity: 1;
}

.timepicker .dropdown-item, .timepicker .dropdown .dropdown-menu .has-link a, .dropdown .dropdown-menu .has-link .timepicker a {
  font-size: inherit;
  padding: 0;
}

.timepicker .timepicker-footer {
  padding: 0 .5rem;
}

.timepicker .dropdown-content .control {
  font-size: 1.25em;
  margin-right: 0 !important;
}

.timepicker .dropdown-content .control .select {
  margin: 0 .125em;
}

.timepicker .dropdown-content .control .select select {
  border: 0;
  padding-right: calc(.75em - 1px);
  font-weight: 600;
}

.timepicker .dropdown-content .control .select select option:disabled {
  color: #7a7a7ab3;
}

.timepicker .dropdown-content .control .select:after {
  display: none;
}

.timepicker .dropdown-content .control.is-colon {
  font-size: 1.7em;
  line-height: 1.7em;
}

.timepicker .dropdown-content .control.is-colon:last-child {
  padding-right: calc(.75em - 1px);
}

.timepicker.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.timepicker.is-medium {
  font-size: 1.25rem;
}

.timepicker.is-large {
  font-size: 1.5rem;
}

.b-tooltip {
  display: inline-flex;
  position: relative;
}

.b-tooltip.is-top .tooltip-content {
  inset: auto auto calc(100% + 7px) 50%;
  transform: translateX(-50%);
}

.b-tooltip.is-top .tooltip-content:before {
  border-top: 5px solid #7957d5;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  inset: 100% auto auto 50%;
  transform: translateX(-50%);
}

.b-tooltip.is-top.is-white .tooltip-content:before {
  border-top-color: #fff;
}

.b-tooltip.is-top.is-black .tooltip-content:before {
  border-top-color: #0a0a0a;
}

.b-tooltip.is-top.is-light .tooltip-content:before {
  border-top-color: #f5f5f5;
}

.b-tooltip.is-top.is-dark .tooltip-content:before {
  border-top-color: #363636;
}

.b-tooltip.is-top.is-primary .tooltip-content:before {
  border-top-color: #7957d5;
}

.b-tooltip.is-top.is-primary.is-light .tooltip-content:before {
  border-top-color: #f2effb;
}

.b-tooltip.is-top.is-link .tooltip-content:before {
  border-top-color: #7957d5;
}

.b-tooltip.is-top.is-link.is-light .tooltip-content:before {
  border-top-color: #f2effb;
}

.b-tooltip.is-top.is-info .tooltip-content:before {
  border-top-color: #167df0;
}

.b-tooltip.is-top.is-info.is-light .tooltip-content:before {
  border-top-color: #ecf4fe;
}

.b-tooltip.is-top.is-success .tooltip-content:before {
  border-top-color: #48c78e;
}

.b-tooltip.is-top.is-success.is-light .tooltip-content:before {
  border-top-color: #effaf5;
}

.b-tooltip.is-top.is-warning .tooltip-content:before {
  border-top-color: #ffe08a;
}

.b-tooltip.is-top.is-warning.is-light .tooltip-content:before {
  border-top-color: #fffaeb;
}

.b-tooltip.is-top.is-danger .tooltip-content:before {
  border-top-color: #f14668;
}

.b-tooltip.is-top.is-danger.is-light .tooltip-content:before {
  border-top-color: #feecf0;
}

.b-tooltip.is-right .tooltip-content {
  inset: 50% auto auto calc(100% + 7px);
  transform: translateY(-50%);
}

.b-tooltip.is-right .tooltip-content:before {
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 5px solid #7957d5;
  inset: 50% 100% auto auto;
  transform: translateY(-50%);
}

.b-tooltip.is-right.is-white .tooltip-content:before {
  border-right-color: #fff;
}

.b-tooltip.is-right.is-black .tooltip-content:before {
  border-right-color: #0a0a0a;
}

.b-tooltip.is-right.is-light .tooltip-content:before {
  border-right-color: #f5f5f5;
}

.b-tooltip.is-right.is-dark .tooltip-content:before {
  border-right-color: #363636;
}

.b-tooltip.is-right.is-primary .tooltip-content:before {
  border-right-color: #7957d5;
}

.b-tooltip.is-right.is-primary.is-light .tooltip-content:before {
  border-right-color: #f2effb;
}

.b-tooltip.is-right.is-link .tooltip-content:before {
  border-right-color: #7957d5;
}

.b-tooltip.is-right.is-link.is-light .tooltip-content:before {
  border-right-color: #f2effb;
}

.b-tooltip.is-right.is-info .tooltip-content:before {
  border-right-color: #167df0;
}

.b-tooltip.is-right.is-info.is-light .tooltip-content:before {
  border-right-color: #ecf4fe;
}

.b-tooltip.is-right.is-success .tooltip-content:before {
  border-right-color: #48c78e;
}

.b-tooltip.is-right.is-success.is-light .tooltip-content:before {
  border-right-color: #effaf5;
}

.b-tooltip.is-right.is-warning .tooltip-content:before {
  border-right-color: #ffe08a;
}

.b-tooltip.is-right.is-warning.is-light .tooltip-content:before {
  border-right-color: #fffaeb;
}

.b-tooltip.is-right.is-danger .tooltip-content:before {
  border-right-color: #f14668;
}

.b-tooltip.is-right.is-danger.is-light .tooltip-content:before {
  border-right-color: #feecf0;
}

.b-tooltip.is-bottom .tooltip-content {
  inset: calc(100% + 7px) auto auto 50%;
  transform: translateX(-50%);
}

.b-tooltip.is-bottom .tooltip-content:before {
  border-bottom: 5px solid #7957d5;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  inset: auto auto 100% 50%;
  transform: translateX(-50%);
}

.b-tooltip.is-bottom.is-white .tooltip-content:before {
  border-bottom-color: #fff;
}

.b-tooltip.is-bottom.is-black .tooltip-content:before {
  border-bottom-color: #0a0a0a;
}

.b-tooltip.is-bottom.is-light .tooltip-content:before {
  border-bottom-color: #f5f5f5;
}

.b-tooltip.is-bottom.is-dark .tooltip-content:before {
  border-bottom-color: #363636;
}

.b-tooltip.is-bottom.is-primary .tooltip-content:before {
  border-bottom-color: #7957d5;
}

.b-tooltip.is-bottom.is-primary.is-light .tooltip-content:before {
  border-bottom-color: #f2effb;
}

.b-tooltip.is-bottom.is-link .tooltip-content:before {
  border-bottom-color: #7957d5;
}

.b-tooltip.is-bottom.is-link.is-light .tooltip-content:before {
  border-bottom-color: #f2effb;
}

.b-tooltip.is-bottom.is-info .tooltip-content:before {
  border-bottom-color: #167df0;
}

.b-tooltip.is-bottom.is-info.is-light .tooltip-content:before {
  border-bottom-color: #ecf4fe;
}

.b-tooltip.is-bottom.is-success .tooltip-content:before {
  border-bottom-color: #48c78e;
}

.b-tooltip.is-bottom.is-success.is-light .tooltip-content:before {
  border-bottom-color: #effaf5;
}

.b-tooltip.is-bottom.is-warning .tooltip-content:before {
  border-bottom-color: #ffe08a;
}

.b-tooltip.is-bottom.is-warning.is-light .tooltip-content:before {
  border-bottom-color: #fffaeb;
}

.b-tooltip.is-bottom.is-danger .tooltip-content:before {
  border-bottom-color: #f14668;
}

.b-tooltip.is-bottom.is-danger.is-light .tooltip-content:before {
  border-bottom-color: #feecf0;
}

.b-tooltip.is-left .tooltip-content {
  inset: 50% calc(100% + 7px) auto auto;
  transform: translateY(-50%);
}

.b-tooltip.is-left .tooltip-content:before {
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #7957d5;
  inset: 50% auto auto 100%;
  transform: translateY(-50%);
}

.b-tooltip.is-left.is-white .tooltip-content:before {
  border-left-color: #fff;
}

.b-tooltip.is-left.is-black .tooltip-content:before {
  border-left-color: #0a0a0a;
}

.b-tooltip.is-left.is-light .tooltip-content:before {
  border-left-color: #f5f5f5;
}

.b-tooltip.is-left.is-dark .tooltip-content:before {
  border-left-color: #363636;
}

.b-tooltip.is-left.is-primary .tooltip-content:before {
  border-left-color: #7957d5;
}

.b-tooltip.is-left.is-primary.is-light .tooltip-content:before {
  border-left-color: #f2effb;
}

.b-tooltip.is-left.is-link .tooltip-content:before {
  border-left-color: #7957d5;
}

.b-tooltip.is-left.is-link.is-light .tooltip-content:before {
  border-left-color: #f2effb;
}

.b-tooltip.is-left.is-info .tooltip-content:before {
  border-left-color: #167df0;
}

.b-tooltip.is-left.is-info.is-light .tooltip-content:before {
  border-left-color: #ecf4fe;
}

.b-tooltip.is-left.is-success .tooltip-content:before {
  border-left-color: #48c78e;
}

.b-tooltip.is-left.is-success.is-light .tooltip-content:before {
  border-left-color: #effaf5;
}

.b-tooltip.is-left.is-warning .tooltip-content:before {
  border-left-color: #ffe08a;
}

.b-tooltip.is-left.is-warning.is-light .tooltip-content:before {
  border-left-color: #fffaeb;
}

.b-tooltip.is-left.is-danger .tooltip-content:before {
  border-left-color: #f14668;
}

.b-tooltip.is-left.is-danger.is-light .tooltip-content:before {
  border-left-color: #feecf0;
}

.b-tooltip .tooltip-content {
  z-index: 38;
  white-space: nowrap;
  border-radius: 6px;
  width: auto;
  padding: .35rem .75rem;
  font-size: .85rem;
  font-weight: 400;
  position: absolute;
  box-shadow: 0 1px 2px 1px #00010033;
}

.b-tooltip .tooltip-content:before {
  content: "";
  pointer-events: none;
  z-index: 38;
  position: absolute;
}

.b-tooltip .tooltip-trigger {
  width: 100%;
}

.b-tooltip.is-white .tooltip-content {
  color: #0a0a0a;
  background: #fff;
}

.b-tooltip.is-black .tooltip-content {
  color: #fff;
  background: #0a0a0a;
}

.b-tooltip.is-light .tooltip-content {
  color: #000000b3;
  background: #f5f5f5;
}

.b-tooltip.is-dark .tooltip-content {
  color: #fff;
  background: #363636;
}

.b-tooltip.is-primary .tooltip-content {
  color: #fff;
  background: #7957d5;
}

.b-tooltip.is-primary.is-light .tooltip-content {
  color: #552fbc;
  background: #f2effb;
}

.b-tooltip.is-link .tooltip-content {
  color: #fff;
  background: #7957d5;
}

.b-tooltip.is-link.is-light .tooltip-content {
  color: #552fbc;
  background: #f2effb;
}

.b-tooltip.is-info .tooltip-content {
  color: #fff;
  background: #167df0;
}

.b-tooltip.is-info.is-light .tooltip-content {
  color: #0d68ce;
  background: #ecf4fe;
}

.b-tooltip.is-success .tooltip-content {
  color: #fff;
  background: #48c78e;
}

.b-tooltip.is-success.is-light .tooltip-content {
  color: #257953;
  background: #effaf5;
}

.b-tooltip.is-warning .tooltip-content {
  color: #000000b3;
  background: #ffe08a;
}

.b-tooltip.is-warning.is-light .tooltip-content {
  color: #946c00;
  background: #fffaeb;
}

.b-tooltip.is-danger .tooltip-content {
  color: #fff;
  background: #f14668;
}

.b-tooltip.is-danger.is-light .tooltip-content {
  color: #cc0f35;
  background: #feecf0;
}

.b-tooltip.is-always .tooltip-content:before, .b-tooltip.is-always .tooltip-content {
  opacity: 1;
  visibility: visible;
}

.b-tooltip.is-multiline .tooltip-content {
  display: flex-block;
  text-align: center;
  white-space: normal;
}

.b-tooltip.is-multiline.is-small .tooltip-content {
  width: 180px;
}

.b-tooltip.is-multiline.is-medium .tooltip-content {
  width: 240px;
}

.b-tooltip.is-multiline.is-large .tooltip-content {
  width: 300px;
}

.b-tooltip.is-dashed .tooltip-trigger {
  cursor: default;
  border-bottom: 1px dashed #b5b5b5;
}

.b-tooltip.is-square .tooltip-content {
  border-radius: 0;
}

.upload {
  display: inline-flex;
  position: relative;
}

.upload input[type="file"] {
  opacity: 0;
  cursor: pointer;
  z-index: -1;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload .upload-draggable {
  cursor: pointer;
  border: 1px dashed #b5b5b5;
  border-radius: 6px;
  padding: .25em;
}

.upload .upload-draggable.is-disabled {
  opacity: .5;
  cursor: not-allowed;
}

.upload .upload-draggable.is-loading {
  pointer-events: none;
  opacity: .5;
  position: relative;
}

.upload .upload-draggable.is-loading:after {
  content: "";
  border: .25em solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  width: 3em;
  height: 3em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
  top: 0;
  left: calc(50% - 1.5em);
}

.upload .upload-draggable:hover.is-white, .upload .upload-draggable.is-hovered.is-white {
  background: #ffffff0d;
  border-color: #fff;
}

.upload .upload-draggable:hover.is-black, .upload .upload-draggable.is-hovered.is-black {
  background: #0a0a0a0d;
  border-color: #0a0a0a;
}

.upload .upload-draggable:hover.is-light, .upload .upload-draggable.is-hovered.is-light {
  background: #f5f5f50d;
  border-color: #f5f5f5;
}

.upload .upload-draggable:hover.is-dark, .upload .upload-draggable.is-hovered.is-dark {
  background: #3636360d;
  border-color: #363636;
}

.upload .upload-draggable:hover.is-primary, .upload .upload-draggable.is-hovered.is-primary, .upload .upload-draggable:hover.is-link, .upload .upload-draggable.is-hovered.is-link {
  background: #7957d50d;
  border-color: #7957d5;
}

.upload .upload-draggable:hover.is-info, .upload .upload-draggable.is-hovered.is-info {
  background: #167df00d;
  border-color: #167df0;
}

.upload .upload-draggable:hover.is-success, .upload .upload-draggable.is-hovered.is-success {
  background: #48c78e0d;
  border-color: #48c78e;
}

.upload .upload-draggable:hover.is-warning, .upload .upload-draggable.is-hovered.is-warning {
  background: #ffe08a0d;
  border-color: #ffe08a;
}

.upload .upload-draggable:hover.is-danger, .upload .upload-draggable.is-hovered.is-danger {
  background: #f146680d;
  border-color: #f14668;
}

.upload .upload-draggable.is-expanded, .upload.is-expanded {
  width: 100%;
}

.upload.is-rounded {
  border-radius: 9999px;
}

.upload.is-rounded .file-name {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .upload input[type="file"] {
    z-index: auto;
  }

  .upload .upload-draggable + input[type="file"] {
    z-index: -1;
  }
}

body {
  font-family: Noto Sans, Noto Sans JP, Noto Sans HK, sans-serif;
}

#root-grid {
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

#map-unit {
  z-index: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

@media (orientation: landscape) {
  #map-unit {
    flex-direction: row;
  }
}

#map {
  flex: 2 50%;
  position: relative;
}

#side-unit {
  direction: rtl;
  height: 100%;
  overflow: hidden auto;
}

.side-unit-overflow {
  pointer-events: all;
  background-color: #fffc;
}

#popup {
  filter: drop-shadow(0 0 5px #777);
  visibility: hidden;
  background-color: #fff;
  flex-direction: column;
  width: 400px;
  height: 350px;
  display: flex;
}

.popup-marker-above {
  z-index: 2;
  border-bottom: 20px solid #f5f5f5;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: -19px;
  left: 20px;
}

.popup-marker-below {
  z-index: 2;
  border-top: 20px solid #fff;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -19px;
  left: 20px;
}

.article-list {
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
}

.article-list-controls {
  z-index: 1;
  color: #000;
  background-color: #f5f5f5;
  margin: 0;
  padding: 5px;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.query-progress {
  border-radius: 0;
}

.wiki-card-content {
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden auto;
}

#wiki-card-overlay {
  pointer-events: all;
  visibility: hidden;
  border-radius: 0;
  flex: 50%;
  display: none;
  overflow: hidden;
}

.layer-item-title {
  padding-bottom: .5em;
}

.collapse-link {
  padding: .5em;
}

.pagination-link {
  background-color: #fff;
}

.wiki-card-image {
  object-fit: cover;
  min-height: 100px;
  max-height: 100px;
}

.layer-title {
  margin-right: 30px;
  font-weight: bold;
}

.layer-details {
  max-height: 200px;
  overflow: hidden auto;
}

.sidebar {
  pointer-events: all;
  direction: ltr;
  background-color: #fff;
  width: 350px;
  margin: 1em;
}

.h1 {
  font-size: 20px;
}

.h2 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.ol-zoom {
  left: unset;
  right: .5em;
}

.ol-gps {
  left: unset;
  top: 5em;
  right: .5em;
}

.spacer-10 {
  width: 10px;
}

.spacer-40 {
  width: 40px;
}

#loading-indicator {
  z-index: 1;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.sidebar-controls {
  background-color: unset;
  margin: 1em;
}

#wiki-card-overlay > .panel > .panel-heading {
  border-radius: 0;
  padding: 5px 20px;
}

.wiki-card-content > .card {
  box-shadow: none;
}

.flex-shrink {
  flex-shrink: 1;
  min-width: 0;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.wiki-card-title {
  justify-content: left;
  margin-right: 10px;
  font-size: small;
  font-style: italic;
}

@media screen and (width <= 1023px) {
  #side-unit {
    pointer-events: all;
    background-color: #fffc;
    width: 100%;
  }

  .sidebar {
    width: unset;
    margin: 1em .5em;
  }

  .sidebar-controls {
    margin: .5em;
  }

  #loading-indicator {
    font-size: 1.25em;
  }

  .layer-title, .layer-details {
    max-width: none;
  }
}

li.mdi {
  list-style: none;
}

li.mdi:before {
  float: left;
  margin-left: -1.5em;
}

.attr-separator {
  border-bottom: 2px solid #000;
  margin-bottom: .5em;
}

.inline-block {
  display: inline-block;
}

.rtl-enable .rtl {
  direction: rtl;
}

.rtl-enable .rtl li.mdi:before {
  float: right;
  margin-right: -1.5em;
}

.rtl-enable .rtl ul {
  margin-left: unset;
  margin-right: 2em;
}

[v-cloak] {
  display: none;
}
/*# sourceMappingURL=index.37ee66d7.css.map */
