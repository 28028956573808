body {
    font-family: "Noto Sans", "Noto Sans JP", "Noto Sans HK", sans-serif;
}

#root-grid {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
}

#map-unit {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    display: flex;
    flex-direction: column;
}

@media (orientation: landscape) {
   #map-unit {
       flex-direction: row;
   }
}

#map {
   flex-shrink: 1;
   flex-grow: 2;
   flex-basis: 50%;
   position: relative;
}

/* TODO: use sass or something */
#side-unit {
    height: 100%;

    direction: rtl;

    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
}

.side-unit-overflow {
    pointer-events: all;
    background-color: rgba(255,255,255,0.8);
}

#popup {
    width: 400px;
    height: 350px;
    background-color: #ffffff;
    filter: drop-shadow(0px 0px 5px #777);
    display: flex;
    visibility: hidden;
    flex-direction: column;
    /*
    scrollbar-width: none;
    */
}
.popup-marker-above {
    z-index: 2;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid hsl(0, 0%, 96%);
    position: absolute;
    top: -19px;
    left: 20px;
}
.popup-marker-below {
    z-index: 2;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ffffff;
    position: absolute;
    bottom: -19px;
    left: 20px;
}
.article-list {
   margin: 0;
   padding: 0;
   height: 100%;
   flex-direction: column;
}
.article-list-controls {
    position: sticky;
    top: 0;
    z-index: 1;
    margin: 0px;
    padding: 5px;
    background-color: hsl(0, 0%, 96%);
    color: black;
    font-weight: bold;
}
.query-progress {
   border-radius: 0;
}

.wiki-card-content {
   background-color: white;
   flex-shrink: 1;
   flex-grow: 1;
   overflow: hidden;
   overflow-y: auto;
}

#wiki-card-overlay {
   pointer-events: all;

   /*
   position: absolute;
   bottom: 0;
   z-index: -1;
   */
   border-radius: 0;

   /* height: 300px; */
   /* max-height: 50%; */
   /* width: 100%; */
   overflow: hidden;

   flex-grow: 1;
   flex-shrink: 1;
   flex-basis: 50%;

   visibility: hidden;
   display: none;
}
.layer-item-title {
   padding-bottom: 0.5em;
}
.collapse-link {
   padding: 0.5em;
}
/* TODO: fix this! */
.pagination-link {
    background-color: white;
}
.wiki-card-image {
/* TODO: stupid hack */
    max-height: 100px;
    min-height: 100px;
    object-fit: cover;
}
/*
.wiki-item {
    height: 100%;
    width: 600px;
    flex-shrink: 0;
}
.wiki-body {
    padding: 5px;
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
}
.wiki-thumbnail {
    height: 100%;
    object-fit: cover;
}
*/
.layer-title {
    font-weight: bold;
    margin-right: 30px;
}
.layer-details {
    max-height: 200px;
    /* TODO: this is ugly :(, do something else */
    /* max-width: 330px; */
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
}
.sidebar {
    /* max-width: 400px; */
    width: 350px;
    pointer-events: all;
    margin: 1em;
    background-color: white;
    direction: ltr;
}
.h1 {
    font-size: 20px;
}
.h2 {
    font-size: 16px;
    margin: 0px;
    padding: 0px;
}
.ol-zoom {
   left: unset;
   right: 0.5em;
}
.ol-gps {
   left: unset;
   right: 0.5em;
   top: 5em;
}

.spacer-10 {
   width: 10px;
}

.spacer-40 {
   width: 40px;
}

#loading-indicator {
   position: absolute;
   bottom: 8px;
   left: 8px;
   z-index: 1;
}

.sidebar-controls {
    margin: 1em;
    background-color: unset;
}

#wiki-card-overlay > .panel > .panel-heading {
   border-radius: 0;
   padding: 5px 20px;
}

.wiki-card-content > .card {
   box-shadow: none;
}

.flex-shrink {
   min-width: 0;
   flex-shrink: 1;
}

.ellipsis {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: block;
}

.wiki-card-title {
   justify-content: left;
   font-size: small;
   font-style: italic;
   margin-right: 10px;
}

@media screen and (max-width: 1023px) {
   #side-unit {
      pointer-events: all;
      width: 100%;
      background-color: rgba(255,255,255,0.8);
   }

   .sidebar {
       width: unset;
       margin: 1.0em 0.5em;
    }

   .sidebar-controls {
       margin: 0.5em;
    }

    #loading-indicator {
       font-size: 1.25em;
    }

   .layer-title {
       max-width: none;
    }

   .layer-details {
       max-width: none;
    }
}

li.mdi {
   list-style: none;
}

li.mdi::before {
   float: left;
   margin-left: -1.5em;
}

.attr-separator {
   border-bottom: black 2px solid;
   margin-bottom: 0.5em;
}

.inline-block {
   display: inline-block;
}

.unpinned {
   /* box-shadow: 0px 0px 5px 3px hsl(48, 100%, 67%); */
}

.rtl-enable {}

.rtl-enable .rtl {
   direction: rtl;
}

.rtl-enable .rtl li.mdi::before {
   float: right;
   margin-right: -1.5em;
}

.rtl-enable .rtl ul {
   margin-left: unset;
   margin-right: 2em;
}

[v-cloak] { display:none; }
